import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import { BeneficiaireType } from "../../../Types/Entites/BeneficiaireType";
// Action asynchrone pour récupérer tous les bénéficiaires
export const findAllBeneficiairesApi = createAsyncThunk(
  "beneficiaires/findAllBeneficiaires",
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await axios.get("beneficiaire/findAllBeneficiaires");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

// Action asynchrone pour récupérer un bénéficiaire par ID
export const findBeneficiaireByIdApi = createAsyncThunk(
  "beneficiaires/findBeneficiaireById",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `beneficiaire/findBeneficiaireById/${id}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

// Action asynchrone pour supprimer un bénéficiaire
export const deleteBeneficiaireApi = createAsyncThunk(
  "beneficiaires/deleteBeneficiaire",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `beneficiaire/deleteBeneficiaire/${id}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

// Action asynchrone pour mettre à jour un bénéficiaire
export const updateBeneficiaireApi = createAsyncThunk(
  "beneficiaires/updateBeneficiaire",
  async (
    { id, data }: { id: number; data: BeneficiaireType },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(
        `beneficiaire/updateBeneficiaire/${id}`,
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

// Action asynchrone pour créer un nouveau bénéficiaire
export const createBeneficiaireApi = createAsyncThunk(
  "beneficiaires/createBeneficiaire",
  async (data: BeneficiaireType, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "beneficiaire/createBeneficiaire",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const getBeneficiaireBySocieteIdsApi = createAsyncThunk(
  "beneficiaires/getBeneficiaireBySocieteIds",
  async (societeIds: number[], { rejectWithValue }) => {
    try {
      const idsString = societeIds.join(',');
      const response = await axios.get(
        `beneficiaire/getBeneficiaireBySocieteId/${idsString}`
      );

      return response.data; 
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);
interface Beneficiaires {
  BeneficiairesList: BeneficiaireType[];
  BeneficiairesListBySociete: BeneficiaireType[];
}

const initialState: Beneficiaires = {
  BeneficiairesList: [],
  BeneficiairesListBySociete: [],
};
export const beneficiaireSlice = createSlice({
  name: "beneficiaires",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findAllBeneficiairesApi.fulfilled, (state, action) => {
      return {
        ...state,
        BeneficiairesList: action.payload,
      };
    });
    builder.addCase(findBeneficiaireByIdApi.fulfilled, (state, action) => {
      return {
        ...state,
        BeneficiairesList: [action.payload],
      };
    });
    builder.addCase(deleteBeneficiaireApi.fulfilled, (state, action) => {
      state.BeneficiairesList = state.BeneficiairesList.filter(
        (beneficiaire) => beneficiaire.id !== action.meta.arg
      );
      state.BeneficiairesListBySociete =
        state.BeneficiairesListBySociete.filter(
          (beneficiaire) => beneficiaire.id !== action.meta.arg
        );
    });
    builder.addCase(updateBeneficiaireApi.fulfilled, (state, action) => {
      const updatedIndex = state.BeneficiairesList.findIndex(
        (beneficiaire) => beneficiaire.id === action.payload.data.id
      );
      if (updatedIndex !== -1) {
        state.BeneficiairesList[updatedIndex] = action.payload.data;
        state.BeneficiairesListBySociete[updatedIndex] = action.payload.data;
      }
    });
    builder.addCase(createBeneficiaireApi.fulfilled, (state, action) => {
      state.BeneficiairesList.push(action.payload.data);
      state.BeneficiairesListBySociete.push(action.payload.data);
    });
    builder.addCase(
      getBeneficiaireBySocieteIdsApi.fulfilled,
      (state, action) => {
        return {
          ...state,
          BeneficiairesListBySociete: action.payload,
        };
      }
    );
  },
});

export const BeneficiairesList = (state: RootState) =>
  state.beneficiaire.BeneficiairesList;
export const BeneficiairesListBySociete = (state: RootState) =>
  state.beneficiaire.BeneficiairesListBySociete;

export default beneficiaireSlice.reducer;
