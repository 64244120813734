import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  createRecapParamApi,
  deleteRecapParamApi,
  findAllRecapParamApi,
  updateRecapParamApi,
} from "../../_App/Redux/Slices/recapParam/recapParamSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { RootState } from "../../_App/Redux/store";
import { RecapParamType } from "../../_App/Types/Entites/RecapParam";

interface RecapParametrageProps {}

const RecapParametrage: React.FC<RecapParametrageProps> = () => {
  const dispatch = useAppDispatch();
  const recapParamList = useAppSelector(
    (state: RootState) => state.recapParam.recapParamList
  );
  const [editableRows, setEditableRows] = useState<number[]>([]);
  const [editedValues, setEditedValues] = useState<Partial<RecapParamType>>({});

  useEffect(() => {
    dispatch(findAllRecapParamApi(null));
  }, [dispatch]);

  const { register, handleSubmit, setValue, reset } = useForm<RecapParamType>();

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    fieldName: keyof RecapParamType,
    rowIndex: number
  ) => {
    const { value } = e.target;
    setEditedValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  const handleEditClick = (rowIndex: number) => {
    if (!editableRows.includes(rowIndex)) {
      setEditableRows([...editableRows, rowIndex]);
    }
  };

  const handleUpdateRow = async (rowIndex: number) => {
    try {
      const data = editedValues;
      const editedData = {
        ...recapParamList[rowIndex],
        ...data,
      };

      await dispatch(
        updateRecapParamApi({ id: editedData.id || 0, data: editedData })
      );
      setEditableRows((prevEditableRows) =>
        prevEditableRows.filter((row) => row !== rowIndex)
      );
      setEditedValues({});
    } catch (error) {
      console.error("Update failed", error);
    }
  };

  const handleDeleteRow = async (id: number) => {
    try {
      await dispatch(deleteRecapParamApi(id));
    } catch (error) {
      console.error("Delete failed", error);
    }
  };

  const onSubmit: SubmitHandler<RecapParamType> = async (data) => {
    try {
      await dispatch(createRecapParamApi(data));
      reset();
    } catch (error) {
      console.error("Creation failed", error);
    }
  };

  return (
    <div style={{ overflowX: "auto" }}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Table bordered striped hover>
          <thead>
            <tr>
              <th style={{ width: "130px" }}>Code</th>
              <th style={{ width: "3000px" }}>Libelle</th>
              <th style={{ width: "140px" }}>Taux</th>
              <th style={{ width: "200px" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {recapParamList.map((recapParam: RecapParamType, rowIndex) => (
              <tr key={recapParam.id}>
                <td>
                  <Form.Control
                    type="number"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.code || recapParam.code
                        : recapParam.code
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e as ChangeEvent<HTMLInputElement>,
                        "code",
                        rowIndex
                      )
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.libelle || recapParam.libelle
                        : recapParam.libelle
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e as ChangeEvent<HTMLInputElement>,
                        "libelle",
                        rowIndex
                      )
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    step="0.1"
                    value={
                      editableRows.includes(rowIndex) &&
                      editedValues.taux !== undefined
                        ? editedValues.taux
                        : recapParam.taux
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e as ChangeEvent<HTMLInputElement>,
                        "taux",
                        rowIndex
                      )
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>

                <td>
                  <span
                    className={`action-icon ${
                      editableRows.includes(rowIndex) ? "success" : "warning"
                    }`}
                    onClick={() => {
                      if (editableRows.includes(rowIndex)) {
                        handleUpdateRow(rowIndex);
                      } else {
                        handleEditClick(rowIndex);
                      }
                    }}
                    title={
                      editableRows.includes(rowIndex)
                        ? "Enregistrer"
                        : "Modifier"
                    }
                  >
                    <i
                      className={`fas ${
                        editableRows.includes(rowIndex) ? "fa-check" : "fa-edit"
                      }`}
                      style={{
                        color: editableRows.includes(rowIndex)
                          ? "#008000"
                          : "#0000ff",
                        marginRight: "8px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>

                  <span
                    className="action-icon danger"
                    onClick={() =>
                      recapParam.id && handleDeleteRow(recapParam.id)
                    }
                    title="Supprimer"
                  >
                    <i
                      className="fas fa-trash"
                      style={{
                        color: "#ff0000",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
                </td>
              </tr>
            ))}
            <tr>
              <td>
                <Form.Control type="number" {...register("code")} />
              </td>
              <td>
                <Form.Control type="text" {...register("libelle")} />
              </td>
              <td>
                <Form.Control type="number" step="0.1" {...register("taux")} />
              </td>
              <td>
                <Button variant="primary" type="submit">
                  Ajouter
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Form>
    </div>
  );
};

export default RecapParametrage;
