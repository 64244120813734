import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Spinner, Table } from "react-bootstrap";
import {
  annexe6ListByDeclaration,
  findAnnexes6ByDeclarationApi,
} from "../../_App/Redux/Slices/Annexe6/annexe6Slice";
import {
  annexe1ListByDeclaration,
  findAnnexes1ByDeclarationApi,
} from "../../_App/Redux/Slices/annexe1/annexe1Slice";
import {
  annexe2ListByDeclaration,
  findAnnexes2ByDeclarationApi,
} from "../../_App/Redux/Slices/annexe2/annexe2Slice";
import {
  annexe3ListByDeclaration,
  findAnnexes3ByDeclarationApi,
} from "../../_App/Redux/Slices/annexe3/annexe3Slice";
import {
  annexe4ListByDeclaration,
  findAnnexes4ByDeclarationApi,
} from "../../_App/Redux/Slices/annexe4/annexe4Slice";
import {
  annexe5ListByDeclaration,
  findAnnexes5ByDeclarationApi,
} from "../../_App/Redux/Slices/annexe5/annexe5Slice";
import {
  annexe7ListByDeclaration,
  findAnnexes7ByDeclarationApi,
} from "../../_App/Redux/Slices/annexe7/annexe7Slice";
import {
  findRecapsByDeclarationApi,
  recapListByDeclaration,
  updateRecapApi,
} from "../../_App/Redux/Slices/recap/recapSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { Annexe1Type } from "../../_App/Types/Entites/Annexe1Type";
import { Annexe2Type } from "../../_App/Types/Entites/Annexe2Type";
import { Annexe3Type } from "../../_App/Types/Entites/Annexe3Type";
import { Annexe4Type } from "../../_App/Types/Entites/Annexe4Type";
import { Annexe5Type } from "../../_App/Types/Entites/Annexe5Type";
import { Annexe6Type } from "../../_App/Types/Entites/Annexe6Type";
import { Annexe7Type } from "../../_App/Types/Entites/Annexe7Type";
import { RecapType } from "../../_App/Types/Entites/RecapType";
import ImpressionRecap from "./impressionRecap";

interface RecapFormProps {
  declarationId: number;
}

const RecapForm: React.FC<RecapFormProps> = ({ declarationId }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (declarationId) {
      dispatch(findRecapsByDeclarationApi(declarationId));
    }
  }, [dispatch, declarationId]);

  useEffect(() => {
    dispatch(findAnnexes1ByDeclarationApi(declarationId));
    dispatch(findAnnexes2ByDeclarationApi(declarationId));
    dispatch(findAnnexes3ByDeclarationApi(declarationId));
    dispatch(findAnnexes4ByDeclarationApi(declarationId));
    dispatch(findAnnexes5ByDeclarationApi(declarationId));
    dispatch(findAnnexes6ByDeclarationApi(declarationId));
    dispatch(findAnnexes7ByDeclarationApi(declarationId));
  }, [dispatch, declarationId]);

  const annexe1List = useAppSelector(annexe1ListByDeclaration);
  const annexe2List = useAppSelector(annexe2ListByDeclaration);
  const annexe4List = useAppSelector(annexe4ListByDeclaration);
  const annexe7List = useAppSelector(annexe7ListByDeclaration);
  const annexe3List = useAppSelector(annexe3ListByDeclaration);
  const annexe5List = useAppSelector(annexe5ListByDeclaration);
  const annexe6List = useAppSelector(annexe6ListByDeclaration);

  const recapsList = useAppSelector(recapListByDeclaration);

  const sortedRecapsList = [...recapsList].sort((a, b) => a.code - b.code);

  const [editableRows, setEditableRows] = useState<number[]>([]);
  const [editedValuesMap, setEditedValuesMap] = useState<{
    [key: number]: Partial<RecapType>;
  }>({});

  type FormControlChangeEvent = React.ChangeEvent<
    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  >;

  const handleInputChange = (
    e: FormControlChangeEvent,
    fieldName: string,
    rowIndex: number
  ) => {
    let { value } = e.target;

    value = value.replace(/[^\d.]/g, "");

    const parts = value.split(".");
    if (parts.length > 2) {
      value = parts[0] + "." + parts.slice(1).join("");
    }

    const [integerPart, decimalPart] = value.split(".");
    if (decimalPart && decimalPart.length > 3) {
      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
    }

    if (value.startsWith(".")) {
      value = "0" + value;
    }

    const editedValuesForRow = editedValuesMap[rowIndex] || {};

    setEditedValuesMap((prevState) => ({
      ...prevState,
      [rowIndex]: {
        ...editedValuesForRow,
        [fieldName]: value,
      },
    }));
  };

  const handleUpdateRow = async (rowIndex: number) => {
    try {
      const data = editedValuesMap[rowIndex] || {};
      const editedData = {
        ...recapsList[rowIndex],
        ...data,
      };

      const updateAction = await dispatch(
        updateRecapApi({
          id: editedData.id || 0,
          data: editedData,
        })
      );

      if (updateAction.payload) {
        setEditableRows((prevEditableRows) =>
          prevEditableRows.filter((row) => row !== rowIndex)
        );
        setEditedValuesMap((prevState) => {
          const newState = { ...prevState };
          delete newState[rowIndex];
          return newState;
        });
      }
    } catch (error) {
      console.error("Update failed", error);
    }
  };

  // ligne 1 recap

  const calculateAssieteDecemp1 = (): number => {
    let sum = 0;
    annexe1List.forEach((annexe1: Annexe1Type) => {
      if (annexe1.retenuRegCommun !== 0) {
        sum += annexe1.revenuImposable ?? 0;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp1 = (): number => {
    let sum = 0;
    annexe1List.forEach((annexe1: Annexe1Type) => {
      if (annexe1.retenuRegCommun) {
        sum += annexe1.retenuRegCommun;
      }
    });
    return sum;
  };

  // ligne 2 recap

  const calculateAssieteDecemp2 = (): number => {
    let sum = 0;
    annexe1List.forEach((annexe1: Annexe1Type) => {
      if (annexe1.typeIdentifiant === 3) {
        sum += annexe1.totalRevenuBrutImposable ?? 0;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp2 = (): number => {
    let sum = 0;
    annexe1List.forEach((annexe1: Annexe1Type) => {
      if (annexe1.retenuOpereEtrang) {
        sum += annexe1.retenuOpereEtrang;
      }
    });
    return sum;
  };

  // ligne 3 recap

  // const calculateAsietteDecemp3 = (): number => {
  //   let sum = 0;
  //   annexe1List.forEach((annexe1: Annexe1Type) => {
  //     if (annexe1.totalRevenuBrutImposable && annexe1.contributionSociale !== 0) {
  //       sum += annexe1.totalRevenuBrutImposable
  //     }
  //   });
  //   return sum;
  // };

  const calculateContributionSocialeSum = (): number => {
    let sum = 0;
    annexe1List.forEach((annexe1: Annexe1Type) => {
      if (annexe1.contributionSociale) {
        sum += annexe1.contributionSociale;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp3 = (): number => {
    let sum = 0;
    annexe1List.forEach((annexe1: Annexe1Type) => {
      if (annexe1.contributionSociale) {
        sum += annexe1.contributionSociale;
      }
    });
    return sum;
  };

  // ligne 4 recap

  const calculateAssieteDecemp4 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (annexe2.categorie === 2 && annexe2.typeMontantServis !== 1) {
        sum += annexe2.montantServisActNonComm ?? 0;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp4 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (
        annexe2.categorie === 2 &&
        annexe2.montantServisActNonComm !== 0 &&
        annexe2.typeMontantServis !== 1
      ) {
        sum += annexe2.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne 5 recap
  const calculateAssieteDecemp5 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (annexe2.categorie === 1 && annexe2.typeMontantServis !== 1) {
        sum += annexe2.montantServisActNonComm ?? 0;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp5 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (
        annexe2.categorie === 1 &&
        annexe2.typeMontantServis !== 1 &&
        annexe2.montantServisActNonComm !== 0
      ) {
        sum += annexe2.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne 6 recap
  const calculateAssieteDecemp6 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (
        annexe4.categorie === 2 &&
        annexe4.MntHonoraire &&
        annexe4.typeIdentifiant === 4
      ) {
        sum += annexe4.MntHonoraire;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp6 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (
        annexe4.categorie === 2 &&
        annexe4.retenuOper &&
        annexe4.typeIdentifiant === 4 &&
        annexe4.MntHonoraire !== 0
      ) {
        sum += annexe4.retenuOper;
      }
    });
    return sum;
  };

  // ligne 7 recap

  const calculateAssieteDecemp7 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (
        annexe4.categorie === 1 &&
        annexe4.MntHonoraire &&
        annexe4.typeIdentifiant === 4
      ) {
        sum += annexe4.MntHonoraire;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp7 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (
        annexe4.categorie === 1 &&
        annexe4.retenuOper &&
        annexe4.typeIdentifiant === 4 &&
        annexe4.MntHonoraire !== 0
      ) {
        sum += annexe4.retenuOper;
      }
    });
    return sum;
  };

  // ligne 8 recap

  const calculateAssieteDecemp8 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (annexe2.categorie === 2 && annexe2.typeMontantServis === 1) {
        sum += annexe2.montantServisActNonComm ?? 0;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp8 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (
        annexe2.categorie === 2 &&
        annexe2.typeMontantServis === 1 &&
        annexe2.montantServisActNonComm !== 0
      ) {
        sum += annexe2.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne 9 recap

  const calculateAssieteDecemp9 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      sum += annexe2.honorairesServisAuxSoc ?? 0;
    });
    return sum;
  };

  const calculateRetenueDecemp9 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (annexe2.honorairesServisAuxSoc !== 0 && annexe2.retenuOper) {
        sum += annexe2.retenuOper;
      }
    });
    return sum;
  };

  //ligne 10
  const calculateAssieteDecemp10 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      sum += annexe2.renumArtist ?? 0;
    });
    return sum;
  };

  const calculateRetenueDecemp10 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (annexe2.renumArtist !== 0 && annexe2.retenuOper) {
        sum += annexe2.retenuOper;
      }
    });
    return sum;
  };

  //ligne 11 recap

  const calculateAssieteDecemp11 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (annexe2.loyeHotelPayeSoc) {
        sum += annexe2.loyeHotelPayeSoc;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp11 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (annexe2.loyeHotelPayeSoc !== 0) {
        sum += annexe2.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne 12 recap

  const calculateAssieteDecemp12 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (annexe2.typeMontantServis === 6) {
        sum += annexe2.montantServisActNonComm ?? 0;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp12 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (
        annexe2.typeMontantServis === 6 &&
        annexe2.montantServisActNonComm !== 0
      ) {
        sum += annexe2.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne 13 recap

  const calculateAssieteDecemp13 = (): number => {
    let sum = 0;
    annexe3List.forEach((annexe3: Annexe3Type) => {
      if (annexe3.interetDesComptEpargne) {
        sum += annexe3.interetDesComptEpargne;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp13 = (): number => {
    let sum = 0;
    annexe3List.forEach((annexe3: Annexe3Type) => {
      if (annexe3.interetDesComptEpargne !== 0) {
        sum += annexe3.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne 14 recap

  const calculateAssieteDecemp14 = (): number => {
    let sum = 0;
    annexe3List.forEach((annexe3: Annexe3Type) => {
      if (
        annexe3.categorie === 2 &&
        annexe3.interetAutreCapitImmob &&
        annexe3.typeIdentifiant !== 4
      ) {
        sum += annexe3.interetAutreCapitImmob;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp14 = (): number => {
    let sum = 0;
    annexe3List.forEach((annexe3: Annexe3Type) => {
      if (
        annexe3.categorie === 2 &&
        annexe3.interetAutreCapitImmob !== 0 &&
        annexe3.typeIdentifiant !== 4
      ) {
        sum += annexe3.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne 15 recap
  const calculateAssieteDecemp15 = (): number => {
    let sum = 0;
    annexe3List.forEach((annexe3: Annexe3Type) => {
      if (
        annexe3.categorie === 1 &&
        annexe3.interetAutreCapitImmob &&
        annexe3.typeIdentifiant !== 4
      ) {
        sum += annexe3.interetAutreCapitImmob;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp15 = (): number => {
    let sum = 0;
    annexe3List.forEach((annexe3: Annexe3Type) => {
      if (
        annexe3.categorie === 1 &&
        annexe3.interetAutreCapitImmob !== 0 &&
        annexe3.typeIdentifiant !== 4
      ) {
        sum += annexe3.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne 16 recap

  const calculateAssieteDecemp16 = (): number => {
    let sum = 0;
    annexe3List.forEach((annexe3: Annexe3Type) => {
      if (
        annexe3.categorie === 2 &&
        annexe3.interetAutreCapitImmob &&
        annexe3.typeIdentifiant === 4
      ) {
        sum += annexe3.interetAutreCapitImmob;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp16 = (): number => {
    let sum = 0;
    annexe3List.forEach((annexe3: Annexe3Type) => {
      if (
        annexe3.categorie === 2 &&
        annexe3.interetAutreCapitImmob !== 0 &&
        annexe3.typeIdentifiant === 4
      ) {
        sum += annexe3.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne recap 17

  const calculateAssieteDecemp17 = (): number => {
    let sum = 0;
    annexe3List.forEach((annexe3: Annexe3Type) => {
      if (
        annexe3.categorie === 1 &&
        annexe3.interetAutreCapitImmob &&
        annexe3.typeIdentifiant === 4
      ) {
        sum += annexe3.interetAutreCapitImmob;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp17 = (): number => {
    let sum = 0;
    annexe3List.forEach((annexe3: Annexe3Type) => {
      if (
        annexe3.categorie === 1 &&
        annexe3.interetAutreCapitImmob !== 0 &&
        annexe3.typeIdentifiant === 4
      ) {
        sum += annexe3.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne recap 18

  const calculateAssieteDecemp18 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (annexe2.categorie === 2 && annexe2.renumPrimeActionPartSoc) {
        sum += annexe2.renumPrimeActionPartSoc;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp18 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (
        annexe2.categorie === 2 &&
        annexe2.retenuOper &&
        annexe2.renumPrimeActionPartSoc !== 0
      ) {
        sum += annexe2.retenuOper;
      }
    });
    return sum;
  };

  // ligne 19

  const calculateAssieteDecemp19 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (annexe4.MntRevenuMobJetPres) {
        sum += annexe4.MntRevenuMobJetPres;
      }
    });
    return sum;
  };
  const calculateRetenueDecemp19 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (annexe4.retenuOper && annexe4.MntRevenuMobJetPres !== 0) {
        sum += annexe4.retenuOper;
      }
    });
    return sum;
  };

  // ligne  recap 20

  const calculateAssieteDecemp20 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (annexe2.categorie === 2 && annexe2.renumPrimeActionPartSoc) {
        sum += annexe2.renumPrimeActionPartSoc;
      }
    });
    return sum;
  };
  const calculateRetenueDecemp20 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (
        annexe2.categorie === 2 &&
        annexe2.retenuOper &&
        annexe2.renumPrimeActionPartSoc !== 0
      ) {
        sum += annexe2.retenuOper;
      }
    });
    return sum;
  };

  // ligne recap 21

  const calculateAssieteDecemp21 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (annexe2.categorie === 1 && annexe2.renumPrimeActionPartSoc) {
        sum += annexe2.renumPrimeActionPartSoc;
      }
    });
    return sum;
  };
  const calculateRetenueDecemp21 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (
        annexe2.categorie === 1 &&
        annexe2.retenuOper &&
        annexe2.renumPrimeActionPartSoc !== 0
      ) {
        sum += annexe2.retenuOper;
      }
    });
    return sum;
  };

  // ligne 22 recap

  const calculateAssieteDecemp22 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (annexe4.categorie === 2 && annexe4.MntRevenuMobJetPres) {
        sum += annexe4.MntRevenuMobJetPres;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp22 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (annexe4.categorie === 2 && annexe4.MntRevenuMobJetPres !== 0) {
        sum += annexe4.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne 23

  const calculateAssieteDecemp23 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (annexe4.categorie === 1 && annexe4.MntRevenuMobJetPres) {
        sum += annexe4.MntRevenuMobJetPres;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp23 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (annexe4.categorie === 1 && annexe4.MntRevenuMobJetPres !== 0) {
        sum += annexe4.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne 24

  const calculateAssieteDecemp24 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (annexe2.renumOccas) {
        sum += annexe2.renumOccas;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp24 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (annexe2.renumOccas !== 0) {
        sum += annexe2.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne 25
  const calculateAssieteDecemp25 = (): number => {
    let sum = 0;
    annexe3List.forEach((annexe3: Annexe3Type) => {
      if (annexe3.interetPretHorsTun) {
        sum += annexe3.interetPretHorsTun;
      }
    });
    return sum;
  };
  const calculateRetenueDecemp25 = (): number => {
    let sum = 0;
    annexe3List.forEach((annexe3: Annexe3Type) => {
      if (annexe3.interetPretHorsTun !== 0) {
        sum += annexe3.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne 26

  const calculateAsietteDecemp26 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (annexe2.plusValueImmob) {
        sum += annexe2.plusValueImmob;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp26 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (annexe2.plusValueImmob !== 0) {
        sum += annexe2.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne 27

  const calculateAsietteDecemp27 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (annexe4.typeIdentifiant === 4 && annexe4.categorie === 2) {
        sum += annexe4.MontantPluValueImmob ?? 0;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp27 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (
        annexe4.categorie === 2 &&
        annexe4.typeIdentifiant === 4 &&
        annexe4.MontantPluValueImmob !== 0
      ) {
        sum += annexe4.retenuOper ?? 0;
      }
    });
    return sum;
  };

  //ligne 28

  const calculateAsietteDecemp28 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (annexe4.typeIdentifiant === 4 && annexe4.categorie === 1) {
        sum += annexe4.MontantPluValueImmob ?? 0;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp28 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (
        annexe4.categorie === 1 &&
        annexe4.typeIdentifiant === 4 &&
        annexe4.MontantPluValueImmob !== 0
      ) {
        sum += annexe4.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne 29

  const calculateAsietteDecemp29 = (): number => {
    let sum = 0;
    annexe5List.forEach((annexe5: Annexe5Type) => {
      sum += annexe5.mntComprisTvaIs15 ?? 0;
    });
    return sum;
  };

  const calculateRetenueDecemp29 = (): number => {
    let sum = 0;
    annexe5List.forEach((annexe5: Annexe5Type) => {
      if (annexe5.mntComprisTvaIs15 !== 0) {
        sum += (annexe5.retenuTitreTva ?? 0) + (annexe5.retenuOpere ?? 0);
      }
    });
    return sum;
  };

  // ligne 30
  const calculateAsietteDecemp30 = (): number => {
    let sum = 0;
    annexe5List.forEach((annexe5: Annexe5Type) => {
      sum += annexe5.mntComprisTvaIs10 ?? 0;
    });
    return sum;
  };

  const calculateRetenueDecemp30 = (): number => {
    let sum = 0;
    annexe5List.forEach((annexe5: Annexe5Type) => {
      if (annexe5.mntComprisTvaIs10 !== 0) {
        sum += (annexe5.retenuTitreTva ?? 0) + (annexe5.retenuOpere ?? 0);
      }
    });
    return sum;
  };

  // ligne 31

  const calculateAsietteDecemp31 = (): number => {
    let sum = 0;
    annexe5List.forEach((annexe5: Annexe5Type) => {
      sum += annexe5.mntComprisTvaEntrepUnipers ?? 0;
    });
    return sum;
  };

  const calculateRetenueDecemp31 = (): number => {
    let sum = 0;
    annexe5List.forEach((annexe5: Annexe5Type) => {
      if (annexe5.mntComprisTvaEntrepUnipers !== 0) {
        sum += (annexe5.retenuTitreTva ?? 0) + (annexe5.retenuOpere ?? 0);
      }
    });
    return sum;
  };

  // ligne 32

  const calculateAsietteDecemp32 = (): number => {
    let sum = 0;
    annexe5List.forEach((annexe5: Annexe5Type) => {
      sum += annexe5.mntComprisTvaAutreEntrep ?? 0;
    });
    return sum;
  };

  const calculateRetenueDecemp32 = (): number => {
    let sum = 0;
    annexe5List.forEach((annexe5: Annexe5Type) => {
      if (annexe5.mntComprisTvaAutreEntrep !== 0) {
        sum += (annexe5.retenuTitreTva ?? 0) + (annexe5.retenuOpere ?? 0);
      }
    });
    return sum;
  };

  // ligne 33

  const calculateAsietteDecemp33 = (): number => {
    let sum = 0;
    annexe7List.forEach((annexe7: Annexe7Type) => {
      if (annexe7.typeMontantPaye === 24) {
        sum += annexe7.montantPaye ?? 0;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp33 = (): number => {
    let sum = 0;
    annexe7List.forEach((annexe7: Annexe7Type) => {
      if (annexe7.montantPaye !== 0 && annexe7.typeMontantPaye === 24) {
        sum += annexe7.retenuSource ?? 0;
      }
    });
    return sum;
  };

  // ligne 34 a verifier

  const calculateAsietteDecemp34 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (annexe4.RetenuTitreTva100 !== 0) {
        sum +=
          (annexe4.MntHonoraire ?? 0) +
          (annexe4.MntRenumNonResid ?? 0) +
          (annexe4.MntPlusValueCessionAct ?? 0) +
          (annexe4.MontantPluValueImmob ?? 0) +
          (annexe4.MntRevenuMobJetPres ?? 0) +
          (annexe4.renumRevEtatRegimeFiscalPriv ?? 0);
      }
    });
    return sum;
  };

  const calculateRetenueDecemp34 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (annexe4.RetenuTitreTva100) {
        sum += annexe4.RetenuTitreTva100;
      }
    });
    return sum;
  };

  // ligne  recap 35

  const calculateAsietteDecemp35 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (annexe4.typeIdentifiant === 3) {
        sum += annexe4.MntRenumNonResid ?? 0;
      }
    });
    return sum;
  };
  const calculateRetenueDecemp35 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (annexe4.typeIdentifiant === 3 && annexe4.MntRenumNonResid !== 0) {
        sum += annexe4.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne  recap 36

  const calculateAsietteDecemp36 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (annexe4.TypeMntServActNonComm === 7 && annexe4.MntHonoraire) {
        sum += annexe4.MntHonoraire;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp36 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (annexe4.TypeMntServActNonComm === 7) {
        sum += annexe4.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne 37

  const calculateAsietteDecemp37 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (annexe4.TypeMntServActNonComm === 8 && annexe4.MntHonoraire) {
        sum += annexe4.MntHonoraire;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp37 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (annexe4.TypeMntServActNonComm === 8) {
        sum += annexe4.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne 38 a vérifier ( type ristourne )
  const calculateAsietteDecemp38 = (): number => {
    let sum = 0;
    annexe6List.forEach((annexe6: Annexe6Type) => {
      if (annexe6.categorie === 2 && annexe6.avancePpForfaitaire) {
        sum += annexe6.avancePpForfaitaire;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp38 = (): number => {
    let sum = 0;
    annexe6List.forEach((annexe6: Annexe6Type) => {
      if (annexe6.categorie === 2 && annexe6.avancePpForfaitaire !== 0) {
        sum += (annexe6.rasProfitDist ?? 0) + (annexe6.mntPercus ?? 0);
      }
    });
    return sum;
  };

  // ligne 39
  const calculateAsietteDecemp39 = (): number => {
    let sum = 0;
    annexe6List.forEach((annexe6: Annexe6Type) => {
      if (annexe6.mntAvanceFabAlcool) {
        sum += annexe6.mntAvanceFabAlcool;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp39 = (): number => {
    let sum = 0;
    annexe6List.forEach((annexe6: Annexe6Type) => {
      if (annexe6.mntAvanceFabAlcool !== 0) {
        sum += (annexe6.rasProfitDist ?? 0) + (annexe6.mntPercus ?? 0);
      }
    });
    return sum;
  };

  // ligne 40
  const calculateAsietteDecemp40 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (
        annexe4.categorie === 2 &&
        annexe4.typeIdentifiant === 4 &&
        annexe4.MntPlusValueCessionAct
      ) {
        sum += annexe4.MntPlusValueCessionAct;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp40 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (
        annexe4.categorie === 2 &&
        annexe4.typeIdentifiant === 4 &&
        annexe4.MntPlusValueCessionAct !== 0
      ) {
        sum += annexe4.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne 41
  const calculateAsietteDecemp41 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (
        annexe4.categorie === 1 &&
        annexe4.typeIdentifiant === 4 &&
        annexe4.MntPlusValueCessionAct
      ) {
        sum += annexe4.MntPlusValueCessionAct;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp41 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (
        annexe4.categorie === 1 &&
        annexe4.typeIdentifiant === 4 &&
        annexe4.MntPlusValueCessionAct !== 0
      ) {
        sum += annexe4.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne 42 a verifier

  const calculateAsietteDecemp42 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (
        annexe4.categorie === 2 &&
        annexe4.typeIdentifiant === 4 &&
        annexe4.TypeMntServActNonComm === 0 &&
        annexe4.MntRevenuMobJetPres
      ) {
        sum += annexe4.MntRevenuMobJetPres;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp42 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (
        annexe4.categorie === 2 &&
        annexe4.typeIdentifiant === 4 &&
        annexe4.MntRevenuMobJetPres !== 0
      ) {
        sum += annexe4.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne 43
  const calculateAsietteDecemp43 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (
        annexe4.categorie === 2 &&
        annexe4.typeIdentifiant === 4 &&
        annexe4.TypeMntServActNonComm === 0 &&
        annexe4.MntRevenuMobJetPres
      ) {
        sum += annexe4.MntRevenuMobJetPres;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp43 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (
        annexe4.categorie === 2 &&
        annexe4.typeIdentifiant === 4 &&
        annexe4.MntRevenuMobJetPres !== 0
      ) {
        sum += annexe4.retenuOper ?? 0;
      }
    });
    return sum;
  };
  // ligne 44

  const calculateAsietteDecemp44 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (annexe4.renumRevEtatRegimeFiscalPriv) {
        sum += annexe4.renumRevEtatRegimeFiscalPriv;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp44 = (): number => {
    let sum = 0;
    annexe4List.forEach((annexe4: Annexe4Type) => {
      if (annexe4.renumRevEtatRegimeFiscalPriv !== 0) {
        sum += annexe4.retenuOper ?? 0;
      }
    });
    return sum;
  };

  // ligne 45

  const calculateAsietteDecemp45 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (annexe2.typeMontantServis === 7 && annexe2.categorie === 2) {
        sum += annexe2.montantServisActNonComm || 0;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp45 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (
        annexe2.typeMontantServis === 7 &&
        annexe2.categorie === 2 &&
        annexe2.montantServisActNonComm !== 0
      ) {
        sum += (annexe2.retenuSurTva || 0) + (annexe2.retenuOper || 0);
      }
    });
    return sum;
  };
  // ligne 46

  const calculateAsietteDecemp46 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (annexe2.typeMontantServis === 7 && annexe2.categorie === 1) {
        sum += annexe2.montantServisActNonComm || 0;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp46 = (): number => {
    let sum = 0;
    annexe2List.forEach((annexe2: Annexe2Type) => {
      if (
        annexe2.typeMontantServis === 7 &&
        annexe2.categorie === 1 &&
        annexe2.montantServisActNonComm !== 0
      ) {
        sum += (annexe2.retenuSurTva || 0) + (annexe2.retenuOper || 0);
      }
    });
    return sum;
  };

  //ligne47
  const calculateAsietteDecemp47 = (): number => {
    let sum = 0;
    annexe6List.forEach((annexe6: Annexe6Type) => {
      if (annexe6.mntRevenuJeux) {
        sum += annexe6.mntRevenuJeux;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp47 = (): number => {
    let sum = 0;
    annexe6List.forEach((annexe6: Annexe6Type) => {
      if (annexe6.mntRevenuJeux !== 0) {
        sum += annexe6.rasRevenuJeux ?? 0;
      }
    });
    return sum;
  };

  // ligne 48

  const calculateAsietteDecemp48 = (): number => {
    let sum = 0;
    annexe6List.forEach((annexe6: Annexe6Type) => {
      if (annexe6.mntVenteProfitDist) {
        sum += annexe6.mntVenteProfitDist;
      }
    });
    return sum;
  };

  const calculateRetenueDecemp48 = (): number => {
    let sum = 0;
    annexe6List.forEach((annexe6: Annexe6Type) => {
      if (annexe6.mntVenteProfitDist !== 0) {
        sum += annexe6.rasProfitDist ?? 0;
      }
    });
    return sum;
  };
  // totaux
  const calculateTotalAssiete = (): number => {
    let totalAssiete = 0;
    recapsList.forEach((recap) => {
      if (recap.code !== 49 && recap.assiete) {
        totalAssiete += recap.assiete;
      }
    });
    return totalAssiete;
  };

  const calculateTotalRetenue = (): number => {
    let totalRetenue = 0;
    recapsList.forEach((recap) => {
      if (recap.code !== 49 && recap.retenue) {
        totalRetenue += recap.retenue;
      }
    });
    return totalRetenue;
  };

  const handleEditClick = (rowIndex: number) => {
    let newEditedValues: Partial<RecapType> = {};
    let sum = 0;
    let newRetenue = 0;
    if (recapsList[rowIndex]?.code === 1) {
      sum = calculateAssieteDecemp1();
    } else if (recapsList[rowIndex]?.code === 2) {
      sum = calculateAssieteDecemp2();
    } else if (recapsList[rowIndex]?.code === 3) {
      sum = calculateContributionSocialeSum();
    } else if (recapsList[rowIndex]?.code === 4) {
      sum = calculateAssieteDecemp4();
    } else if (recapsList[rowIndex]?.code === 5) {
      sum = calculateAssieteDecemp5();
    } else if (recapsList[rowIndex]?.code === 6) {
      sum = calculateAssieteDecemp6();
    } else if (recapsList[rowIndex]?.code === 7) {
      sum = calculateAssieteDecemp7();
    } else if (recapsList[rowIndex]?.code === 8) {
      sum = calculateAssieteDecemp8();
    } else if (recapsList[rowIndex]?.code === 9) {
      sum = calculateAssieteDecemp9();
    } else if (recapsList[rowIndex]?.code === 10) {
      sum = calculateAssieteDecemp10();
    } else if (recapsList[rowIndex]?.code === 11) {
      sum = calculateAssieteDecemp11();
    } else if (recapsList[rowIndex]?.code === 12) {
      sum = calculateAssieteDecemp12();
    } else if (recapsList[rowIndex]?.code === 13) {
      sum = calculateAssieteDecemp13();
    } else if (recapsList[rowIndex]?.code === 14) {
      sum = calculateAssieteDecemp14();
    } else if (recapsList[rowIndex]?.code === 15) {
      sum = calculateAssieteDecemp15();
    } else if (recapsList[rowIndex]?.code === 16) {
      sum = calculateAssieteDecemp16();
    } else if (recapsList[rowIndex]?.code === 17) {
      sum = calculateAssieteDecemp17();
    } else if (recapsList[rowIndex]?.code === 18) {
      sum = calculateAssieteDecemp18();
    } else if (recapsList[rowIndex]?.code === 19) {
      sum = calculateAssieteDecemp19();
    } else if (recapsList[rowIndex]?.code === 20) {
      sum = calculateAssieteDecemp20();
    } else if (recapsList[rowIndex]?.code === 21) {
      sum = calculateAssieteDecemp21();
    } else if (recapsList[rowIndex]?.code === 22) {
      sum = calculateAssieteDecemp22();
    } else if (recapsList[rowIndex]?.code === 23) {
      sum = calculateAssieteDecemp23();
    } else if (recapsList[rowIndex]?.code === 24) {
      sum = calculateAssieteDecemp24();
    } else if (recapsList[rowIndex]?.code === 25) {
      sum = calculateAssieteDecemp25();
    } else if (recapsList[rowIndex]?.code === 26) {
      sum = calculateAsietteDecemp26();
    } else if (recapsList[rowIndex]?.code === 27) {
      sum = calculateAsietteDecemp27();
    } else if (recapsList[rowIndex]?.code === 28) {
      sum = calculateAsietteDecemp28();
    } else if (recapsList[rowIndex]?.code === 29) {
      sum = calculateAsietteDecemp29();
    } else if (recapsList[rowIndex]?.code === 30) {
      sum = calculateAsietteDecemp30();
    } else if (recapsList[rowIndex]?.code === 31) {
      sum = calculateAsietteDecemp31();
    } else if (recapsList[rowIndex]?.code === 32) {
      sum = calculateAsietteDecemp32();
    } else if (recapsList[rowIndex]?.code === 33) {
      sum = calculateAsietteDecemp33();
    } else if (recapsList[rowIndex]?.code === 34) {
      sum = calculateAsietteDecemp34();
    } else if (recapsList[rowIndex]?.code === 35) {
      sum = calculateAsietteDecemp35();
    } else if (recapsList[rowIndex]?.code === 36) {
      sum = calculateAsietteDecemp36();
    } else if (recapsList[rowIndex]?.code === 37) {
      sum = calculateAsietteDecemp37();
    } else if (recapsList[rowIndex]?.code === 38) {
      sum = calculateAsietteDecemp38();
    } else if (recapsList[rowIndex]?.code === 39) {
      sum = calculateAsietteDecemp39();
    } else if (recapsList[rowIndex]?.code === 40) {
      sum = calculateAsietteDecemp40();
    } else if (recapsList[rowIndex]?.code === 41) {
      sum = calculateAsietteDecemp41();
    } else if (recapsList[rowIndex]?.code === 42) {
      sum = calculateAsietteDecemp42();
    } else if (recapsList[rowIndex]?.code === 43) {
      sum = calculateAsietteDecemp43();
    } else if (recapsList[rowIndex]?.code === 44) {
      sum = calculateAsietteDecemp44();
    } else if (recapsList[rowIndex]?.code === 45) {
      sum = calculateAsietteDecemp45();
    } else if (recapsList[rowIndex]?.code === 46) {
      sum = calculateAsietteDecemp46();
    } else if (recapsList[rowIndex]?.code === 47) {
      sum = calculateAsietteDecemp47();
    } else if (recapsList[rowIndex]?.code === 48) {
      sum = calculateAsietteDecemp48();
    } else if (recapsList[rowIndex]?.code === 49) {
      sum = calculateTotalAssiete();
      newRetenue = calculateTotalRetenue();
    }

    if (sum !== 0) {
      newEditedValues = { ...editedValuesMap[rowIndex], assiete: sum };
    } else {
      newEditedValues = { ...editedValuesMap[rowIndex] };
    }
    const newAssiete = parseFloat(String(newEditedValues.assiete || "0"));
    const taux = parseFloat(recapsList[rowIndex]?.taux?.toString() || "0");
    if (!isNaN(newAssiete) && !isNaN(taux)) {
      newRetenue = (newAssiete * taux) / 100;
    }

    newEditedValues.retenue = parseFloat(newRetenue.toFixed(3));

    if (editableRows.includes(rowIndex)) {
      handleUpdateRow(rowIndex);
    }

    setEditedValuesMap((prevState) => ({
      ...prevState,
      [rowIndex]: newEditedValues,
    }));

    setEditableRows((prevEditableRows) =>
      prevEditableRows.includes(rowIndex)
        ? prevEditableRows.filter((row) => row !== rowIndex)
        : [...prevEditableRows, rowIndex]
    );
  };

  const [allRowsEditable, setAllRowsEditable] = useState(false);
  const handleEditClickAll = () => {
    const updatedEditedValuesMap: { [key: number]: Partial<RecapType> } = {};

    recapsList.forEach((_, rowIndex) => {
      let sum = 0;
      let newRetenue = 0;

      const code = recapsList[rowIndex]?.code;

      if (code === 1) {
        sum = calculateAssieteDecemp1();
        newRetenue = calculateRetenueDecemp1();
      } else if (code === 2) {
        sum = calculateAssieteDecemp2();
        newRetenue = calculateRetenueDecemp2();
      } else if (code === 3) {
        sum = calculateContributionSocialeSum();
      } else if (code === 4) {
        sum = calculateAssieteDecemp4();
        newRetenue = calculateRetenueDecemp4();
      } else if (code === 5) {
        sum = calculateAssieteDecemp5();
        newRetenue = calculateRetenueDecemp5();
      } else if (code === 6) {
        sum = calculateAssieteDecemp6();
        newRetenue = calculateRetenueDecemp6();
      } else if (code === 7) {
        sum = calculateAssieteDecemp7();
        newRetenue = calculateRetenueDecemp7();
      } else if (code === 8) {
        sum = calculateAssieteDecemp8();
        newRetenue = calculateRetenueDecemp8();
      } else if (code === 9) {
        sum = calculateAssieteDecemp9();
        newRetenue = calculateRetenueDecemp9();
      } else if (code == 10) {
        sum = calculateAssieteDecemp10();
        newRetenue = calculateRetenueDecemp10();
      } else if (code === 11) {
        sum = calculateAssieteDecemp11();
        newRetenue = calculateRetenueDecemp11();
      } else if (code === 12) {
        sum = calculateAssieteDecemp12();
        newRetenue = calculateRetenueDecemp12();
      } else if (code === 13) {
        sum = calculateAssieteDecemp13();
        newRetenue = calculateRetenueDecemp13();
      } else if (code === 14) {
        sum = calculateAssieteDecemp14();
        newRetenue = calculateRetenueDecemp14();
      } else if (code === 15) {
        sum = calculateAssieteDecemp15();
        newRetenue = calculateRetenueDecemp15();
      } else if (code === 16) {
        sum = calculateAssieteDecemp16();
        newRetenue = calculateRetenueDecemp16();
      } else if (code === 17) {
        sum = calculateAssieteDecemp17();
        newRetenue = calculateRetenueDecemp17();
      } else if (code === 18) {
        sum = calculateAssieteDecemp18();
        newRetenue = calculateRetenueDecemp18();
      } else if (code === 19) {
        sum = calculateAssieteDecemp19();
        newRetenue = calculateRetenueDecemp19();
      } else if (code === 20) {
        sum = calculateAssieteDecemp20();
        newRetenue = calculateRetenueDecemp20();
      } else if (code === 21) {
        sum = calculateAssieteDecemp21();
        newRetenue = calculateRetenueDecemp21();
      } else if (code === 22) {
        sum = calculateAssieteDecemp22();
        newRetenue = calculateRetenueDecemp22();
      } else if (code === 23) {
        sum = calculateAssieteDecemp23();
        newRetenue = calculateRetenueDecemp23();
      } else if (code === 24) {
        sum = calculateAssieteDecemp24();
        newRetenue = calculateRetenueDecemp24();
      } else if (code === 25) {
        sum = calculateAssieteDecemp25();
        newRetenue = calculateRetenueDecemp25();
      } else if (code === 26) {
        sum = calculateAsietteDecemp26();
        newRetenue = calculateRetenueDecemp26();
      } else if (code === 27) {
        sum = calculateAsietteDecemp27();
        newRetenue = calculateRetenueDecemp27();
      } else if (code === 28) {
        sum = calculateAsietteDecemp28();
        newRetenue = calculateRetenueDecemp28();
      } else if (code === 29) {
        sum = calculateAsietteDecemp29();
        newRetenue = calculateRetenueDecemp29();
      } else if (code === 30) {
        sum = calculateAsietteDecemp30();
        newRetenue = calculateRetenueDecemp30();
      } else if (code === 31) {
        sum = calculateAsietteDecemp31();
        newRetenue = calculateRetenueDecemp31();
      } else if (code === 32) {
        sum = calculateAsietteDecemp32();
        newRetenue = calculateRetenueDecemp32();
      } else if (code === 33) {
        sum = calculateAsietteDecemp33();
        newRetenue = calculateRetenueDecemp33();
      } else if (code === 34) {
        sum = calculateAsietteDecemp34();
        newRetenue = calculateRetenueDecemp34();
      } else if (code === 35) {
        sum = calculateAsietteDecemp35();
        newRetenue = calculateRetenueDecemp35();
      } else if (code === 36) {
        sum = calculateAsietteDecemp36();
        newRetenue = calculateRetenueDecemp36();
      } else if (code === 37) {
        sum = calculateAsietteDecemp37();
        newRetenue = calculateRetenueDecemp37();
      } else if (code === 38) {
        sum = calculateAsietteDecemp38();
        newRetenue = calculateRetenueDecemp38();
      } else if (code === 39) {
        sum = calculateAsietteDecemp39();
        newRetenue = calculateRetenueDecemp39();
      } else if (code === 40) {
        sum = calculateAsietteDecemp40();
        newRetenue = calculateRetenueDecemp40();
      } else if (code === 41) {
        sum = calculateAsietteDecemp41();
        newRetenue = calculateRetenueDecemp41();
      } else if (code === 42) {
        sum = calculateAsietteDecemp42();
        newRetenue = calculateRetenueDecemp42();
      } else if (code === 43) {
        sum = calculateAsietteDecemp43();
        newRetenue = calculateRetenueDecemp43();
      } else if (code === 44) {
        sum = calculateAsietteDecemp44();
        newRetenue = calculateRetenueDecemp44();
      } else if (code === 45) {
        sum = calculateAsietteDecemp45();
        newRetenue = calculateRetenueDecemp45();
      } else if (code === 46) {
        sum = calculateAsietteDecemp46();
        newRetenue = calculateRetenueDecemp46();
      } else if (code === 47) {
        sum = calculateAsietteDecemp47();
        newRetenue = calculateRetenueDecemp47();
      } else if (code === 48) {
        sum = calculateAsietteDecemp48();
        newRetenue = calculateRetenueDecemp48();
      } else if (code === 49) {
        sum = calculateTotalAssiete();
        newRetenue = calculateTotalRetenue();
      }

      const newEditedValues: Partial<RecapType> =
        sum !== 0
          ? { ...editedValuesMap[rowIndex], assiete: sum, retenue: newRetenue }
          : { ...editedValuesMap[rowIndex] };

      updatedEditedValuesMap[rowIndex] = newEditedValues;
    });

    setEditedValuesMap(updatedEditedValuesMap);
    setEditableRows(Array.from(Array(recapsList.length).keys()));
    setAllRowsEditable(true);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateAllRow = async () => {
    setIsLoading(true);

    try {
      for (const rowIndex of Object.keys(editedValuesMap)) {
        await handleUpdateRow(Number(rowIndex));
      }
      setAllRowsEditable(false);
    } catch (error) {
      console.error("Update failed", error);
    } finally {
      setIsLoading(false);
    }
  };

  const editAllButtonLabel = allRowsEditable ? "Enregistrer" : "Générer";
  const editAllButtonHandler = allRowsEditable
    ? handleUpdateAllRow
    : handleEditClickAll;

  const [editableRowsUpdate, setEditableRowsUpdate] = useState<number[]>([]);

  const handleUpdateButtonClick = (rowIndex: number) => {
    setEditableRows((prevEditableRows) => {
      return [...prevEditableRows, rowIndex];
    });
  };
  const printRef = useRef<HTMLDivElement>(null);

  const handlePrint = () => {
    const printContents = printRef.current;

    if (printContents) {
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents.innerHTML;
      window.print();

      document.body.innerHTML = originalContents;

      window.location.reload();
    }
  };

  // recherche

  const [searchQuery, setSearchQuery] = useState("");

  const filteredRecapsList = sortedRecapsList.filter((recap) => {
    const lowerCaseQuery = searchQuery.toLowerCase();

    return (
      recap.code?.toString().includes(lowerCaseQuery) ||
      recap.libelle?.toLowerCase().includes(lowerCaseQuery) ||
      recap.assiete?.toString().includes(lowerCaseQuery) ||
      recap.taux?.toString().includes(lowerCaseQuery) ||
      recap.retenue?.toString().includes(lowerCaseQuery)
    );
  });

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "20px",
          marginBottom: "10px",
          gap: "10px",
        }}
      >
        <Button onClick={handlePrint} variant="info">
          Imprimer
        </Button>

        <Button onClick={editAllButtonHandler}>
          {editAllButtonLabel}
          {isLoading && (
            <Spinner
              animation="border"
              size="sm"
              style={{ marginLeft: "5px", marginRight: "5px" }}
            />
          )}
        </Button>

        <div ref={printRef} style={{ display: "none" }}>
          <ImpressionRecap recapsList={sortedRecapsList} />
        </div>
      </div>
      <span>
        <Form.Control
          type="text"
          placeholder="Rechercher..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            width: "200px",

            height: "40px",
            textAlign: "center",
          }}
        />
      </span>

      <div
      // style={{
      //   overflowY: "scroll",
      //   height: "700px",
      //   scrollbarWidth: "thin",
      //   scrollbarColor: "rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1)",
      // }}
      >
        <Form>
          <Table bordered striped hover>
            <thead>
              <tr>
                <th style={{ width: "78px", padding: "4px", margin: "0" }}>
                  <div
                    style={{
                      width: "30px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Code
                  </div>
                </th>

                <th style={{ width: "1750px", padding: "4px", margin: "0" }}>
                  <div
                    style={{
                      width: "30px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Libelle
                  </div>
                </th>
                <th style={{ width: "170px", padding: "4px", margin: "0" }}>
                  <div
                    style={{
                      width: "30px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Assiette
                  </div>
                </th>
                <th style={{ width: "120px", padding: "4px", margin: "0" }}>
                  <div
                    style={{
                      width: "30px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Taux
                  </div>
                </th>
                <th style={{ width: "170px", padding: "4px", margin: "0" }}>
                  <div
                    style={{
                      width: "30px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Retenue opéree
                  </div>
                </th>
                <th style={{ width: "130px", padding: "4px", margin: "0" }}>
                  <div
                    style={{
                      width: "30px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Action
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredRecapsList.map((recap: RecapType, rowIndex) => (
                <tr key={recap.id}>
                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{ fontSize: "1em", height: "33px" }}
                      type="number"
                      name={`recapsList[${rowIndex}].code`}
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValuesMap[rowIndex]?.code !== undefined
                            ? editedValuesMap[rowIndex]?.code
                            : recap.code
                          : recap.code
                      }
                      onChange={(e) => handleInputChange(e, "code", rowIndex)}
                      disabled
                    />
                  </td>

                  <td style={{ padding: "4px", margin: "0" }}>
                    <div style={{ width: "100%", wordWrap: "break-word" }}>
                      <Form.Control
                        style={{ fontSize: "1em", height: "33px" }}
                        type="text"
                        name="libelle"
                        value={
                          editableRows.includes(rowIndex)
                            ? editedValuesMap[rowIndex]?.libelle !== undefined
                              ? editedValuesMap[rowIndex]?.libelle
                              : recap.libelle
                            : recap.libelle
                        }
                        onChange={(e) =>
                          handleInputChange(e, "libelle", rowIndex)
                        }
                        disabled
                      />
                    </div>
                  </td>

                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{ fontSize: "1em", height: "33px" }}
                      type="text"
                      name={`recapsList[${rowIndex}].assiete`}
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValuesMap[rowIndex]?.assiete !== undefined
                            ? editedValuesMap[rowIndex]?.assiete
                            : recap.assiete?.toFixed(3)
                          : recap.assiete?.toFixed(3)
                      }
                      onChange={(e) =>
                        handleInputChange(e, "assiete", rowIndex)
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </td>
                  <td style={{ padding: "4px", margin: "0" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Form.Control
                        style={{ fontSize: "1em", height: "33px" }}
                        type="number"
                        step="0.1"
                        name={`recapsList[${rowIndex}].taux`}
                        value={
                          editableRows.includes(rowIndex)
                            ? editedValuesMap[rowIndex]?.taux !== undefined
                              ? editedValuesMap[rowIndex]?.taux
                              : recap.taux
                            : recap.taux
                        }
                        onChange={(e) => handleInputChange(e, "taux", rowIndex)}
                        disabled
                      />
                      <span style={{ marginLeft: "5px" }}>%</span>
                    </div>
                  </td>

                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{ fontSize: "1em", height: "33px" }}
                      type="text"
                      name={`recapsList[${rowIndex}].retenue`}
                      value={
                        editableRows.includes(rowIndex) &&
                        editedValuesMap[rowIndex]?.retenue !== undefined
                          ? editedValuesMap[rowIndex]?.retenue!
                          : recap.retenue?.toFixed(3) !== undefined
                          ? recap.retenue?.toFixed(3)
                          : ""
                      }
                      onChange={(e) =>
                        handleInputChange(e, "retenue", rowIndex)
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </td>

                  <td style={{ padding: "4px", margin: "0" }}>
                    <span
                      className={`action-icon`}
                      onClick={() => handleUpdateButtonClick(rowIndex)}
                      title="Modifier"
                    >
                      <i
                        className={`fas fa-edit`}
                        style={{
                          color: "#0000ff",
                          marginRight: "8px",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      ></i>
                    </span>
                    <span
                      className={`action-icon ${
                        editableRows.includes(rowIndex) ? "success" : "warning"
                      }`}
                      onClick={() => handleEditClick(rowIndex)}
                      title={
                        editableRows.includes(rowIndex)
                          ? "Enregistrer"
                          : "Calculer selon le taux"
                      }
                    >
                      <i
                        className={`fas ${
                          editableRows.includes(rowIndex)
                            ? " fas fa-check"
                            : "fas fa-calculator"
                        }`}
                        style={{
                          color: editableRows.includes(rowIndex)
                            ? "#008000"
                            : "#ffa500",
                          marginLeft: "8px",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      ></i>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Form>
      </div>
    </div>
  );
};

export default RecapForm;
