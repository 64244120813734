import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  CertificatsBySocieteAndExerciceAndMonth,
  getEnteteCertificatBySocieteAndExerciceAndMonthApi,
} from "../../_App/Redux/Slices/EnteteCertificat/EnteteCertificatSlice";
import { getLigneCertificatByNumCertApi } from "../../_App/Redux/Slices/LigneCertificat/LigneCertificatSlice";
import { EnteteCertificatType } from "../../_App/Types/Entites/EnteteCertificatType";
import { LigneCertificatType } from "../../_App/Types/Entites/LigneCertificatType";
import { findAllPaysDevisesApi, selectPaysDevisesList } from "../../_App/Redux/Slices/paysDevise/paysDeviseSlice";

interface ExportXmlProps {
  selectedMonth: string;
  declarantId: number;
  exerciceId: number;
  onExportXml: () => void;
}

const ExportXml: React.FC<ExportXmlProps> = ({
  selectedMonth,
  declarantId,
  exerciceId,
  onExportXml,
}) => {
  const dispatch = useAppDispatch();
  const certificatsList = useAppSelector(CertificatsBySocieteAndExerciceAndMonth);
  const paysDevisesList = useAppSelector(selectPaysDevisesList);


  useEffect(() => {
    dispatch(findAllPaysDevisesApi());
    dispatch(
      getEnteteCertificatBySocieteAndExerciceAndMonthApi({
        societeId: declarantId,
        exerciceId: exerciceId,
        month: parseInt(selectedMonth),
      })
    );
  }, [selectedMonth, declarantId, exerciceId, dispatch]);

  const handleExportXml = async () => {
    const lignesMap = new Map<string, LigneCertificatType[]>();

    await Promise.all(
      certificatsList.map(async (certificat) => {
        const lignesResponse = await dispatch(getLigneCertificatByNumCertApi(certificat.numCertificat));
        const lignesData = lignesResponse.payload as LigneCertificatType[];
        lignesMap.set(certificat.numCertificat, lignesData);
      })
    );

    const certificatsWithLines = certificatsList.map((certificat) => ({
      ...certificat,
      lignes: lignesMap.get(certificat.numCertificat) || [],
    }));

    const xmlContent = generateXmlFile(certificatsWithLines , selectedMonth);
    downloadXmlFile(xmlContent);
    onExportXml();
  };

  const generateXmlFile = (listCertificats: (EnteteCertificatType & { lignes: LigneCertificatType[] })[], selectedMonth: string): string => {
    console.log("Generating XML file...");
  
    let xmlContent = '<?xml version="1.0" encoding="UTF-8"?>\n<DeclarationsRs>\n';
  
    if (listCertificats.length > 0) {
      const firstCertificat = listCertificats[0];
      const societeMatricule = firstCertificat.Societe.matriculeFiscale.substring(0, 8);
  
      xmlContent += `  <Declarant>\n`;
      xmlContent += `    <TypeIdentifiant>1</TypeIdentifiant>\n`;
      xmlContent += `    <matriculeFiscale>${societeMatricule}</matriculeFiscale>\n`;
      xmlContent += `    <CategorieContribuable>PM</CategorieContribuable>\n`;
      xmlContent += `  </Declarant>\n`;
  
      xmlContent += `  <ReferenceDeclaration>\n`;
      xmlContent += `    <ActeDepot>0</ActeDepot>\n`;
      xmlContent += `    <AnneeDepot>${firstCertificat.Exercice.libelle}</AnneeDepot>\n`;
      xmlContent += `    <MoisDepot>${selectedMonth}</MoisDepot>\n`;
      xmlContent += `  </ReferenceDeclaration>\n`;
    }
  
    xmlContent += `  <AjouterCertificats>\n`;
  
    listCertificats.forEach((certificat) => {
      xmlContent += `    <Certificat>\n`;
  
      xmlContent += `      <Benificiaire>\n`;
      xmlContent += `        <IdTaxPayer>\n`;
      if (certificat.Beneficiaire.typeIdentifiant === 1) {
        const identifiant = certificat.Beneficiaire.identifiant.substring(0, 8);
        const categorie = certificat.Beneficiaire.categorie === 1 ? 'PM' : 'PP';
        xmlContent += `          <MatriculeFiscale>\n`;
        xmlContent += `            <TypeIdentifiant>1</TypeIdentifiant>\n`;
        xmlContent += `            <Identifiant>${identifiant}</Identifiant>\n`;
        xmlContent += `            <CategorieContribuable>${categorie}</CategorieContribuable>\n`;
        xmlContent += `          </MatriculeFiscale>\n`;
      } else if (certificat.Beneficiaire.typeIdentifiant === 2) {
        const dateNaissance = certificat.Beneficiaire.dateNaissance ? new Date(certificat.Beneficiaire.dateNaissance).toLocaleDateString('fr-FR') : '';
        xmlContent += `          <CIN>\n`;
        xmlContent += `            <TypeIdentifiant>2</TypeIdentifiant>\n`;
        xmlContent += `            <Identifiant>${certificat.Beneficiaire.identifiant}</Identifiant>\n`;
        xmlContent += `            <DateDeNaissance>${dateNaissance}</DateDeNaissance>\n`;
        xmlContent += `            <CategorieContribuable>PP</CategorieContribuable>\n`;
        xmlContent += `          </CIN>\n`;
      } else if (certificat.Beneficiaire.typeIdentifiant === 3) {
        const dateNaissance = new Date(certificat.Beneficiaire.dateNaissance || 0).toLocaleDateString('fr-FR');
        const paysDevise = paysDevisesList.find(pays => pays.id === certificat.Beneficiaire.paysDevise);
        const codePays = paysDevise ? paysDevise.codePays : '';
        xmlContent += `          <Passeport>\n`;
        xmlContent += `            <TypeIdentifiant>3</TypeIdentifiant>\n`;
        xmlContent += `            <Identifiant>${certificat.Beneficiaire.identifiant}</Identifiant>\n`;
        xmlContent += `            <DateDeNaissance>${dateNaissance}</DateDeNaissance>\n`;
        xmlContent += `            <Pays>${codePays}</Pays>\n`;
        xmlContent += `            <CategorieContribuable>PP</CategorieContribuable>\n`;
        xmlContent += `          </Passeport>\n`;
      }else if (certificat.Beneficiaire.typeIdentifiant === 4) {
        const dateNaissance = new Date(certificat.Beneficiaire.dateNaissance || 0).toLocaleDateString('fr-FR');
        const paysDevise = paysDevisesList.find(pays => pays.id === certificat.Beneficiaire.paysDevise);
        const codePays = paysDevise ? paysDevise.codePays : '';
        xmlContent += `          <CarteSejour>\n`;
        xmlContent += `            <TypeIdentifiant>4</TypeIdentifiant>\n`;
        xmlContent += `            <Identifiant>${certificat.Beneficiaire.identifiant}</Identifiant>\n`;
        xmlContent += `            <DateDeNaissance>${dateNaissance}</DateDeNaissance>\n`;
        xmlContent += `            <Pays>${codePays}</Pays>\n`;
        xmlContent += `            <CategorieContribuable>PP</CategorieContribuable>\n`;
        xmlContent += `          </CarteSejour>\n`;
      }
      else if (certificat.Beneficiaire.typeIdentifiant === 5) {
        const paysDevise = paysDevisesList.find(pays => pays.id === certificat.Beneficiaire.paysDevise);
        const codePays = paysDevise ? paysDevise.codePays : '';
        xmlContent += `          <CarteSejour>\n`;
        xmlContent += `            <TypeIdentifiant>5</TypeIdentifiant>\n`;
        xmlContent += `            <Identifiant>${certificat.Beneficiaire.identifiant}</Identifiant>\n`;
        xmlContent += `            <Pays>${codePays}</Pays>\n`;
        xmlContent += `            <CategorieContribuable>${certificat.Beneficiaire.categorie === 1 ? 'PM' : 'PP'}</CategorieContribuable>\n`;
        xmlContent += `          </CarteSejour>\n`;
      }
      xmlContent += `        </IdTaxPayer>\n`;
      xmlContent += `        <Resident>${certificat.Beneficiaire.resident == true ? '1' : '0'}</Resident>\n`;
      xmlContent += `        <NometprenonOuRaisonsociale>${certificat.Beneficiaire.name}</NometprenonOuRaisonsociale>\n`;
      xmlContent += `        <Adresse>${certificat.Beneficiaire.adresse}</Adresse>\n`;
      xmlContent += `        <Activite>${certificat.Beneficiaire.activity}</Activite>\n`;

      xmlContent += `          <InfosContact>\n`;
      xmlContent += `            <AdresseMail>${certificat.Beneficiaire.adresseMail}</AdresseMail>\n`;
      xmlContent += `            <NumTel>${certificat.Beneficiaire.numTel}</NumTel>\n`;
      xmlContent += `          </InfosContact>\n`;




      xmlContent += `      </Benificiaire>\n`;
      const datePayement = new Date(certificat.date || 0).toLocaleDateString('fr-FR');


      xmlContent += `      <DatePayement>${datePayement}</DatePayement>\n`;

  
      xmlContent += `      <Ref_certif_chez_declarant>${certificat.numCertificat}</Ref_certif_chez_declarant>\n`;
      xmlContent += `      <ListeOperations>\n`;
      if (Array.isArray(certificat.lignes)) {
        certificat.lignes.forEach((ligne) => {
          xmlContent += `        <${ligne.codeRetenue}>\n`;
          xmlContent += `          <AnneeFacturation>${ligne.anneFacturation}</AnneeFacturation>\n`;
          xmlContent += `          <CNPC>${ligne.cnps == true ? '1' : '0'}</CNPC>\n`;
          xmlContent += `          <P_Charge>${ligne.priseEnCharge == true ? '1' : '0'}</P_Charge>\n`;
          const montantHT = typeof ligne.montant === 'number' ? (parseFloat(ligne.montant.toFixed(3)) * 1000).toFixed(0) : '';
          xmlContent += `          <MontantHT>${montantHT}</MontantHT>\n`;
          xmlContent += `          <TauxRS>${ligne.taux}</TauxRS>\n`;
          xmlContent += `          <TauxTVA>${ligne.tauxTva}</TauxTVA>\n`;
          const montantTva = typeof ligne.montantTva === 'number' ? (parseFloat(ligne.montantTva.toFixed(3)) * 1000).toFixed(0) : '';
          xmlContent += `          <MontantTVA>${montantTva}</MontantTVA>\n`;
          const montantTtc = typeof ligne.montantTtc === 'number' ? (parseFloat(ligne.montantTtc.toFixed(3)) * 1000).toFixed(0) : '';
          xmlContent += `          <MontantTTC>${montantTtc}</MontantTTC>\n`;
          const montantRs = typeof ligne.mntRetenue === 'number' ? (parseFloat(ligne.mntRetenue.toFixed(3)) * 1000).toFixed(0) : '';
          xmlContent += `          <MontantRS>${montantRs}</MontantRS>\n`;

          xmlContent += `          ${ligne.additionalTax ? '<TaxeAdditionnelle>\n' : ''}`;
          if (ligne.additionalTax) {
            xmlContent += `            <Code>${ligne.codeTaxeAdd}</Code>\n`;
            xmlContent += `            <Taux>${ligne.tauxTaxeAdd}</Taux>\n`;
          }
          xmlContent += `          ${ligne.additionalTax ? '</TaxeAdditionnelle>\n' : ''}`;
          const montantNetServi = typeof ligne.netServi === 'number' ? (parseFloat(ligne.netServi.toFixed(3)) * 1000).toFixed(0) : '';
          xmlContent += `          <MontantNetServi>${montantNetServi}</MontantNetServi>\n`;


          xmlContent += `          ${ligne.devise ? '<Devise>\n' : ''}`;
          if (ligne.devise) {
            xmlContent += `            <CodeDevise>${ligne.devise}</CodeDevise>\n`;
            xmlContent += `            <TauxChange>${ligne.tauxDevise}</TauxChange>\n`;
          }
          xmlContent += `          ${ligne.devise ? '</Devise>\n' : ''}`;    
          
          xmlContent += `        </${ligne.codeRetenue}>\n`;
        });
      }
      xmlContent += `      </ListeOperations>\n`;


    xmlContent += `      <TotalPayement>\n`;

    xmlContent += `        <TotalMontantHT>${certificat.montantTotal}</TotalMontantHT>\n`;
    xmlContent += `        <TotalMontantTVA>${certificat.totalMontantTva}</TotalMontantTVA>\n`;
    xmlContent += `        <TotalMontantTTC>${certificat.totalMontantTtc}</TotalMontantTTC>\n`;
    xmlContent += `        <TotalMontantRS>${certificat.retenueTotal}</TotalMontantRS>\n`;
    xmlContent += `        <TotalTaxes>${certificat.totalTaxes}</TotalTaxes>\n`;
    xmlContent += `        <TotalMontantNetServi>${certificat.netServiTotal}</TotalMontantNetServi>\n`;


    
    
    xmlContent += `      </TotalPayement>\n`;
      xmlContent += `    </Certificat>\n`;



    });
  
    xmlContent += `  </AjouterCertificats>\n`;
  
    xmlContent += `</DeclarationsRs>`;
  
    console.log("XML content:", xmlContent);
    return xmlContent;
  };
  
  
  const downloadXmlFile = (content: string) => {
    const blob = new Blob([content], { type: "application/xml" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;

    if (certificatsList.length > 0) {
      const firstCertificat = certificatsList[0];
      const societeMatricule = firstCertificat.Societe.matriculeFiscale.substring(0, 8);
      const exerciceLibelle = firstCertificat.Exercice.libelle;
      const fileName = `${societeMatricule}-${exerciceLibelle}-${selectedMonth}-0.xml`;
      a.download = fileName;
    } else {
      a.download = `certificats_${selectedMonth}.xml`;
    }

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <Button variant="info" onClick={handleExportXml}>
      Exporter XML
    </Button>
  );
};

export default ExportXml;
