import { saveAs } from "file-saver";

interface Annexe6FileProps {
    currentDeclaration: any;
    currentExercice: any;
    currentActe: any;
    currentSociete : any;
    annexe6List : any[] | null; 
}

export const generateAnnexe6File = (
    currentDeclaration: any,
    currentExercice: any,
    currentActe: any,
    currentSociete :any,
    annexe6List:any

) => {
    const generateAnnexe6Content = () => {
        if (!currentSociete || !currentExercice || !currentActe || !annexe6List) return "";
    
        const matriculeFiscale = (currentSociete.matriculeFiscale).slice(0, 8) + (currentSociete.matriculeFiscale).slice(   9, 13);
        const exerciceLibelle = (currentExercice.libelle).padEnd(4);
        const companyName = (currentSociete.name).padEnd(40);
        const companyActivity = (currentSociete.activity).padEnd(40);
        const companyCity = (currentSociete.ville).padEnd(40);
        const companyStreet = (currentSociete.rue).padEnd(72); 
        const companyNum = (currentSociete.num).toString().padEnd(4); 
        const codePostal = (currentSociete.codePostal).toString().padEnd(4);
        const spaces = " ".repeat(171);
    
        let annexe6Content = `E6${matriculeFiscale}${exerciceLibelle}An6${currentActe.code}${String(annexe6List.length).padStart(6, '0')}${companyName}${companyActivity}${companyCity}${companyStreet}${companyNum}${codePostal}${spaces}`;
    
        annexe6List.forEach((annexe6 : any, index : any) => {
            const order = String(index + 1).padStart(6, '0');
            const typeIdentifiant = String(annexe6.typeIdentifiant)
            const identifiantBeneficiaire = String(annexe6.identifiant).padStart(13, '0');;
            const nomBeneficiaire = annexe6.name.padEnd(40);
            const emploiBenef = annexe6.activity.padEnd(40);
            const adresseBenef = annexe6.adresse.padEnd(120);
            const typeRistournes = String(annexe6.typeRistournes)
            const montantRistourne = String(annexe6.montantRistourne * 1000 || 0).padStart(15, '0');
            const ventePpForfaitaire = String(annexe6.ventePpForfaitaire * 1000 || 0).padStart(15, '0');
            const avancePpForfaitaire = String(annexe6.avancePpForfaitaire * 1000 || 0).padStart(15, '0');
            const mntRevenuJeux = String(annexe6.mntRevenuJeux * 1000 || 0).padStart(15, '0');
            const rasRevenuJeux = String(annexe6.rasRevenuJeux * 1000 || 0).padStart(15, '0');
            const mntVenteProfitDist = String(annexe6.mntVenteProfitDist * 1000 || 0).padStart(15, '0');
            const rasProfitDist = String(annexe6.rasProfitDist * 1000 || 0).padStart(15, '0');
            const mntPercus = String(annexe6.mntPercus * 1000 || 0).padStart(15, '0');

            const mntVenteFabAlcool = String(annexe6.mntVenteFabAlcool * 1000 || 0).padStart(15, '0');
            const mntAvanceFabAlcool = String(annexe6.mntAvanceFabAlcool * 1000 || 0).padStart(15, '0');
    
    
            const EndZone = " ".repeat(10);
            
    
            annexe6Content += `\nL6${matriculeFiscale}${exerciceLibelle}${order}${typeIdentifiant}${identifiantBeneficiaire}${nomBeneficiaire}${emploiBenef}${adresseBenef}${typeRistournes}${montantRistourne}${ventePpForfaitaire}${avancePpForfaitaire}${mntRevenuJeux}${rasRevenuJeux}${mntVenteProfitDist}${rasProfitDist}${mntPercus}${mntVenteFabAlcool}${mntAvanceFabAlcool}${EndZone}`;
        
        
          });
          const EndZoneAnx = " ".repeat(221);
          const totalRistourne = String(
            (annexe6List.reduce((acc : any, annexe6 : any) => acc + (annexe6.montantRistourne || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
          const totalVentePp = String(
            (annexe6List.reduce((acc : any, annexe6 : any) => acc + (annexe6.ventePpForfaitaire || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
          const totalAvencePp = String(
            (annexe6List.reduce((acc : any, annexe6 : any) => acc + (annexe6.avancePpForfaitaire || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
          const totalmntRevenuJeux = String(
            (annexe6List.reduce((acc : any, annexe6 : any) => acc + (annexe6.mntRevenuJeux || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
          const totalrasRevenuJeux = String(
            (annexe6List.reduce((acc : any, annexe6 : any) => acc + (annexe6.rasRevenuJeux || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
          const totalmntVenteProfitDist = String(
            (annexe6List.reduce((acc : any, annexe6 : any) => acc + (annexe6.mntVenteProfitDist || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
          const totalrasProfitDist = String(
            (annexe6List.reduce((acc : any, annexe6 : any) => acc + (annexe6.rasProfitDist || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
          const totalMntPercus = String(
            (annexe6List.reduce((acc : any, annexe6 : any) => acc + (annexe6.mntPercus || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
          const totalmntVenteFabAlcool = String(
            (annexe6List.reduce((acc : any, annexe6 : any) => acc + (annexe6.mntVenteFabAlcool || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
          const totalmntAvanceFabAlcool = String(
            (annexe6List.reduce((acc : any, annexe6 : any) => acc + (annexe6.mntAvanceFabAlcool || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
    
    
            const EndZone = " ".repeat(10);
    
    
        const FinAnnexe = `T6${matriculeFiscale}${exerciceLibelle}${EndZoneAnx}${totalRistourne}${totalVentePp}${totalAvencePp}${totalmntRevenuJeux}${totalrasRevenuJeux}${totalmntVenteProfitDist}${totalrasProfitDist}${totalMntPercus}${totalmntVenteFabAlcool}${totalmntAvanceFabAlcool}${EndZone}`;
        annexe6Content += `\n${FinAnnexe}`;
        return annexe6Content;
    };

    const textContent = generateAnnexe6Content();
    const blob = new Blob([textContent], { type: "text/plain;charset=utf-8" });
    const exerciseLabel = currentExercice.libelle;
    const lastTwoChars = exerciseLabel.slice(-2);
    const fileName = `ANXEMP_6_${lastTwoChars}_1.txt`;
    saveAs(blob, fileName);
};

const Annexe6File: Annexe6FileProps = {
    currentDeclaration: null,
    currentExercice: null,
    currentActe: null,
    currentSociete :null,
    annexe6List:null,
};

export default Annexe6File;
