import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Annexe2Type } from "../../../Types/Entites/Annexe2Type";
import { RootState } from "../../store";

export const createAnnexe2Api = createAsyncThunk(
  "annexe2/createAnnexe2",
  async (data: Annexe2Type, { rejectWithValue }) => {
    try {
      const response = await axios.post("annexe2/createAnnexe2", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

// Action asynchrone pour récupérer toutes les Annexe2
export const findAllAnnexe2Api = createAsyncThunk(
  "annexe2/findAllAnnexe2",
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await axios.get("annexe2/findAllAnnexes2");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const updateAnnexe2Api = createAsyncThunk(
  "annexe2/updateAnnexe2",
  async (
    { id, data }: { id: number; data: Annexe2Type },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(`annexe2/updateAnnexe2/${id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const getAnnexe2ByIdApi = createAsyncThunk(
  "annexe2/getAnnexe2ById",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(`annexe2/getAnnexe2ById/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const deleteAnnexe2Api = createAsyncThunk(
  "annexe2/deleteAnnexe2",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`annexe2/deleteAnnexe2/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);
export const findAnnexes2ByDeclarationApi = createAsyncThunk(
  "annexe2/findAnnexes2ByDeclaration",
  async (declarationId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `annexe2/findAnnexes2ByDeclaration/${declarationId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour supprimer toutes les Annexe2 par ID de déclaration
export const deleteAllAnnexes2ByDeclarationApi = createAsyncThunk(
  "annexe2/deleteAllAnnexes2ByDeclaration",
  async (declarationId: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `annexe2/deleteAllAnnexes2ByDeclaration/${declarationId}`
      );
      return { declarationId, data: response.data };
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);


interface Annexe2s {
  annexe2List: Annexe2Type[];
  annexe2ListByDeclaration: Annexe2Type[];
}

const initialState: Annexe2s = {
  annexe2List: [],
  annexe2ListByDeclaration: [],
};

export const annexe2Slice = createSlice({
  name: "annexe2",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAnnexe2Api.fulfilled, (state, action) => {
      state.annexe2List.push(action.payload.data);
      state.annexe2ListByDeclaration.push(action.payload.data);
    });
    builder.addCase(findAllAnnexe2Api.fulfilled, (state, action) => {
      return {
        ...state,
        annexe2List: action.payload,
      };
    });
    builder.addCase(updateAnnexe2Api.fulfilled, (state, action) => {
      const updatedIndex = state.annexe2List.findIndex(
        (annexe2) => annexe2.id === action.payload.data.id
      );
      if (updatedIndex !== -1) {
        state.annexe2List[updatedIndex] = action.payload.data;
      }

      const updatedDeclarationIndex = state.annexe2ListByDeclaration.findIndex(
        (annexe2) => annexe2.id === action.payload.data.id
      );
      if (updatedDeclarationIndex !== -1) {
        state.annexe2ListByDeclaration[updatedDeclarationIndex] =
          action.payload.data;
      }
    });
    builder.addCase(getAnnexe2ByIdApi.fulfilled, (state, action) => {
      return {
        ...state,
        annexe2List: [action.payload],
      };
    });
    builder.addCase(deleteAnnexe2Api.fulfilled, (state, action) => {
      state.annexe2List = state.annexe2List.filter(
        (annexe2) => annexe2.id !== action.meta.arg
      );
      state.annexe2ListByDeclaration = state.annexe2ListByDeclaration.filter(
        (annexe2) => annexe2.id !== action.meta.arg
      );
    });

    builder.addCase(findAnnexes2ByDeclarationApi.fulfilled, (state, action) => {
      return {
        ...state,
        annexe2ListByDeclaration: action.payload,
      };
    });

    builder.addCase(deleteAllAnnexes2ByDeclarationApi.fulfilled, (state, action) => {
      state.annexe2List = state.annexe2List.filter(
        (annexe2) => annexe2.declaration !== action.payload.declarationId
      );
      state.annexe2ListByDeclaration = state.annexe2ListByDeclaration.filter(
        (annexe2) => annexe2.declaration !== action.payload.declarationId
      );
    });
  },
});

export const annexe2List = (state: RootState) => state.annexe2.annexe2List;
export const annexe2ListByDeclaration = (state: RootState) =>
  state.annexe2.annexe2ListByDeclaration;

export default annexe2Slice.reducer;
