import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Redirection
import Layout from "./_Layouts/Layout/Layout";
import LoginLayout from "./_Layouts/Layout/LoginLayout";
import LoginPage from "./Pages/Login/LoginPage";
import TvaCategoryPage from "./Pages/TvaCategory/TvaCategoryPage";
import SocietePage from "./Pages/Societe/SocietePage";
import BeneficiairePage from "./Pages/Beneficiaire/BeneficiairePage";
import ExercicePage from "./Pages/Exercice/ExercicePage";
import DeclarationPage from "./Pages/Declaration/DeclarationPage";
import { currentToken } from "./_App/Redux/Slices/user/userSlice";
import { useAppSelector } from "./_App/Redux/hooks";
import AnnexesPage from "./Pages/Annexes/AnnexesPage";
import RecapParamPage from "./Pages/RecapParam/RecapParamPage";
import RetenueParamPage from "./Pages/RetenueParam/RetenueParamPage";
import CertificatsRetenues from "./Pages/CertificatsRetenues/CertificatsRetenues";
import ListCertificatsPage from "./Pages/CertificatsRetenues/ListCertificatsPage";

function App() {
  /**
   * Configuration d'axios
   */
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  return (
    <div>
      <BrowserRouter>
        <MainApp />
      </BrowserRouter>
    </div>
  );
}

function MainApp() {
  const navigate = useNavigate();
  const token = useAppSelector(currentToken); // Récupérer le token depuis Redux

  useEffect(() => {
    if (!token) {
      navigate("/login"); // Redirige vers la page de connexion si le token est absent
    }
  }, [token, navigate]); // Re-exécute chaque fois que le token change

  return (
    <Routes>
      <Route path="/login" element={<LoginLayout />}>
        <Route path="/login" element={<LoginPage />} />
      </Route>

      {/* Routes protégées */}
      <Route path="/" element={<Layout />}>
        <Route path="/tvaCategories" element={<TvaCategoryPage />} />
        <Route path="/societes" element={<SocietePage />} />
        <Route path="/beneficiaires" element={<BeneficiairePage />} />
        <Route path="/exercices" element={<ExercicePage />} />
        <Route path="/declarations/:declarantId" element={<DeclarationPage />} />
        <Route path="/annexes/:declarationId" element={<AnnexesPage />} />
        <Route path="/parametrageRecap" element={<RecapParamPage />} />
        <Route path="/parametrageRetenues" element={<RetenueParamPage />} />
        <Route path="/certificatRetenue/:exerciceId/:declarantId/:declarationId" element={<CertificatsRetenues />} />
        <Route path="/listCertificats/:declarantId/:exerciceId/:declarationId" element={<ListCertificatsPage />} />




        
      </Route>
    </Routes>
  );
}

export default App;
