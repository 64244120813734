import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { TVAType } from "../../../Types/Entites/TvaType";
import { RootState } from "../../store";

// Action asynchrone pour récupérer toutes les TVA
export const findAllTVAApi = createAsyncThunk(
  "tva/findAllTVA",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("tva/findAllTVA"); 
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

interface TVAState {
  tvaList: TVAType[]; 
}

const initialState: TVAState = {
  tvaList: [],
};

export const tvaSlice = createSlice({
  name: "tva",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findAllTVAApi.fulfilled, (state, action) => {
      state.tvaList = action.payload;
    });
  },
});

export const {} = tvaSlice.actions;
export const TVAList = (state: RootState) => state.tva.tvaList; 

export default tvaSlice.reducer;
