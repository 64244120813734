import { saveAs } from "file-saver";

interface Annexe4FileProps {
  currentDeclaration: any;
  currentExercice: any;
  currentActe: any;
  currentSociete: any;
  annexe4List: any[] | null;
}

export const generateAnnexe4File = (
  currentDeclaration: any,
  currentExercice: any,
  currentActe: any,
  currentSociete: any,
  annexe4List: any
) => {
  const generateAnnexe4Content = () => {
    if (!currentSociete || !currentExercice || !currentActe || !annexe4List)
      return "";

    const matriculeFiscale =
      currentSociete.matriculeFiscale.slice(0, 8) +
      currentSociete.matriculeFiscale.slice(9, 13);
    const exerciceLibelle = currentExercice.libelle.padEnd(4);
    const companyName = currentSociete.name.padEnd(40);
    const companyActivity = currentSociete.activity.padEnd(40);
    const companyCity = currentSociete.ville.padEnd(40);
    const companyStreet = currentSociete.rue.padEnd(72);
    const companyNum = currentSociete.num.toString().padEnd(4);
    const codePostal = currentSociete.codePostal.toString().padEnd(4);
    const spaces = " ".repeat(171);

    let annexe4Content = `E4${matriculeFiscale}${exerciceLibelle}An4${
      currentActe.code
    }${String(annexe4List.length).padStart(
      6,
      "0"
    )}${companyName}${companyActivity}${companyCity}${companyStreet}${companyNum}${codePostal}${spaces}`;

    annexe4List.forEach((annexe4: any, index: any) => {
      const order = String(index + 1).padStart(6, "0");
      const typeIdentifiant = String(annexe4.typeIdentifiant);
      const identifiantBeneficiaire = String(annexe4.identifiant).padStart(
        13,
        "0"
      );
      const nomBeneficiaire = annexe4.name.padEnd(40);
      const emploiBenef = annexe4.activity.padEnd(40);
      const adresseBenef = annexe4.adresse.padEnd(120);
      const typeMontantServis = String(annexe4.TypeMntServActNonComm);
      const tauxMontantServisTitreHonoraire = String(
        annexe4.tauxHonoraire * 1000 || 0
      ).padStart(5, "0");
      const mntHonoraire = String(annexe4.MntHonoraire * 1000 || 0).padStart(
        15,
        "0"
      );
      const tauxRenumNonResid = String(
        annexe4.tauxRenumNonResid * 1000 || 0
      ).padStart(5, "0");
      const MntRenumNonResid = String(
        annexe4.MntRenumNonResid * 1000 || 0
      ).padStart(15, "0");
      const TauxPluValueImmob = String(
        annexe4.TauxPluValueImmob * 1000 || 0
      ).padStart(5, "0");
      const MontantPluValueImmob = String(
        annexe4.MontantPluValueImmob * 1000 || 0
      ).padStart(15, "0");
      const tauxPlusValueCessionAct = String(
        annexe4.tauxPlusValueCessionAct * 1000 || 0
      ).padStart(5, "0");
      const MntPlusValueCessionAct = String(
        annexe4.MntPlusValueCessionAct * 1000 || 0
      ).padStart(15, "0");
      const tauxRevenuMobJetPres = String(
        annexe4.tauxRevenuMobJetPres * 1000 || 0
      ).padStart(5, "0");
      const MntRevenuMobJetPres = String(
        annexe4.MntPlusValueCessionAct * 1000 || 0
      ).padStart(15, "0");
      const renumRevEtatRegimeFiscalPriv = String(
        annexe4.renumRevEtatRegimeFiscalPriv * 1000 || 0
      ).padStart(15, "0");
      const RetenuTitreTva100 = String(
        annexe4.RetenuTitreTva100 * 1000 || 0
      ).padStart(15, "0");

      const retenuOpere = String(annexe4.retenuOper * 1000 || 0).padStart(
        15,
        "0"
      );
      const netServi = String(annexe4.netServi * 1000 || 0).padStart(15, "0");

      const EndZone = " ".repeat(10);

      annexe4Content += `\nL4${matriculeFiscale}${exerciceLibelle}${order}${typeIdentifiant}${identifiantBeneficiaire}${nomBeneficiaire}${emploiBenef}${adresseBenef}${typeMontantServis}${tauxMontantServisTitreHonoraire}${mntHonoraire}${tauxRenumNonResid}${MntRenumNonResid}${TauxPluValueImmob}${MontantPluValueImmob}${tauxPlusValueCessionAct}${MntPlusValueCessionAct}${tauxRevenuMobJetPres}${MntRevenuMobJetPres}${renumRevEtatRegimeFiscalPriv}${RetenuTitreTva100}${retenuOpere}${netServi}`;
    });
    const EndZoneAnx = " ".repeat(221);
    const fiveZero = "00000";

    const totalHonoraire = String(
      annexe4List
        .reduce(
          (acc: any, annexe4: any) => acc + (annexe4.MntHonoraire || 0),
          0
        )
        .toFixed(3) * 1000
    ).padStart(15, "0");

    const totalMntRenumNonResid = String(
      annexe4List
        .reduce(
          (acc: any, annexe4: any) => acc + (annexe4.MntRenumNonResid || 0),
          0
        )
        .toFixed(3) * 1000
    ).padStart(15, "0");

    const totalplusValueImmob = String(
      annexe4List
        .reduce(
          (acc: any, annexe4: any) => acc + (annexe4.MontantPluValueImmob || 0),
          0
        )
        .toFixed(3) * 1000
    ).padStart(15, "0");

    const totalMntPlusValueCessionAct = String(
      annexe4List
        .reduce(
          (acc: any, annexe4: any) =>
            acc + (annexe4.MntPlusValueCessionAct || 0),
          0
        )
        .toFixed(3) * 1000
    ).padStart(15, "0");

    const totalMntRevenuMobJetPres = String(
      annexe4List
        .reduce(
          (acc: any, annexe4: any) => acc + (annexe4.MntRevenuMobJetPres || 0),
          0
        )
        .toFixed(3) * 1000
    ).padStart(15, "0");

    const totalrenumRevEtatRegimeFiscalPriv = String(
      annexe4List
        .reduce(
          (acc: any, annexe4: any) =>
            acc + (annexe4.renumRevEtatRegimeFiscalPriv || 0),
          0
        )
        .toFixed(3) * 1000
    ).padStart(15, "0");

    const totalRetenuTitreTva100 = String(
      annexe4List
        .reduce(
          (acc: any, annexe4: any) => acc + (annexe4.RetenuTitreTva100 || 0),
          0
        )
        .toFixed(3) * 1000
    ).padStart(15, "0");

    const totalretenuOper = String(
      annexe4List
        .reduce((acc: any, annexe4: any) => acc + (annexe4.retenuOper || 0), 0)
        .toFixed(3) * 1000
    ).padStart(15, "0");

    const totalnetServi = String(
      annexe4List
        .reduce((acc: any, annexe4: any) => acc + (annexe4.netServi || 0), 0)
        .toFixed(3) * 1000
    ).padStart(15, "0");

    const FinAnnexe = `T4${matriculeFiscale}${exerciceLibelle}${EndZoneAnx}${fiveZero}${totalHonoraire}${fiveZero}${totalMntRenumNonResid}${fiveZero}${totalplusValueImmob}${fiveZero}${totalMntPlusValueCessionAct}${fiveZero}${totalMntRevenuMobJetPres}${totalrenumRevEtatRegimeFiscalPriv}${totalRetenuTitreTva100}${totalretenuOper}${totalnetServi}`;
    annexe4Content += `\n${FinAnnexe}`;
    return annexe4Content;
  };
  const textContent = generateAnnexe4Content();
  const blob = new Blob([textContent], { type: "text/plain;charset=utf-8" });
  const exerciseLabel = currentExercice.libelle;
  const lastTwoChars = exerciseLabel.slice(-2);
  const fileName = `ANXEMP_4_${lastTwoChars}_1.txt`;
  saveAs(blob, fileName);
};

const Annexe4File: Annexe4FileProps = {
  currentDeclaration: null,
  currentExercice: null,
  currentActe: null,
  currentSociete: null,
  annexe4List: null,
};

export default Annexe4File;
