import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { SocieteType } from "../../../Types/Entites/SocieteType";
import { RootState } from "../../store";

// Action asynchrone pour créer une nouvelle société
export const createSocieteApi = createAsyncThunk(
  "societes/createSociete",
  async (data: SocieteType, { rejectWithValue }) => {
    console.log("Calling createSocieteApi with data:", data);

    try {
      const response = await axios.post("societe/createSociete", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

// Action asynchrone pour récupérer toutes les sociétés
export const findAllSocietesApi = createAsyncThunk(
  "societes/findAllSocietes",
  async (data: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get("societe/findAllSocietes");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour mettre à jour une société
export const updateSocieteApi = createAsyncThunk(
  "societes/updateSociete",
  async ({ id, data }: { id: number; data: SocieteType }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`societe/updateSociete/${id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

// Action asynchrone pour récupérer une société par ID
export const findByIdApi = createAsyncThunk(
  "societes/findById",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(`societe/findById/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour supprimer une société
export const deleteSocieteApi = createAsyncThunk(
  "societes/deleteSociete",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`societe/deleteSociete/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface Societes {
  societesList: SocieteType[];
  currentSociete: SocieteType | null;
}

const initialState: Societes = {
  societesList: [],
  currentSociete: null,
};

export const societeSlice = createSlice({
  name: "societes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createSocieteApi.fulfilled, (state, action) => {
      state.societesList.push(action.payload.data);
    });
    builder.addCase(findAllSocietesApi.fulfilled, (state, action) => {
      return {
        ...state,
        societesList: action.payload,
      };
    });
    builder.addCase(updateSocieteApi.fulfilled, (state, action) => {
      const updatedIndex = state.societesList.findIndex(
        (societe) => societe.id === action.payload.data.id
      );
      if (updatedIndex !== -1) {
        state.societesList[updatedIndex] = action.payload.data;
      }
    });
    builder.addCase(findByIdApi.fulfilled, (state, action) => {
      state.currentSociete = action.payload; 
    });
    builder.addCase(deleteSocieteApi.fulfilled, (state, action) => {
      state.societesList = state.societesList.filter(societe => societe.id !== action.meta.arg);

    });
  },
});

export const societesList = (state: RootState) => state.societes.societesList;
export const selectCurrentSociete = (state: RootState) => state.societes.currentSociete; 


export default societeSlice.reducer;
