import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";
import Annexe1Form from "../Annexe1/Annexe1Form";
import Annexe2Form from "../Annexe2/Annexe2Form";
import Annexe3Form from "../Annexe3/Annexe3Form";
import Annexe7Form from "../Annexe7/Annexe7Form";
import Annexe6Form from "../Annexe6/Annexe6Form";
import Annexe5Form from "../Annexe5/Annexe5Form";
import Annexe4Form from "../Annexe4/Annexe4Form";
import RecapForm from "../Recap/recapForm";
import { DeclarationType } from "../../_App/Types/Entites/DeclarationType";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { findAllDeclarationsApi } from "../../_App/Redux/Slices/declaration/declarationSlice";

const StyledTabs = styled(Tabs)`
  background-color: #f8f9fa; /* Couleur de fond des onglets */
  border-bottom: 1px solid #dee2e6; /* Bordure inférieure des onglets */
`;

const StyledNavLink = styled(Tab)`
  &.nav-item .nav-link {
    color: #007bff; /* Couleur du texte des onglets */
    background-color: #ffffff; /* Couleur de fond des onglets actifs */
    border: 1px solid #dee2e6; /* Bordure des onglets actifs */
    border-bottom-color: transparent; /* Supprimer la bordure inférieure */
  }
`;

const StyledTabContent = styled(Tab.Content)`
  border: 1px solid #dee2e6; /* Bordure des panneaux de contenu */
  padding: 15px; /* Espacement interne des panneaux de contenu */
`;

interface AnnexesTabsProps {
  declarationId: string | undefined;
}

const AnnexesTabs: React.FC<AnnexesTabsProps> = ({ declarationId }) => {
  const [key, setKey] = useState<string>("annexe1");
  const currentDeclaration: DeclarationType | undefined = useAppSelector(
    (state) =>
      state.declarations.declarationsList.find(
        (declaration) => declaration.id === parseInt(declarationId || "0")
      )
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(findAllDeclarationsApi(null));
  }, [dispatch]);
  const [selectedAnnexe, setSelectedAnnexe] = useState(null);

  const handleAnnexeSelect = (annexe :any) => {
    setSelectedAnnexe(annexe);
  };

  return (
    <StyledTabs
      id="controlled-tabs"
      activeKey={key}
      onSelect={(k) => k && setKey(k.toString())}
    >
      {currentDeclaration && currentDeclaration.annexe1 === true && (
        <StyledNavLink eventKey="annexe1" title="Annexe 1">
          <Annexe1Form declarationId={parseInt(declarationId || "0", 10)} />
        </StyledNavLink>
      )}

      {currentDeclaration && currentDeclaration.annexe2 === true && (
        <StyledNavLink eventKey="annexe2" title="Annexe 2">
          <Annexe2Form declarationId={parseInt(declarationId || "0", 10)} />
        </StyledNavLink>
      )}

      {currentDeclaration && currentDeclaration.annexe3 === true && (
        <StyledNavLink eventKey="annexe3" title="Annexe 3">
          <Annexe3Form declarationId={parseInt(declarationId || "0", 10)} />
        </StyledNavLink>
      )}

      {currentDeclaration && currentDeclaration.annexe4 === true && (
        <StyledNavLink eventKey="annexe4" title="Annexe 4">
          <Annexe4Form declarationId={parseInt(declarationId || "0", 10)} />
        </StyledNavLink>
      )}

      {currentDeclaration && currentDeclaration.annexe5 === true && (
        <StyledNavLink eventKey="annexe5" title="Annexe 5">
          <Annexe5Form declarationId={parseInt(declarationId || "0", 10)} />
        </StyledNavLink>
      )}

      {currentDeclaration && currentDeclaration.annexe6 === true && (
        <StyledNavLink eventKey="annexe6" title="Annexe 6">
          <Annexe6Form declarationId={parseInt(declarationId || "0", 10)} />
        </StyledNavLink>
      )}

      {currentDeclaration && currentDeclaration.annexe7 === true && (
        <StyledNavLink eventKey="annexe7" title="Annexe 7">
          <Annexe7Form declarationId={parseInt(declarationId || "0", 10)} />
        </StyledNavLink>
      )}

      <StyledNavLink eventKey="recap" title="Recap">
        <RecapForm declarationId={parseInt(declarationId || "0", 10)} />
      </StyledNavLink>
    </StyledTabs>
  );
};

export default AnnexesTabs;
