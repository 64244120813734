import { yupResolver } from "@hookform/resolvers/yup";
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { loginUserApi } from "../../_App/Redux/Slices/user/userSlice";
import { useAppDispatch } from "../../_App/Redux/hooks";
import { LoginSchema } from "../../_App/Types/Forms/Schema/LoginSchema";
import "./auth.css"; // Importation du CSS

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const { errors } = formState;

  const onSubmit = async (data: any) => {
    try {
      const response = await dispatch(loginUserApi(data));
      if (loginUserApi.fulfilled.match(response)) {
        ToastSuccess("Connexion réussie!");
        navigate("/societes");
      } else {
        ToastWarning("Connexion échouée");
      }
    } catch (error) {
      ToastWarning("Une erreur est survenue");
    }
  };

  return (
    <div className="login-background">
      <div className="shape"></div>
      <span className="custom-text">
        <h4>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Déclaration d'employeur selon le cahier des charges de la ministère de
          la finance 2023
        </h4>
      </span>

      <span>
        {" "}
        <img
          src="https://scontent.ftun1-2.fna.fbcdn.net/v/t39.30808-6/301013873_740304147309472_5274208426008867305_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=UYrxc-_NDYIQ7kNvgGNcE3q&_nc_ht=scontent.ftun1-2.fna&oh=00_AfBSTLluwRLjHns-3CUENW6lGTC7xk1qAVcKq6djBSCTow&oe=66386DCE"
          alt="Logo"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "200px",
            height: "200px",
          }}
        />{" "}
      </span>
      <div className="shape"></div>

      <div className="left-section">
        <span>
          {" "}
          {/* Appliquer l'animation de défilement */}
          <h4></h4>
        </span>
      </div>

      <div className="login-form">
        <h3>Connexion</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label>Email</label>
          <input
            type="email"
            className={`form-control ${errors.email ? "is-invalid" : ""}`}
            placeholder="Votre email"
            {...register("email")}
          />
          {errors.email && (
            <span className="invalid-feedback">{errors.email.message}</span>
          )}

          <label>Mot de passe</label>
          <input
            type="password"
            placeholder="Votre mot de passe"
            {...register("password")}
          />
          {errors.password && (
            <span className="invalid-feedback">{errors.password.message}</span>
          )}

          <button type="submit">Connexion</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
