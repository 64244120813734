import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../_Layouts/Navbar/Navbar";
import EnteteCertificatList from "../../Components/LigneCertificat/EnteteCertificatList";
import GenerateXmlModal from "../../Components/LigneCertificat/GenerateXmlModal";
import { Button } from "react-bootstrap";



const ListCertificatsPage: React.FC = () => {
  const { declarantId, exerciceId, declarationId } = useParams<{
    declarantId: string;
    exerciceId: string;
    declarationId: string;
  }>();

  const [showModal, setShowModal] = useState(false);

  const handleToggleModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <div>
      <div style={{ marginBottom: "30px" }}>
        <Navbar titre={"Liste des Certificats des Retenues"} />
      </div>

      <div className="d-flex justify-content-end mb-3">
        <Button variant="success" onClick={handleToggleModal}>
          Générer XML
        </Button>
      </div>

      <EnteteCertificatList
        declarantId={parseInt(declarantId ?? "0")}
        exerciceId={parseInt(exerciceId ?? "0")}
        declarationId={parseInt(declarationId ?? "0")}
      />

      {/* Modal */}
      <GenerateXmlModal
        show={showModal}
        onClose={handleToggleModal}
        declarantId={parseInt(declarantId ?? "0")}
        exerciceId={parseInt(exerciceId ?? "0")}
      />
    </div>
  );
};

export default ListCertificatsPage;
