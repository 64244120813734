import styled from 'styled-components';

export const Asterisk = styled.span`
  padding-left: 5px; 
  padding-right: 5px; 
  color: red; 
`;





export const calculTTc = (prixht: number, tva: number) => {
  return prixht + (prixht * tva) / 100;
};

export const calculHt = (prixttc: number, tva: number) => {
  return (100 * prixttc) / (100 + tva);
};

export const calculTTC_Remise = (
  prix_ttc: number,
  quantite: number,
  remise: number
) => {
  const totalBeforeRemise = quantite * prix_ttc;
  const remiseAmount = (totalBeforeRemise * remise) / 100;
  const totalAfterRemise = totalBeforeRemise - remiseAmount;

  const round = (value: number, decimalPlaces: number): number => {
    const factor = 10 ** decimalPlaces;
    return Math.round(value * factor) / factor;
  };

  return round(totalAfterRemise, 3);
};

export const parseDate = (dateString: string): Date => {
  const parts = dateString.split("/").map(Number);
  const day = parts[0];
  const month = parts[1] - 1; // Les mois sont indexés à partir de 0 dans les objets Date
  const year = parts[2];

  return new Date(year, month, day);
};

export const formadate = (date: any) => {
  const inputDate = new Date(date);

  const day = (inputDate.getDate() + 1).toString().padStart(2, "0");
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const year = inputDate.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export const generateRandomEAN = (length: any) => {
  const randomDigits = Array.from({ length }, () =>
    Math.floor(Math.random() * 10)
  ).join("");
  return calculateEANCheckDigit(randomDigits, length);
};

const calculateEANCheckDigit = (digits: any, length: any) => {
  if (digits.length !== length) {
    throw new Error(`Invalid number of digits for EAN-${length}`);
  }

  let sum = 0;
  for (let i = 0; i < digits.length; i++) {
    const digit = parseInt(digits[i], 10);
    sum += i % 2 === 0 ? digit : digit * 3;
  }

  const checkDigit = (10 - (sum % 10)) % 10;

  return `${digits}${checkDigit}`;
};
export const areArraysEqual = (arr1: any, arr2: any) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};
export const handleKeyPress = (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  const charCode = event.which || event.keyCode;

  // Allow only numeric characters (0-9), period (.), and minus sign (-)
  if ((charCode < 48 || charCode > 57) && charCode !== 46 && charCode !== 45) {
    event.preventDefault();
  }

  // Ensure that only one period is allowed
  const inputText = event.currentTarget.value;
  if (charCode === 46 && inputText.indexOf(".") !== -1) {
    event.preventDefault();
  }

  // Ensure that only one minus sign is allowed at the beginning
  if (charCode === 45 && inputText.indexOf("-") !== -1) {
    event.preventDefault();
  }
};
export const formatDateAndTime = (timestamp: Date) => {
  const dateObject = new Date(timestamp);

  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObject.getDate().toString().padStart(2, "0");

  const hours = dateObject.getHours().toString().padStart(2, "0");
  const minutes = dateObject.getMinutes().toString().padStart(2, "0");
  const seconds = dateObject.getSeconds().toString().padStart(2, "0");

  const formattedDateAndTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDateAndTime;
};

export const formatTime = ({ date }: { date: string }) => {
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return new Date(date).toLocaleTimeString(undefined, options);
};

export const getLettreAssociee = (chiffres: string): string => {
  const CorrespondanceLettre: string[] = [
    "",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "M",
    "N",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  if (!/^\d+$/.test(chiffres)) {
    return "";
  }

  const somme = chiffres
    .split("")
    .map((chiffre, index) => parseInt(chiffre) * (7 - index))
    .reduce((acc, val) => acc + val, 0);

  const reste = (somme % 23) + 1;

  if (reste >= 1 && reste <= 23) {
    return CorrespondanceLettre[reste];
  } else {
    return "Numéro hors de la plage valide.";
  }
};

export const verifierLettreAssociee = (codeAvecLettre: string): boolean => {
  if (codeAvecLettre.length !== 8) {
    return false;
  }

  const chiffres = codeAvecLettre.slice(0, 7);

  if (!/^\d+$/.test(chiffres)) {
    return false;
  }

  const lettreAssociee = getLettreAssociee(chiffres);

  return lettreAssociee === codeAvecLettre[7];
};
export const MatriculFiscalValidate = (input: string): boolean => {
  if (input.length !== 13) {
    return false;
  }
  const chiffres = input.slice(0, 7);
  if (!/^\d+$/.test(chiffres)) {
    return false;
  }

  const lettreAssociee = getLettreAssociee(chiffres);

  if (lettreAssociee !== input[7]) {
    return false;
  }

  const characterBeforeZeros = input[9];
  const lastThreeCharacters = input.slice(10);

  if (lastThreeCharacters !== "000") {
    return false;
  }

  if (characterBeforeZeros === "E") {
    return false;
  }

  return true;
};

export const handleTabulation = (e: any) => {
  if (e.key === "Tab" || e.key === "Enter") {
    e.preventDefault(); 

    const fields = Array.from(e.target.form.elements).filter(
      (element: any) =>
        ["INPUT", "SELECT", "BUTTON"].includes(element.tagName) &&
        !element.disabled 
    );

    const currentIndex = fields.findIndex((field) => field === e.target);

    if (currentIndex === -1) {
      return;
    }

    let nextIndex = currentIndex + 1;

    while (
      nextIndex < fields.length &&
      (fields[nextIndex] as HTMLInputElement | HTMLSelectElement | HTMLButtonElement).disabled
    ) {
      nextIndex++;
    }

    if (nextIndex < fields.length) {
      const nextField = fields[nextIndex] as HTMLInputElement | HTMLSelectElement | HTMLButtonElement;

      if (nextField.tagName === "BUTTON" && e.key === "Enter") {
        nextField.click();
      } else if (nextField && "focus" in nextField) {
        nextField.focus();
      }
    }
  }
};


export function isValidDate(dateString: any) {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateRegex.test(dateString)) {
    return false; // La saisie ne correspond pas au format AAAA-MM-JJ
  }

  const year = parseInt(dateString.substr(0, 4), 10);
  if (year < 1000 || year > 9999) {
    return false; // L'année doit avoir quatre chiffres
  }

  // Vous pouvez ajouter d'autres vérifications si nécessaire, par exemple, pour vérifier les mois et les jours

  return true;
}




