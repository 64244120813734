import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Annexe3Type } from "../../../Types/Entites/Annexe3Type";
import { RootState } from "../../store";

export const createAnnexe3Api = createAsyncThunk(
  "annexe3/createAnnexe3",
  async (data: Annexe3Type, { rejectWithValue }) => {
    try {
      const response = await axios.post("annexe3/createAnnexe3", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const findAllAnnexe3Api = createAsyncThunk(
  "annexe3/findAllAnnexe3",
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await axios.get("annexe3/findAllAnnexes3");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const updateAnnexe3Api = createAsyncThunk(
  "annexe3/updateAnnexe3",
  async (
    { id, data }: { id: number; data: Annexe3Type },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(`annexe3/updateAnnexe3/${id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const getAnnexe3ByIdApi = createAsyncThunk(
  "annexe3/getAnnexe3ById",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(`annexe3/getAnnexe3ById/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const deleteAnnexe3Api = createAsyncThunk(
  "annexe3/deleteAnnexe3",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`annexe3/deleteAnnexe3/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const findAnnexes3ByDeclarationApi = createAsyncThunk(
  "annexe3/findAnnexes3ByDeclaration",
  async (declarationId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `annexe3/findAnnexes3ByDeclaration/${declarationId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);



// Action asynchrone pour supprimer toutes les Annexe3 par ID de déclaration
export const deleteAllAnnexes3ByDeclarationApi = createAsyncThunk(
  "annexe2/deleteAllAnnexes3ByDeclaration",
  async (declarationId: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `annexe3/deleteAllAnnexes3ByDeclaration/${declarationId}`
      );
      return { declarationId, data: response.data };
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);


interface Annexe3s {
  annexe3List: Annexe3Type[];
  annexe3ListByDeclaration: Annexe3Type[];
}

const initialState: Annexe3s = {
  annexe3List: [],
  annexe3ListByDeclaration: [],
};

export const annexe3Slice = createSlice({
  name: "annexe3",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAnnexe3Api.fulfilled, (state, action) => {
      state.annexe3List.push(action.payload.data);
      state.annexe3ListByDeclaration.push(action.payload.data);
    });
    builder.addCase(findAllAnnexe3Api.fulfilled, (state, action) => {
      return {
        ...state,
        annexe3List: action.payload,
      };
    });
    builder.addCase(updateAnnexe3Api.fulfilled, (state, action) => {
      const updatedIndex = state.annexe3List.findIndex(
        (annexe3) => annexe3.id === action.payload.data.id
      );
      if (updatedIndex !== -1) {
        state.annexe3List[updatedIndex] = action.payload.data;
      }

      const updatedDeclarationIndex = state.annexe3ListByDeclaration.findIndex(
        (annexe3) => annexe3.id === action.payload.data.id
      );
      if (updatedDeclarationIndex !== -1) {
        state.annexe3ListByDeclaration[updatedDeclarationIndex] =
          action.payload.data;
      }
    });
    builder.addCase(getAnnexe3ByIdApi.fulfilled, (state, action) => {
      return {
        ...state,
        annexe3List: [action.payload],
      };
    });
    builder.addCase(deleteAnnexe3Api.fulfilled, (state, action) => {
      state.annexe3List = state.annexe3List.filter(annexe3 => annexe3.id !== action.meta.arg);
      state.annexe3ListByDeclaration = state.annexe3ListByDeclaration.filter(annexe3 => annexe3.id !== action.meta.arg);
    });
    builder.addCase(findAnnexes3ByDeclarationApi.fulfilled, (state, action) => {
      return {
        ...state,
        annexe3ListByDeclaration: action.payload,
      };
    });

    builder.addCase(deleteAllAnnexes3ByDeclarationApi.fulfilled, (state, action) => {
      state.annexe3List = state.annexe3List.filter(
        (annexe3) => annexe3.declaration !== action.payload.declarationId
      );
      state.annexe3ListByDeclaration = state.annexe3ListByDeclaration.filter(
        (annexe3) => annexe3.declaration !== action.payload.declarationId
      );
    });

  },
});

export const annexe3List = (state: RootState) => state.annexe3.annexe3List;
export const annexe3ListByDeclaration = (state: RootState) =>
  state.annexe3.annexe3ListByDeclaration;

export default annexe3Slice.reducer;
