import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { UserSocieteType } from "../../../Types/Entites/UserSocieteType";
import { SocieteType } from "../../../Types/Entites/SocieteType";
import { RootState } from "../../store";
import { createSocieteApi, deleteSocieteApi } from "../societes/societeSlice"; // Import de l'action de suppression du slice "societes"

// Actions asynchrones
export const findAllUserSocietesApi = createAsyncThunk(
  "userSocietes/findAllUserSocietes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/userSocietes/findAllUserSocietes");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur lors de la récupération des données",
      });
    }
  }
);

export const createUserSocieteApi = createAsyncThunk(
  "userSocietes/createUserSociete",
  async (data: UserSocieteType, { rejectWithValue }) => {
    try {
      const response = await axios.post("/userSociete/createUserSociete", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur lors de la création",
      });
    }
  }
);

export const deleteUserSocieteApi = createAsyncThunk(
  "userSocietes/deleteUserSociete",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/userSociete/deleteUserSociete/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur lors de la suppression",
      });
    }
  }
);

export const updateUserSocieteApi = createAsyncThunk(
  "userSocietes/updateUserSociete",
  async ({ id, data }: { id: number; data: UserSocieteType }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/userSocietes/updateUserSociete/${id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur lors de la mise à jour",
      });
    }
  }
);

export const findSocietesByUserIdApi = createAsyncThunk(
  "userSocietes/findSocietesByUserId",
  async (userId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/userSociete/user/${userId}/societes`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur lors de la récupération des sociétés",
      });
    }
  }
);

// Interface pour l'état du slice
interface UserSocietesState {
  userSocietes: UserSocieteType[];
  societesByUser: SocieteType[];
  loading: boolean;
  error: string | null;
}

// État initial
const initialState: UserSocietesState = {
  userSocietes: [],
  societesByUser: [],
  loading: false,
  error: null,
};

// Création du slice "userSocietes"
export const userSocietesSlice = createSlice({
  name: "userSocietes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(deleteSocieteApi.fulfilled, (state, action) => {
      const deletedSocieteId = action.meta.arg; // Récupère l'ID de la société supprimée
      state.societesByUser = state.societesByUser.filter(
        (societe) => societe.id !== deletedSocieteId
      ); // Met à jour "societesByUser" après suppression
    });

    // Cas des actions asynchrones
    builder.addCase(findAllUserSocietesApi.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(findAllUserSocietesApi.fulfilled, (state, action) => {
      state.userSocietes = action.payload;
      state.loading = false;
    });

    builder.addCase(createUserSocieteApi.fulfilled, (state, action) => {
    });

    builder.addCase(updateUserSocieteApi.fulfilled, (state, action) => {
      const updatedIndex = state.userSocietes.findIndex(
        (societe) => societe.id === action.payload.id
      );
      if (updatedIndex !== -1) {
        state.userSocietes[updatedIndex] = action.payload;
      }
    });

    builder.addCase(findSocietesByUserIdApi.fulfilled, (state, action) => {
      state.societesByUser = action.payload;
    });
    
    builder.addCase(deleteUserSocieteApi.fulfilled, (state, action) => {
      state.societesByUser = state.societesByUser.filter(
        (societe) => societe.id !== action.meta.arg
      );
    });
    builder.addCase(createSocieteApi.fulfilled, (state, action) => {
      state.societesByUser.push(action.payload.data);
    });

    
  },
});

// Sélecteurs
export const selectUserSocietes = (state: RootState) => state.userSocietes.userSocietes;
export const selectSocietesByUser = (state: RootState) => state.userSocietes.societesByUser;

export default userSocietesSlice.reducer;
