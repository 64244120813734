import { differenceInDays } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

import styled from "styled-components";
import * as XLSX from "xlsx";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  MatriculFiscalValidate,
  handleTabulation,
} from "../../_App/Helpers/helpers";
import {
  annexe1ListByDeclaration,
  createAnnexe1Api,
  deleteAnnexe1Api,
  findAnnexes1ByDeclarationApi,
  updateAnnexe1Api,
} from "../../_App/Redux/Slices/annexe1/annexe1Slice";
import {
  BeneficiairesList,
  createBeneficiaireApi,
  findAllBeneficiairesApi,
} from "../../_App/Redux/Slices/beneficiaire/beneficiaireSlice";
import {
  declarationsList,
  findAllDeclarationsApi,
} from "../../_App/Redux/Slices/declaration/declarationSlice";
import {
  exercicesList,
  findAllExercicesApi,
} from "../../_App/Redux/Slices/exercices/exerciceSlice";
import { findAllSocietesApi } from "../../_App/Redux/Slices/societes/societeSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { Annexe1Type } from "../../_App/Types/Entites/Annexe1Type";
import { ExerciceType } from "../../_App/Types/Entites/ExerciceType";
import "../AnnexesTab/Annexes.css";
import ImpressionAnnexe1 from "./ImpressionAnnexe1";

const FixedNewColumnCell = styled.th`
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedNewDataCell = styled.td`
  position: sticky;
  left: 0;
  z-index: 1;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedHeaderCell = styled.th`
  position: sticky;
  left: 37px;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedSecondHeaderCell = styled.th`
  position: sticky;
  left: 112px;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedThirdHeaderCell = styled.th`
  position: sticky;
  left: 255px;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedDataCell = styled.td`
  position: sticky;
  left: 37px;
  z-index: 1;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedSecondDataCell = styled.td`
  position: sticky;
  left: 112px;
  z-index: 1;
  padding: 2px !important;
  margin: 0 !important;
`;

const FixedThirdDataCell = styled.td`
  position: sticky;
  left: 255px;
  z-index: 1;
  padding: 2px !important;
  margin: 0 !important;
`;

interface Annexe1FormProps {
  declarationId: number;
}

const Annexe1Form: React.FC<Annexe1FormProps> = ({ declarationId }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    [
      findAllSocietesApi,
      findAllExercicesApi,
      findAllDeclarationsApi,
      findAllBeneficiairesApi,
    ].forEach((action) => dispatch(action(null)));

    declarationId && dispatch(findAnnexes1ByDeclarationApi(declarationId));
  }, [dispatch, declarationId]);

  const annexesList = useAppSelector(annexe1ListByDeclaration);
  const allExercices = useAppSelector(exercicesList);
  const allDeclarations = useAppSelector(declarationsList);

  const currentDeclaration = declarationId
    ? allDeclarations.find(
        (declaration) => declaration.id === parseInt(declarationId.toString())
      )
    : undefined;

  const currentExercice: ExerciceType | undefined = currentDeclaration
    ? allExercices.find(
        (exercice) => exercice.id === currentDeclaration.exercice
      )
    : undefined;

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,

    formState: { errors },
    setError,
    clearErrors,
  } = useForm<Annexe1Type>();
  const situationFamiliale = watch("situationFamiliale");
  const dateDebTravail = watch("dateDebTravail");
  const dateFinTravail = watch("dateFinTravail");

  const revenuImposable = isNaN(watch("revenuImposable") as number)
    ? 0
    : watch("revenuImposable");
  const valeurAvantageNature = isNaN(watch("valeurAvantageNature") as number)
    ? 0
    : watch("valeurAvantageNature");

  const totalRevenuBrutImposable = revenuImposable! + valeurAvantageNature!;

  const revenuReinvesti = isNaN(watch("revenuReinvesti") as number)
    ? 0
    : watch("revenuReinvesti");
  const retenuRegCommun = isNaN(watch("retenuRegCommun") as number)
    ? 0
    : watch("retenuRegCommun");
  const retenuOpereEtrang = isNaN(watch("retenuOpereEtrang") as number)
    ? 0
    : watch("retenuOpereEtrang");
  const contributionSociale = isNaN(watch("contributionSociale") as number)
    ? 0
    : watch("contributionSociale");
  let netServi: any;

  netServi =
    totalRevenuBrutImposable -
    (revenuReinvesti! +
      retenuRegCommun! +
      retenuOpereEtrang! +
      contributionSociale!);

  useEffect(() => {
    setValue(
      "totalRevenuBrutImposable",
      parseFloat(totalRevenuBrutImposable.toFixed(3))
    );
    setValue("netServi", parseFloat(netServi.toFixed(3)));
  }, [setValue, totalRevenuBrutImposable, netServi]);

  const [netServiValue, setNetServiValue] = useState(netServi);
  useEffect(() => {
    setNetServiValue(parseFloat(netServi.toFixed(3)));
  }, [netServi]);

  const [editableRows, setEditableRows] = useState<number[]>([]);
  const [editedValues, setEditedValues] = useState<Partial<Annexe1Type>>({});

  useEffect(() => {
    if (editedValues.dateDebTravail && editedValues.dateFinTravail) {
      const startDate = new Date(editedValues.dateDebTravail);
      const endDate = new Date(editedValues.dateFinTravail);

      const daysDifference = differenceInDays(endDate, startDate);

      setEditedValues((prevValues) => ({
        ...prevValues,
        periodeTravail: daysDifference,
      }));
    }
  }, [editedValues.dateDebTravail, editedValues.dateFinTravail]);

  const [daysDifference, setDaysDifference] = useState<number | null>(null);

  useEffect(() => {
    if (dateDebTravail && dateFinTravail) {
      const startDate = new Date(dateDebTravail);
      const endDate = new Date(dateFinTravail);

      const daysDifference = differenceInDays(endDate, startDate);
      setValue("periodeTravail", daysDifference);
      const difference = differenceInDays(endDate, startDate);
      setDaysDifference(difference);
    }
  }, [dateDebTravail, dateFinTravail, setValue]);

  const handleUpdateRow = async (rowIndex: number) => {
    try {
      const data = editedValues;
      const editedData = {
        ...annexesList[rowIndex],
        ...data,
      };

      const updateAction = await dispatch(
        updateAnnexe1Api({
          id: editedData.id || 0,
          data: editedData,
        })
      );

      if (updateAction.payload && updateAction.payload.success) {
        ToastSuccess(updateAction.payload.message);
        setEditableRows((prevEditableRows) =>
          prevEditableRows.filter((row) => row !== rowIndex)
        );
        setEditedValues({});
      } else {
        ToastWarning(updateAction.payload.message);
      }
    } catch (error) {
      console.error("Update failed", error);
      ToastWarning("Une erreur est survenue lors de la mise à jour");
    }
  };

  const [formKey, setFormKey] = useState(0);

  const societeId = currentDeclaration?.declarant;

  const onSubmit = async (data: any) => {
    try {
      data.declaration = declarationId;
      data.periodeTravail = daysDifference !== null ? daysDifference : 0;
      const createAction = await dispatch(createAnnexe1Api(data));

      if (createAction.payload && createAction.payload.success) {
        ToastSuccess(createAction.payload.message);

        const beneficiaireData = {
          typeIdentifiant: data.typeIdentifiant,
          identifiant: data.identifiantBeneficiaire,
          name: data.nomBeneficiaire,
          activity: data.emploiBenef,
          adresse: data.adresseBenef,
          situationFamiliale: data.situationFamiliale ?? null,
          nbrEnfant: data.nbrEnfant ?? null,
          societe: societeId || 0,
          typeBeneficiaire: "Salarié",
        };

        const beneficiaireExiste = beneficiaireList.some(
          (b) => b.identifiant === data.identifiantBeneficiaire
        );

        if (!beneficiaireExiste) {
          await dispatch(createBeneficiaireApi(beneficiaireData));
          if (createAction.payload && createAction.payload.success) {
            ToastSuccess("Bénéficiaire ajouté avec succès");
          } else {
            ToastWarning(createAction.payload?.message);
          }
        }

        reset();
        setFormKey((prevKey) => prevKey + 1);
      } else {
        ToastWarning(createAction.payload?.message);
      }
    } catch (error) {
      console.error("Échec de la soumission", error);
      ToastWarning(
        "Une erreur est survenue lors de la soumission du formulaire"
      );
    }
  };

  // const handleEditClick = (rowIndex: number) => {
  //   const valuesToEdit: Partial<Annexe1Type> = annexesList[rowIndex] || {};

  //   setEditedValues(valuesToEdit);

  //   if (editableRows.includes(rowIndex)) {
  //     handleUpdateRow(rowIndex);
  //   }

  //   setEditableRows((prevEditableRows) =>
  //     prevEditableRows.includes(rowIndex)
  //       ? prevEditableRows.filter((row) => row !== rowIndex)
  //       : [...prevEditableRows, rowIndex]
  //   );
  // };

  type FormControlChangeEvent = React.ChangeEvent<
    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  >;

  // const handleInputChange = (e: FormControlChangeEvent, fieldName: string) => {
  //   const { value } = e.target;
  //   setEditedValues((prevValues) => ({
  //     ...prevValues,
  //     [fieldName]: value,
  //   }));
  // };

  const handleInputChangeAndValidation = (name: any, value: any) => {
    const regex = /^\d+(\.\d{1,3})?$/;

    if (value === "") {
      setValue(name, 0.0);
      clearErrors(name);
    } else if (regex.test(value)) {
      setValue(name, parseFloat(value));
      clearErrors(name);
    } else {
      setError(name, {
        type: "manual",
        message:
          "Veuillez saisir un nombre valide avec un maximum de 3 chiffres après le point décimal.",
      });
    }
  };

  const [dateDebTravailValue, setDateDebTravailValue] = useState<string>("");
  const [dateFinTravailValue, setDateFinTravailValue] = useState<string>("");

  useEffect(() => {
    if (currentExercice) {
      const dateDebut = new Date(currentExercice.dateDebut);
      const dateFin = new Date(currentExercice.dateFin);

      setDateDebTravailValue(dateDebut.toISOString().split("T")[0]);
      setDateFinTravailValue(dateFin.toISOString().split("T")[0]);

      setValue("dateDebTravail", dateDebut || "");
      setValue("dateFinTravail", dateFin || "");
    }
  }, [currentExercice, setValue]);

  const [excelData, setExcelData] = useState<any[]>([]);

  const parseDate = (dateString: any) => {
    const [day, month, year] = dateString.split("/").map(Number);

    const date = new Date(year, month - 1, day);

    date.setHours(12);
    date.setMinutes(12);
    date.setSeconds(12);

    return date;
  };

  const handleAddRowFromExcel = async (rowData: any) => {
    const errors = validateExcelData([rowData]);
    const hasErrors = errors.some((error) => error.rowIndex === 0);

    if (hasErrors) {
      ToastWarning(
        "La ligne contient des erreurs. Corrigez-les avant d'ajouter."
      );
      return;
    }

    try {
      const newData = {
        typeIdentifiant: rowData[0],
        identifiantBeneficiaire: rowData[1],
        nomBeneficiaire: rowData[2],
        emploiBenef: rowData[3],
        adresseBenef: rowData[4],
        situationFamiliale: rowData[5],
        nbrEnfant: rowData[6],
        // Conversion manuelle des dates
        dateDebTravail: parseDate(rowData[7]),
        dateFinTravail: parseDate(rowData[8]),
        revenuImposable: rowData[9],
        valeurAvantageNature: rowData[10],
        totalRevenuBrutImposable: rowData[11],
        revenuReinvesti: rowData[12],
        retenuRegCommun: rowData[13],
        retenuOpereEtrang: rowData[14],
        contributionSociale: rowData[15],
        netServi: rowData[16],
        declaration: declarationId,
        periodeTravail: daysDifference !== null ? daysDifference : 0,
      };

      const createAction = await dispatch(createAnnexe1Api(newData));

      if (createAction.payload && createAction.payload.success) {
        ToastSuccess(createAction.payload.message);
        setExcelData((prevExcelData) =>
          prevExcelData.filter((row) => row !== rowData)
        );
      } else if (createAction.payload && createAction.payload.message) {
        ToastWarning(createAction.payload.message);
      } else {
        ToastWarning("Une erreur s'est produite lors de l'ajout de la ligne.");
      }
    } catch (error) {
      console.error("Addition of row from Excel failed", error);
    }
  };

  const exportToExcel = () => {
    const filteredAnnexesList = annexesList.map(
      ({ id, type, declaration, numOrdre, periodeTravail, ...rest }) => {
        const formattedRest = Object.entries(rest).reduce(
          (acc, [key, value]) => {
            if (key === "dateDebTravail" || key === "dateFinTravail") {
              const formattedDate = format(new Date(value), "dd/MM/yyyy");
              return {
                ...acc,
                [key]: formattedDate,
              };
            }
            return { ...acc, [key]: value };
          },
          {}
        );

        return formattedRest;
      }
    );

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredAnnexesList);
    XLSX.utils.book_append_sheet(wb, ws, "Annexes");
    XLSX.writeFile(wb, "annexe1.xlsx");
  };

  const handleRemoveRowFromExcel = (rowIndex: any) => {
    const updatedExcelData = [...excelData];
    updatedExcelData.splice(rowIndex, 1);
    setExcelData(updatedExcelData);
  };

  const beneficiaireList = useAppSelector(BeneficiairesList);

  const identifiant = watch("identifiantBeneficiaire");
  const typeIdentifiant = watch("typeIdentifiant");

  useEffect(() => {
    if (identifiant) {
      const beneficiaire = beneficiaireList.find(
        (b) =>
          b.identifiant === identifiant && b.typeIdentifiant == typeIdentifiant
      );

      if (beneficiaire) {
        setValue("nomBeneficiaire", beneficiaire.name);
        setValue("emploiBenef", beneficiaire.activity);
        setValue("adresseBenef", beneficiaire.adresse);
        const situationFamiliale = beneficiaire.situationFamiliale ?? 0;
        setValue("situationFamiliale", situationFamiliale);

        setValue("nbrEnfant", beneficiaire.nbrEnfant);
      }
    }
  }, [identifiant, setValue, beneficiaireList]);

  const handleIdentifiantInputChange = (e: any) => {
    const value = e.target.value;
    const typeIdentifiant = +watch("typeIdentifiant");

    checkForDuplicates(value, typeIdentifiant);

    if (typeIdentifiant === 3) {
      const truncatedValue = value.slice(0, 13);
      setValue("identifiantBeneficiaire", truncatedValue);

      if (truncatedValue.length < 8) {
        setError("identifiantBeneficiaire", {
          type: "manual",
          message: "Format identifiant incorrect",
        });
      } else {
        clearErrors("identifiantBeneficiaire");
      }
    } else if (typeIdentifiant === 2) {
      const digitsOnly = value.replace(/\D/g, "");
      const truncatedValue = digitsOnly.slice(0, 8);
      setValue("identifiantBeneficiaire", truncatedValue);

      if (truncatedValue.length !== 8) {
        setError("identifiantBeneficiaire", {
          type: "manual",
          message: "Format CIN incorrect",
        });
      } else {
        clearErrors("identifiantBeneficiaire");
      }
    }
  };

  const printRef = useRef<HTMLDivElement>(null);

  const handlePrint = () => {
    const printContents = printRef.current;

    if (printContents) {
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents.innerHTML;
      window.print();

      document.body.innerHTML = originalContents;

      window.location.reload();
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const handleDeleteRow = (id: number) => {
    if (id !== undefined) {
      setDeleteId(id);
      setShowDeleteModal(true);
    }
  };
  const handleConfirmDelete = async () => {
    if (deleteId !== null) {
      try {
        const deleteAction = await dispatch(deleteAnnexe1Api(deleteId));

        if (deleteAction.payload && deleteAction.payload.success) {
          ToastSuccess(deleteAction.payload.message);
          setShowDeleteModal(false);
        } else if (deleteAction.payload && deleteAction.payload.message) {
          ToastWarning(deleteAction.payload.message);
        }

        setDeleteId(null);
      } catch (error) {
        console.error("La suppression a échoué", error);
        ToastWarning("Une erreur est survenue lors de la suppression.");
      }
    }
  };
  // gestion des doublons
  // gestion des doublons
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showTypeErrorModal, setShowTypeErrorModal] = useState(false);
  const [duplicateIndex, setDuplicateIndex] = useState<number | null>(null);

  // Fonction pour vérifier les doublons
  const checkForDuplicates = (identifiant: string, typeIdentifiant: number) => {
    const index = annexesList.findIndex(
      (annexe) => annexe.identifiantBeneficiaire === identifiant
    );

    if (index !== -1) {
      setDuplicateIndex(index);

      setShowDuplicateModal(true);
    } else {
      setDuplicateIndex(null);
      setShowDuplicateModal(false);
      setShowTypeErrorModal(false);
    }
  };

  const handleContinue = () => {
    if (duplicateIndex != null) {
      const existingType = annexesList[duplicateIndex].typeIdentifiant;

      if (existingType != typeIdentifiant) {
        setShowTypeErrorModal(true);

        setShowDuplicateModal(false);
      } else {
        setShowDuplicateModal(false);
      }
    }
  };

  const handleEdit = () => {
    if (duplicateIndex != null) {
      handleEditClick(duplicateIndex);
      setSearchQuery(annexesList[duplicateIndex].identifiantBeneficiaire); // Mettre à jour la recherche avec l'identifiant modifié

      reset();
      setShowDuplicateModal(false);
    }
  };

  // recherche

  const [searchQuery, setSearchQuery] = useState("");

  const filteredAnnexes = annexesList.filter((annexe) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      annexe.typeIdentifiant.toString().includes(lowerCaseQuery) ||
      annexe.identifiantBeneficiaire.toLowerCase().includes(lowerCaseQuery) ||
      annexe.nomBeneficiaire.toLowerCase().includes(lowerCaseQuery) ||
      annexe.emploiBenef.toLowerCase().includes(lowerCaseQuery) ||
      annexe.adresseBenef.toLowerCase().includes(lowerCaseQuery) ||
      annexe.situationFamiliale.toString().includes(lowerCaseQuery) ||
      annexe.nbrEnfant?.toString().includes(lowerCaseQuery) ||
      annexe.revenuImposable?.toString().includes(lowerCaseQuery) ||
      annexe.valeurAvantageNature?.toString().includes(lowerCaseQuery) ||
      annexe.totalRevenuBrutImposable?.toString().includes(lowerCaseQuery) ||
      annexe.revenuReinvesti?.toString().includes(lowerCaseQuery) ||
      annexe.retenuRegCommun?.toString().includes(lowerCaseQuery) ||
      annexe.retenuOpereEtrang?.toString().includes(lowerCaseQuery) ||
      annexe.contributionSociale?.toString().includes(lowerCaseQuery) ||
      annexe.netServi?.toString().includes(lowerCaseQuery)
    );
  });

  // validate excel

  type ExcelDataRow = (string | number | null | undefined)[];

  type ExcelError = {
    rowIndex: number;
    cellIndices: number[];
  };

  // Fonction de validation pour les données Excel
  const validateExcelData = (data: ExcelDataRow[]): ExcelError[] => {
    const errors: ExcelError[] = [];
    let situationFamilialeValue: number | null = null;
    data.forEach((row, rowIndex) => {
      const errorIndices: number[] = [];
      row.forEach((cell, cellIndex) => {
        let hasError = false;
        let value = String(cell);

        switch (cellIndex) {
          case 0: // Type d'identifiant
            const validIdentifiantTypes = [2, 3];
            if (!validIdentifiantTypes.includes(Number(value))) {
              hasError = true;
            }
            break;

          case 1: // Identifiant
            const typeIdentifiant = Number(row[0]);
            if (typeIdentifiant === 2) {
              const digitsOnly = value.replace(/\D/g, "");
              if (digitsOnly.length !== 8) {
                hasError = true;
              } else if (
                ([3].includes(typeIdentifiant) && value.length < 8) ||
                value.length > 13
              ) {
                hasError = true;
              }
            }
            break;

          case 2: // Nom
            if (value.length > 40) {
              hasError = true;
            }
            break;
          case 3: // Activité
            if (value.length > 40) {
              hasError = true;
            }
            break;

          case 4: // Adresse
            if (value.length > 120) {
              hasError = true;
            }
            break;

          case 5: // situation familiale
            const validValues = [1, 2, 3, 4];
            const numericValue = Number(value);

            if (!validValues.includes(numericValue)) {
              hasError = true;
            } else {
              // Conserver la valeur de situation familiale pour utilisation ultérieure
              situationFamilialeValue = numericValue;
            }
            break;

          case 6: // Autre contrôle qui dépend de situation familiale
            const numericValue6 = Number(value);

            // Si situation familiale est 1, case 6 doit être obligatoirement 0
            if (situationFamilialeValue === 1) {
              if (numericValue6 !== 0) {
                hasError = true;
              }
            } else {
              // Autre contrôle pour 0 à 99 (deux chiffres)
              if (
                isNaN(numericValue6) ||
                numericValue6 < 0 ||
                numericValue6 > 99 ||
                Math.floor(numericValue6) !== numericValue6
              ) {
                hasError = true;
              }
            }
            break;

          case 9:
          case 10:
          case 11:
          case 12:
          case 13:
          case 14:
          case 15:
          case 16:
            value = value.replace(/[^\d.]/g, "");
            const parts = value.split(".");
            if (parts.length > 2 || (parts[1] && parts[1].length > 3)) {
              hasError = true;
            } else {
              const numericValue = parseFloat(value);
              if (isNaN(numericValue) || numericValue < 0) {
                hasError = true;
              }
            }
            break;
        }

        if (hasError) {
          errorIndices.push(cellIndex);
        }
      });

      if (errorIndices.length > 0) {
        errors.push({ rowIndex, cellIndices: errorIndices });
      }
    });

    return errors;
  };

  const [excelErrors, setExcelErrors] = useState<ExcelError[]>([]);

  const handleExcelInputChange = (
    rowIndex: number,
    cellIndex: number,
    newValue: string
  ) => {
    const updatedExcelData = [...excelData];
    updatedExcelData[rowIndex][cellIndex] = newValue;

    setExcelData(updatedExcelData);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result;
      if (result instanceof ArrayBuffer) {
        const data = new Uint8Array(result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const rawData = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
        }) as ExcelDataRow[];

        const validatedData = rawData.slice(1); // Retirer les en-têtes
        setExcelData(validatedData);
        setExcelErrors(validateExcelData(validatedData));
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const isExcelLoaded = excelData.length > 0;

  const validateExcel = () => {
    const errors = validateExcelData(excelData);
    setExcelErrors(errors);

    if (errors.length === 0) {
      console.log("Données Excel valides.");
    } else {
      console.log("Des erreurs existent dans les données Excel.");
    }
  };

  // update

  const [rowErrors, setRowErrors] = useState<{
    [key: number]: { [key: string]: boolean };
  }>({});

  const handleInputChange = (
    e: FormControlChangeEvent,
    fieldName: string,
    rowIndex: number
  ) => {
    const { value } = e.target;

    const hasError = validateField(fieldName, value, rowIndex);

    setRowError(rowIndex, fieldName, hasError); // Met à jour les erreurs de la ligne

    setEditedValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  const validateField = (
    fieldName: string,
    value: string,
    rowIndex: number
  ): boolean => {
    let hasError = false;

    switch (fieldName) {
      case "typeIdentifiant":
        const validIdentifiantTypes = [2, 3];
        if (!validIdentifiantTypes.includes(Number(value))) {
          hasError = true;
        }
        break;

      case "identifiantBeneficiaire":
        const typeIdentifiant = Number(editedValues.typeIdentifiant);
        if (typeIdentifiant === 2) {
          const digitsOnly = value.replace(/\D/g, "");
          if (digitsOnly.length !== 8) {
            hasError = true;
          }
        } else if (typeIdentifiant === 3) {
          if (value.length < 8 || value.length > 13) {
            hasError = true;
          }
        }
        break;

      case "nomBeneficiaire":
        if (value.length > 40) {
          hasError = true;
        }
        break;

      case "emploiBenef":
        if (value.length > 40) {
          hasError = true;
        }
        break;

      case "adresseBenef":
        if (value.length > 120) {
          hasError = true;
        }
        break;

      case "situationFamiliale":
        const validValues = [1, 2, 3, 4];
        const numericValue = Number(value);

        if (!validValues.includes(numericValue)) {
          hasError = true;
        }
        break;

      case "nbrEnfant":
        const numericValue6 = Number(value);

        const situationFamilialeValue = Number(editedValues.situationFamiliale);

        if (situationFamilialeValue === 1) {
          if (numericValue6 !== 0) {
            hasError = true;
          }
        } else {
          if (
            isNaN(numericValue6) ||
            numericValue6 < 0 ||
            numericValue6 > 99 ||
            Math.floor(numericValue6) !== numericValue6
          ) {
            hasError = true;
          }
        }
        break;

      case "revenuImposable":
      case "valeurAvantageNature":
      case "totalRevenuBrutImposable":
      case "revenuReinvesti":
      case "retenuRegCommun":
      case "retenuOpereEtrang":
      case "contributionSociale":
      case "netServi":
        value = value.replace(/[^\d.]/g, ""); // Ne conserve que les chiffres et les points
        const parts = value.split(".");
        if (parts.length > 2 || (parts[1] && parts[1].length > 3)) {
          hasError = true; // Vérifie si le format du montant est valide (maximum un point et trois chiffres après le point)
        } else {
          const numericValue = parseFloat(value);
          if (isNaN(numericValue) || numericValue < 0) {
            hasError = true; // Vérifie si la valeur est un nombre valide et positif
          }
        }
        break;

      // Ajoutez d'autres cas de validation pour d'autres champs si nécessaire

      default:
        break;
    }

    return hasError;
  };

  const setRowError = (
    rowIndex: number,
    fieldName: string,
    hasError: boolean
  ) => {
    setRowErrors((prevRowErrors) => ({
      ...prevRowErrors,
      [rowIndex]: {
        ...prevRowErrors[rowIndex],
        [fieldName]: hasError,
      },
    }));
  };

  const handleEditClick = (rowIndex: number) => {
    const rowHasErrors = Object.values(rowErrors[rowIndex] || {}).some(
      (error) => error
    );

    if (rowHasErrors) {
      ToastWarning("Veuillez corriger les erreurs avant de continuer.");

      return;
    }

    const valuesToEdit: Partial<Annexe1Type> = annexesList[rowIndex] || {};

    setEditedValues(valuesToEdit);

    if (editableRows.includes(rowIndex)) {
      handleUpdateRow(rowIndex);
    }

    setEditableRows((prevEditableRows) =>
      prevEditableRows.includes(rowIndex)
        ? prevEditableRows.filter((row) => row !== rowIndex)
        : [...prevEditableRows, rowIndex]
    );
  };

  return (
    <div
      key={formKey}
      style={{
        overflowX: "auto",
        width: "100%",
        scrollbarWidth: "thin",
        scrollbarColor: "rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1)",
      }}
    >
      <div style={{ display: "flex", marginTop: "20px" }}>
        <div style={{ flex: 0.7 }}>
          <p>
            Traitements, salaires, pensions, rentes viagères payés, contribution
            sociale de solidarité et la contribution provisoire et
            exceptionnelle
          </p>
        </div>

        <div
          style={{
            flex: 0.3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              height: "32px",
            }}
            onClick={handlePrint}
            variant="info"
          >
            Imprimer
          </Button>
          <div ref={printRef} style={{ display: "none" }}>
            <ImpressionAnnexe1 annexesList={annexesList} />
          </div>
          <Button
            variant="light"
            onClick={exportToExcel}
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              height: "32px",
            }}
          >
            Save as Excel
            <i
              className="fas fa-download"
              style={{ color: "green", fontSize: "18px", marginLeft: "10px" }}
            ></i>
          </Button>

          <Button
            variant="light"
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "32px",
            }}
          >
            <label
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                color: "black",
              }}
            >
              <input
                type="file"
                accept=".xls,.xlsx"
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
              Import Excel
              <i
                className="far fa-file-excel"
                style={{ color: "green", fontSize: "18px", marginLeft: "10px" }}
              ></i>
            </label>
          </Button>
        </div>
      </div>

      <div
        style={{
          marginTop: "10px",
          padding: "10px",
          display: "flex",
          justifyContent: "space-between", // Les éléments se répartissent entre début et fin
          alignItems: "center", // Pour aligner verticalement
        }}
      >
        <span style={{ fontWeight: "bold", fontSize: "1.1em", color: "black" }}>
          {`Nombre total de bénéficiaires : ${annexesList.length}`}
        </span>

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>
            {isExcelLoaded && (
              <Button
                variant="success"
                onClick={validateExcel}
                style={{
                  marginRight: "15px",
                  height: "30px",
                  fontSize: "0.8em",
                  marginBottom: "7px",
                }}
              >
                Valider l'import
              </Button>
            )}
          </span>

          <Form.Control
            type="text"
            placeholder="Rechercher..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{
              width: "200px",
              height: "30px",
              textAlign: "center",
            }}
          />
        </div>
      </div>
      <Form onKeyDown={handleTabulation} onSubmit={handleSubmit(onSubmit)}>
        <Table
          bordered
          striped
          hover
          className="table-responsive"
          style={{ padding: "0", margin: "0", marginTop: "10px" }}
        >
          <thead>
            <tr>
              <FixedNewColumnCell>
                <div
                  style={{
                    width: "30px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  N°
                </div>
              </FixedNewColumnCell>

              <FixedHeaderCell>
                <div
                  style={{
                    width: "70px",
                    display: "inline-block",
                    fontSize: "0.85em",
                  }}
                >
                  Type ID
                </div>
              </FixedHeaderCell>
              <FixedSecondHeaderCell>
                <div
                  style={{
                    width: "135px",
                    display: "inline-block",
                    fontSize: "0.85em",
                  }}
                >
                  Identifiant
                </div>
              </FixedSecondHeaderCell>
              <FixedThirdHeaderCell>
                <div
                  style={{
                    width: "140px",
                    display: "inline-block",
                    fontSize: "0.85em",
                  }}
                >
                  Nom et prénom
                </div>
              </FixedThirdHeaderCell>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "140px",
                    display: "inline-block",
                    fontSize: "0.85em",
                  }}
                >
                  Activité
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "160px",
                    display: "inline-block",
                    fontSize: "0.85em",
                  }}
                >
                  Adresse
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "100px",
                    display: "inline-block",
                    fontSize: "0.85em",
                  }}
                >
                  S. Familiale
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "90px",
                    display: "inline-block",
                    fontSize: "0.85em",
                  }}
                >
                  Nbr d'Enf
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "30px",
                    display: "inline-block",
                    fontSize: "0.85em",
                  }}
                >
                  Date Début
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "30px",
                    display: "inline-block",
                    fontSize: "0.85em",
                  }}
                >
                  Date Fin
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "160px",
                    display: "inline-block",
                    fontSize: "0.85em",
                  }}
                >
                  Revenu Imposable
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "160px",
                    display: "inline-block",
                    fontSize: "0.85em",
                  }}
                >
                  Avantage Nature
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "160px",
                    display: "inline-block",
                    fontSize: "0.85em",
                  }}
                >
                  Total B.Imposable
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "160px",
                    display: "inline-block",
                    fontSize: "0.85em",
                  }}
                >
                  Revenu Réinvesti
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "170px",
                    display: "inline-block",
                    fontSize: "0.85em",
                  }}
                >
                  Retenu Régime Commun
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "170px",
                    display: "inline-block",
                    fontSize: "0.85em",
                  }}
                >
                  Retenu Opérée Étranger
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "160px",
                    display: "inline-block",
                    fontSize: "0.85em",
                  }}
                >
                  Contribution Sociale
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "170px",
                    display: "inline-block",
                    fontSize: "0.85em",
                  }}
                >
                  Net Servi
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "180px",
                    display: "inline-block",
                    fontSize: "0.85em",
                  }}
                >
                  Action
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {/* Lignes pour les éléments existants dans annexe1List */}
            {filteredAnnexes.map((annexe1: Annexe1Type, rowIndex) => (
              <tr
                key={annexe1.id}
                onDoubleClick={() => handleEditClick(rowIndex)}
              >
                <FixedNewDataCell
                  style={{ padding: "4px", margin: "0", textAlign: "center" }}
                >
                  {rowIndex + 1}
                </FixedNewDataCell>
                <FixedDataCell>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    as="select"
                    name="typeIdentifiant"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.typeIdentifiant ||
                          annexe1.typeIdentifiant
                        : annexe1.typeIdentifiant
                    }
                    onChange={(e) =>
                      handleInputChange(e, "typeIdentifiant", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  >
                    <option value={2}>2 : CIN</option>
                    <option value={3}>3 : CS</option>
                  </Form.Control>
                </FixedDataCell>

                <FixedSecondDataCell>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border:
                        rowErrors[rowIndex]?.identifiantBeneficiaire !==
                          undefined &&
                        rowErrors[rowIndex]?.identifiantBeneficiaire
                          ? "1px solid red"
                          : "1px solid #ced4da",
                    }}
                    type="text"
                    name="identifiantBeneficiaire"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.identifiantBeneficiaire !== undefined
                          ? editedValues.identifiantBeneficiaire
                          : annexe1.identifiantBeneficiaire
                        : annexe1.identifiantBeneficiaire
                    }
                    onChange={(e) =>
                      handleInputChange(e, "identifiantBeneficiaire", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </FixedSecondDataCell>

                <FixedThirdDataCell>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.nomBeneficiaire
                        ? "1px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="text"
                    name="nomBeneficiaire"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.nomBeneficiaire !== undefined
                          ? editedValues.nomBeneficiaire
                          : annexe1.nomBeneficiaire
                        : annexe1.nomBeneficiaire
                    }
                    onChange={(e) =>
                      handleInputChange(e, "nomBeneficiaire", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </FixedThirdDataCell>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.emploiBenef
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="text"
                    name="emploiBenef"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.emploiBenef !== undefined
                          ? editedValues.emploiBenef
                          : annexe1.emploiBenef
                        : annexe1.emploiBenef
                    }
                    onChange={(e) =>
                      handleInputChange(e, "emploiBenef", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.adresseBenef
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="text"
                    name="adresseBenef"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.adresseBenef !== undefined
                          ? editedValues.adresseBenef
                          : annexe1.adresseBenef
                        : annexe1.adresseBenef
                    }
                    onChange={(e) =>
                      handleInputChange(e, "adresseBenef", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.situationFamiliale
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    as="select"
                    name="situationFamiliale"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.situationFamiliale ||
                          annexe1.situationFamiliale
                        : annexe1.situationFamiliale
                    }
                    onChange={(e) =>
                      handleInputChange(e, "situationFamiliale", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  >
                    <option value={1}>1 : Célibataire</option>
                    <option value={2}>2 : Marié</option>
                    <option value={3}>3 : Divorcé</option>
                    <option value={4}>4 : Veuf</option>
                  </Form.Control>
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.nbrEnfant
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name="nbrEnfant"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.nbrEnfant !== undefined
                          ? editedValues.nbrEnfant
                          : annexe1.nbrEnfant
                        : annexe1.nbrEnfant
                    }
                    onChange={(e) =>
                      handleInputChange(e, "nbrEnfant", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="date"
                    name="dateDebTravail"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.dateDebTravail
                          ? new Date(editedValues.dateDebTravail)
                              .toISOString()
                              .split("T")[0]
                          : ""
                        : annexe1.dateDebTravail
                        ? new Date(annexe1.dateDebTravail)
                            .toISOString()
                            .split("T")[0]
                        : ""
                    }
                    onChange={(e) =>
                      handleInputChange(e, "dateDebTravail", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="date"
                    name="dateFinTravail"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.dateFinTravail
                          ? new Date(editedValues.dateFinTravail)
                              .toISOString()
                              .split("T")[0]
                          : ""
                        : annexe1.dateFinTravail
                        ? new Date(annexe1.dateFinTravail)
                            .toISOString()
                            .split("T")[0]
                        : ""
                    }
                    onChange={(e) =>
                      handleInputChange(e, "dateFinTravail", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>

                {/* <td>
                  <Form.Control
                    type="number"
                    name="periodeTravail"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.periodeTravail || annexe1.periodeTravail
                        : annexe1.periodeTravail
                    }
                    onChange={(e) => handleInputChange(e, "periodeTravail")}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td> */}
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.revenuImposable
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="text"
                    step="0.01"
                    name="revenuImposable"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.revenuImposable !== undefined
                          ? editedValues.revenuImposable
                          : annexe1.revenuImposable?.toFixed(3)
                        : annexe1.revenuImposable?.toFixed(3)
                    }
                    onChange={(e) =>
                      handleInputChange(e, "revenuImposable", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.valeurAvantageNature
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="text"
                    step="0.001"
                    name="valeurAvantageNature"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.valeurAvantageNature !== undefined
                          ? editedValues.valeurAvantageNature
                          : annexe1.valeurAvantageNature?.toFixed(3)
                        : annexe1.valeurAvantageNature?.toFixed(3)
                    }
                    onChange={(e) =>
                      handleInputChange(e, "valeurAvantageNature", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.totalRevenuBrutImposable
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="text"
                    step="0.001"
                    name="totalRevenuBrutImposable"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.totalRevenuBrutImposable !== undefined
                          ? editedValues.totalRevenuBrutImposable
                          : annexe1.totalRevenuBrutImposable?.toFixed(3)
                        : annexe1.totalRevenuBrutImposable?.toFixed(3)
                    }
                    onChange={(e) =>
                      handleInputChange(e, "totalRevenuBrutImposable", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.revenuReinvesti
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    step="0.001"
                    name="revenuReinvesti"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.revenuReinvesti !== undefined
                          ? editedValues.revenuReinvesti
                          : annexe1.revenuReinvesti?.toFixed(3)
                        : annexe1.revenuReinvesti?.toFixed(3)
                    }
                    onChange={(e) =>
                      handleInputChange(e, "revenuReinvesti", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.retenuRegCommun
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    step="0.001"
                    name="retenuRegCommun"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.retenuRegCommun !== undefined
                          ? editedValues.retenuRegCommun
                          : annexe1.retenuRegCommun?.toFixed(3)
                        : annexe1.retenuRegCommun?.toFixed(3)
                    }
                    onChange={(e) =>
                      handleInputChange(e, "retenuRegCommun", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.retenuOpereEtrang
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    step="0.001"
                    name="retenuOpereEtrang"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.retenuOpereEtrang !== undefined
                          ? editedValues.retenuOpereEtrang
                          : annexe1.retenuOpereEtrang?.toFixed(3)
                        : annexe1.retenuOpereEtrang?.toFixed(3)
                    }
                    onChange={(e) =>
                      handleInputChange(e, "retenuOpereEtrang", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.contributionSociale
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    step="0.001"
                    name="contributionSociale"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.contributionSociale !== undefined
                          ? editedValues.contributionSociale
                          : annexe1.contributionSociale?.toFixed(3)
                        : annexe1.contributionSociale?.toFixed(3)
                    }
                    onChange={(e) =>
                      handleInputChange(e, "contributionSociale", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.netServi
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    step="0.001"
                    name="netServi"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.netServi !== undefined
                          ? editedValues.netServi
                          : annexe1.netServi?.toFixed(3)
                        : annexe1.netServi?.toFixed(3)
                    }
                    onChange={(e) => handleInputChange(e, "netServi", rowIndex)}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <span
                    className={`action-icon ${
                      editableRows.includes(rowIndex) ? "success" : "warning"
                    }`}
                    onClick={() => handleEditClick(rowIndex)}
                    title={
                      editableRows.includes(rowIndex)
                        ? "Enregistrer"
                        : "Modifier"
                    }
                  >
                    <i
                      className={`fas ${
                        editableRows.includes(rowIndex) ? "fa-check" : "fa-edit"
                      }`}
                      style={{
                        color: editableRows.includes(rowIndex)
                          ? "#008000"
                          : "#0000ff",
                        marginRight: "8px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
                  <span
                    className="action-icon danger"
                    onClick={() => annexe1.id && handleDeleteRow(annexe1.id)}
                    title="Supprimer une ligne"
                  >
                    <i
                      className="far fa-trash-alt"
                      style={{
                        color: "#ff0000",
                        marginRight: "13px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
                </td>
              </tr>
            ))}

            {/* Ligne d'ajout  avec excel*/}

            {excelData.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                <FixedNewDataCell
                  style={{ padding: "4px", margin: "0", textAlign: "center" }}
                ></FixedNewDataCell>
                {/* Composants fixes pour les premières colonnes */}
                {[0, 1, 2].map((cellIndex) => {
                  const hasError = excelErrors.some(
                    (error) =>
                      error.rowIndex === rowIndex &&
                      error.cellIndices.includes(cellIndex)
                  );

                  const borderStyle = hasError
                    ? "2px solid red"
                    : "1px solid grey";
                  const cellStyle = {
                    padding: "3px",
                    margin: "0",
                    border: borderStyle,
                  };

                  let CellComponent;
                  switch (cellIndex) {
                    case 0:
                      CellComponent = FixedDataCell;
                      break;
                    case 1:
                      CellComponent = FixedSecondDataCell;
                      break;
                    case 2:
                      CellComponent = FixedThirdDataCell;
                      break;

                    default:
                      CellComponent = "td"; // Default case
                  }

                  return (
                    <CellComponent key={cellIndex}>
                      <Form.Control
                        style={{
                          fontSize: "1em",
                          height: "33px",
                          border: borderStyle,
                          borderRadius: "4px",
                        }}
                        type="text"
                        value={rowData[cellIndex]}
                        onChange={(e) =>
                          handleExcelInputChange(
                            rowIndex,
                            cellIndex,
                            e.target.value
                          )
                        }
                      />
                    </CellComponent>
                  );
                })}

                {/* Autres cellules */}
                {rowData.slice(3).map((cell: any, additionalCellIndex: any) => {
                  const actualCellIndex = additionalCellIndex + 3;
                  const hasError = excelErrors.some(
                    (error) =>
                      error.rowIndex === rowIndex &&
                      error.cellIndices.includes(actualCellIndex)
                  );

                  const borderStyle = hasError
                    ? "2px solid red"
                    : "1px solid grey";
                  const cellStyle = {
                    padding: "3px",
                    margin: "0",
                  };

                  return (
                    <td key={actualCellIndex} style={cellStyle}>
                      <Form.Control
                        style={{
                          fontSize: "1em",
                          height: "33px",
                          border: borderStyle,
                          borderRadius: "4px",
                        }}
                        type="text"
                        value={String(cell)}
                        onChange={(e) =>
                          handleExcelInputChange(
                            rowIndex,
                            actualCellIndex,
                            e.target.value
                          )
                        }
                      />
                    </td>
                  );
                })}

                {/* Actions pour ajouter/supprimer */}
                <td style={{ padding: "4px", margin: "0" }}>
                  <Button
                    variant="primary"
                    onClick={() => handleAddRowFromExcel(rowData)}
                  >
                    Ajouter
                  </Button>
                  <span
                    className="action-icon danger"
                    onClick={() => handleRemoveRowFromExcel(rowIndex)}
                    title="Retirer cette ligne"
                  >
                    <i
                      className="fas fa-times"
                      style={{
                        color: "red",
                        marginLeft: "10px",
                        fontSize: "30px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
                </td>
              </tr>
            ))}
            <tr>
              <FixedNewDataCell></FixedNewDataCell>

              <FixedDataCell>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  as="select"
                  {...register("typeIdentifiant")}
                  onChange={(e) => {
                    setValue("typeIdentifiant", parseInt(e.target.value, 10));
                  }}
                >
                  <option value={""}></option>

                  <option value={2}>2 : CIN</option>
                  <option value={3}>3 : CS</option>
                </Form.Control>
              </FixedDataCell>
              <FixedSecondDataCell>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="identifiantBenificiaire"
                  onChange={handleIdentifiantInputChange}
                  maxLength={watch("typeIdentifiant") == 3 ? 13 : 8}
                  isInvalid={!!errors.identifiantBeneficiaire}
                />
                {errors.identifiantBeneficiaire && (
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ fontSize: "0.8rem" }}
                  >
                    {errors.identifiantBeneficiaire.message}
                  </Form.Control.Feedback>
                )}
              </FixedSecondDataCell>

              <FixedThirdDataCell>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="nomBeneficiaire"
                  className={errors.nomBeneficiaire ? "is-invalid" : ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    const truncatedValue = value.slice(0, 40);
                    e.target.value = truncatedValue;
                    setValue("nomBeneficiaire", truncatedValue);
                  }}
                  value={watch("nomBeneficiaire") || ""}
                />
              </FixedThirdDataCell>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="emploiBenef"
                  className={errors.emploiBenef ? "is-invalid" : ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    const truncatedValue = value.slice(0, 40);
                    setValue("emploiBenef", truncatedValue);
                  }}
                  value={watch("emploiBenef") || ""}
                />
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="adresseBenef"
                  className={errors.adresseBenef ? "is-invalid" : ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    const truncatedValue = value.slice(0, 120);
                    setValue("adresseBenef", truncatedValue);
                  }}
                  value={watch("adresseBenef") || ""}
                />
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  defaultValue={""}
                  as="select"
                  name="situationFamiliale"
                  onChange={(e) => {
                    setValue(
                      "situationFamiliale",
                      parseInt(e.target.value, 10)
                    );
                    if (parseInt(e.target.value, 10) === 1) {
                      setValue("nbrEnfant", 0);
                    } else {
                      setValue("nbrEnfant", undefined);
                    }
                  }}
                  value={watch("situationFamiliale") || ""}
                >
                  <option value={""}></option>
                  <option value={1}>1 : Célibataire</option>
                  <option value={2}>2 : Marié</option>
                  <option value={3}>3 : Divorcé</option>
                  <option value={4}>4 : Veuf</option>
                </Form.Control>
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="nbrEnfant"
                  onChange={(e) => {
                    const { value } = e.target;
                    const digitsOnly = value.replace(/\D/g, "");
                    const truncatedValue = digitsOnly.slice(0, 2);
                    e.target.value = truncatedValue;
                    setValue(
                      "nbrEnfant",
                      truncatedValue !== ""
                        ? parseInt(truncatedValue, 10)
                        : undefined
                    );
                  }}
                  disabled={situationFamiliale === 1}
                  value={
                    situationFamiliale === 1
                      ? 0
                      : watch("nbrEnfant") || undefined
                  }
                />
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="date"
                  name="dateDebTravail"
                  value={dateDebTravailValue}
                  onChange={(e) => {
                    setDateDebTravailValue(e.target.value);
                    setValue("dateDebTravail", new Date(e.target.value));
                  }}
                />
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="date"
                  name="dateFinTravail"
                  value={dateFinTravailValue}
                  onChange={(e) => {
                    setDateFinTravailValue(e.target.value);

                    setValue("dateFinTravail", new Date(e.target.value));
                  }}
                />
              </td>

              {/* <td>
                <Form.Control
                  type="number"
                  name="periodeTravail"
                  readOnly
                  value={watch("periodeTravail")}
                />
              </td> */}

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="revenuImposable"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("revenuImposable", value);
                  }}
                />
                {errors.revenuImposable && (
                  <span style={{ color: "red" }}>
                    {errors.revenuImposable.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="valeurAvantageNature"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation(
                      "valeurAvantageNature",
                      value
                    );
                  }}
                />
                {errors.valeurAvantageNature && (
                  <span style={{ color: "red" }}>
                    {errors.valeurAvantageNature.message}
                  </span>
                )}
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  step="0.001"
                  name="totalRevenuBrutImposable"
                  value={totalRevenuBrutImposable.toFixed(3)}
                  onChange={(e) =>
                    setValue(
                      "totalRevenuBrutImposable",
                      parseFloat(e.target.value)
                    )
                  }
                />
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="revenuReinvesti"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("revenuReinvesti", value);
                  }}
                />
                {errors.revenuReinvesti && (
                  <span style={{ color: "red" }}>
                    {errors.revenuReinvesti.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="retenuRegCommun"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("retenuRegCommun", value);
                  }}
                />
                {errors.retenuRegCommun && (
                  <span style={{ color: "red" }}>
                    {errors.retenuRegCommun.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="retenuOpereEtrang"
                  disabled={watch("typeIdentifiant") == 2}
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("retenuOpereEtrang", value);
                  }}
                />
                {errors.retenuOpereEtrang && (
                  <span style={{ color: "red" }}>
                    {errors.retenuOpereEtrang.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="contributionSociale"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation(
                      "contributionSociale",
                      value
                    );
                  }}
                />
                {errors.contributionSociale && (
                  <span style={{ color: "red" }}>
                    {errors.contributionSociale.message}
                  </span>
                )}
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="netServi"
                  value={netServiValue}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    setNetServiValue(value);

                    handleInputChangeAndValidation("netServi", value);
                  }}
                />
                {errors.netServi && (
                  <span style={{ color: "red" }}>
                    {errors.netServi.message}
                  </span>
                )}
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Button variant="primary" type="submit">
                  Ajouter
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Form>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cet élément ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDuplicateModal}
        onHide={() => {
          setShowDuplicateModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Identifiant déjà utilisé</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Cet identifiant est déjà utilisé. Voulez-vous continuer ou modifier la
          ligne existante?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleContinue}>
            Continuer
          </Button>
          <Button variant="primary" onClick={handleEdit}>
            Modifier
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showTypeErrorModal}
        onHide={() => setShowTypeErrorModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Erreur de type d'identifiant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Le type d'identifiant ne correspond pas à la ligne existante. Veuillez
          vérifier votre saisie.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowTypeErrorModal(false)}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Annexe1Form;
