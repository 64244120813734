import React from 'react';
import Login from '../../Components/Login/LoginForm';

const LoginPage: React.FC = () => {
  return (
    <div>
      <Login />
    </div>
  );
}

export default LoginPage;

