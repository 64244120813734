import React from "react";
import Navbar from "../../_Layouts/Navbar/Navbar";
import SocieteList from "../../Components/Societe/SocieteList";
const SocietePage= () => {
  return (
    <div>
      <Navbar titre={"Sociétés"} />

      <SocieteList />
    </div>
  );
};

export default SocietePage;
