import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { Asterisk } from "../../_App/Helpers/helpers";
import {
  actesList,
  findAllActesApi,
} from "../../_App/Redux/Slices/acte/acteSlice";
import { createDeclarationApi } from "../../_App/Redux/Slices/declaration/declarationSlice";
import {
  exercicesListByDeclarant,
  findExercicesByDeclarantApi,
} from "../../_App/Redux/Slices/exercices/exerciceSlice";
import { createRecapApi } from "../../_App/Redux/Slices/recap/recapSlice";
import { findAllRecapParamApi } from "../../_App/Redux/Slices/recapParam/recapParamSlice";
import {
  findAllSocietesApi,
  societesList,
} from "../../_App/Redux/Slices/societes/societeSlice";
import { RootState } from "../../_App/Redux/store";
import { ActeType } from "../../_App/Types/Entites/ActeType";
import { DeclarationType } from "../../_App/Types/Entites/DeclarationType";
import { ExerciceType } from "../../_App/Types/Entites/ExerciceType";
import DeclarationSchema from "../../_App/Types/Forms/Schema/DeclarationSchema";
import ExerciceForm from "../Exercice/ExerciceForm";
import SocieteForm from "../Societe/SocieteForm";
interface DeclarationFormProps {
  closeForm: () => void;
  declarantId?: number;
}

const DeclarationForm: React.FC<DeclarationFormProps> = ({
  closeForm,
  declarantId,
}) => {
  const dispatch = useAppDispatch();
  const societes = useAppSelector(societesList);

  const filteredSocietes = societes.filter(
    (societe) => societe.id == declarantId
  );

  useEffect(() => {}, [declarantId]);

  useEffect(() => {}, [filteredSocietes]);

  const actes = useAppSelector(actesList);
  const exercices = useAppSelector(exercicesListByDeclarant);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DeclarationType>({
    resolver: yupResolver(DeclarationSchema),
  });

  useEffect(() => {
    dispatch(findAllActesApi(null));
    dispatch(findAllSocietesApi(null));
  }, [dispatch]);

  useEffect(() => {
    if (declarantId !== undefined) {
      dispatch(findExercicesByDeclarantApi(declarantId));
    }
  }, [declarantId, dispatch]);

  const recapParamList = useAppSelector(
    (state: RootState) => state.recapParam.recapParamList
  );

  useEffect(() => {
    dispatch(findAllRecapParamApi(null));
  }, [dispatch]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit: SubmitHandler<DeclarationType> = async (data) => {
    setIsSubmitting(true);
    try {
      await DeclarationSchema.validate(data);
      const response = await dispatch(createDeclarationApi(data));

      if (response.payload.success) {
        ToastSuccess(response.payload.message);

        const newDeclarationId = response.payload.data.id;

        const sortedRecapParams = [...recapParamList].sort(
          (a, b) => a.code - b.code
        );

        for (const { id, ...recapParam } of sortedRecapParams) {
          const recapData = {
            ...recapParam,
            assiette: 0,
            retenue: 0,
            declaration: newDeclarationId,
          };

          await dispatch(createRecapApi(recapData));
        }
      } else {
        ToastWarning(response.payload.message);
      }
      closeForm();
    } catch (error) {
      console.error("Validation failed", error);
      ToastWarning("Une erreur s'est produite lors de l'enregistrement.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const [showCreateExerciceForm, setShowCreateExerciceForm] = useState(false);

  const openCreateExerciceForm = () => {
    setShowCreateExerciceForm(true);
  };

  const closeCreateExerciceForm = () => {
    setShowCreateExerciceForm(false);
  };
  const [showCreateSocieteForm, setShowCreateSocieteForm] = useState(false);

  const openCreateSocieteForm = () => {
    setShowCreateSocieteForm(true);
  };

  const closeCreateSocieteForm = () => {
    setShowCreateSocieteForm(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col>
          <Form.Group controlId="date">
            <Form.Label>
              Date <Asterisk>*</Asterisk>
            </Form.Label>
            <Form.Control
              type="date"
              {...register("date")}
              className={errors.date ? "is-invalid" : ""}
            />
            {errors.date && (
              <Form.Control.Feedback type="invalid">
                {errors.date.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="declarant">
            <Form.Label>
              Déclarant <Asterisk>*</Asterisk>
            </Form.Label>
            <Form.Control
              as="select"
              {...register("declarant")}
              className={errors.declarant ? "is-invalid" : ""}
            >
              <option value={""}></option>
              {filteredSocietes.map((societe) => (
                <option key={societe.id} value={societe.id}>
                  {societe.name}
                </option>
              ))}
            </Form.Control>
            {errors.declarant && (
              <Form.Control.Feedback type="invalid">
                {errors.declarant.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="exercice">
            <Form.Label>
              Exercice <Asterisk>*</Asterisk>
              <span
                onClick={openCreateExerciceForm}
                style={{ cursor: "pointer", marginLeft: "5px" }}
              >
                <i
                  className="fas fa-plus"
                  style={{ marginRight: "5px", color: "green" }}
                />{" "}
              </span>
            </Form.Label>
            <Form.Control
              as="select"
              {...register("exercice")}
              className={errors.exercice ? "is-invalid" : ""}
            >
              {exercices.map((exercice: ExerciceType) => (
                <option key={exercice.id} value={exercice.id}>
                  {exercice.libelle}
                </option>
              ))}
            </Form.Control>
            {errors.exercice && (
              <Form.Control.Feedback type="invalid">
                {errors.exercice.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>

      <Row>
        {/* Première colonne */}
        <Col xs={5}>
          <Card
            style={{ marginTop: "20px", height: "170px", marginBottom: "20px" }}
          >
            <Card.Body>
              <Card.Title>
                Acte <Asterisk>*</Asterisk>
              </Card.Title>
              <Form.Group controlId="acte">
                {actes.map((acte: ActeType) => (
                  <Form.Check
                    key={acte.id}
                    type="radio"
                    label={acte.libelle}
                    value={acte.id}
                    {...register("acte")}
                  />
                ))}
                {errors.acte && (
                  <div className="text-danger">{errors.acte.message}</div>
                )}
              </Form.Group>
            </Card.Body>
          </Card>
        </Col>

        {/* Deuxième colonne avec la deuxième card divisée en deux */}
        <Col xs={7}>
          <Card style={{ marginTop: "20px" }}>
            <Card.Body>
              <Card.Title>
                Annexes à déposer <Asterisk>*</Asterisk>
              </Card.Title>
              <Row>
                {/* Première moitié des checkboxes */}
                <Col xs={4}>
                  <Form.Group controlId="annexe1">
                    <Form.Check
                      type="checkbox"
                      label="Annexe 1"
                      {...register("annexe1")}
                      className={errors.annexe1 ? "is-invalid" : ""}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Annexe 2"
                      {...register("annexe2")}
                      className={errors.annexe2 ? "is-invalid" : ""}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Annexe 3"
                      {...register("annexe3")}
                      className={errors.annexe3 ? "is-invalid" : ""}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Annexe 4"
                      {...register("annexe4")}
                      className={errors.annexe4 ? "is-invalid" : ""}
                    />
                  </Form.Group>
                </Col>

                {/* Deuxième moitié des checkboxes */}
                <Col xs={4}>
                  <Form.Group controlId="annexe5">
                    <Form.Check
                      type="checkbox"
                      label="Annexe 5"
                      {...register("annexe5")}
                      className={errors.annexe5 ? "is-invalid" : ""}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Annexe 6"
                      {...register("annexe6")}
                      className={errors.annexe6 ? "is-invalid" : ""}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Annexe 7"
                      {...register("annexe7")}
                      className={errors.annexe7 ? "is-invalid" : ""}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col></Col>
        <Col></Col>

        <Col>
          <Modal show={showCreateExerciceForm} onHide={closeCreateExerciceForm}>
            <Modal.Header closeButton>
              <Modal.Title>Créer un nouvel exercice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ExerciceForm
                show={showCreateExerciceForm}
                closeForm={closeCreateExerciceForm}
                declarantId={declarantId}
              />
            </Modal.Body>
          </Modal>

          <Modal
            show={showCreateSocieteForm}
            onHide={closeCreateSocieteForm}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Créer une nouvelle société</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SocieteForm closeForm={closeCreateSocieteForm} />
            </Modal.Body>
          </Modal>
        </Col>
      </Row>

      <Button
        type="submit"
        variant="success"
        style={{ float: "right", marginTop: "10px" }}
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Enregistrement...
          </>
        ) : (
          "Enregistrer"
        )}
      </Button>
    </Form>
  );
};

export default DeclarationForm;
