import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import * as XLSX from "xlsx";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  MatriculFiscalValidate,
  handleTabulation,
} from "../../_App/Helpers/helpers";
import {
  annexe7ListByDeclaration,
  createAnnexe7Api,
  deleteAnnexe7Api,
  findAnnexes7ByDeclarationApi,
  updateAnnexe7Api,
} from "../../_App/Redux/Slices/annexe7/annexe7Slice";
import {
  BeneficiairesList,
  createBeneficiaireApi,
  findAllBeneficiairesApi,
} from "../../_App/Redux/Slices/beneficiaire/beneficiaireSlice";
import {
  declarationsList,
  findAllDeclarationsApi,
} from "../../_App/Redux/Slices/declaration/declarationSlice";
import { findAllSocietesApi } from "../../_App/Redux/Slices/societes/societeSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { Annexe7Type } from "../../_App/Types/Entites/Annexe7Type";
import ImpressionAnnexe7 from "./ImpressionAnnexe7";

interface Annexe7FormProps {
  declarationId: number;
}

const Annexe7Form: React.FC<Annexe7FormProps> = ({ declarationId }) => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<Annexe7Type>();
  const annexesList = useAppSelector(annexe7ListByDeclaration);
  const [editableRows, setEditableRows] = useState<number[]>([]);
  const [editedValues, setEditedValues] = useState<Partial<Annexe7Type>>({});

  type FormControlChangeEvent = React.ChangeEvent<
    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  >;

  const handleRemoveRowFromExcel = (rowIndex: any) => {
    const updatedExcelData = [...excelData];
    updatedExcelData.splice(rowIndex, 1);
    setExcelData(updatedExcelData);
  };

  const handleAddRowFromExcel = async (rowData: any) => {
    const errors = validateExcelData([rowData]);
    const hasErrors = errors.some((error) => error.rowIndex === 0);

    if (hasErrors) {
      ToastWarning(
        "La ligne contient des erreurs. Corrigez-les avant d'ajouter."
      );
      return;
    }

    try {
      const newData: Annexe7Type = {
        typeIdentifiant: rowData[0],
        identifiant: rowData[1],
        categorie: rowData[2],
        name: rowData[3],
        activity: rowData[4],
        adresse: rowData[5],
        typeMontantPaye: rowData[6],
        montantPaye: rowData[7],
        retenuSource: rowData[8],
        netServi: rowData[9],
        declaration: declarationId,
      };

      await dispatch(createAnnexe7Api(newData));

      setExcelData((prevExcelData) =>
        prevExcelData.filter((row) => row !== rowData)
      );
    } catch (error) {
      console.error("Addition of row from Excel failed", error);
    }
  };

  const handleUpdateRow = async (rowIndex: number) => {
    try {
      const data = editedValues;
      const editedData = {
        ...annexesList[rowIndex],
        ...data,
      };

      const updateAction = await dispatch(
        updateAnnexe7Api({
          id: editedData.id || 0,
          data: editedData,
        })
      );

      if (updateAction.payload) {
        setEditableRows((prevEditableRows) =>
          prevEditableRows.filter((row) => row !== rowIndex)
        );
        setEditedValues({});
      }
    } catch (error) {
      console.error("Update failed", error);
    }
  };

  useEffect(() => {
    [
      findAllSocietesApi,
      findAllDeclarationsApi,
      findAllBeneficiairesApi,
    ].forEach((action) => dispatch(action(null)));

    declarationId && dispatch(findAnnexes7ByDeclarationApi(declarationId));
  }, [dispatch, declarationId]);

  const allDeclarations = useAppSelector(declarationsList);

  const currentDeclaration = declarationId
    ? allDeclarations.find(
        (declaration) => declaration.id === parseInt(declarationId.toString())
      )
    : undefined;
  const societeId = currentDeclaration?.declarant;
  const [formKey, setFormKey] = useState(0);

  const onSubmit: SubmitHandler<Annexe7Type> = async (data) => {
    try {
      data.declaration = declarationId;
      const createAction = await dispatch(createAnnexe7Api(data));

      if (createAction.payload && createAction.payload.success) {
        ToastSuccess(createAction.payload.message);
        reset();

        const beneficiaireData = {
          typeIdentifiant: data.typeIdentifiant,
          identifiant: data.identifiant,
          name: data.name,
          activity: data.activity,
          adresse: data.adresse,

          societe: societeId || 0,
          typeBeneficiaire: "Fournisseur",
        };

        const beneficiaireExiste = beneficiaireList.some(
          (b) => b.identifiant === data.identifiant
        );

        if (!beneficiaireExiste) {
          const createBeneficiaireAction = await dispatch(
            createBeneficiaireApi(beneficiaireData)
          );

          if (
            createBeneficiaireAction.payload &&
            createBeneficiaireAction.payload.success
          ) {
            ToastSuccess("Bénéficiaire ajouté avec succès");
          } else {
            ToastWarning(createBeneficiaireAction.payload?.message);
          }
        }

        setFormKey((prevKey) => prevKey + 1);
      } else {
        ToastWarning(createAction.payload?.message);
      }
    } catch (error) {
      console.error("La soumission a échoué", error);
      ToastWarning(
        "Une erreur est survenue lors de la soumission du formulaire"
      );
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const handleDeleteRow = (id: number) => {
    if (id !== undefined) {
      setDeleteId(id);
      setShowDeleteModal(true);
    }
  };
  const handleConfirmDelete = async () => {
    if (deleteId !== null) {
      try {
        const deleteAction = await dispatch(deleteAnnexe7Api(deleteId));

        if (deleteAction.payload && deleteAction.payload.success) {
          reset();
          ToastSuccess(deleteAction.payload.message);
          setShowDeleteModal(false);
        } else if (deleteAction.payload && deleteAction.payload.message) {
          ToastWarning(deleteAction.payload.message);
        }

        setDeleteId(null);
      } catch (error) {
        console.error("La suppression a échoué", error);
        ToastWarning("Une erreur est survenue lors de la suppression.");
      }
    }
  };

  const handleInputChangeAndValidation = (name: any, value: any) => {
    const regex = /^\d+(\.\d{1,3})?$/;

    if (value === "") {
      setValue(name, 0.0);
      clearErrors(name);
    } else if (regex.test(value)) {
      setValue(name, parseFloat(value));
      clearErrors(name);
    } else {
      setError(name, {
        type: "manual",
        message:
          "Veuillez saisir un nombre valide avec un maximum de 3 chiffres après le point décimal.",
      });
    }
  };

  const montantPaye = parseFloat(String(watch("montantPaye") || "0"));
  const retenuSource = parseFloat(String(watch("retenuSource") || "0"));

  const netServi = montantPaye - retenuSource;

  useEffect(() => {
    setValue("netServi", parseFloat(netServi.toFixed(3)));
  }, [setValue, netServi]);

  const [netServiValue, setNetServiValue] = useState(netServi.toFixed(3));
  useEffect(() => {
    setNetServiValue(netServi.toFixed(3));
  }, [netServi]);

  const exportToExcel = () => {
    const filteredAnnexesList = annexesList.map(
      ({ id, type, declaration, numOrdre, ...rest }) => rest
    );

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredAnnexesList);
    XLSX.utils.book_append_sheet(wb, ws, "Annexes");
    XLSX.writeFile(wb, "annexe7.xlsx");
  };

  const printRef = useRef<HTMLDivElement>(null);

  const handlePrint = () => {
    const printContents = printRef.current;

    if (printContents) {
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents.innerHTML;
      window.print();

      document.body.innerHTML = originalContents;

      window.location.reload();
    }
  };

  const beneficiaireList = useAppSelector(BeneficiairesList);

  const identifiantBeneficiaire = watch("identifiant");

  useEffect(() => {
    if (identifiantBeneficiaire) {
      const beneficiaire = beneficiaireList.find(
        (b) =>
          b.identifiant === identifiantBeneficiaire &&
          b.typeIdentifiant == typeIdentifiant
      );

      if (beneficiaire) {
        setValue("name", beneficiaire.name);
        setValue("activity", beneficiaire.activity);
        setValue("adresse", beneficiaire.adresse);
      }
    }
  }, [identifiantBeneficiaire, setValue, beneficiaireList]);

  // gestion des doublons
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [duplicateIndex, setDuplicateIndex] = useState<number | null>(null);

  const [showTypeErrorModal, setShowTypeErrorModal] = useState(false); // Pour contrôler le modal de type d'identifiant
  const typeIdentifiant = watch("typeIdentifiant"); // Nouveau contrôle

  const checkForDuplicates = (identifiant: string, typeIdentifiant: number) => {
    const index = annexesList.findIndex(
      (annexe) => annexe.identifiant === identifiant
    );

    if (index !== -1) {
      setDuplicateIndex(index);

      setShowDuplicateModal(true);
    } else {
      setDuplicateIndex(null);
      setShowDuplicateModal(false);
      setShowTypeErrorModal(false);
    }
  };

  const handleContinue = () => {
    if (duplicateIndex != null) {
      const existingType = annexesList[duplicateIndex].typeIdentifiant;

      if (existingType != typeIdentifiant) {
        setShowTypeErrorModal(true);

        setShowDuplicateModal(false);
      } else {
        setShowDuplicateModal(false);
      }
    }
  };

  const handleEdit = () => {
    if (duplicateIndex != null) {
      handleEditClick(duplicateIndex);
      setSearchQuery(annexesList[duplicateIndex].identifiant); // Mettre à jour la recherche avec l'identifiant modifié

      reset();
      setShowDuplicateModal(false);
    }
  };
  // recherche
  const [searchQuery, setSearchQuery] = useState("");

  const filteredAnnexes = annexesList.filter((annexe) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      annexe.type?.toLowerCase().includes(lowerCaseQuery) ||
      annexe.identifiant.toLowerCase().includes(lowerCaseQuery) ||
      annexe.categorie.toString().includes(lowerCaseQuery) ||
      annexe.name.toLowerCase().includes(lowerCaseQuery) ||
      annexe.activity.toLowerCase().includes(lowerCaseQuery) ||
      annexe.adresse.toLowerCase().includes(lowerCaseQuery) ||
      annexe.typeMontantPaye.toString().includes(lowerCaseQuery) ||
      annexe.montantPaye.toString().includes(lowerCaseQuery) ||
      annexe.retenuSource.toString().includes(lowerCaseQuery) ||
      annexe.netServi.toString().includes(lowerCaseQuery)
    );
  });

  // validation excel

  type ExcelDataRow = (string | number | null | undefined)[];

  type ExcelError = {
    rowIndex: number;
    cellIndices: number[];
  };
  const validateExcelData = (data: ExcelDataRow[]): ExcelError[] => {
    const errors: ExcelError[] = [];

    data.forEach((row, rowIndex) => {
      const errorIndices: number[] = [];

      row.forEach((cell, cellIndex) => {
        let hasError = false;
        let value = String(cell);

        switch (cellIndex) {
          case 0:
            const validTypes = [1, 2, 3, 4];
            if (!validTypes.includes(Number(value))) {
              hasError = true;
            }
            break;

          case 1: // Identifiant
            const typeIdentifiant = Number(row[0]);
            if (typeIdentifiant === 1) {
              if (!MatriculFiscalValidate(value)) {
                hasError = true;
              } else if (value.length !== 13) {
                hasError = true;
              }
            } else if (typeIdentifiant === 2) {
              const digitsOnly = value.replace(/\D/g, "");
              if (digitsOnly.length !== 8) {
                hasError = true;
              }
            } else if (typeIdentifiant === 3 || typeIdentifiant === 4) {
              if (value.length < 8 || value.length > 13) {
                hasError = true;
              }
            }
            break;

          case 2: // Catégorie
            if (![1, 2].includes(Number(value))) {
              hasError = true;
            }
            break;

          case 3: // Nom
            if (value.length > 40) {
              hasError = true;
            }
            break;

          case 4: // Activité
            if (value.length > 40) {
              hasError = true;
            }
            break;

          case 5: // Adresse
            if (value.length > 120) {
              hasError = true;
            }
            break;

          case 6: // Type de Montant Payé
            if (
              ![
                1, 2, 3, 4, 5, 6, 7, 8, 15, 16, 17, 19, 20, 21, 22, 23, 24, 25,
                26, 27,
              ].includes(Number(value))
            ) {
              hasError = true;
            }
            break;
          case 7: // Montant Payé
          case 8: // Retenu à la Source
          case 9: // Net Servi
            // Nettoyer et formater la valeur
            value = value.replace(/[^\d.]/g, ""); // autoriser uniquement des chiffres et un seul point décimal
            const parts = value.split(".");
            if (parts.length > 2 || (parts[1] && parts[1].length > 3)) {
              hasError = true;
            } else {
              const numericValue = parseFloat(value);
              if (isNaN(numericValue) || numericValue < 0) {
                hasError = true;
              }
            }
            break;
        }
        if (hasError) {
          errorIndices.push(cellIndex);
        }
      });

      if (errorIndices.length > 0) {
        errors.push({ rowIndex, cellIndices: errorIndices });
      }
    });

    return errors;
  };

  const [excelErrors, setExcelErrors] = useState<ExcelError[]>([]);

  const handleExcelInputChange = (
    rowIndex: number,
    cellIndex: number,
    newValue: string
  ) => {
    const updatedExcelData = [...excelData];
    updatedExcelData[rowIndex][cellIndex] = newValue;

    setExcelData(updatedExcelData);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result;
      if (result instanceof ArrayBuffer) {
        const data = new Uint8Array(result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const rawData = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
        }) as ExcelDataRow[];

        const validatedData = rawData.slice(1); // Retirer les en-têtes
        setExcelData(validatedData);
        setExcelErrors(validateExcelData(validatedData));
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const [excelData, setExcelData] = useState<ExcelDataRow[]>([]);

  const isExcelLoaded = excelData.length > 0;

  const validateExcel = () => {
    const errors = validateExcelData(excelData);
    setExcelErrors(errors);

    if (errors.length === 0) {
      console.log("Données Excel valides.");
    } else {
      console.log("Des erreurs existent dans les données Excel.");
    }
  };

  // update

  // update

  const [rowErrors, setRowErrors] = useState<{
    [key: number]: { [key: string]: boolean };
  }>({});

  const handleInputChange = (
    e: FormControlChangeEvent,
    fieldName: string,
    rowIndex: number
  ) => {
    const { value } = e.target;

    const hasError = validateField(fieldName, value, rowIndex);

    if (hasError && editableRows.includes(rowIndex)) {
      setRowError(rowIndex, fieldName, true);
    } else if (editableRows.includes(rowIndex)) {
      setRowError(rowIndex, fieldName, false);
    }

    setEditedValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  const validateField = (
    fieldName: string,
    value: string,
    rowIndex: number
  ): boolean => {
    let hasError = false;

    switch (fieldName) {
      case "typeIdentifiant":
        const validIdentifiantTypes = [1, 2, 3, 4];
        if (!validIdentifiantTypes.includes(Number(value))) {
          hasError = true;
        }
        break;

      case "identifiant":
        const typeIdentifiant = Number(editedValues.typeIdentifiant);
        if (typeIdentifiant === 1) {
          if (!MatriculFiscalValidate(value)) {
            hasError = true;
          } else if (value.length !== 13) {
            hasError = true;
          }
        } else if (typeIdentifiant === 2) {
          const digitsOnly = value.replace(/\D/g, "");
          if (digitsOnly.length !== 8) {
            hasError = true;
          }
        } else if (typeIdentifiant === 3 || typeIdentifiant === 4) {
          if (value.length < 8 || value.length > 13) {
            hasError = true;
          }
        }
        break;

      case "categorie":
        if (![1, 2].includes(Number(value))) {
          hasError = true;
        }
        break;

      case "name":
        if (value.length > 40) {
          hasError = true;
        }
        break;

      case "activity":
        if (value.length > 40) {
          hasError = true;
        }
        break;

      case "adresse":
        if (value.length > 120) {
          hasError = true;
        }
        break;

      case "montantPaye":
      case "retenuSource":
      case "netServi":
        value = value.replace(/[^\d.]/g, ""); // Ne conserve que les chiffres et les points
        const parts = value.split(".");
        if (parts.length > 2 || (parts[1] && parts[1].length > 3)) {
          hasError = true; // Vérifie si le format du montant est valide (maximum un point et trois chiffres après le point)
        } else {
          const numericValue = parseFloat(value);
          if (isNaN(numericValue) || numericValue < 0) {
            hasError = true; // Vérifie si la valeur est un nombre valide et positif
          }
        }
        break;

      default:
        break;
    }

    return hasError;
  };

  const setRowError = (
    rowIndex: number,
    fieldName: string,
    hasError: boolean
  ) => {
    setRowErrors((prevRowErrors) => ({
      ...prevRowErrors,
      [rowIndex]: {
        ...prevRowErrors[rowIndex],
        [fieldName]: hasError,
      },
    }));
  };

  const handleEditClick = (rowIndex: number) => {
    const rowHasErrors = Object.values(rowErrors[rowIndex] || {}).some(
      (error) => error
    );

    if (rowHasErrors) {
      ToastWarning("Veuillez corriger les erreurs avant de continuer.");

      return;
    }

    const valuesToEdit: Partial<Annexe7Type> = annexesList[rowIndex] || {};

    setEditedValues(valuesToEdit);

    if (editableRows.includes(rowIndex)) {
      handleUpdateRow(rowIndex);
    }

    setEditableRows((prevEditableRows) =>
      prevEditableRows.includes(rowIndex)
        ? prevEditableRows.filter((row) => row !== rowIndex)
        : [...prevEditableRows, rowIndex]
    );
  };

  return (
    <div
      style={{
        overflowX: "auto",
        width: "100%",
        scrollbarWidth: "thin",
        scrollbarColor: "rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1)",
      }}
    >
      <div style={{ display: "flex", marginTop: "10px" }}>
        <div style={{ flex: 0.7, marginRight: "5px", paddingRight: "20px" }}>
          <p
            style={{
              textAlign: "justify",
              lineHeight: "1.5",
              margin: "10px 0",
            }}
          >
            Montants payés pour autrui (L’avant dernier sous paragraphe du
            paragraphe I de l’article 52 du code de l’impôts sur les sociétés et
            de l’impôt sur le revenu).
          </p>
        </div>

        <div
          style={{
            flex: 0.3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              height: "32px",
            }}
            onClick={handlePrint}
            variant="info"
          >
            Imprimer
          </Button>
          <div ref={printRef} style={{ display: "none" }}>
            <ImpressionAnnexe7 annexesList={annexesList} />
          </div>

          <Button
            variant="light"
            onClick={exportToExcel}
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              height: "32px",
            }}
          >
            Save as Excel
            <i
              className="fas fa-download"
              style={{ color: "green", fontSize: "18px", marginLeft: "10px" }}
            ></i>
          </Button>

          <Button
            variant="light"
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "32px",
            }}
          >
            <label
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                color: "black",
              }}
            >
              <input
                type="file"
                accept=".xls,.xlsx"
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
              Import Excel
              <i
                className="far fa-file-excel"
                style={{ color: "green", fontSize: "18px", marginLeft: "10px" }}
              ></i>
            </label>
          </Button>
        </div>
      </div>
      <div
        style={{
          marginTop: "10px",
          padding: "10px",
          display: "flex",
          justifyContent: "space-between", // Les éléments se répartissent entre début et fin
          alignItems: "center", // Pour aligner verticalement
        }}
      >
        {/* Premier span aligné à gauche */}
        <span style={{ fontWeight: "bold", fontSize: "1.1em", color: "black" }}>
          {`Nombre total de bénéficiaires : ${annexesList.length}`}
        </span>

        {/* Deuxième et troisième span alignés à droite */}
        <div
          style={{
            display: "flex",
            alignItems: "center", // Pour aligner verticalement les éléments
          }}
        >
          <span>
            {isExcelLoaded && (
              <Button
                variant="success"
                onClick={validateExcel}
                style={{
                  marginRight: "15px", // Espacement entre le bouton et le champ de recherche
                  height: "30px",
                  fontSize: "0.8em",
                  marginBottom: "7px",
                }}
              >
                Valider l'import
              </Button>
            )}
          </span>

          {/* Champ de recherche à droite */}
          <Form.Control
            type="text"
            placeholder="Rechercher..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{
              width: "200px",
              height: "30px",
              textAlign: "center",
            }}
          />
        </div>
      </div>

      <Form onKeyDown={handleTabulation} onSubmit={handleSubmit(onSubmit)}>
        <Table bordered striped hover className="table-responsive">
          <thead>
            <tr>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "30px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  N°
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "70px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Type ID
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "135px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Identifiant
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "100px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Catégorie
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "140px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Nom ou raison soc
                </div>
              </th>

              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "140px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Activité
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "140px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Adresse
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "80px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Type Montant Payé
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "150px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Montant Payé
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "150px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Retenu à la Source
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "150px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Net Servi
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "200px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Action
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredAnnexes.map((annexe7: Annexe7Type, rowIndex) => (
              <tr
                key={annexe7.id}
                onDoubleClick={() => handleEditClick(rowIndex)}
              >
                <td
                  style={{ padding: "4px", margin: "0", textAlign: "center" }}
                >
                  {rowIndex + 1}
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    as="select"
                    name="typeIdentifiant"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.typeIdentifiant ||
                          annexe7.typeIdentifiant
                        : annexe7.typeIdentifiant
                    }
                    onChange={(e) =>
                      handleInputChange(e, "typeIdentifiant", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  >
                    <option value={1}>1 : MF</option>
                    <option value={2}>2 : CIN</option>
                    <option value={3}>3 : CS</option>
                    <option value={4}>4 : IDE</option>
                  </Form.Control>
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.identifiant
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="text"
                    name="identifiant"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.identifiant !== undefined
                          ? editedValues.identifiant
                          : annexe7.identifiant
                        : annexe7.identifiant
                    }
                    onChange={(e) =>
                      handleInputChange(e, "identifiant", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.categorie
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    as="select"
                    name={`annexesList[${rowIndex}].categorie`}
                    value={annexe7.categorie}
                    onChange={(e) =>
                      handleInputChange(e, "categorie", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  >
                    <option value={1}>1 : Personne Morale</option>
                    <option value={2}>2 : Personne Physique</option>
                  </Form.Control>
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.name
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="text"
                    name={`annexesList[${rowIndex}].name`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.name !== undefined
                          ? editedValues.name
                          : annexe7.name
                        : annexe7.name
                    }
                    onChange={(e) => handleInputChange(e, "name", rowIndex)}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.activity
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="text"
                    name={`annexesList[${rowIndex}].activity`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.activity !== undefined
                          ? editedValues.activity
                          : annexe7.activity
                        : annexe7.activity
                    }
                    onChange={(e) => handleInputChange(e, "activity", rowIndex)}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.adresse
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="text"
                    name={`annexesList[${rowIndex}].adresse`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.adresse !== undefined
                          ? editedValues.adresse
                          : annexe7.adresse
                        : annexe7.adresse
                    }
                    onChange={(e) => handleInputChange(e, "adresse", rowIndex)}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    as="select"
                    name="typeMontantPaye"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.typeMontantPaye !== undefined
                          ? editedValues.typeMontantPaye
                          : annexe7.typeMontantPaye
                        : annexe7.typeMontantPaye
                    }
                    onChange={(e) =>
                      handleInputChange(e, "typeMontantPaye", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  >
                    <option value={1}>01 : Salaires</option>
                    <option value={2}>02 : Honoraires</option>
                    <option value={3}>03 : Commisions</option>
                    <option value={4}>04 : Courtages</option>
                    <option value={5}>05 : Loyers</option>
                    <option value={6}>
                      06 : Renum des activité Non commerciales
                    </option>
                    <option value={7}>
                      07 : Honoraires servis aux personnes morales et personnes
                      physiques soumises au régime réel{" "}
                    </option>
                    <option value={8}>
                      08 : Rémunérations servies aux artistes et créateurs
                    </option>
                    <option value={15}>
                      15 : Intérêts des comptes spéciaux d’épargne ouverts
                      auprès des banques et de la CENT{" "}
                    </option>
                    <option value={16}>
                      16 :Intérêts des prêts payés aux établissements bancaires
                      non établis en Tunisie.
                    </option>
                    <option value={17}>
                      17 : Revenus des autres capitaux mobiliers
                    </option>
                    <option value={19}>
                      19 : Honoraires servis aux personnes non résidentes qui
                      réalisent des travaux de construction ou des opérations de
                      montages ou des services de contrôle connexes ou d'autres
                      services pour une durée ne dépassant pas 6 mois.{" "}
                    </option>
                    <option value={20}>
                      20 :Revenues des valeurs mobilières y compris jetons de
                      présence, rémunérations et primes attribuées aux membres
                      des conseils, des directoires et des comités des sociétés,
                      actions et parts sociales revenant aux personnes physiques
                      et aux personnes morales non résidentes{" "}
                    </option>
                    <option value={21}>
                      21 :Rémunérations servies à des personnes résidentes ou
                      établies dans un Etat ou un territoire dont le régime
                      fiscal est privilégié{" "}
                    </option>
                    <option value={22}>
                      22 : Retenues à la source au titre des montants des
                      acquisitions de marchandises, matériels, équipements et de
                      services égaux ou supérieurs à 1000D y compris la TVA
                      provenant des sociétés soumises à un IS de 15% et des
                      sociétés soumises à un IS de 10% et des sociétés
                      unipersonnelles dont les revenus bénéficient d'une
                      déduction de deux tiers et des autres sociétés.{" "}
                    </option>
                    <option value={23}>
                      23 : Retenues à la source au titre des montants égaux ou
                      supérieurs à 1000 dinars y compris la TVA au titre des
                      autres opérations.{" "}
                    </option>
                    <option value={24}>
                      24 : Retenues à la source de la TVA au titre des montants
                      égaux ou supérieurs 1000 dinars payés par les
                      établissements et les entreprises publics.{" "}
                    </option>
                    <option value={25}>
                      25 : Retenues à la source de la TVA au titre des
                      opérations réalisées avec les personnes n’ayant pas
                      d’établissement en Tunisie.{" "}
                    </option>
                    <option value={26}>
                      26 : Redevance au profit de la caisse générale de
                      compensation.{" "}
                    </option>
                    <option value={27}>
                      27 : Honoraires servis aux personnes physiques non
                      soumises au régime réel{" "}
                    </option>
                    <option value={28}>
                      28 : Montant de l’avance due sur les ventes des fabricants
                      et embouteilleurs de vin, de bière et de boissons
                      alcoolisées{" "}
                    </option>
                  </Form.Control>
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.montantPaye
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name="montantPaye"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.montantPaye !== undefined
                          ? editedValues.montantPaye
                          : annexe7.montantPaye
                        : annexe7.montantPaye
                    }
                    onChange={(e) =>
                      handleInputChange(e, "montantPaye", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.retenuSource
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name="retenuSource"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.retenuSource !== undefined
                          ? editedValues.retenuSource
                          : annexe7.retenuSource
                        : annexe7.retenuSource
                    }
                    onChange={(e) =>
                      handleInputChange(e, "retenuSource", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.netServi
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name="netServi"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.netServi !== undefined
                          ? editedValues.netServi
                          : annexe7.netServi
                        : annexe7.netServi
                    }
                    onChange={(e) => handleInputChange(e, "netServi", rowIndex)}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <span
                    className={`action-icon ${
                      editableRows.includes(rowIndex) ? "success" : "warning"
                    }`}
                    onClick={() => handleEditClick(rowIndex)}
                    title={
                      editableRows.includes(rowIndex)
                        ? "Enregistrer"
                        : "Modifier"
                    }
                  >
                    <i
                      className={`fas ${
                        editableRows.includes(rowIndex) ? "fa-check" : "fa-edit"
                      }`}
                      style={{
                        color: editableRows.includes(rowIndex)
                          ? "#008000"
                          : "#0000ff",
                        marginRight: "8px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
                  <span
                    className={`action-icon danger`}
                    onClick={() => annexe7.id && handleDeleteRow(annexe7.id)}
                    title="Supprimer une ligne"
                  >
                    <i
                      className="far fa-trash-alt"
                      style={{
                        color: "#ff0000",
                        marginRight: "13px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
                </td>
              </tr>
            ))}

            {excelData.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                <td
                  style={{ padding: "4px", margin: "0", textAlign: "center" }}
                ></td>
                {rowData.map((cell, cellIndex) => {
                  const hasError = excelErrors.some(
                    (error) =>
                      error.rowIndex === rowIndex &&
                      error.cellIndices.includes(cellIndex)
                  );

                  const borderStyle = hasError
                    ? "2px solid red"
                    : "1px solid grey";
                  const cellStyle = {
                    padding: "3px",
                    margin: "0",
                  };

                  return (
                    <td key={cellIndex} style={cellStyle}>
                      <Form.Control
                        style={{
                          fontSize: "1em",
                          height: "33px",
                          border: borderStyle,
                          borderRadius: "4px",
                        }}
                        type="text"
                        value={String(cell)}
                        onChange={(e) =>
                          handleExcelInputChange(
                            rowIndex,
                            cellIndex,
                            e.target.value
                          )
                        }
                      />
                    </td>
                  );
                })}
                <td style={{ padding: "4px", margin: "0" }}>
                  <Button
                    variant="primary"
                    onClick={() => handleAddRowFromExcel(rowData)}
                  >
                    Ajouter
                  </Button>
                  <span
                    className="action-icon danger"
                    onClick={() => handleRemoveRowFromExcel(rowIndex)}
                    title="Retirer cette ligne"
                  >
                    <i
                      className="fas fa-times"
                      style={{
                        color: "red",
                        marginLeft: "10px",
                        fontSize: "30px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
                </td>
              </tr>
            ))}

            <tr>
              <td
                style={{ padding: "4px", margin: "0", textAlign: "center" }}
              ></td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  as="select"
                  {...register("typeIdentifiant")}
                  onChange={(e) => {
                    const selectedValue = parseInt(e.target.value, 10);
                    setValue("typeIdentifiant", selectedValue);
                    if (selectedValue === 2 || selectedValue === 3) {
                      setValue("categorie", 2);
                    }
                  }}
                >
                  <option value={""}></option>
                  <option value={1}>1 : MF</option>
                  <option value={2}>2 : CIN</option>
                  <option value={3}>3 : CS</option>
                  <option value={4}>4 : IDE</option>
                </Form.Control>
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="identifiant"
                  onChange={(e) => {
                    let value = e.target.value;
                    const typeIdentifiant = +watch("typeIdentifiant");
                    const identifiant = e.target.value;
                    checkForDuplicates(identifiant, typeIdentifiant);

                    let errorMessage = "";

                    if (typeIdentifiant === 1) {
                      const truncatedValue = value.slice(0, 13);
                      if (!MatriculFiscalValidate(truncatedValue)) {
                        errorMessage = "L'identifiant MF n'est pas valide";
                      } else if (truncatedValue.length !== 13) {
                        errorMessage =
                          "La longueur de l'identifiant MF doit être de 13 caractères";
                      }
                    } else if (typeIdentifiant === 2) {
                      const digitsOnly = value.replace(/\D/g, "");
                      const truncatedValue = digitsOnly.slice(0, 8);
                      value = truncatedValue;
                      if (truncatedValue.length !== 8) {
                        errorMessage =
                          "Le numéro CIN doit contenir exactement 8 chiffres";
                      }
                    } else if (typeIdentifiant === 3) {
                      const truncatedValue = value.slice(0, 13);
                      value = truncatedValue;
                    }

                    if (errorMessage) {
                      setError("identifiant", {
                        type: "manual",
                        message: errorMessage,
                      });
                    } else {
                      clearErrors("identifiant");
                    }

                    e.target.value = value;
                    setValue("identifiant", value);
                  }}
                  minLength={watch("typeIdentifiant") === 1 ? 13 : 8}
                  maxLength={watch("typeIdentifiant") === 1 ? 13 : 13}
                  isInvalid={!!errors.identifiant}
                />

                {errors.identifiant && (
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ fontSize: "0.8rem" }}
                  >
                    {errors.identifiant.message}
                  </Form.Control.Feedback>
                )}
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  as="select"
                  {...register("categorie")}
                  defaultValue={""}
                  disabled={
                    watch("typeIdentifiant") == 2 ||
                    watch("typeIdentifiant") == 3
                  }
                  onChange={(e) =>
                    setValue("categorie", parseInt(e.target.value))
                  }
                >
                  <option value={""}></option>
                  <option value={1}>1 : Personne morale</option>
                  <option value={2}>2 : Personne physique</option>
                </Form.Control>
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="name"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 40) {
                      setError("name", {
                        type: "manual",
                        message: "Le nom ne doit pas dépasser 40 caractères.",
                      });
                    } else {
                      clearErrors("name");
                      setValue("name", value);
                    }
                  }}
                  value={watch("name") || ""}
                />
                {errors.name && (
                  <span style={{ color: "red" }}>{errors.name.message}</span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="activity"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 40) {
                      setError("activity", {
                        type: "manual",
                        message: "L'emploi ne doit pas dépasser 40 caractères.",
                      });
                    } else {
                      clearErrors("activity");
                      setValue("activity", value);
                    }
                  }}
                  value={watch("activity") || ""}
                />
                {errors.activity && (
                  <span style={{ color: "red" }}>
                    {errors.activity.message}
                  </span>
                )}
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="adresse"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 120) {
                      setError("adresse", {
                        type: "manual",
                        message:
                          "L'adresse ne doit pas dépasser 120 caractères.",
                      });
                    } else {
                      clearErrors("adresse");
                      setValue("adresse", value);
                    }
                  }}
                  value={watch("adresse") || ""}
                />
                {errors.adresse && (
                  <span style={{ color: "red" }}>{errors.adresse.message}</span>
                )}
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  as="select"
                  {...register("typeMontantPaye")}
                >
                  <option value={""}></option>

                  <option value={1}>01 : Salaires</option>
                  <option value={2}>02 : Honoraires</option>
                  <option value={3}>03 : Commisions</option>
                  <option value={4}>04 : Courtages</option>
                  <option value={5}>05 : Loyers</option>
                  <option value={6}>
                    06 : Renum des activité Non commerciales
                  </option>
                  <option value={7}>
                    07 : Honoraires servis aux personnes morales et personnes
                    physiques soumises au régime réel{" "}
                  </option>
                  <option value={8}>
                    08 : Rémunérations servies aux artistes et créateurs
                  </option>
                  <option value={15}>
                    15 : Intérêts des comptes spéciaux d’épargne ouverts auprès
                    des banques et de la CENT{" "}
                  </option>
                  <option value={16}>
                    16 :Intérêts des prêts payés aux établissements bancaires
                    non établis en Tunisie.
                  </option>
                  <option value={17}>
                    17 : Revenus des autres capitaux mobiliers
                  </option>
                  <option value={19}>
                    19 : Honoraires servis aux personnes non résidentes qui
                    réalisent des travaux de construction ou des opérations de
                    montages ou des services de contrôle connexes ou d'autres
                    services pour une durée ne dépassant pas 6 mois.{" "}
                  </option>
                  <option value={20}>
                    20 :Revenues des valeurs mobilières y compris jetons de
                    présence, rémunérations et primes attribuées aux membres des
                    conseils, des directoires et des comités des sociétés,
                    actions et parts sociales revenant aux personnes physiques
                    et aux personnes morales non résidentes{" "}
                  </option>
                  <option value={21}>
                    21 :Rémunérations servies à des personnes résidentes ou
                    établies dans un Etat ou un territoire dont le régime fiscal
                    est privilégié{" "}
                  </option>
                  <option value={22}>
                    22 : Retenues à la source au titre des montants des
                    acquisitions de marchandises, matériels, équipements et de
                    services égaux ou supérieurs à 1000D y compris la TVA
                    provenant des sociétés soumises à un IS de 15% et des
                    sociétés soumises à un IS de 10% et des sociétés
                    unipersonnelles dont les revenus bénéficient d'une déduction
                    de deux tiers et des autres sociétés.{" "}
                  </option>
                  <option value={23}>
                    23 : Retenues à la source au titre des montants égaux ou
                    supérieurs à 1000 dinars y compris la TVA au titre des
                    autres opérations.{" "}
                  </option>
                  <option value={24}>
                    24 : Retenues à la source de la TVA au titre des montants
                    égaux ou supérieurs 1000 dinars payés par les établissements
                    et les entreprises publics.{" "}
                  </option>
                  <option value={25}>
                    25 : Retenues à la source de la TVA au titre des opérations
                    réalisées avec les personnes n’ayant pas d’établissement en
                    Tunisie.{" "}
                  </option>
                  <option value={26}>
                    26 : Redevance au profit de la caisse générale de
                    compensation.{" "}
                  </option>
                  <option value={27}>
                    27 : Honoraires servis aux personnes physiques non soumises
                    au régime réel{" "}
                  </option>
                  <option value={28}>
                    28 : Montant de l’avance due sur les ventes des fabricants
                    et embouteilleurs de vin, de bière et de boissons
                    alcoolisées{" "}
                  </option>
                </Form.Control>
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="montantPaye"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("montantPaye", value);
                  }}
                />
                {errors.montantPaye && (
                  <span style={{ color: "red" }}>
                    {errors.montantPaye.message}
                  </span>
                )}
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="retenuSource"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("retenuSource", value);
                  }}
                />
                {errors.retenuSource && (
                  <span style={{ color: "red" }}>
                    {errors.retenuSource.message}
                  </span>
                )}
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="netServi"
                  value={netServiValue}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    setNetServiValue(value);

                    handleInputChangeAndValidation("netServi", value);
                  }}
                />
                {errors.netServi && (
                  <span style={{ color: "red" }}>
                    {errors.netServi.message}
                  </span>
                )}
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Button variant="primary" type="submit">
                  Ajouter
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Form>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cet élément ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDuplicateModal}
        onHide={() => {
          setShowDuplicateModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Identifiant déjà utilisé</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Cet identifiant est déjà utilisé. Voulez-vous continuer ou modifier la
          ligne existante?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleContinue}>
            Continuer
          </Button>
          <Button variant="primary" onClick={handleEdit}>
            Modifier
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showTypeErrorModal}
        onHide={() => setShowTypeErrorModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Erreur de type d'identifiant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Le type d'identifiant ne correspond pas à la ligne existante. Veuillez
          vérifier votre saisie.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowTypeErrorModal(false)}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Annexe7Form;
