import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RetenueParamType } from "../../../Types/Entites/RetenueParamType";
import { RootState } from "../../store";

export const createRetenueParamApi = createAsyncThunk(
  "retenueParam/createRetenueParam",
  async (data: RetenueParamType, { rejectWithValue }) => {
    try {
      const response = await axios.post("retenueParam/createRetenueParam", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const findAllRetenueParamsApi = createAsyncThunk(
  "retenueParam/findAllRetenueParams",
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await axios.get("retenueParam/findAllRetenueParams");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const updateRetenueParamApi = createAsyncThunk(
  "retenueParam/updateRetenueParam",
  async (
    { id, data }: { id: number; data: RetenueParamType },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(
        `retenueParam/updateRetenueParam/${id}`,
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const deleteRetenueParamApi = createAsyncThunk(
  "retenueParam/deleteRetenueParam",
  async (id: number, { rejectWithValue }) => {
    try {
      await axios.delete(`retenueParam/deleteRetenueParam/${id}`);
      return id;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const findRetenueParamsByExerciceApi = createAsyncThunk(
  "retenueParam/findRetenueParamsByExercice",
  async (exerciceId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(`retenueParam/findRetenueParamsByExercice/${exerciceId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

interface RetenueParams {
  retenueParamsList: RetenueParamType[];
  retenueParamsListByExercie :  RetenueParamType[];
}

const initialState: RetenueParams = {
  retenueParamsList: [],
  retenueParamsListByExercie: [],
};

export const retenueParamSlice = createSlice({
  name: "retenueParam",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createRetenueParamApi.fulfilled, (state, action) => {
      state.retenueParamsList.push(action.payload.data);
      state.retenueParamsListByExercie.push(action.payload.data);

    });
    builder.addCase(findAllRetenueParamsApi.fulfilled, (state, action) => {
      state.retenueParamsList = action.payload;
    });
    builder.addCase(updateRetenueParamApi.fulfilled, (state, action) => {
      const updatedRetenueParam = action.payload.data;
      const index = state.retenueParamsList.findIndex(
        (retenueParam) => retenueParam.id === updatedRetenueParam.id
      );
      if (index !== -1) {
        state.retenueParamsList[index] = updatedRetenueParam;
      }
    });
    builder.addCase(findRetenueParamsByExerciceApi.fulfilled, (state, action) => {
      state.retenueParamsListByExercie = action.payload;
    });
    builder.addCase(deleteRetenueParamApi.fulfilled, (state, action) => {
      const id = action.payload;
      state.retenueParamsList = state.retenueParamsList.filter(
        (retenueParam) => retenueParam.id !== id
      );
      state.retenueParamsListByExercie = state.retenueParamsList.filter(
        (retenueParam) => retenueParam.id !== id
      );
    });
  },
});

export const { } = retenueParamSlice.actions;
export const selectRetenueParamsList = (state: RootState) => state.retenueParam.retenueParamsList;
export const retenueParamsListByExercice = (state: RootState) => state.retenueParam.retenueParamsListByExercie;

export default retenueParamSlice.reducer;
