import { saveAs } from "file-saver";

interface Annexe7FileProps {
  currentDeclaration: any;
  currentExercice: any;
  currentActe: any;
  currentSociete: any;
  annexe7List: any[] | null;
}

export const generateAnnexe7File = (
  currentDeclaration: any,
  currentExercice: any,
  currentActe: any,
  currentSociete: any,
  annexe7List: any
) => {
  const generateAnnexe7Content = () => {
    if (!currentSociete || !currentExercice || !currentActe || !annexe7List)
      return "";

    const matriculeFiscale =
      currentSociete.matriculeFiscale.slice(0, 8) +
      currentSociete.matriculeFiscale.slice(9, 13);
    const exerciceLibelle = currentExercice.libelle.padEnd(4);
    const companyName = currentSociete.name.padEnd(40);
    const companyActivity = currentSociete.activity.padEnd(40);
    const companyCity = currentSociete.ville.padEnd(40);
    const companyStreet = currentSociete.rue.padEnd(72);
    const companyNum = currentSociete.num.toString().padEnd(4);
    const codePostal = currentSociete.codePostal.toString().padEnd(4);
    const spaces = " ".repeat(171);

    let annexe7Content = `E7${matriculeFiscale}${exerciceLibelle}An7${
      currentActe.code
    }${String(annexe7List.length).padStart(
      6,
      "0"
    )}${companyName}${companyActivity}${companyCity}${companyStreet}${companyNum}${codePostal}${spaces}`;

    annexe7List.forEach((annexe7: any, index: any) => {
      const order = String(index + 1).padStart(6, "0");
      const typeIdentifiant = String(annexe7.typeIdentifiant);
      const identifiantBeneficiaire = String(annexe7.identifiant).padStart(
        13,
        "0"
      );
      const nomBeneficiaire = annexe7.name.padEnd(40);
      const emploiBenef = annexe7.activity.padEnd(40);
      const adresseBenef = annexe7.adresse.padEnd(120);
      const typeMontantPaye = String(annexe7.typeMontantPaye || 0).padStart(
        2,
        "0"
      );
      const montantPaye = String(annexe7.montantPaye * 1000 || 0).padStart(
        15,
        "0"
      );
      const retenuSource = String(annexe7.retenuSource * 1000 || 0).padStart(
        15,
        "0"
      );
      const netServi = String(annexe7.netServi * 1000 || 0).padStart(15, "0");

      const EndZone = " ".repeat(114);

      annexe7Content += `\nL7${matriculeFiscale}${exerciceLibelle}${order}${typeIdentifiant}${identifiantBeneficiaire}${nomBeneficiaire}${emploiBenef}${adresseBenef}${typeMontantPaye}${montantPaye}${retenuSource}${netServi}${EndZone}`;
    });
    const totalmontantPaye = String(
      (annexe7List.reduce(
        (acc: any, annexe7: any) => acc + (annexe7.montantPaye || 0),
        0
      ).toFixed(3) * 1000)
    ).padStart(15, "0");
    
    const totalRetenu = String(
      (annexe7List.reduce(
        (acc: any, annexe7: any) => acc + (annexe7.retenuSource || 0),
        0
      ).toFixed(3) * 1000)
    ).padStart(15, "0");
    
    const totalnetServi = String(
      (annexe7List.reduce(
        (acc: any, annexe7: any) => acc + (annexe7.netServi || 0),
        0
      ).toFixed(3) * 1000)
    ).padStart(15, "0");
    

    const EndZone = " ".repeat(114);
    const EndZoneAnx = " ".repeat(222);

    const FinAnnexe = `T7${matriculeFiscale}${exerciceLibelle}${EndZoneAnx}${totalmontantPaye}${totalRetenu}${totalnetServi}${EndZone}`;
    annexe7Content += `\n${FinAnnexe}`;
    return annexe7Content;
  };

  const textContent = generateAnnexe7Content();
  const blob = new Blob([textContent], { type: "text/plain;charset=utf-8" });
  const exerciseLabel = currentExercice.libelle;
  const lastTwoChars = exerciseLabel.slice(-2);
  const fileName = `ANXEMP_7_${lastTwoChars}_1.txt`;
  saveAs(blob, fileName);
};

const Annexe7File: Annexe7FileProps = {
  currentDeclaration: null,
  currentExercice: null,
  currentActe: null,
  currentSociete: null,
  annexe7List: null,
};

export default Annexe7File;
