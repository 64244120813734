import { saveAs } from "file-saver";

interface Annexe2FileProps {
    currentDeclaration: any;
    currentExercice: any;
    currentActe: any;
    currentSociete : any,
    annexe2List: any[] | null; }



export const generateAnnexe2File = (
    currentDeclaration: any,
    currentExercice: any,
    currentActe: any,
    currentSociete :any,
    annexe2List:any

) => {
       

  const generateAnnexe2Content = () => {
    if (!currentSociete || !currentExercice || !currentActe || !annexe2List) return "";

    const matriculeFiscale = (currentSociete.matriculeFiscale).slice(0, 8) + (currentSociete.matriculeFiscale).slice(   9, 13);
    const exerciceLibelle = (currentExercice.libelle).padEnd(4);
    const companyName = (currentSociete.name).padEnd(40);
    const companyActivity = (currentSociete.activity).padEnd(40);
    const companyCity = (currentSociete.ville).padEnd(40);
    const companyStreet = (currentSociete.rue).padEnd(72); 
    const companyNum = (currentSociete.num).toString().padEnd(4); 
    const codePostal = (currentSociete.codePostal).toString().padEnd(4);
    const spaces = " ".repeat(171);

    let annexe2Content = `E2${matriculeFiscale}${exerciceLibelle}An2${currentActe.code}${String(annexe2List.length).padStart(6, '0')}${companyName}${companyActivity}${companyCity}${companyStreet}${companyNum}${codePostal}${spaces}`;

    annexe2List.forEach((annexe2 : any, index : any) => {
        const order = String(index + 1).padStart(6, '0');
        const typeIdentifiant = String(annexe2.typeIdentifiant)
        const identifiantBeneficiaire = String(annexe2.identifiant).padStart(13, '0');;
        const nomBeneficiaire = annexe2.nom.padEnd(40);
        const emploiBenef = annexe2.activity.padEnd(40);
        const adresseBenef = annexe2.adresse.padEnd(120);
        let typeMontantServis = String(annexe2.typeMontantServis);
        if (typeMontantServis === '7') {
          typeMontantServis = '1';
        }       
         const montantServisActNonCommercial = String(annexe2.montantServisActNonComm * 1000 || 0).padStart(15, '0');
        const honorairesServisAuxPerMorPhy = String(annexe2.honorairesServisAuxSoc * 1000 || 0).padStart(15, '0');
        const renumPrimeActPartSoc = String(annexe2.renumPrimeActionPartSoc * 1000 || 0).padStart(15, '0');
        const renumSalar = String(annexe2.renumOccas * 1000 || 0).padStart(15, '0');
        const plueValueImmobil = String(annexe2.plusValueImmob * 1000 || 0).padStart(15, '0');
        const loyeHotelPayeSociete = String(annexe2.loyeHotelPayeSoc * 1000 || 0).padStart(15, '0');
        const renumArtist = String(annexe2.renumArtist * 1000 || 0).padStart(15, '0');
        const retenuSurTva = String(annexe2.retenuSurTva * 1000 || 0).padStart(15, '0');
        const retenuOpere = String(annexe2.retenuOper * 1000 || 0).padStart(15, '0');
        const netServi = String(annexe2.netServi * 1000 || 0).padStart(15, '0');


        const EndZone = " ".repeat(10);
        

        annexe2Content += `\nL2${matriculeFiscale}${exerciceLibelle}${order}${typeIdentifiant}${identifiantBeneficiaire}${nomBeneficiaire}${emploiBenef}${adresseBenef}${typeMontantServis}${montantServisActNonCommercial}${honorairesServisAuxPerMorPhy}${renumPrimeActPartSoc}${renumSalar}${plueValueImmobil}${loyeHotelPayeSociete}${renumArtist}${retenuSurTva}${retenuOpere}${netServi}${EndZone}`;
    
    
      });
      const EndZoneAnx = " ".repeat(221);
      const totalHonoraire = String(
        (annexe2List.reduce((acc : any, annexe2 : any) => acc + (annexe2.montantServisActNonComm || 0), 0).toFixed(3) * 1000)
      ).padStart(15, '0');
      
      const totalHonorairesServisAuxSoc = String(
        (annexe2List.reduce((acc : any, annexe2 : any) => acc + (annexe2.honorairesServisAuxSoc || 0), 0).toFixed(3) * 1000)
      ).padStart(15, '0');
      
      const totalRenumPrimeActionPartSoc = String(
        (annexe2List.reduce((acc : any, annexe2 : any) => acc + (annexe2.renumPrimeActionPartSoc || 0), 0).toFixed(3) * 1000)
      ).padStart(15, '0');
      
      const totalRenumOccas = String(
        (annexe2List.reduce((acc : any, annexe2 : any) => acc + (annexe2.renumOccas || 0), 0).toFixed(3) * 1000)
      ).padStart(15, '0');
      
      const totalplusValueImmob = String(
        (annexe2List.reduce((acc : any, annexe2 : any) => acc + (annexe2.plusValueImmob || 0), 0).toFixed(3) * 1000)
      ).padStart(15, '0');
      
      const totalloyeHotelPayeSoc = String(
        (annexe2List.reduce((acc : any, annexe2 : any) => acc + (annexe2.loyeHotelPayeSoc || 0), 0).toFixed(3) * 1000)
      ).padStart(15, '0');
      
      const totalrenumArtist = String(
        (annexe2List.reduce((acc : any, annexe2 : any) => acc + (annexe2.renumArtist || 0), 0).toFixed(3) * 1000)
      ).padStart(15, '0');
      
      const totalretenuSurTva = String(
        (annexe2List.reduce((acc : any, annexe2 : any) => acc + (annexe2.retenuSurTva || 0), 0).toFixed(3) * 1000)
      ).padStart(15, '0');
      
      const totalretenuOper = String(
        (annexe2List.reduce((acc : any, annexe2 : any) => acc + (annexe2.retenuOper || 0), 0).toFixed(3) * 1000)
      ).padStart(15, '0');
      
      const totalnetServi = String(
        (annexe2List.reduce((acc : any, annexe2 : any) => acc + (annexe2.netServi || 0), 0).toFixed(3) * 1000)
      ).padStart(15, '0');
      



        const EndZone = " ".repeat(10);


    const FinAnnexe = `T2${matriculeFiscale}${exerciceLibelle}${EndZoneAnx}${totalHonoraire}${totalHonorairesServisAuxSoc}${totalRenumPrimeActionPartSoc}${totalRenumOccas}${totalplusValueImmob}${totalloyeHotelPayeSoc}${totalrenumArtist}${totalretenuSurTva}${totalretenuOper}${totalnetServi}${EndZone}`;
    annexe2Content += `\n${FinAnnexe}`;
    return annexe2Content;
};


    const textContent = generateAnnexe2Content();
    const blob = new Blob([textContent], { type: "text/plain;charset=utf-8" });
    const exerciseLabel = currentExercice.libelle;
    const lastTwoChars = exerciseLabel.slice(-2);
    const fileName = `ANXEMP_2_${lastTwoChars}_1.txt`;
    saveAs(blob, fileName);
    console.log("La fonction generateAnnexe2File a été appelée.");
};

const Annexe2File: Annexe2FileProps = {
    currentDeclaration: null,
    currentExercice: null,
    currentActe: null,
    currentSociete:null,
    annexe2List : null,

};

export default Annexe2File;
