import { useEffect } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import styled from "styled-components";
import {
  actesList,
  findAllActesApi,
} from "../../_App/Redux/Slices/acte/acteSlice";
import {
  declarationsList,
  findAllDeclarationsApi,
} from "../../_App/Redux/Slices/declaration/declarationSlice";
import {
  exercicesList,
  findAllExercicesApi,
} from "../../_App/Redux/Slices/exercices/exerciceSlice";
import {
  findAllSocietesApi,
  societesList,
} from "../../_App/Redux/Slices/societes/societeSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { Annexe4Type } from "../../_App/Types/Entites/Annexe4Type";

const StyledTable = styled(Table)`
  font-size: 22px !important;
  text-align: center;
`;

const StyledTableCell = styled.td`
  font-size: 22px !important;
  text-align: center;
  color: black;
`;

const PageBreak = styled.div`
  page-break-after: always;
`;

const ImpressionAnnexe4 = ({ annexesList }: { annexesList: Annexe4Type[] }) => {
  const firstHalf = annexesList.map((item, index) => ({
    ...item,
    index: index + 1,
  }));
  const secondHalf = annexesList.map((item, index) => ({
    ...item,
    index: index + 1,
  }));

  const declarationId = annexesList[0]?.declaration;

  const allDeclarations = useAppSelector(declarationsList);
  const allExercices = useAppSelector(exercicesList);
  const allActes = useAppSelector(actesList);
  const allSocietes = useAppSelector(societesList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(findAllActesApi(null));
    dispatch(findAllDeclarationsApi(null));
    dispatch(findAllExercicesApi(null));
    dispatch(findAllSocietesApi(null));
  }, [dispatch]);

  const currentDeclaration = declarationId
    ? allDeclarations.find(
        (declaration: any) => declaration.id === declarationId
      )
    : undefined;
  const currentExercice = currentDeclaration
    ? allExercices.find(
        (exercice) => exercice.id === currentDeclaration.exercice
      )
    : undefined;
  const currentActe = currentDeclaration
    ? allActes.find((acte) => acte.id === currentDeclaration.acte)
    : undefined;

  const currentSociete = currentDeclaration
    ? allSocietes.find((societe) => societe.id === currentDeclaration.declarant)
    : undefined;

  const formattedDate = currentDeclaration
    ? new Date(currentDeclaration.date).toLocaleDateString("fr-FR")
    : "Date non disponible";
  const formattedStartDate = currentExercice
    ? new Date(currentExercice.dateDebut).toLocaleDateString("fr-FR")
    : "Date non disponible";
  const formattedEndDate = currentExercice
    ? new Date(currentExercice.dateFin).toLocaleDateString("fr-FR")
    : "Date non disponible";

  return (
    <div style={{ color: "black" }}>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <h6 style={{ color: "black" }}>Société</h6>
              {currentSociete && (
                <>
                  <p>Nom : {currentSociete.name}</p>
                  <p>Activité : {currentSociete.activity}</p>
                  <p>Matricule Fiscale : {currentSociete.matriculeFiscale}</p>
                </>
              )}
            </Col>

            <Col>
              <h6 style={{ color: "black" }}>Exercice</h6>
              {currentExercice && (
                <>
                  <p>Date de début : {formattedStartDate}</p>
                  <p>Date de fin : {formattedEndDate}</p>
                  <p>
                    Nombre de jours d'exercice : {currentExercice.nombreJours}
                  </p>
                </>
              )}
            </Col>
            <Col>
              <h6 style={{ color: "black" }}>Déclaration</h6>
              {currentDeclaration && (
                <>
                  <p>Date : {formattedDate}</p>
                  {currentActe && <p>Acte : {currentActe.libelle}</p>}
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <h1>Annexes 4</h1>

      <PageBreak>
        <StyledTable bordered striped hover style={{ color: "black" }}>
          <thead>
            <tr>
              <th>#</th>
              <th>Type id</th>
              <th>Id</th>
              <th>Catégorie</th>
              <th>Nom</th>
              <th>Activité</th>
              <th>Adresse</th>
              <th>Type Mnt</th>
              <th>% honoraire</th>
              <th>honoraire</th>
              <th>% renum non resid</th>
              <th>renum </th>
            </tr>
          </thead>
          <tbody>
            {firstHalf.map((annexe) => (
              <tr key={annexe.index}>
                <StyledTableCell>{annexe.index}</StyledTableCell>
                <StyledTableCell>{annexe.typeIdentifiant}</StyledTableCell>
                <StyledTableCell>{annexe.identifiant}</StyledTableCell>
                <StyledTableCell>{annexe.categorie}</StyledTableCell>
                <StyledTableCell>{annexe.name}</StyledTableCell>
                <StyledTableCell>{annexe.activity}</StyledTableCell>
                <StyledTableCell>{annexe.adresse}</StyledTableCell>
                <StyledTableCell>
                  {annexe.TypeMntServActNonComm}
                </StyledTableCell>
                <StyledTableCell>{annexe.tauxHonoraire}</StyledTableCell>
                <StyledTableCell>{annexe.MntHonoraire}</StyledTableCell>
                <StyledTableCell>{annexe.tauxRenumNonResid}</StyledTableCell>
                <StyledTableCell>{annexe.MntRenumNonResid}</StyledTableCell>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </PageBreak>

      <StyledTable bordered striped hover style={{ color: "black" }}>
        <thead>
          <tr>
            <th>#</th>
            <th>% plus-value immob</th>
            <th>plus-value immob</th>
            <th>% Value Cession</th>
            <th>Value Cession</th>
            <th>% Revenu Mob</th>
            <th>Revenu Mob </th>
            <th>Renum rev</th>
            <th>Retenue tva</th>
            <th>Retenue operée</th>
            <th>Net Servi</th>
          </tr>
        </thead>
        <tbody>
          {secondHalf.map((annexe) => (
            <tr key={annexe.index}>
              <StyledTableCell>{annexe.index}</StyledTableCell>
              <StyledTableCell>{annexe.TauxPluValueImmob}</StyledTableCell>

              <StyledTableCell>{annexe.MontantPluValueImmob}</StyledTableCell>
              <StyledTableCell>
                {annexe.tauxPlusValueCessionAct}
              </StyledTableCell>
              <StyledTableCell>{annexe.MntPlusValueCessionAct}</StyledTableCell>
              <StyledTableCell>{annexe.tauxRevenuMobJetPres}</StyledTableCell>
              <StyledTableCell>{annexe.MntRevenuMobJetPres}</StyledTableCell>
              <StyledTableCell>
                {annexe.renumRevEtatRegimeFiscalPriv}
              </StyledTableCell>
              <StyledTableCell>{annexe.RetenuTitreTva100}</StyledTableCell>
              <StyledTableCell>{annexe.retenuOper}</StyledTableCell>

              <StyledTableCell>{annexe.netServi}</StyledTableCell>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </div>
  );
};

export default ImpressionAnnexe4;
