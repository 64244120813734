import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import { useAppSelector } from "../../_App/Redux/hooks";
import Sidebar from "../SideBar/Sidebar";

const RestyledLayout = styled.div<{ $menu: any | null }>`
  ${(props) => {
    var style = ``;
    // check the postion of menu right, left up , down
    switch (props.$menu?.menuPosition.position) {
      case `down`:
        style = `#content {
                    width: 100%;
                    flex-grow: none;
                    margin-bottom: 0;
                    margin-left: 0px;
                  }`;
        break;
      case `left`:
        style = `#content {
                    width: calc(100% - 150px);
                    margin-bottom: 0;
                    margin-left: 0px;
                  }`;
        break;

      case `right`:
        style = `#content {
                    width: calc(100% - 150px);
                    margin-bottom: 0;
                    margin-left: 0px;
                  }`;
        break;

      case `up`:
        style = `.main-container {
                   flex-direction: column;
                  }
                  #content {
                    width: 100%;
                    margin-bottom: 0;
                    margin-left: 0px;
                  }`;
        break;

      default:
        console.log(`Sorry, we are out of parms.`);
    }
    return style;
  }}

  #content .col-left {
    padding: 0;
    -webkit-box-flex: 1;
    flex: 1 auto;
    -ms-flex: 1 0 0px;
    min-width: 0;
    margin-right: 0px;
  }
`;

export default function Layout() {

  return (
    <>
      <ToastContainer />
      <div
        className="dashboard-analytics admin-header"
        style={{
          color: "#888ea8",
          height: "100%",
          fontSize: "0.875rem",
          background: "#f1f2f3",
          overflowX: "hidden",
          overflowY: "auto",
          letterSpacing: "0.0312rem",
          fontFamily: "Quicksand, sans-serif",
        }}
      >
        <div className="main-container" id="container">
          <div id="content" className="main-content">
            <Sidebar  />

            <div className="layout-px-spacing">
              <div className="">
                <div className="col-left">
                  <div className="col-left-content" style={{ margin: "50px" }}>
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-wrapper col-xl-12">
          <div className="footer-section f-section-1"></div>
          <div className="footer-section f-section-2">
            <p className="">
              © 2024{" "}
              <a target="_blank" href="https://designreset.com">
                Clediss
              </a>{" "}
              Tous droits réservés.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
