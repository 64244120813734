import { saveAs } from "file-saver";

interface Annexe3FileProps {
    currentDeclaration: any;
    currentExercice: any;
    currentActe: any;
    currentSociete: any;
    annexe3List: any[] | null; 

}

export const generateAnnexe3File = (
    currentDeclaration: any,
    currentExercice: any,
    currentActe: any,
    currentSociete:any,
    annexe3List : any
    
) => {
    const generateAnnexe3Content = () => {
        if (!currentSociete || !currentExercice || !currentActe || !annexe3List) return "";
    
        const matriculeFiscale = (currentSociete.matriculeFiscale).slice(0, 8) + (currentSociete.matriculeFiscale).slice(   9, 13);
        const exerciceLibelle = (currentExercice.libelle).padEnd(4);
        const companyName = (currentSociete.name).padEnd(40);
        const companyActivity = (currentSociete.activity).padEnd(40);
        const companyCity = (currentSociete.ville).padEnd(40);
        const companyStreet = (currentSociete.rue).padEnd(72); 
        const companyNum = (currentSociete.num).toString().padEnd(4); 
        const codePostal = (currentSociete.codePostal).toString().padEnd(4);
        const spaces = " ".repeat(171);
    
        let annexe3Content = `E3${matriculeFiscale}${exerciceLibelle}An3${currentActe.code}${String(annexe3List.length).padStart(6, '0')}${companyName}${companyActivity}${companyCity}${companyStreet}${companyNum}${codePostal}${spaces}`;
    
        annexe3List.forEach((annexe3 : any, index : any) => {
            const order = String(index + 1).padStart(6, '0');
            const typeIdentifiant = String(annexe3.typeIdentifiant)
            const identifiantBeneficiaire = String(annexe3.identifiant).padStart(13, '0');;
            const nomBeneficiaire = annexe3.nom.padEnd(40);
            const emploiBenef = annexe3.activity.padEnd(40);
            const adresseBenef = annexe3.adresse.padEnd(120);
            const interetDesComptEpargne = String(annexe3.interetDesComptEpargne * 1000 || 0).padStart(15, '0');
            const interetAutreCapitImmob = String(annexe3.interetAutreCapitImmob * 1000 || 0).padStart(15, '0');
            const interetPretHorsTun = String(annexe3.interetPretHorsTun * 1000 || 0).padStart(15, '0');
            const retenuOper = String(annexe3.retenuOper * 1000 || 0).padStart(15, '0');
            
            const netServi = String(annexe3.netServi * 1000 || 0).padStart(15, '0');
    
    
            const EndZone = " ".repeat(86);
            
    
            annexe3Content += `\nL3${matriculeFiscale}${exerciceLibelle}${order}${typeIdentifiant}${identifiantBeneficiaire}${nomBeneficiaire}${emploiBenef}${adresseBenef}${interetDesComptEpargne}${interetAutreCapitImmob}${interetPretHorsTun}${retenuOper}${netServi}${EndZone}`;
        
        
          });
          const totalInteretDesComptEpargne = String(
            (annexe3List.reduce((acc : any, annexe3 : any) => acc + (annexe3.interetDesComptEpargne || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
          const totalInteretAutreCapitImmob = String(
            (annexe3List.reduce((acc : any, annexe3 : any) => acc + (annexe3.interetAutreCapitImmob || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
          const totalInteretPretHorsTun = String(
            (annexe3List.reduce((acc : any, annexe3 : any) => acc + (annexe3.interetPretHorsTun || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
          const totalRetenuOper = String(
            (annexe3List.reduce((acc : any, annexe3 : any) => acc + (annexe3.retenuOper || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
          const totalnetServi = String(
            (annexe3List.reduce((acc : any, annexe3 : any) => acc + (annexe3.netServi || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
    
    
            const EndZone = " ".repeat(86);
            const EndZoneAnx = " ".repeat(220);

    
    
        const FinAnnexe = `T3${matriculeFiscale}${exerciceLibelle}${EndZoneAnx}${totalInteretDesComptEpargne}${totalInteretAutreCapitImmob}${totalInteretPretHorsTun}${totalRetenuOper}${totalnetServi}${EndZone}`;
        annexe3Content += `\n${FinAnnexe}`;
        return annexe3Content;
    };
    

    const textContent = generateAnnexe3Content();
    const blob = new Blob([textContent], { type: "text/plain;charset=utf-8" });
    const exerciseLabel = currentExercice.libelle;
    const lastTwoChars = exerciseLabel.slice(-2);
    const fileName = `ANXEMP_3_${lastTwoChars}_1.txt`;
    saveAs(blob, fileName);
};

const Annexe3File: Annexe3FileProps = {
    currentDeclaration: null,
    currentExercice: null,
    currentActe: null,
    currentSociete:null,
    annexe3List : null,
};

export default Annexe3File;
