import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { resetCurrentUser } from "../../_App/Redux/Slices/user/userSlice";
import { useAppDispatch } from "../../_App/Redux/hooks";

const NavbarWrapper = styled.div`
  height: 60px;
  background-color: #333;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    width: 60px;
    height: 40px;
    margin-right: 10px;
  }

  span {
    font-size: 20px;
  }
`;

const MenuCategories = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;

  .menu {
    cursor: pointer;
    padding: 10px;
    border-right: 1px solid #555;

    &:hover {
      background-color: #555;
    }

    a {
      text-decoration: none;
      color: white;
    }
  }

  .menu:last-child {
    border-right: none;
  }
`;

const Navbar = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const segment = currentPath.split("/").filter(Boolean).pop();

   const logoutUser = () => (dispatch : any) => {
    dispatch(resetCurrentUser());
  };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logoutUser());
    //navigate("/login"); 
  };

  return (
    <NavbarWrapper>
      
      
      <Logo>
      <a
            href="/"
          >     
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/e/e3/Tunisia_logo.svg"
          className="navbar-logo"
          alt="logo"
        />
          </a>
        <span onClick={() => console.log(segment)}>
          {" "}
          DECLARATION D'EMPLOYEUR <span></span>
        </span>
      </Logo>  
    

      <MenuCategories>
        <li className="menu">
          <a
            href="/societes"
            data-active={segment === "societes" ? true : false}
          >
            Sociétés
          </a>
        </li>

        <li className="menu">
          <a
            href="/beneficiaires"
            data-active={segment === "beneficiaires" ? true : false}
          >
            Beneficiaires
          </a>
        </li>
        

        <li className="menu">
          <a
            href="/tvaCategories"
            data-active={segment === "categories" ? true : false}
          >
            Tva Categories
          </a>
        </li>
        <li className="menu">
          <a
            href="/parametrageRecap"
            data-active={segment === "Paramétrage Recap" ? true : false}
          >
            Paramétrage Recap
          </a>
        </li>
        <li className="menu">
          <a
            href="/parametrageRetenues"
            data-active={segment === "Paramétrage retenues" ? true : false}
          >
            Paramétrage retenues
          </a>
        </li>
        <li className="menu" onClick={handleLogout}>
          Déconnexion
        </li>

        
      </MenuCategories>
    </NavbarWrapper>
  );
};

export default Navbar;
