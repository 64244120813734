import React from "react";
import Navbar from "../../_Layouts/Navbar/Navbar";
import ListTvaCategories from "../../Components/TvaCategory/TvaCategoryList";

const TvaCategoryPage= () => {
  return (
    <div>
      <Navbar titre={"Tva / cateogrie List"} />

      <ListTvaCategories />
    </div>
  );
};

export default TvaCategoryPage;
