import { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import {
  findAllTvaCategoriesApi,
  tvaCategoriesList,
} from "../../_App/Redux/Slices/tvaCategory/tvaCategorySlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import TvaCategoryForm from "./TvaCategoryForm";

const ListTvaCategories = () => {
  const dispatch = useAppDispatch();
  const tvaCategories = useAppSelector(tvaCategoriesList);

  const [showCreateForm, setShowCreateForm] = useState(false);

  useEffect(() => {
    dispatch(findAllTvaCategoriesApi(null));
  }, [dispatch]);

  const openCreateForm = () => {
    setShowCreateForm(true);
  };

  const closeCreateForm = () => {
    setShowCreateForm(false);
  };

  // Séparer les catégories en deux groupes: 'category' et 'tva'
  const categories = tvaCategories.filter(
    (category) => category.type === "category"
  );
  const tva = tvaCategories.filter((category) => category.type === "tva");

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="primary" onClick={openCreateForm}>
          Create
        </Button>
      </div>

      <div>
        <h2>Category</h2>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Type</th>
              <th>Code</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category, index) => (
              <tr key={index}>
                <td>{category.type}</td>
                <td>{category.code}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div>
        <h2>TVA</h2>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Type</th>
              <th>Code</th>
            </tr>
          </thead>
          <tbody>
            {tva.map((category, index) => (
              <tr key={index}>
                <td>{category.type}</td>
                <td>{category.code}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {showCreateForm && (
        <Modal show={true} onHide={closeCreateForm}>
          <Modal.Header closeButton>
            <Modal.Title>Créer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TvaCategoryForm closeForm={closeCreateForm} />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default ListTvaCategories;
