
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import { ActeType } from "../../../Types/Entites/ActeType";

export const createActeApi = createAsyncThunk(
  "actes/createActe",
  async (data: ActeType, { rejectWithValue }) => {
    try {
      const response = await axios.post("acte/createActe", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour récupérer tous les actes
export const findAllActesApi = createAsyncThunk(
  "actes/findAllActes",
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await axios.get("acte/findAllActes");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour mettre à jour un acte
export const updateActeApi = createAsyncThunk(
  "actes/updateActe",
  async ({ id, data }: { id: number; data: ActeType }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`acte/updateActe/${id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour récupérer un acte par ID
export const getActeByIdApi = createAsyncThunk(
  "actes/getActeById",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(`acte/getActeById/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour supprimer un acte
export const deleteActeApi = createAsyncThunk(
  "actes/deleteActe",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`acte/deleteActe/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface Actes {
  actesList: ActeType[];
}

const initialState: Actes = {
  actesList: [],
};

export const acteSlice = createSlice({
  name: "actes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createActeApi.fulfilled, (state, action) => {
      state.actesList.push(action.payload.data);
    });
    builder.addCase(findAllActesApi.fulfilled, (state, action) => {
      return {
        ...state,
        actesList: action.payload,
      };
    });
    builder.addCase(updateActeApi.fulfilled, (state, action) => {
      const updatedIndex = state.actesList.findIndex(
        (acte) => acte.id === action.payload.data.id
      );
      if (updatedIndex !== -1) {
        state.actesList[updatedIndex] = action.payload.data;
      }
    });
    builder.addCase(getActeByIdApi.fulfilled, (state, action) => {
      return {
        ...state,
        actesList: [action.payload],
      };
    });
    builder.addCase(deleteActeApi.fulfilled, (state, action) => {
      const updatedList = state.actesList.filter(
        (acte) => acte.id !== action.payload.data.id
      );
      return {
        ...state,
        actesList: updatedList,
      };
    });
  },
});

export const actesList = (state: RootState) => state.actes.actesList;

export default acteSlice.reducer;
