import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import styled from "styled-components"; 
import { Annexe6Type } from "../../_App/Types/Entites/Annexe6Type";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { declarationsList, findAllDeclarationsApi } from "../../_App/Redux/Slices/declaration/declarationSlice";
import { exercicesList, findAllExercicesApi } from "../../_App/Redux/Slices/exercices/exerciceSlice";
import { actesList, findAllActesApi } from "../../_App/Redux/Slices/acte/acteSlice";
import { findAllSocietesApi, societesList } from "../../_App/Redux/Slices/societes/societeSlice";
import { Card, Col, Row } from "react-bootstrap";


const StyledTable = styled(Table)`
  font-size: 22px !important;  
  text-align: center; 
`;

const StyledTableCell = styled.td`
  font-size: 22px !important; 
  text-align: center;
  color : black;
`;

const PageBreak = styled.div`
  page-break-after: always; 
`;

const ImpressionAnnexe6 = ({ annexesList }: { annexesList: Annexe6Type[] }) => {
  const firstHalf = annexesList.map((item, index) => ({ ...item, index: index + 1 })); 
  const secondHalf = annexesList.map((item, index) => ({ ...item, index: index + 1 }));

  const declarationId = annexesList[0]?.declaration;

  const allDeclarations = useAppSelector(declarationsList);
  const allExercices = useAppSelector(exercicesList);
  const allActes = useAppSelector(actesList);
  const allSocietes = useAppSelector(societesList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(findAllActesApi(null));
    dispatch(findAllDeclarationsApi(null));
    dispatch(findAllExercicesApi(null));
    dispatch(findAllSocietesApi(null));
  }, [dispatch]);

  const currentDeclaration = declarationId
    ? allDeclarations.find(
        (declaration: any) => declaration.id === declarationId
      )
    : undefined;
  const currentExercice = currentDeclaration
    ? allExercices.find(
        (exercice) => exercice.id === currentDeclaration.exercice
      )
    : undefined;
  const currentActe = currentDeclaration
    ? allActes.find((acte) => acte.id === currentDeclaration.acte)
    : undefined;

  const currentSociete = currentDeclaration
    ? allSocietes.find((societe) => societe.id === currentDeclaration.declarant)
    : undefined;

  const formattedDate = currentDeclaration
    ? new Date(currentDeclaration.date).toLocaleDateString("fr-FR")
    : "Date non disponible";
  const formattedStartDate = currentExercice
    ? new Date(currentExercice.dateDebut).toLocaleDateString("fr-FR")
    : "Date non disponible";
  const formattedEndDate = currentExercice
    ? new Date(currentExercice.dateFin).toLocaleDateString("fr-FR")
    : "Date non disponible";

  return (
    <div style={{ color: "black" }}>
        <Card>
        <Card.Body>
          <Row>
            <Col>
              <h6 style={{ color: "black" }}>Société</h6>
              {currentSociete && (
                <>
                  <p>Nom : {currentSociete.name}</p>
                  <p>Activité : {currentSociete.activity}</p>
                  <p>Matricule Fiscale : {currentSociete.matriculeFiscale}</p>
                </>
              )}
            </Col>

            <Col>
              <h6 style={{ color: "black" }}>Exercice</h6>
              {currentExercice && (
                <>
                  <p>Date de début : {formattedStartDate}</p>
                  <p>Date de fin : {formattedEndDate}</p>
                  <p>
                    Nombre de jours d'exercice : {currentExercice.nombreJours}
                  </p>
                </>
              )}
            </Col>
            <Col>
              <h6 style={{ color: "black" }}>Déclaration</h6>
              {currentDeclaration && (
                <>
                  <p>Date : {formattedDate}</p>
                  {currentActe && <p>Acte : {currentActe.libelle}</p>}
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <h1>Annexes 6</h1>

      <PageBreak>
        <StyledTable bordered striped hover style={{ color: "black" }}>
          <thead>
            <tr>
              <th>#</th> 
              <th>Type id</th>
              <th>Id</th>
              <th>Catégorie</th>
              <th>Nom</th>
              <th>Activité</th>
              <th>Adresse</th>
              <th>Type Ristournes</th>
              <th>Montant</th>
              <th>Vente pp forfaitaire</th>
             
            </tr>
          </thead>
          <tbody>
            {firstHalf.map((annexe) => (
              <tr key={annexe.index}>
                <StyledTableCell>{annexe.index}</StyledTableCell> {/* Application du style */}
                <StyledTableCell>{annexe.typeIdentifiant}</StyledTableCell>
                <StyledTableCell>{annexe.identifiant}</StyledTableCell>
                <StyledTableCell>{annexe.categorie}</StyledTableCell>
                <StyledTableCell>{annexe.name}</StyledTableCell>
                <StyledTableCell>{annexe.activity}</StyledTableCell>
                <StyledTableCell>{annexe.adresse}</StyledTableCell>
                <StyledTableCell>{annexe.typeRistournes}</StyledTableCell>
                <StyledTableCell>{annexe.montantRistourne}</StyledTableCell>
                <StyledTableCell>{annexe.ventePpForfaitaire}</StyledTableCell>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </PageBreak>

     
      <StyledTable bordered striped hover style={{ color: "black" }}>
        <thead>
          <tr>
            <th>#</th> 
            <th>Avance pp forfaitaire</th>
            <th>Mnt revenu jeux</th>
            <th>Ras revenu jeux</th>
            <th>Mnt vente profit distribution</th>
            <th>Ras profit distribution</th>
            <th>Mnt perçus</th>
            <th>Vente fab alcool</th>
            <th>Avance fab alcool</th>
          </tr>
        </thead>
        <tbody>
          {secondHalf.map((annexe) => (
              <tr key={annexe.index}>
                <StyledTableCell>{annexe.index}</StyledTableCell> 
                <StyledTableCell>{annexe.avancePpForfaitaire}</StyledTableCell>

                <StyledTableCell>{annexe.mntRevenuJeux}</StyledTableCell>
                <StyledTableCell>{annexe.rasRevenuJeux}</StyledTableCell>
                <StyledTableCell>{annexe.mntVenteProfitDist}</StyledTableCell>
                <StyledTableCell>{annexe.rasProfitDist}</StyledTableCell>
                <StyledTableCell>{annexe.mntPercus}</StyledTableCell>
                <StyledTableCell>{annexe.mntVenteFabAlcool}</StyledTableCell>
                <StyledTableCell>{annexe.mntAvanceFabAlcool}</StyledTableCell>

              </tr>
          ))}
          </tbody>
        </StyledTable>
      </div>
  );
};

export default ImpressionAnnexe6;
