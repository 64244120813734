import { useParams } from "react-router-dom";
import CertificatRetenueForm from "../../Components/LigneCertificat/LigneCetificatForm";
import Navbar from "../../_Layouts/Navbar/Navbar";

const CertificatsRetenues = () => {
  const { exerciceId, declarantId, declarationId } = useParams();

  if (!declarantId || !exerciceId || !declarationId) {
    return <div>Paramètres manquants</div>;
  }

  return (
    <div>
      <div style={{ marginBottom: "30px" }}>
        <Navbar titre={"Certificats des retenues"} />
      </div>

      <div style={{ marginTop: "10px" }}>
        <CertificatRetenueForm
          declarantId={parseInt(declarantId)}
          exerciceId={parseInt(exerciceId)}
          declarationId={parseInt(declarationId)}
        />
      </div>
    </div>
  );
};

export default CertificatsRetenues;
