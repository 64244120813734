import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";

import * as XLSX from "xlsx";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  MatriculFiscalValidate,
  handleTabulation,
} from "../../_App/Helpers/helpers";
import {
  annexe2ListByDeclaration,
  createAnnexe2Api,
  deleteAnnexe2Api,
  findAnnexes2ByDeclarationApi,
  updateAnnexe2Api,
} from "../../_App/Redux/Slices/annexe2/annexe2Slice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { Annexe2Type } from "../../_App/Types/Entites/Annexe2Type";

import styled from "styled-components";
import ImpressionAnnexe2 from "./ImpressionAnnexe2";
import {
  BeneficiairesList,
  createBeneficiaireApi,
  findAllBeneficiairesApi,
} from "../../_App/Redux/Slices/beneficiaire/beneficiaireSlice";
import {
  declarationsList,
  findAllDeclarationsApi,
} from "../../_App/Redux/Slices/declaration/declarationSlice";
import { findAllSocietesApi } from "../../_App/Redux/Slices/societes/societeSlice";

const FixedNewColumnCell = styled.th`
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedNewDataCell = styled.td`
  position: sticky;
  left: 0;
  z-index: 1;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedHeaderCell = styled.th`
  position: sticky;
  left: 37px;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedSecondHeaderCell = styled.th`
  position: sticky;
  left: 112px;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedThirdHeaderCell = styled.th`
  position: sticky;
  left: 255px;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedDataCell = styled.td`
  position: sticky;
  left: 37px;
  z-index: 1;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedSecondDataCell = styled.td`
  position: sticky;
  left: 112px;
  z-index: 1;
  padding: 2px !important;
  margin: 0 !important;
`;

const FixedThirdDataCell = styled.td`
  position: sticky;
  left: 255px;
  z-index: 1;
  padding: 2px !important;
  margin: 0 !important;
`;
const FixedFourHeaderCell = styled.th`
  position: sticky;
  left: 389px;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedFourDataCell = styled.td`
  position: sticky;
  left: 389px;
  z-index: 1;
  padding: 4px !important;
  margin: 0 !important;
`;

interface Annexe2FormProps {
  declarationId: number;
}

const Annexe2Form: React.FC<Annexe2FormProps> = ({ declarationId }) => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
    watch,
    reset,
  } = useForm<Annexe2Type>();
  const annexesList = useAppSelector(annexe2ListByDeclaration);
  const [editableRows, setEditableRows] = useState<number[]>([]);
  const [editedValues, setEditedValues] = useState<Partial<Annexe2Type>>({});

  const handleInputChangeAndValidation = (name: any, value: any) => {
    const regex = /^\d+(\.\d{1,3})?$/;

    if (value === "") {
      setValue(name, 0.0);
      clearErrors(name);
    } else if (regex.test(value)) {
      setValue(name, parseFloat(value));
      clearErrors(name);
    } else {
      setError(name, {
        type: "manual",
        message:
          "Veuillez saisir un nombre valide avec un maximum de 3 chiffres après le point décimal.",
      });
    }
  };

  const handleUpdateRow = async (rowIndex: number) => {
    try {
      const data = editedValues;
      const editedData = {
        ...annexesList[rowIndex],
        ...data,
      };

      const updateAction = await dispatch(
        updateAnnexe2Api({
          id: editedData.id || 0,
          data: editedData,
        })
      );

      if (updateAction.payload && updateAction.payload.success) {
        ToastSuccess(updateAction.payload.message);
        setEditableRows((prevEditableRows) =>
          prevEditableRows.filter((row) => row !== rowIndex)
        );
        setEditedValues({});
      } else if (updateAction.payload) {
        ToastWarning(updateAction.payload.message);
      }
    } catch (error) {
      console.error("Update failed", error);
      ToastWarning("Une erreur est survenue lors de la mise à jour");
    }
  };

  const [formKey, setFormKey] = useState(0);

  useEffect(() => {
    [
      findAllSocietesApi,
      findAllDeclarationsApi,
      findAllBeneficiairesApi,
    ].forEach((action) => dispatch(action(null)));

    declarationId && dispatch(findAnnexes2ByDeclarationApi(declarationId));
  }, [dispatch, declarationId]);

  const allDeclarations = useAppSelector(declarationsList);

  const currentDeclaration = declarationId
    ? allDeclarations.find(
        (declaration) => declaration.id === parseInt(declarationId.toString())
      )
    : undefined;
  const societeId = currentDeclaration?.declarant;

  const onSubmit: SubmitHandler<Annexe2Type> = async (data) => {
    try {
      data.declaration = declarationId;
      const createAction = await dispatch(createAnnexe2Api(data));

      if (createAction.payload && createAction.payload.success) {
        ToastSuccess(createAction.payload.message);

        const beneficiaireData = {
          typeIdentifiant: data.typeIdentifiant,
          identifiant: data.identifiant,
          name: data.nom,
          activity: data.activity,
          adresse: data.adresse,

          societe: societeId || 0,
          typeBeneficiaire: "Fournisseur",
        };

        const beneficiaireExiste = beneficiaireList.some(
          (b) => b.identifiant === data.identifiant
        );

        if (!beneficiaireExiste) {
          const createBeneficiaireAction = await dispatch(
            createBeneficiaireApi(beneficiaireData)
          );

          if (
            createBeneficiaireAction.payload &&
            createBeneficiaireAction.payload.success
          ) {
            ToastSuccess("Bénéficiaire ajouté avec succès");
          } else {
            ToastWarning(createBeneficiaireAction.payload?.message);
          }
        }

        reset();
        setFormKey((prevKey) => prevKey + 1);
      } else {
        ToastWarning(createAction.payload?.message);
      }
    } catch (error) {
      console.error("La soumission a échoué", error);
      ToastWarning(
        "Une erreur est survenue lors de la soumission du formulaire"
      );
    }
  };

  type FormControlChangeEvent = React.ChangeEvent<
    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  >;

  const typeMontantServis = isNaN(watch("typeMontantServis") as number)
    ? 0
    : watch("typeMontantServis");

  const montantServisActNonComm = isNaN(
    watch("montantServisActNonComm") as number
  )
    ? 0
    : watch("montantServisActNonComm");
  const honorairesServisAuxSoc = isNaN(
    watch("honorairesServisAuxSoc") as number
  )
    ? 0
    : watch("honorairesServisAuxSoc");

  const retenuSurTva = isNaN(watch("retenuSurTva") as number)
    ? 0
    : watch("retenuSurTva");
  const retenuOper = isNaN(watch("retenuOper") as number)
    ? 0
    : watch("retenuOper");

  let netServi: any;

  if (typeMontantServis == 0) {
    netServi = honorairesServisAuxSoc! - (retenuSurTva! + retenuOper!);
  } else {
    netServi = montantServisActNonComm! - (retenuSurTva! + retenuOper!);
  }

  useEffect(() => {
    setValue("netServi", netServi.toFixed(3));
  }, [setValue, netServi]);

  const [netServiValue, setNetServiValue] = useState(netServi);
  useEffect(() => {
    setNetServiValue(netServi.toFixed(3));
  }, [netServi]);

  const [excelData, setExcelData] = useState<any[]>([]);

  const handleRemoveRowFromExcel = (rowIndex: any) => {
    const updatedExcelData = [...excelData];
    updatedExcelData.splice(rowIndex, 1);
    setExcelData(updatedExcelData);
  };
  const handleAddRowFromExcel = async (rowData: any) => {
    const errors = validateExcelData([rowData]);
    const hasErrors = errors.some((error) => error.rowIndex === 0);

    if (hasErrors) {
      ToastWarning(
        "La ligne contient des erreurs. Corrigez-les avant d'ajouter."
      );
      return;
    }
    try {
      const newData: Annexe2Type = {
        typeIdentifiant: rowData[0],
        identifiant: rowData[1],
        categorie: rowData[2],
        nom: rowData[3],
        activity: rowData[4],
        adresse: rowData[5],
        typeMontantServis: rowData[6],
        montantServisActNonComm: rowData[7],
        honorairesServisAuxSoc: rowData[8],
        renumPrimeActionPartSoc: rowData[9],
        renumOccas: rowData[10],
        plusValueImmob: rowData[11],
        loyeHotelPayeSoc: rowData[12],
        renumArtist: rowData[13],
        retenuSurTva: rowData[14],
        retenuOper: rowData[15],

        netServi: rowData[16],
        declaration: declarationId,
      };

      await dispatch(createAnnexe2Api(newData));

      setExcelData((prevExcelData) =>
        prevExcelData.filter((row) => row !== rowData)
      );
    } catch (error) {
      console.error("Addition of row from Excel failed", error);
    }
  };

  const exportToExcel = () => {
    const filteredAnnexesList = annexesList.map(
      ({ id, type, declaration, numOrdre, ...rest }) => rest
    );

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredAnnexesList);
    XLSX.utils.book_append_sheet(wb, ws, "Annexes");
    XLSX.writeFile(wb, "annexe2.xlsx");
  };

  const printRef = useRef<HTMLDivElement>(null);

  const handlePrint = () => {
    const printContents = printRef.current;

    if (printContents) {
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents.innerHTML;
      window.print();

      document.body.innerHTML = originalContents;

      window.location.reload();
    }
  };

  const beneficiaireList = useAppSelector(BeneficiairesList);

  const identifiantBeneficiaire = watch("identifiant");
  const typeIdentifiant = watch("typeIdentifiant");

  useEffect(() => {
    if (identifiantBeneficiaire) {
      const beneficiaire = beneficiaireList.find(
        (b) =>
          b.identifiant === identifiantBeneficiaire &&
          b.typeIdentifiant == typeIdentifiant
      );

      if (beneficiaire) {
        setValue("nom", beneficiaire.name);
        setValue("activity", beneficiaire.activity);
        setValue("adresse", beneficiaire.adresse);
      }
    }
  }, [identifiantBeneficiaire, setValue, beneficiaireList]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const handleDeleteRow = (id: number) => {
    if (id !== undefined) {
      setDeleteId(id);
      setShowDeleteModal(true);
    }
  };
  const handleConfirmDelete = async () => {
    if (deleteId !== null) {
      try {
        const deleteAction = await dispatch(deleteAnnexe2Api(deleteId));

        if (deleteAction.payload && deleteAction.payload.success) {
          ToastSuccess(deleteAction.payload.message);
          setShowDeleteModal(false);
        } else if (deleteAction.payload && deleteAction.payload.message) {
          ToastWarning(deleteAction.payload.message);
        }

        setDeleteId(null);
      } catch (error) {
        console.error("La suppression a échoué", error);
        ToastWarning("Une erreur est survenue lors de la suppression.");
      }
    }
  };

  // gestion des doublons
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showTypeErrorModal, setShowTypeErrorModal] = useState(false);
  const [duplicateIndex, setDuplicateIndex] = useState<number | null>(null);

  // Fonction pour vérifier les doublons
  const checkForDuplicates = (identifiant: string, typeIdentifiant: number) => {
    const index = annexesList.findIndex(
      (annexe) => annexe.identifiant === identifiant
    );

    if (index !== -1) {
      setDuplicateIndex(index);

      setShowDuplicateModal(true);
    } else {
      setDuplicateIndex(null);
      setShowDuplicateModal(false);
      setShowTypeErrorModal(false);
    }
  };

  const handleContinue = () => {
    if (duplicateIndex != null) {
      const existingType = annexesList[duplicateIndex].typeIdentifiant;

      if (existingType != typeIdentifiant) {
        setShowTypeErrorModal(true);

        setShowDuplicateModal(false);
      } else {
        setShowDuplicateModal(false);
      }
    }
  };

  const handleEdit = () => {
    if (duplicateIndex != null) {
      handleEditClick(duplicateIndex);
      setSearchQuery(annexesList[duplicateIndex].identifiant); // Mettre à jour la recherche avec l'identifiant modifié

      reset();
      setShowDuplicateModal(false);
    }
  };

  // recherche

  const [searchQuery, setSearchQuery] = useState("");

  const filteredAnnexes = annexesList.filter((annexe) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      annexe.typeIdentifiant.toString().includes(lowerCaseQuery) ||
      annexe.identifiant.toLowerCase().includes(lowerCaseQuery) ||
      annexe.categorie.toString().includes(lowerCaseQuery) ||
      annexe.nom.toLowerCase().includes(lowerCaseQuery) ||
      annexe.activity.toLowerCase().includes(lowerCaseQuery) ||
      annexe.adresse.toLowerCase().includes(lowerCaseQuery) ||
      annexe.typeMontantServis.toString().includes(lowerCaseQuery) ||
      annexe.montantServisActNonComm?.toString().includes(lowerCaseQuery) ||
      annexe.honorairesServisAuxSoc?.toString().includes(lowerCaseQuery) ||
      annexe.renumPrimeActionPartSoc?.toString().includes(lowerCaseQuery) ||
      annexe.renumOccas?.toString().includes(lowerCaseQuery) ||
      annexe.plusValueImmob?.toString().includes(lowerCaseQuery) ||
      annexe.loyeHotelPayeSoc?.toString().includes(lowerCaseQuery) ||
      annexe.renumArtist?.toString().includes(lowerCaseQuery) ||
      annexe.retenuSurTva?.toString().includes(lowerCaseQuery) ||
      annexe.retenuOper?.toString().includes(lowerCaseQuery) ||
      annexe.netServi.toString().includes(lowerCaseQuery)
    );
  });

  // validate excel

  type ExcelDataRow = (string | number | null | undefined)[];

  type ExcelError = {
    rowIndex: number;
    cellIndices: number[];
  };

  // Fonction de validation pour les données Excel
  const validateExcelData = (data: ExcelDataRow[]): ExcelError[] => {
    const errors: ExcelError[] = [];

    data.forEach((row, rowIndex) => {
      const errorIndices: number[] = [];
      row.forEach((cell, cellIndex) => {
        let hasError = false;
        let value = String(cell);

        switch (cellIndex) {
          case 0: // Type d'identifiant
            const validIdentifiantTypes = [1, 2];
            if (!validIdentifiantTypes.includes(Number(value))) {
              hasError = true;
            }
            break;

          case 1: // Identifiant
            const typeIdentifiant = Number(row[0]);
            if (
              typeIdentifiant === 1 &&
              (value.length !== 13 || !MatriculFiscalValidate(value))
            ) {
              hasError = true;
            } else if (typeIdentifiant === 2) {
              const digitsOnly = value.replace(/\D/g, "");
              if (digitsOnly.length !== 8) {
                hasError = true;
              }
            }
            break;

          case 2: // Catégorie
            if (![1, 2].includes(Number(value))) {
              hasError = true;
            }
            break;

          case 3: // Nom
            if (value.length > 40) {
              hasError = true;
            }
            break;
          case 4: // Activité
            if (value.length > 40) {
              hasError = true;
            }
            break;

          case 5: // Adresse
            if (value.length > 120) {
              hasError = true;
            }
            break;

          case 6:
            if (![0, 1, 2, 3, 4, 5, 6].includes(Number(value))) {
              hasError = true;
            }
            break;
          case 7:
          case 8:
          case 9:
          case 10:
          case 11:
          case 12:
          case 13:
          case 14:
          case 15:
          case 16:
            value = value.replace(/[^\d.]/g, "");
            const parts = value.split(".");
            if (parts.length > 2 || (parts[1] && parts[1].length > 3)) {
              hasError = true;
            } else {
              const numericValue = parseFloat(value);
              if (isNaN(numericValue) || numericValue < 0) {
                hasError = true;
              }
            }
            break;
        }

        if (hasError) {
          errorIndices.push(cellIndex);
        }
      });

      if (errorIndices.length > 0) {
        errors.push({ rowIndex, cellIndices: errorIndices });
      }
    });

    return errors;
  };

  const [excelErrors, setExcelErrors] = useState<ExcelError[]>([]);

  const handleExcelInputChange = (
    rowIndex: number,
    cellIndex: number,
    newValue: string
  ) => {
    const updatedExcelData = [...excelData];
    updatedExcelData[rowIndex][cellIndex] = newValue;

    setExcelData(updatedExcelData);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result;
      if (result instanceof ArrayBuffer) {
        const data = new Uint8Array(result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const rawData = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
        }) as ExcelDataRow[];

        const validatedData = rawData.slice(1); // Retirer les en-têtes
        setExcelData(validatedData);
        setExcelErrors(validateExcelData(validatedData));
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const isExcelLoaded = excelData.length > 0;

  const validateExcel = () => {
    const errors = validateExcelData(excelData);
    setExcelErrors(errors);

    if (errors.length === 0) {
      console.log("Données Excel valides.");
    } else {
      console.log("Des erreurs existent dans les données Excel.");
    }
  };

  // update

  const [rowErrors, setRowErrors] = useState<{
    [key: number]: { [key: string]: boolean };
  }>({});

  const handleInputChange = (
    e: FormControlChangeEvent,
    fieldName: string,
    rowIndex: number
  ) => {
    const { value } = e.target;

    const hasError = validateField(fieldName, value, rowIndex);

    if (hasError && editableRows.includes(rowIndex)) {
      setRowError(rowIndex, fieldName, true);
    } else if (editableRows.includes(rowIndex)) {
      setRowError(rowIndex, fieldName, false);
    }

    setEditedValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  const validateField = (
    fieldName: string,
    value: string,
    rowIndex: number
  ): boolean => {
    let hasError = false;

    switch (fieldName) {
      case "typeIdentifiant":
        const validIdentifiantTypes = [1, 2];
        if (!validIdentifiantTypes.includes(Number(value))) {
          hasError = true;
        }
        break;

      case "identifiant":
        const typeIdentifiant = Number(editedValues.typeIdentifiant);
        if (
          typeIdentifiant === 1 &&
          (value.length !== 13 || !MatriculFiscalValidate(value))
        ) {
          hasError = true;
        } else if (typeIdentifiant === 2) {
          const digitsOnly = value.replace(/\D/g, "");
          if (digitsOnly.length !== 8) {
            hasError = true;
          }
        }
        break;

      case "categorie":
        if (![1, 2].includes(Number(value))) {
          hasError = true;
        }
        break;

      case "nom":
        if (value.length > 40) {
          hasError = true;
        }
        break;

      case "activity":
        if (value.length > 40) {
          hasError = true;
        }
        break;

      case "adresse":
        if (value.length > 120) {
          hasError = true;
        }
        break;

      case "montantServisActNonComm":
      case "honorairesServisAuxSoc":
      case "renumPrimeActionPartSoc":
      case "renumOccas":
      case "plusValueImmob":
      case "loyeHotelPayeSoc":
      case "renumArtist":
      case "retenuSurTva":
      case "retenuOper":
      case "netServi":
        value = value.replace(/[^\d.]/g, ""); // Ne conserve que les chiffres et les points
        const parts = value.split(".");
        if (parts.length > 2 || (parts[1] && parts[1].length > 3)) {
          hasError = true; // Vérifie si le format du montant est valide (maximum un point et trois chiffres après le point)
        } else {
          const numericValue = parseFloat(value);
          if (isNaN(numericValue) || numericValue < 0) {
            hasError = true; // Vérifie si la valeur est un nombre valide et positif
          }
        }
        break;

      default:
        break;
    }

    return hasError;
  };

  const setRowError = (
    rowIndex: number,
    fieldName: string,
    hasError: boolean
  ) => {
    setRowErrors((prevRowErrors) => ({
      ...prevRowErrors,
      [rowIndex]: {
        ...prevRowErrors[rowIndex],
        [fieldName]: hasError,
      },
    }));
  };

  const handleEditClick = (rowIndex: number) => {
    const rowHasErrors = Object.values(rowErrors[rowIndex] || {}).some(
      (error) => error
    );

    if (rowHasErrors) {
      ToastWarning("Veuillez corriger les erreurs avant de continuer.");

      return;
    }

    const valuesToEdit: Partial<Annexe2Type> = annexesList[rowIndex] || {};

    setEditedValues(valuesToEdit);

    if (editableRows.includes(rowIndex)) {
      handleUpdateRow(rowIndex);
    }

    setEditableRows((prevEditableRows) =>
      prevEditableRows.includes(rowIndex)
        ? prevEditableRows.filter((row) => row !== rowIndex)
        : [...prevEditableRows, rowIndex]
    );
  };

  return (
    <div>
      <div style={{ display: "flex", marginTop: "5px" }}>
        <div style={{ flex: 0.7, marginRight: "5px", paddingRight: "20px" }}>
          <p
            style={{
              textAlign: "justify",
              lineHeight: "1.5",
              margin: "10px 0",
            }}
          >
            Montants servis à des résidents en Tunisie au titre des honoraires,
            commissions, courtages, rémunérations payées aux salariés et aux non
            salariés en contrepartie d’un travail occasionnel ou accidentel en
            dehors de leur activité principale, loyers, loyers des hôtels,
            honoraires servis à des personnes soumises au régime réel, jetons de
            présence, actions et parts sociales et plus-value immobilière,
            rémunérations payées en contrepartie de la performance dans la
            prestation des services pour le compte d'autrui, rémunérations des
            activités non commerciales, rémuns et primes attribuées aux membres
            des conseils, des directoires et des comités.
          </p>
        </div>

        <div
          style={{
            flex: 0.3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              height: "32px",
            }}
            onClick={handlePrint}
            variant="info"
          >
            Imprimer
          </Button>
          <div ref={printRef} style={{ display: "none" }}>
            <ImpressionAnnexe2 annexesList={annexesList} />
          </div>
          <Button
            variant="light"
            onClick={exportToExcel}
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              height: "32px",
            }}
          >
            Save as Excel
            <i
              className="fas fa-download"
              style={{ color: "green", fontSize: "18px", marginLeft: "10px" }}
            ></i>
          </Button>

          <Button
            variant="light"
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "32px",
            }}
          >
            <label
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                color: "black",
              }}
            >
              <input
                type="file"
                accept=".xls,.xlsx"
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
              Import Excel
              <i
                className="far fa-file-excel"
                style={{ color: "green", fontSize: "18px", marginLeft: "10px" }}
              ></i>
            </label>
          </Button>
        </div>
      </div>
      <div
        key={formKey}
        style={{
          overflowX: "auto",
          overflowY: "auto",
          width: "100%",
          //height: "550px",
          //scrollbarWidth: "thin",
          scrollbarColor: "rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            marginTop: "10px",
            padding: "10px",
            display: "flex",
            justifyContent: "space-between", // Les éléments se répartissent entre début et fin
            alignItems: "center", // Pour aligner verticalement
          }}
        >
          <span
            style={{ fontWeight: "bold", fontSize: "1.1em", color: "black" }}
          >
            {`Nombre total de bénéficiaires : ${annexesList.length}`}
          </span>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>
              {isExcelLoaded && (
                <Button
                  variant="success"
                  onClick={validateExcel}
                  style={{
                    marginRight: "15px",
                    height: "30px",
                    fontSize: "0.8em",
                    marginBottom: "7px",
                  }}
                >
                  Valider l'import
                </Button>
              )}
            </span>

            <Form.Control
              type="text"
              placeholder="Rechercher..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{
                width: "200px",
                height: "30px",
                textAlign: "center",
              }}
            />
          </div>
        </div>
        <Form onKeyDown={handleTabulation} onSubmit={handleSubmit(onSubmit)}>
          <Table
            bordered
            striped
            hover
            className="table-responsive"
            style={{ padding: "0", margin: "0", marginTop: "10px" }}
          >
            <thead>
              <tr>
                <FixedNewColumnCell>
                  <div
                    style={{
                      width: "30px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    N°
                  </div>
                </FixedNewColumnCell>
                <FixedHeaderCell>
                  <div
                    style={{
                      width: "70px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Type ID
                  </div>
                </FixedHeaderCell>
                <FixedSecondHeaderCell>
                  <div
                    style={{
                      width: "135px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Identifiant
                  </div>
                </FixedSecondHeaderCell>
                <FixedThirdHeaderCell>
                  {" "}
                  <div
                    style={{
                      width: "130px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Catégorie
                  </div>
                </FixedThirdHeaderCell>
                <FixedFourHeaderCell>
                  {" "}
                  <div
                    style={{
                      width: "140px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Nom ou raison soc
                  </div>
                </FixedFourHeaderCell>
                <th style={{ padding: "4px", margin: "0" }}>
                  <div
                    style={{
                      width: "140px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Activité
                  </div>
                </th>
                <th style={{ padding: "4px", margin: "0" }}>
                  <div
                    style={{
                      width: "160px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Adresse
                  </div>
                </th>
                <th style={{ padding: "4px", margin: "0" }}>
                  <div
                    style={{
                      width: "120px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Type Montant
                  </div>
                </th>
                <th style={{ padding: "4px", margin: "0" }}>
                  <div
                    style={{
                      width: "160px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Montants honoraires
                  </div>
                </th>
                <th style={{ padding: "4px", margin: "0" }}>
                  <div
                    style={{
                      width: "180px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Honoraires servis au soc
                  </div>
                </th>
                <th style={{ padding: "4px", margin: "0" }}>
                  <div
                    style={{
                      width: "165px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Renums et Primes
                  </div>
                </th>
                <th style={{ padding: "4px", margin: "0" }}>
                  <div
                    style={{
                      width: "160px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Renumération Occas
                  </div>
                </th>
                <th style={{ padding: "4px", margin: "0" }}>
                  <div
                    style={{
                      width: "155px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Plus-Value Immob
                  </div>
                </th>
                <th style={{ padding: "4px", margin: "0" }}>
                  <div
                    style={{
                      width: "165px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Loye Hotels Payé aux Soc
                  </div>
                </th>
                <th style={{ padding: "4px", margin: "0" }}>
                  <div
                    style={{
                      width: "155px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Renum aux Artistes
                  </div>
                </th>
                <th style={{ padding: "4px", margin: "0" }}>
                  <div
                    style={{
                      width: "180px",
                      display: "inline-block",
                      fontSize: "0.75em",
                    }}
                  >
                    Retenu Sur Tva - ou = 1000DT
                  </div>
                </th>
                <th style={{ padding: "4px", margin: "0" }}>
                  <div
                    style={{
                      width: "135px",
                      display: "inline-block",
                      fontSize: "0.85em",
                    }}
                  >
                    Retenu Operees
                  </div>
                </th>
                <th style={{ padding: "4px", margin: "0" }}>
                  <div
                    style={{
                      width: "135px",
                      display: "inline-block",
                      fontSize: "0.85em",
                    }}
                  >
                    Net Servi
                  </div>
                </th>
                <th style={{ padding: "4px", margin: "0" }}>
                  <div
                    style={{
                      width: "180px",
                      display: "inline-block",
                      fontSize: "0.85em",
                    }}
                  >
                    Action
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredAnnexes.map((annexe2: Annexe2Type, rowIndex: any) => (
                <tr
                  key={annexe2.id}
                  onDoubleClick={() => handleEditClick(rowIndex)}
                >
                  <FixedNewDataCell
                    style={{ padding: "4px", margin: "0", textAlign: "center" }}
                  >
                    {rowIndex + 1}
                  </FixedNewDataCell>

                  <FixedDataCell>
                    <Form.Control
                      style={{ fontSize: "1em", height: "33px" }}
                      as="select"
                      name="typeIdentifiant"
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.typeIdentifiant ||
                            annexe2.typeIdentifiant
                          : annexe2.typeIdentifiant
                      }
                      onChange={(e) =>
                        handleInputChange(e, "typeIdentifiant", rowIndex)
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    >
                      <option value={1}>1 : MF</option>
                      <option value={2}>2 : CIN</option>
                    </Form.Control>
                  </FixedDataCell>

                  <FixedSecondDataCell>
                    <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                        border: rowErrors[rowIndex]?.identifiant
                          ? "2px solid red"
                          : "1px solid #ced4da",
                      }}
                      type="text"
                      name="identifiant"
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.identifiant !== undefined
                            ? editedValues.identifiant
                            : annexe2.identifiant
                          : annexe2.identifiant
                      }
                      onChange={(e) =>
                        handleInputChange(e, "identifiant", rowIndex)
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </FixedSecondDataCell>

                  <FixedThirdDataCell>
                    <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                        border: rowErrors[rowIndex]?.categorie
                          ? "2px solid red"
                          : "1px solid #ced4da",
                      }}
                      as="select"
                      name={`annexesList[${rowIndex}].categorie`}
                      value={annexe2.categorie}
                      onChange={(e) =>
                        handleInputChange(e, "categorie", rowIndex)
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    >
                      <option value={1}>1 : Personne Morale</option>
                      <option value={2}>2 : Personne Physique</option>
                    </Form.Control>
                  </FixedThirdDataCell>
                  <FixedFourDataCell>
                    <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                        border: rowErrors[rowIndex]?.nom
                          ? "2px solid red"
                          : "1px solid #ced4da",
                      }}
                      type="text"
                      name={`annexesList[${rowIndex}].nom`}
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.nom !== undefined
                            ? editedValues.nom
                            : annexe2.nom
                          : annexe2.nom
                      }
                      onChange={(e) => handleInputChange(e, "nom", rowIndex)}
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </FixedFourDataCell>
                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                        border: rowErrors[rowIndex]?.activity
                          ? "2px solid red"
                          : "1px solid #ced4da",
                      }}
                      type="text"
                      name={`annexesList[${rowIndex}].activity`}
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.activity !== undefined
                            ? editedValues.activity
                            : annexe2.activity
                          : annexe2.activity
                      }
                      onChange={(e) =>
                        handleInputChange(e, "activity", rowIndex)
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </td>
                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                        border: rowErrors[rowIndex]?.adresse
                          ? "2px solid red"
                          : "1px solid #ced4da",
                      }}
                      type="text"
                      name={`annexesList[${rowIndex}].adresse`}
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.adresse !== undefined
                            ? editedValues.adresse
                            : annexe2.adresse
                          : annexe2.adresse
                      }
                      onChange={(e) =>
                        handleInputChange(e, "adresse", rowIndex)
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </td>

                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{ fontSize: "1em", height: "33px" }}
                      as="select"
                      name={`annexesList[${rowIndex}].typeMontantServis`}
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.typeMontantServis !== undefined
                            ? editedValues.typeMontantServis
                            : annexe2.typeMontantServis
                          : annexe2.typeMontantServis
                      }
                      onChange={(e) =>
                        handleInputChange(e, "typeMontantServis", rowIndex)
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    >
                      <option value={1}>1 : Honoraires</option>
                      <option value={2}>2 : Commissions</option>
                      <option value={3}>3 : Courtages</option>
                      <option value={4}>4 : Loyers</option>
                      <option value={5}>
                        5 : Rémunération non commerciale
                      </option>
                      <option value={6}>
                        6 : Rémunération de la performance dans la prestation
                      </option>
                      <option value={7}>
                        7 : Commissions des distributeurs agrées des opérateurs
                        téléphoniques.
                      </option>
                      <option value={0}>0 : Autres</option>
                    </Form.Control>
                  </td>

                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                        border: rowErrors[rowIndex]?.montantServisActNonComm
                          ? "2px solid red"
                          : "1px solid #ced4da",
                      }}
                      type="text"
                      step="0.001"
                      name={`annexesList[${rowIndex}].montantServisActNonComm`}
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.montantServisActNonComm !== undefined
                            ? editedValues.montantServisActNonComm
                            : annexe2.montantServisActNonComm
                          : annexe2.montantServisActNonComm
                      }
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "montantServisActNonComm",
                          rowIndex
                        )
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </td>
                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                        border: rowErrors[rowIndex]?.honorairesServisAuxSoc
                          ? "2px solid red"
                          : "1px solid #ced4da",
                      }}
                      type="text"
                      step="0.001"
                      name={`annexesList[${rowIndex}].honorairesServisAuxSoc`}
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.honorairesServisAuxSoc !== undefined
                            ? editedValues.honorairesServisAuxSoc
                            : annexe2.honorairesServisAuxSoc
                          : annexe2.honorairesServisAuxSoc
                      }
                      onChange={(e) =>
                        handleInputChange(e, "honorairesServisAuxSoc", rowIndex)
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </td>
                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                        border: rowErrors[rowIndex]?.renumPrimeActionPartSoc
                          ? "2px solid red"
                          : "1px solid #ced4da",
                      }}
                      type="text"
                      step="0.001"
                      name={`annexesList[${rowIndex}].renumPrimeActionPartSoc`}
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.renumPrimeActionPartSoc !== undefined
                            ? editedValues.renumPrimeActionPartSoc
                            : annexe2.renumPrimeActionPartSoc
                          : annexe2.renumPrimeActionPartSoc
                      }
                      onChange={(e) =>
                        handleInputChange(
                          e,
                          "renumPrimeActionPartSoc",
                          rowIndex
                        )
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </td>
                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                        border: rowErrors[rowIndex]?.renumOccas
                          ? "2px solid red"
                          : "1px solid #ced4da",
                      }}
                      type="text"
                      step="0.001"
                      name={`annexesList[${rowIndex}].renumOccas`}
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.renumOccas !== undefined
                            ? editedValues.renumOccas
                            : annexe2.renumOccas
                          : annexe2.renumOccas
                      }
                      onChange={(e) =>
                        handleInputChange(e, "renumOccas", rowIndex)
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </td>
                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                        border: rowErrors[rowIndex]?.plusValueImmob
                          ? "2px solid red"
                          : "1px solid #ced4da",
                      }}
                      type="text"
                      step="0.001"
                      name={`annexesList[${rowIndex}].plusValueImmob`}
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.plusValueImmob !== undefined
                            ? editedValues.plusValueImmob
                            : annexe2.plusValueImmob
                          : annexe2.plusValueImmob
                      }
                      onChange={(e) =>
                        handleInputChange(e, "plusValueImmob", rowIndex)
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </td>
                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                        border: rowErrors[rowIndex]?.loyeHotelPayeSoc
                          ? "2px solid red"
                          : "1px solid #ced4da",
                      }}
                      type="text"
                      step="0.001"
                      name={`annexesList[${rowIndex}].loyeHotelPayeSoc`}
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.loyeHotelPayeSoc !== undefined
                            ? editedValues.loyeHotelPayeSoc
                            : annexe2.loyeHotelPayeSoc
                          : annexe2.loyeHotelPayeSoc
                      }
                      onChange={(e) =>
                        handleInputChange(e, "loyeHotelPayeSoc", rowIndex)
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </td>
                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                        border: rowErrors[rowIndex]?.renumArtist
                          ? "2px solid red"
                          : "1px solid #ced4da",
                      }}
                      type="text"
                      step="0.001"
                      name={`annexesList[${rowIndex}].renumArtist`}
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.renumArtist !== undefined
                            ? editedValues.renumArtist
                            : annexe2.renumArtist
                          : annexe2.renumArtist
                      }
                      onChange={(e) =>
                        handleInputChange(e, "renumArtist", rowIndex)
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </td>
                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                        border: rowErrors[rowIndex]?.retenuSurTva
                          ? "2px solid red"
                          : "1px solid #ced4da",
                      }}
                      type="text"
                      step="0.001"
                      name={`annexesList[${rowIndex}].retenuSurTva`}
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.retenuSurTva !== undefined
                            ? editedValues.retenuSurTva
                            : annexe2.retenuSurTva
                          : annexe2.retenuSurTva
                      }
                      onChange={(e) =>
                        handleInputChange(e, "retenuSurTva", rowIndex)
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </td>
                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                        border: rowErrors[rowIndex]?.retenuOper
                          ? "2px solid red"
                          : "1px solid #ced4da",
                      }}
                      type="text"
                      step="0.001"
                      name={`annexesList[${rowIndex}].retenuOper`}
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.retenuOper !== undefined
                            ? editedValues.retenuOper
                            : annexe2.retenuOper
                          : annexe2.retenuOper
                      }
                      onChange={(e) =>
                        handleInputChange(e, "retenuOper", rowIndex)
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </td>

                  <td style={{ padding: "4px", margin: "0" }}>
                    <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                        border: rowErrors[rowIndex]?.netServi
                          ? "2px solid red"
                          : "1px solid #ced4da",
                      }}
                      type="text"
                      step="0.001"
                      name={`annexesList[${rowIndex}].netServi`}
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.netServi !== undefined
                            ? editedValues.netServi
                            : annexe2.netServi
                          : annexe2.netServi
                      }
                      onChange={(e) =>
                        handleInputChange(e, "netServi", rowIndex)
                      }
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </td>

                  <td style={{ padding: "4px", margin: "0" }}>
                    <span
                      className={`action-icon ${
                        editableRows.includes(rowIndex) ? "success" : "warning"
                      }`}
                      onClick={() => handleEditClick(rowIndex)}
                      title={
                        editableRows.includes(rowIndex)
                          ? "Enregistrer"
                          : "Modifier"
                      }
                    >
                      <i
                        className={`fas ${
                          editableRows.includes(rowIndex)
                            ? "fa-check"
                            : "fa-edit"
                        }`}
                        style={{
                          color: editableRows.includes(rowIndex)
                            ? "#008000"
                            : "#0000ff",
                          marginRight: "8px",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                      ></i>
                    </span>
                    <span
                      className="action-icon danger"
                      onClick={() => annexe2.id && handleDeleteRow(annexe2.id)}
                      title="Supprimer une ligne"
                    >
                      <i
                        className="far fa-trash-alt"
                        style={{
                          color: "#ff0000",
                          marginRight: "13px",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                      ></i>
                    </span>
                  </td>
                </tr>
              ))}

              {/* Ligne d'ajout */}
              {excelData.map((rowData, rowIndex) => (
                <tr key={rowIndex}>
                  <FixedNewDataCell
                    style={{ padding: "4px", margin: "0", textAlign: "center" }}
                  ></FixedNewDataCell>
                  {/* Composants fixes pour les premières colonnes */}
                  {[0, 1, 2, 3].map((cellIndex) => {
                    const hasError = excelErrors.some(
                      (error) =>
                        error.rowIndex === rowIndex &&
                        error.cellIndices.includes(cellIndex)
                    );

                    const borderStyle = hasError
                      ? "2px solid red"
                      : "1px solid grey";
                    const cellStyle = {
                      padding: "3px",
                      margin: "0",
                      border: borderStyle,
                    };

                    let CellComponent;
                    switch (cellIndex) {
                      case 0:
                        CellComponent = FixedDataCell;
                        break;
                      case 1:
                        CellComponent = FixedSecondDataCell;
                        break;
                      case 2:
                        CellComponent = FixedThirdDataCell;
                        break;
                      case 3:
                        CellComponent = FixedFourDataCell;
                        break;
                      default:
                        CellComponent = "td"; // Default case
                    }

                    return (
                      <CellComponent key={cellIndex}>
                        <Form.Control
                          style={{
                            fontSize: "1em",
                            height: "33px",
                            border: borderStyle,
                            borderRadius: "4px",
                          }}
                          type="text"
                          value={rowData[cellIndex]}
                          onChange={(e) =>
                            handleExcelInputChange(
                              rowIndex,
                              cellIndex,
                              e.target.value
                            )
                          }
                        />
                      </CellComponent>
                    );
                  })}

                  {/* Autres cellules */}
                  {rowData
                    .slice(4)
                    .map((cell: any, additionalCellIndex: any) => {
                      const actualCellIndex = additionalCellIndex + 4;
                      const hasError = excelErrors.some(
                        (error) =>
                          error.rowIndex === rowIndex &&
                          error.cellIndices.includes(actualCellIndex)
                      );

                      const borderStyle = hasError
                        ? "2px solid red"
                        : "1px solid grey";
                      const cellStyle = {
                        padding: "3px",
                        margin: "0",
                      };

                      return (
                        <td key={actualCellIndex} style={cellStyle}>
                          <Form.Control
                            style={{
                              fontSize: "1em",
                              height: "33px",
                              border: borderStyle,
                              borderRadius: "4px",
                            }}
                            type="text"
                            value={String(cell)}
                            onChange={(e) =>
                              handleExcelInputChange(
                                rowIndex,
                                actualCellIndex,
                                e.target.value
                              )
                            }
                          />
                        </td>
                      );
                    })}

                  {/* Actions pour ajouter/supprimer */}
                  <td style={{ padding: "4px", margin: "0" }}>
                    <Button
                      variant="primary"
                      onClick={() => handleAddRowFromExcel(rowData)}
                    >
                      Ajouter
                    </Button>
                    <span
                      className="action-icon danger"
                      onClick={() => handleRemoveRowFromExcel(rowIndex)}
                      title="Retirer cette ligne"
                    >
                      <i
                        className="fas fa-times"
                        style={{
                          color: "red",
                          marginLeft: "10px",
                          fontSize: "30px",
                          cursor: "pointer",
                        }}
                      ></i>
                    </span>
                  </td>
                </tr>
              ))}

              <tr>
                <FixedNewDataCell></FixedNewDataCell>
                <FixedDataCell>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    as="select"
                    {...register("typeIdentifiant")}
                    onChange={(e) => {
                      const selectedValue = parseInt(e.target.value, 10);
                      setValue("typeIdentifiant", selectedValue);
                      if (selectedValue === 2) {
                        setValue("categorie", 2);
                      }
                    }}
                  >
                    <option value={""}></option>
                    <option value={1}>1 : MF</option>
                    <option value={2}>2 : CIN</option>
                  </Form.Control>
                </FixedDataCell>
                <FixedSecondDataCell>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    name="identifiant"
                    onChange={(e) => {
                      const value = e.target.value;
                      const identifiant = e.target.value;
                      const typeIdentifiant = +watch("typeIdentifiant");
                      checkForDuplicates(identifiant, typeIdentifiant);

                      if (typeIdentifiant == 1) {
                        const truncatedValue = value.slice(0, 13);
                        setValue("identifiant", truncatedValue);

                        if (!MatriculFiscalValidate(truncatedValue)) {
                          setError("identifiant", {
                            type: "manual",
                            message: "MF invalide",
                          });
                        } else if (truncatedValue.length !== 13) {
                          setError("identifiant", {
                            type: "manual",
                            message: "longeur MF invalide",
                          });
                        } else {
                          clearErrors("identifiant");
                        }
                      } else if (typeIdentifiant == 2) {
                        const digitsOnly = value.replace(/\D/g, "");
                        const truncatedValue = digitsOnly.slice(0, 8);
                        e.target.value = truncatedValue;
                        setValue("identifiant", truncatedValue);

                        if (truncatedValue.length !== 8) {
                          setError("identifiant", {
                            type: "manual",
                            message: "Format n° cin : 8 chiffres",
                          });
                        } else {
                          clearErrors("identifiant");
                        }
                      }
                    }}
                    minLength={watch("typeIdentifiant") == 1 ? 13 : 8}
                    maxLength={watch("typeIdentifiant") == 1 ? 13 : 8}
                    isInvalid={!!errors.identifiant}
                  />

                  {errors.identifiant && (
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ fontSize: "0.8rem" }}
                    >
                      {errors.identifiant.message}
                    </Form.Control.Feedback>
                  )}
                </FixedSecondDataCell>

                <FixedThirdDataCell>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    as="select"
                    {...register("categorie")}
                    defaultValue={""}
                    disabled={watch("typeIdentifiant") == 2}
                    onChange={(e) =>
                      setValue("categorie", parseInt(e.target.value))
                    }
                  >
                    <option value={""}></option>
                    <option value={1}>1 : Personne morale</option>
                    <option value={2}>2 : Personne physique</option>
                  </Form.Control>
                </FixedThirdDataCell>

                <FixedFourDataCell>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    name="nom"
                    className={errors.nom ? "is-invalid" : ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      const truncatedValue = value.slice(0, 40);
                      e.target.value = truncatedValue;
                      setValue("nom", truncatedValue);
                    }}
                    value={watch("nom") || ""}
                  />
                </FixedFourDataCell>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    name="activity"
                    className={errors.activity ? "is-invalid" : ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      const truncatedValue = value.slice(0, 40);
                      setValue("activity", truncatedValue);
                    }}
                    value={watch("activity") || ""}
                  />
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    name="adresse"
                    className={errors.adresse ? "is-invalid" : ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      const truncatedValue = value.slice(0, 120);
                      setValue("adresse", truncatedValue);
                    }}
                    value={watch("adresse") || ""}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    as="select"
                    {...register("typeMontantServis")}
                    defaultValue={""}
                    onChange={(e) => {
                      const selectedValue = parseInt(e.target.value, 10);
                      setValue("typeMontantServis", selectedValue);
                    }}
                  >
                    <option value={""}></option>

                    <option value={1}>1 : Honoraires</option>
                    <option value={2}>2 : Commissions</option>
                    <option value={3}>3 : Courtages</option>
                    <option value={4}>4 : Loyers</option>
                    <option value={5}>5 : Rémunération non commerciale</option>
                    <option value={6}>
                      6 : Rémunération de la performance dans la prestation
                    </option>
                    <option value={7}>
                      7 : Commissions des distributeurs agrées des opérateurs
                      téléphoniques.
                    </option>
                    <option value={0}>0 : Autres</option>
                  </Form.Control>
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    name="montantServisActNonComm"
                    placeholder="0.000"
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^\d.]/g, "");
                      const [integerPart, decimalPart] = value.split(".");
                      if (decimalPart && decimalPart.length > 3) {
                        value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                      }
                      e.target.value = value;
                      handleInputChangeAndValidation(
                        "montantServisActNonComm",
                        value
                      );
                    }}
                    disabled={typeMontantServis == 0}
                    value={typeMontantServis == 0 ? 0 : undefined}
                  />
                  {errors.montantServisActNonComm && (
                    <span style={{ color: "red" }}>
                      {errors.montantServisActNonComm.message}
                    </span>
                  )}
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    name="honorairesServisAuxSoc"
                    placeholder="0.000"
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^\d.]/g, "");
                      const [integerPart, decimalPart] = value.split(".");
                      if (decimalPart && decimalPart.length > 3) {
                        value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                      }
                      e.target.value = value;
                      handleInputChangeAndValidation(
                        "honorairesServisAuxSoc",
                        value
                      );
                    }}
                    disabled={
                      typeMontantServis == 1 ||
                      typeMontantServis == 2 ||
                      typeMontantServis == 3 ||
                      typeMontantServis == 4 ||
                      typeMontantServis == 5 ||
                      typeMontantServis == 6 ||
                      typeMontantServis == 7
                    }
                  />
                  {errors.honorairesServisAuxSoc && (
                    <span style={{ color: "red" }}>
                      {errors.honorairesServisAuxSoc.message}
                    </span>
                  )}
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    name="renumPrimeActionPartSoc"
                    placeholder="0.000"
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^\d.]/g, "");
                      const [integerPart, decimalPart] = value.split(".");
                      if (decimalPart && decimalPart.length > 3) {
                        value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                      }
                      e.target.value = value;
                      handleInputChangeAndValidation(
                        "renumPrimeActionPartSoc",
                        value
                      );
                    }}
                    disabled={
                      typeMontantServis == 1 ||
                      typeMontantServis == 2 ||
                      typeMontantServis == 3 ||
                      typeMontantServis == 4 ||
                      typeMontantServis == 5 ||
                      typeMontantServis == 6 ||

                      typeMontantServis == 7
                    }
                  />
                  {errors.renumPrimeActionPartSoc && (
                    <span style={{ color: "red" }}>
                      {errors.renumPrimeActionPartSoc.message}
                    </span>
                  )}
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    name="renumOccas"
                    placeholder="0.000"
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^\d.]/g, "");
                      const [integerPart, decimalPart] = value.split(".");
                      if (decimalPart && decimalPart.length > 3) {
                        value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                      }
                      e.target.value = value;
                      handleInputChangeAndValidation("renumOccas", value);
                    }}
                    disabled={
                      typeMontantServis == 1 ||
                      typeMontantServis == 2 ||
                      typeMontantServis == 3 ||
                      typeMontantServis == 4 ||
                      typeMontantServis == 5 ||
                      typeMontantServis == 6 ||

                      typeMontantServis == 7
                    }
                  />
                  {errors.renumOccas && (
                    <span style={{ color: "red" }}>
                      {errors.renumOccas.message}
                    </span>
                  )}
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    name="plusValueImmob"
                    placeholder="0.000"
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^\d.]/g, "");
                      const [integerPart, decimalPart] = value.split(".");
                      if (decimalPart && decimalPart.length > 3) {
                        value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                      }
                      e.target.value = value;
                      handleInputChangeAndValidation("plusValueImmob", value);
                    }}
                    disabled={
                      typeMontantServis == 1 ||
                      typeMontantServis == 2 ||
                      typeMontantServis == 3 ||
                      typeMontantServis == 4 ||
                      typeMontantServis == 5 ||
                      typeMontantServis == 6 ||

                      typeMontantServis == 7
                    }
                  />
                  {errors.plusValueImmob && (
                    <span style={{ color: "red" }}>
                      {errors.plusValueImmob.message}
                    </span>
                  )}
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    name="loyeHotelPayeSoc"
                    placeholder="0.000"
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^\d.]/g, "");
                      const [integerPart, decimalPart] = value.split(".");
                      if (decimalPart && decimalPart.length > 3) {
                        value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                      }
                      e.target.value = value;
                      handleInputChangeAndValidation("loyeHotelPayeSoc", value);
                    }}
                    disabled={
                      typeMontantServis == 1 ||
                      typeMontantServis == 2 ||
                      typeMontantServis == 3 ||
                      typeMontantServis == 4 ||
                      typeMontantServis == 5 ||
                      typeMontantServis == 6 ||

                      typeMontantServis == 7
                    }
                  />
                  {errors.loyeHotelPayeSoc && (
                    <span style={{ color: "red" }}>
                      {errors.loyeHotelPayeSoc.message}
                    </span>
                  )}
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    name="renumArtist"
                    placeholder="0.000"
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^\d.]/g, "");
                      const [integerPart, decimalPart] = value.split(".");
                      if (decimalPart && decimalPart.length > 3) {
                        value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                      }
                      e.target.value = value;
                      handleInputChangeAndValidation("renumArtist", value);
                    }}
                    disabled={
                      typeMontantServis == 1 ||
                      typeMontantServis == 2 ||
                      typeMontantServis == 3 ||
                      typeMontantServis == 4 ||
                      typeMontantServis == 5 ||
                      typeMontantServis == 6 ||

                      typeMontantServis == 7
                    }
                  />
                  {errors.renumArtist && (
                    <span style={{ color: "red" }}>
                      {errors.renumArtist.message}
                    </span>
                  )}
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    name="retenuSurTva"
                    placeholder="0.000"
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^\d.]/g, "");
                      const [integerPart, decimalPart] = value.split(".");
                      if (decimalPart && decimalPart.length > 3) {
                        value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                      }
                      e.target.value = value;
                      handleInputChangeAndValidation("retenuSurTva", value);
                    }}
                  />
                  {errors.retenuSurTva && (
                    <span style={{ color: "red" }}>
                      {errors.retenuSurTva.message}
                    </span>
                  )}
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    name="retenuOper"
                    placeholder="0.000"
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^\d.]/g, "");
                      const [integerPart, decimalPart] = value.split(".");
                      if (decimalPart && decimalPart.length > 3) {
                        value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                      }
                      e.target.value = value;
                      handleInputChangeAndValidation("retenuOper", value);
                    }}
                  />
                  {errors.retenuOper && (
                    <span style={{ color: "red" }}>
                      {errors.retenuOper.message}
                    </span>
                  )}
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    name="netServi"
                    value={netServiValue}
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^\d.]/g, "");
                      const [integerPart, decimalPart] = value.split(".");
                      if (decimalPart && decimalPart.length > 3) {
                        value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                      }
                      setNetServiValue(value);

                      handleInputChangeAndValidation("netServi", value);
                    }}
                  />
                  {errors.netServi && (
                    <span style={{ color: "red" }}>
                      {errors.netServi.message}
                    </span>
                  )}
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Button variant="primary" type="submit">
                    Ajouter
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Form>

        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation de suppression</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Êtes-vous sûr de vouloir supprimer cet élément ?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Annuler
            </Button>
            <Button variant="danger" onClick={handleConfirmDelete}>
              Supprimer
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showDuplicateModal}
          onHide={() => {
            setShowDuplicateModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Identifiant déjà utilisé</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Cet identifiant est déjà utilisé. Voulez-vous continuer ou modifier
            la ligne existante?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleContinue}>
              Continuer
            </Button>
            <Button variant="primary" onClick={handleEdit}>
              Modifier
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showTypeErrorModal}
          onHide={() => setShowTypeErrorModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Erreur de type d'identifiant</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Le type d'identifiant ne correspond pas à la ligne existante.
            Veuillez vérifier votre saisie.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowTypeErrorModal(false)}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Annexe2Form;
