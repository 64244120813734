import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";

import {
  MatriculFiscalValidate,
  handleTabulation,
} from "../../_App/Helpers/helpers";
import {
  annexe6ListByDeclaration,
  createAnnexe6Api,
  deleteAnnexe6Api,
  findAnnexes6ByDeclarationApi,
  updateAnnexe6Api,
} from "../../_App/Redux/Slices/Annexe6/annexe6Slice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { Annexe6Type } from "../../_App/Types/Entites/Annexe6Type";

import styled from "styled-components";
import * as XLSX from "xlsx";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  BeneficiairesList,
  createBeneficiaireApi,
  findAllBeneficiairesApi,
} from "../../_App/Redux/Slices/beneficiaire/beneficiaireSlice";
import {
  declarationsList,
  findAllDeclarationsApi,
} from "../../_App/Redux/Slices/declaration/declarationSlice";
import { findAllSocietesApi } from "../../_App/Redux/Slices/societes/societeSlice";
import CustomModal from "../AnnexesTab/ModalDoublon";
import ImpressionAnnexe6 from "./ImpressionAnnexe6";

const FixedNewColumnCell = styled.th`
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedNewDataCell = styled.td`
  position: sticky;
  left: 0;
  z-index: 1;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedHeaderCell = styled.th`
  position: sticky;
  left: 37px;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedSecondHeaderCell = styled.th`
  position: sticky;
  left: 112px;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedThirdHeaderCell = styled.th`
  position: sticky;
  left: 260px;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedDataCell = styled.td`
  position: sticky;
  left: 37px;
  z-index: 1;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedSecondDataCell = styled.td`
  position: sticky;
  left: 112px;
  z-index: 1;
  padding: 2px !important;
  margin: 0 !important;
`;

const FixedThirdDataCell = styled.td`
  position: sticky;
  left: 260px;
  z-index: 1;
  padding: 2px !important;
  margin: 0 !important;
`;
const FixedFourHeaderCell = styled.th`
  position: sticky;
  left: 395px;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedFourDataCell = styled.td`
  position: sticky;
  left: 395px;
  z-index: 1;
  padding: 4px !important;
  margin: 0 !important;
`;

const PrintContainer = styled.div`
  @media print {
    @page {
      size: landscape;
    }
    transform: rotate(-90deg);
    transform-origin: top left;
    /* Ajuster d'autres styles nécessaires pour l'impression */
    /* Par exemple, pour s'assurer que le texte soit noir et les couleurs bien imprimées */
  }
`;

interface Annexe6FormProps {
  declarationId: number;
}

const Annexe6Form: React.FC<Annexe6FormProps> = ({ declarationId }) => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<Annexe6Type>();
  const annexesList = useAppSelector(annexe6ListByDeclaration);
  const [editableRows, setEditableRows] = useState<number[]>([]);
  const [editedValues, setEditedValues] = useState<Partial<Annexe6Type>>({});

  type FormControlChangeEvent = React.ChangeEvent<
    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  >;

  const typeRistournes = watch("typeRistournes");
  const isMontantRistourneDisabled = typeRistournes == 0;
  const isMontantRistourneEnabled = typeRistournes == 1 || typeRistournes == 2;

  const handleInputChange = (e: FormControlChangeEvent, fieldName: string) => {
    const { value } = e.target;
    setEditedValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  const handleUpdateRow = async (rowIndex: number) => {
    try {
      const data = editedValues;
      const editedData = {
        ...annexesList[rowIndex],
        ...data,
      };

      const updateAction = await dispatch(
        updateAnnexe6Api({
          id: editedData.id || 0,
          data: editedData,
        })
      );

      if (updateAction.payload) {
        setEditableRows((prevEditableRows) =>
          prevEditableRows.filter((row) => row !== rowIndex)
        );
        setEditedValues({});
      }
    } catch (error) {
      console.error("Update failed", error);
    }
  };

  useEffect(() => {
    [
      findAllSocietesApi,
      findAllDeclarationsApi,
      findAllBeneficiairesApi,
    ].forEach((action) => dispatch(action(null)));

    declarationId && dispatch(findAnnexes6ByDeclarationApi(declarationId));
  }, [dispatch, declarationId]);

  const allDeclarations = useAppSelector(declarationsList);

  const currentDeclaration = declarationId
    ? allDeclarations.find(
        (declaration) => declaration.id === parseInt(declarationId.toString())
      )
    : undefined;
  const societeId = currentDeclaration?.declarant;
  const [formKey, setFormKey] = useState(0);

  const onSubmit: SubmitHandler<Annexe6Type> = async (data) => {
    try {
      data.declaration = declarationId;
      const createAction = await dispatch(createAnnexe6Api(data));

      if (createAction.payload && createAction.payload.success) {
        ToastSuccess(createAction.payload.message);

        const beneficiaireData = {
          typeIdentifiant: data.typeIdentifiant,
          identifiant: data.identifiant,
          name: data.name,
          activity: data.activity,
          adresse: data.adresse,

          societe: societeId || 0,
          typeBeneficiaire: "Fournisseur",
        };

        const beneficiaireExiste = beneficiaireList.some(
          (b) => b.identifiant === data.identifiant
        );

        if (!beneficiaireExiste) {
          const createBeneficiaireAction = await dispatch(
            createBeneficiaireApi(beneficiaireData)
          );

          if (
            createBeneficiaireAction.payload &&
            createBeneficiaireAction.payload.success
          ) {
            ToastSuccess("Bénéficiaire ajouté avec succès");
          } else {
            ToastWarning(createBeneficiaireAction.payload?.message);
          }
        }

        reset();
        setFormKey((prevKey) => prevKey + 1);
      } else {
        ToastWarning(createAction.payload?.message);
      }
    } catch (error) {
      console.error("La soumission a échoué", error);
      ToastWarning(
        "Une erreur est survenue lors de la soumission du formulaire"
      );
    }
  };

  const handleEditClick = (rowIndex: number) => {
    const valuesToEdit: Partial<Annexe6Type> = annexesList[rowIndex] || {};

    setEditedValues(valuesToEdit);

    if (editableRows.includes(rowIndex)) {
      handleUpdateRow(rowIndex);
    }

    setEditableRows((prevEditableRows) =>
      prevEditableRows.includes(rowIndex)
        ? prevEditableRows.filter((row) => row !== rowIndex)
        : [...prevEditableRows, rowIndex]
    );
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const handleDeleteRow = (id: number) => {
    if (id !== undefined) {
      setDeleteId(id);
      setShowDeleteModal(true);
    }
  };
  const handleConfirmDelete = async () => {
    if (deleteId !== null) {
      try {
        const deleteAction = await dispatch(deleteAnnexe6Api(deleteId));

        if (deleteAction.payload && deleteAction.payload.success) {
          ToastSuccess(deleteAction.payload.message);
          setShowDeleteModal(false);
        } else if (deleteAction.payload && deleteAction.payload.message) {
          ToastWarning(deleteAction.payload.message);
        }

        setDeleteId(null);
      } catch (error) {
        console.error("La suppression a échoué", error);
        ToastWarning("Une erreur est survenue lors de la suppression.");
      }
    }
  };

  const handleInputChangeAndValidation = (name: any, value: any) => {
    const regex = /^\d+(\.\d{1,3})?$/;

    if (value === "") {
      setValue(name, 0.0);
      clearErrors(name);
    } else if (regex.test(value)) {
      setValue(name, parseFloat(value));
      clearErrors(name);
    } else {
      setError(name, {
        type: "manual",
        message:
          "Veuillez saisir un nombre valide avec un maximum de 3 chiffres après le point décimal.",
      });
    }
  };
  const [showModal, setShowModal] = useState(false);

  const exportToExcel = () => {
    const filteredAnnexesList = annexesList.map(
      ({ id, type, declaration, numOrdre, ...rest }) => rest
    );

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredAnnexesList);
    XLSX.utils.book_append_sheet(wb, ws, "Annexes");
    XLSX.writeFile(wb, "annexe6.xlsx");
  };

  const [excelData, setExcelData] = useState<any[]>([]);

  const handleRemoveRowFromExcel = (rowIndex: any) => {
    const updatedExcelData = [...excelData];
    updatedExcelData.splice(rowIndex, 1);
    setExcelData(updatedExcelData);
  };

  const handleAddRowFromExcel = async (rowData: any) => {
    const errors = validateExcelData([rowData]);
    const hasErrors = errors.some((error) => error.rowIndex === 0);

    if (hasErrors) {
      ToastWarning(
        "La ligne contient des erreurs. Corrigez-les avant d'ajouter."
      );
      return;
    }

    try {
      const newData: Annexe6Type = {
        typeIdentifiant: rowData[0],
        identifiant: rowData[1],
        categorie: rowData[2],
        name: rowData[3],
        activity: rowData[4],
        adresse: rowData[5],
        typeRistournes: rowData[6],
        montantRistourne: rowData[7],
        ventePpForfaitaire: rowData[8],
        avancePpForfaitaire: rowData[9],
        mntRevenuJeux: rowData[10],
        rasRevenuJeux: rowData[11],
        mntVenteProfitDist: rowData[12],
        rasProfitDist: rowData[13],
        mntPercus: rowData[14],

        mntVenteFabAlcool: rowData[15],
        mntAvanceFabAlcool: rowData[16],

        declaration: declarationId,
      };

      await dispatch(createAnnexe6Api(newData));

      setExcelData((prevExcelData) =>
        prevExcelData.filter((row) => row !== rowData)
      );
    } catch (error) {
      console.error("Addition of row from Excel failed", error);
    }
  };

  const printRef = useRef<HTMLDivElement>(null);

  const handlePrint = () => {
    const printContents = printRef.current;

    if (printContents) {
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents.innerHTML;
      window.print();

      document.body.innerHTML = originalContents;

      window.location.reload();
    }
  };

  const beneficiaireList = useAppSelector(BeneficiairesList);

  const identifiantBeneficiaire = watch("identifiant");

  useEffect(() => {
    if (identifiantBeneficiaire) {
      const beneficiaire = beneficiaireList.find(
        (b) =>
          b.identifiant === identifiantBeneficiaire &&
          b.typeIdentifiant == typeIdentifiant
      );

      if (beneficiaire) {
        setValue("name", beneficiaire.name);
        setValue("activity", beneficiaire.activity);
        setValue("adresse", beneficiaire.adresse);
      }
    }
  }, [identifiantBeneficiaire, setValue, beneficiaireList]);

  // gestion des doublons
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [duplicateIndex, setDuplicateIndex] = useState<number | null>(null);

  const [showTypeErrorModal, setShowTypeErrorModal] = useState(false);
  const typeIdentifiant = watch("typeIdentifiant");

  const checkForDuplicates = (identifiant: string, typeIdentifiant: number) => {
    const index = annexesList.findIndex(
      (annexe) => annexe.identifiant === identifiant
    );

    if (index !== -1) {
      setDuplicateIndex(index);

      setShowDuplicateModal(true);
    } else {
      setDuplicateIndex(null);
      setShowDuplicateModal(false);
      setShowTypeErrorModal(false);
    }
  };

  const handleContinue = () => {
    if (duplicateIndex != null) {
      const existingType = annexesList[duplicateIndex].typeIdentifiant;

      if (existingType != typeIdentifiant) {
        setShowTypeErrorModal(true);

        setShowDuplicateModal(false);
      } else {
        setShowDuplicateModal(false);
      }
    }
  };

  const handleEdit = () => {
    if (duplicateIndex != null) {
      handleEditClick(duplicateIndex);
      setSearchQuery(annexesList[duplicateIndex].identifiant); // Mettre à jour la recherche avec l'identifiant modifié

      reset();
      setShowDuplicateModal(false);
    }
  };

  // recherche

  const [searchQuery, setSearchQuery] = useState("");

  const filteredAnnexes = annexesList.filter((annexe) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      annexe.identifiant.toLowerCase().includes(lowerCaseQuery) ||
      annexe.typeIdentifiant.toString().includes(lowerCaseQuery) ||
      annexe.categorie.toString().includes(lowerCaseQuery) ||
      annexe.name.toLowerCase().includes(lowerCaseQuery) ||
      annexe.activity.toLowerCase().includes(lowerCaseQuery) ||
      annexe.adresse.toLowerCase().includes(lowerCaseQuery) ||
      annexe.typeRistournes.toString().includes(lowerCaseQuery) ||
      annexe.montantRistourne?.toString().includes(lowerCaseQuery) ||
      annexe.ventePpForfaitaire?.toString().includes(lowerCaseQuery) ||
      annexe.mntRevenuJeux?.toString().includes(lowerCaseQuery) ||
      annexe.rasRevenuJeux?.toString().includes(lowerCaseQuery) ||
      annexe.mntVenteProfitDist?.toString().includes(lowerCaseQuery) ||
      annexe.mntAvanceFabAlcool?.toString().includes(lowerCaseQuery)
    );
  });

  // validate excel

  type ExcelDataRow = (string | number | null | undefined)[];

  type ExcelError = {
    rowIndex: number;
    cellIndices: number[];
  };

  // Fonction de validation pour les données Excel
  const validateExcelData = (data: ExcelDataRow[]): ExcelError[] => {
    const errors: ExcelError[] = [];

    data.forEach((row, rowIndex) => {
      const errorIndices: number[] = [];
      row.forEach((cell, cellIndex) => {
        let hasError = false;
        let value = String(cell);

        switch (cellIndex) {
          case 0: // Type d'identifiant
            const validIdentifiantTypes = [1, 2, 3, 4];
            if (!validIdentifiantTypes.includes(Number(value))) {
              hasError = true;
            }
            break;

          case 1: // Identifiant
            const typeIdentifiant = Number(row[0]);
            if (
              typeIdentifiant === 1 &&
              (value.length !== 13 || !MatriculFiscalValidate(value))
            ) {
              hasError = true;
            } else if (typeIdentifiant === 2) {
              const digitsOnly = value.replace(/\D/g, "");
              if (digitsOnly.length !== 8) {
                hasError = true;
              }
            } else if (
              [3, 4].includes(typeIdentifiant) &&
              value.length < 8 ||
              value.length > 13
            ) {
              hasError = true;
            }
            break;

          case 2: // Catégorie
            if (![1, 2].includes(Number(value))) {
              hasError = true;
            }
            break;

          case 3: // Nom
            if (value.length > 40) {
              hasError = true;
            }
            break;
            case 4: // Activité
            if (value.length > 40) {
              hasError = true;
            }
            break;
  
          case 5: // Adresse
            if (value.length > 120) {
              hasError = true;
            }
            break;

          case 6: // Type de ristournes
            if (![0, 1, 2].includes(Number(value))) {
              hasError = true;
            }
            break;

          case 7: // montantRistourne:
          case 8: // ventePpForfaitaire
          case 9: //avancePpForfaitaire
          case 10: //revenu jeux
          case 11: //ras revenu jeux
          case 12: //vente profit
          case 13: //ras vente profit
          case 14: //mnt percus
          case 15: //vente alcol
          case 16: //avance alcol
            // Nettoyer et formater la valeur
            value = value.replace(/[^\d.]/g, ""); // autoriser uniquement des chiffres et un seul point décimal
            const parts = value.split(".");
            if (parts.length > 2 || (parts[1] && parts[1].length > 3)) {
              hasError = true;
            } else {
              const numericValue = parseFloat(value);
              if (isNaN(numericValue) || numericValue < 0) {
                hasError = true;
              }
            }
            break;
          // Ajoutez d'autres validations nécessaires pour vos champs
        }

        if (hasError) {
          errorIndices.push(cellIndex);
        }
      });

      if (errorIndices.length > 0) {
        errors.push({ rowIndex, cellIndices: errorIndices });
      }
    });

    return errors;
  };

  const [excelErrors, setExcelErrors] = useState<ExcelError[]>([]);

  const handleExcelInputChange = (
    rowIndex: number,
    cellIndex: number,
    newValue: string
  ) => {
    const updatedExcelData = [...excelData];
    updatedExcelData[rowIndex][cellIndex] = newValue;

    setExcelData(updatedExcelData);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result;
      if (result instanceof ArrayBuffer) {
        const data = new Uint8Array(result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const rawData = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
        }) as ExcelDataRow[];

        const validatedData = rawData.slice(1); // Retirer les en-têtes
        setExcelData(validatedData);
        setExcelErrors(validateExcelData(validatedData));
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const isExcelLoaded = excelData.length > 0;

  const validateExcel = () => {
    const errors = validateExcelData(excelData);
    setExcelErrors(errors);

    if (errors.length === 0) {
      console.log("Données Excel valides.");
    } else {
      console.log("Des erreurs existent dans les données Excel.");
    }
  };

  return (
    <div
      style={{
        overflowX: "auto",
        width: "100%",
        scrollbarWidth: "thin",
        scrollbarColor: "rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1)",
      }}
    >
      <div style={{ display: "flex", marginTop: "10px" }}>
        <div style={{ flex: 0.7, marginRight: "5px", paddingRight: "20px" }}>
          <p style={{ color: "black" }}>
            Ristournes commerciales et non commerciales , ventes effectuées par
            les entreprises industrielles et les entreprises de commerce en gros
            aux personnes physiques soumises à l’impôt sur le revenu selon le
            régime forfaitaire,montants perçus en espèces au titre des
            marchandises vendues et services rendus , revenus des jeux de pari ,
            de hasard et de loterie , ventes des entreprises industrielles et de
            commerce au profit des intervenants dans la distribution de biens et
            de produits et services , qui ne dépassent pas 20.000 dinars par an
            , avance due sur les ventes des fabricants et embouteilleurs de vin,
            de bière et de boissons alcoolisées.
          </p>
        </div>

        <div
          style={{
            flex: 0.3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              height: "32px",
            }}
            onClick={handlePrint}
            variant="info"
          >
            Imprimer
          </Button>
          <div ref={printRef} style={{ display: "none" }}>
            <ImpressionAnnexe6 annexesList={annexesList} />
          </div>
          <Button
            variant="light"
            onClick={exportToExcel}
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              height: "32px",
            }}
          >
            Save as Excel
            <i
              className="fas fa-download"
              style={{ color: "green", fontSize: "18px", marginLeft: "10px" }}
            ></i>
          </Button>

          <Button
            variant="light"
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "32px",
            }}
          >
            <label
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                color: "black",
              }}
            >
              <input
                type="file"
                accept=".xls,.xlsx"
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
              Import Excel
              <i
                className="far fa-file-excel"
                style={{ color: "green", fontSize: "18px", marginLeft: "10px" }}
              ></i>
            </label>
          </Button>
        </div>
      </div>

      <div
        style={{
          marginTop: "10px",
          padding: "10px",
          display: "flex",
          justifyContent: "space-between", // Les éléments se répartissent entre début et fin
          alignItems: "center", // Pour aligner verticalement
        }}
      >
        <span style={{ fontWeight: "bold", fontSize: "1.1em", color: "black" }}>
          {`Nombre total de bénéficiaires : ${annexesList.length}`}
        </span>

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>
            {isExcelLoaded && (
              <Button
                variant="success"
                onClick={validateExcel}
                style={{
                  marginRight: "15px",
                  height: "30px",
                  fontSize: "0.8em",
                  marginBottom: "7px",
                }}
              >
                Valider l'import
              </Button>
            )}
          </span>

          <Form.Control
            type="text"
            placeholder="Rechercher..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{
              width: "200px",
              height: "30px",
              textAlign: "center",
            }}
          />
        </div>
      </div>
      <Form onKeyDown={handleTabulation} onSubmit={handleSubmit(onSubmit)}>
        <Table bordered striped hover className="table-responsive">
          <thead>
            <tr>
              <FixedNewColumnCell>
                <div
                  style={{
                    width: "30px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  N°
                </div>
              </FixedNewColumnCell>
              <FixedHeaderCell>
                <div
                  style={{
                    width: "70px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Type ID
                </div>
              </FixedHeaderCell>
              <FixedSecondHeaderCell>
                <div
                  style={{
                    width: "140px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Identifiant
                </div>
              </FixedSecondHeaderCell>
              <FixedThirdHeaderCell>
                <div
                  style={{
                    width: "130px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Catégorie
                </div>
              </FixedThirdHeaderCell>
              <FixedFourHeaderCell>
                {" "}
                <div
                  style={{
                    width: "140px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Nom ou raison soc
                </div>
              </FixedFourHeaderCell>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "140px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Activité
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "140px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Adresse
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "200px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Type Ristournes
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "130px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Montant Ristourne
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "150px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Vente Pp Forfaitaire
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "150px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Avance Pp Forfaitaire
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "170px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Mnt Revenu Jeux
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "170px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Ras Revenu Jeux
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "250px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Mnt Vente Profit Dist
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "180px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Ras Profit Dist
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "150px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Mnt Percus
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "180px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Mnt Vente Fab Alcool
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "180px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Mnt Avance Fab Alcool
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "180px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Action
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredAnnexes.map((annexe6: Annexe6Type, rowIndex) => (
              <tr
                key={annexe6.id}
                onDoubleClick={() => handleEditClick(rowIndex)}
              >
                <FixedNewDataCell
                  style={{ padding: "4px", margin: "0", textAlign: "center" }}
                >
                  {rowIndex + 1}
                </FixedNewDataCell>
                <FixedDataCell>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    as="select"
                    name="typeIdentifiant"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.typeIdentifiant ||
                          annexe6.typeIdentifiant
                        : annexe6.typeIdentifiant
                    }
                    onChange={(e) => handleInputChange(e, "typeIdentifiant")}
                    disabled={!editableRows.includes(rowIndex)}
                  >
                    <option value={1}>1 : MF</option>
                    <option value={2}>2 : CIN</option>
                    <option value={3}>3 : CS</option>
                    <option value={4}>4 : IDE</option>
                  </Form.Control>
                </FixedDataCell>
                <FixedSecondDataCell>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    name="identifiant"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.identifiant !== undefined
                          ? editedValues.identifiant
                          : annexe6.identifiant
                        : annexe6.identifiant
                    }
                    onChange={(e) => handleInputChange(e, "identifiant")}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </FixedSecondDataCell>
                <FixedThirdDataCell>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    as="select"
                    name={`annexesList[${rowIndex}].categorie`}
                    value={annexe6.categorie}
                    onChange={(e) => handleInputChange(e, "categorie")}
                    disabled={!editableRows.includes(rowIndex)}
                  >
                    <option value={1}>1 : Personne Morale</option>
                    <option value={2}>2 : Personne Physique</option>
                  </Form.Control>
                </FixedThirdDataCell>
                <FixedFourDataCell>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    name={`annexesList[${rowIndex}].name`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.name !== undefined
                          ? editedValues.name
                          : annexe6.name
                        : annexe6.name
                    }
                    onChange={(e) => handleInputChange(e, "name")}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </FixedFourDataCell>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    name={`annexesList[${rowIndex}].activity`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.activity !== undefined
                          ? editedValues.activity
                          : annexe6.activity
                        : annexe6.activity
                    }
                    onChange={(e) => handleInputChange(e, "activity")}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="text"
                    name={`annexesList[${rowIndex}].adresse`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.adresse !== undefined
                          ? editedValues.adresse
                          : annexe6.adresse
                        : annexe6.adresse
                    }
                    onChange={(e) => handleInputChange(e, "adresse")}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    as="select"
                    name="typeRistournes"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.typeRistournes || annexe6.typeRistournes
                        : annexe6.typeRistournes
                    }
                    onChange={(e) => handleInputChange(e, "typeRistournes")}
                    disabled={!editableRows.includes(rowIndex)}
                  >
                    <option value={0}>
                      0 : Si le montant ristourne est null
                    </option>
                    <option value={1}>1 : Ristournes Commerciales</option>
                    <option value={2}>2 : Ristournes non Commerciales</option>
                  </Form.Control>
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="number"
                    name={`annexesList[${rowIndex}].montantRistourne`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.montantRistourne !== undefined
                          ? editedValues.montantRistourne
                          : annexe6.montantRistourne
                        : annexe6.montantRistourne
                    }
                    onChange={(e) => handleInputChange(e, "montantRistourne")}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="number"
                    name={`annexesList[${rowIndex}].ventePpForfaitaire`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.ventePpForfaitaire !== undefined
                          ? editedValues.ventePpForfaitaire
                          : annexe6.ventePpForfaitaire
                        : annexe6.ventePpForfaitaire
                    }
                    onChange={(e) => handleInputChange(e, "ventePpForfaitaire")}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="number"
                    name={`annexesList[${rowIndex}].avancePpForfaitaire`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.avancePpForfaitaire !== undefined
                          ? editedValues.avancePpForfaitaire
                          : annexe6.avancePpForfaitaire
                        : annexe6.avancePpForfaitaire
                    }
                    onChange={(e) =>
                      handleInputChange(e, "avancePpForfaitaire")
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="number"
                    name={`annexesList[${rowIndex}].mntRevenuJeux`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.mntRevenuJeux !== undefined
                          ? editedValues.mntRevenuJeux
                          : annexe6.mntRevenuJeux
                        : annexe6.mntRevenuJeux
                    }
                    onChange={(e) => handleInputChange(e, "mntRevenuJeux")}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="number"
                    name={`annexesList[${rowIndex}].rasRevenuJeux`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.rasRevenuJeux !== undefined
                          ? editedValues.rasRevenuJeux
                          : annexe6.rasRevenuJeux
                        : annexe6.rasRevenuJeux
                    }
                    onChange={(e) => handleInputChange(e, "rasRevenuJeux")}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="number"
                    name={`annexesList[${rowIndex}].mntVenteProfitDist`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.mntVenteProfitDist !== undefined
                          ? editedValues.mntVenteProfitDist
                          : annexe6.mntVenteProfitDist
                        : annexe6.mntVenteProfitDist
                    }
                    onChange={(e) => handleInputChange(e, "mntVenteProfitDist")}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="number"
                    name={`annexesList[${rowIndex}].rasProfitDist`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.rasProfitDist !== undefined
                          ? editedValues.rasProfitDist
                          : annexe6.rasProfitDist
                        : annexe6.rasProfitDist
                    }
                    onChange={(e) => handleInputChange(e, "rasProfitDist")}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="number"
                    name={`annexesList[${rowIndex}].mntPercus`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.mntPercus !== undefined
                          ? editedValues.mntPercus
                          : annexe6.mntPercus
                        : annexe6.mntPercus
                    }
                    onChange={(e) => handleInputChange(e, "mntPercus")}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="number"
                    name={`annexesList[${rowIndex}].mntVenteFabAlcool`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.mntVenteFabAlcool !== undefined
                          ? editedValues.mntVenteFabAlcool
                          : annexe6.mntVenteFabAlcool
                        : annexe6.mntVenteFabAlcool
                    }
                    onChange={(e) => handleInputChange(e, "mntVenteFabAlcool")}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    type="number"
                    name={`annexesList[${rowIndex}].mntAvanceFabAlcool`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.mntAvanceFabAlcool !== undefined
                          ? editedValues.mntAvanceFabAlcool
                          : annexe6.mntAvanceFabAlcool
                        : annexe6.mntAvanceFabAlcool
                    }
                    onChange={(e) => handleInputChange(e, "mntAvanceFabAlcool")}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <span
                    className={`action-icon ${
                      editableRows.includes(rowIndex) ? "success" : "warning"
                    }`}
                    onClick={() => handleEditClick(rowIndex)}
                    title={
                      editableRows.includes(rowIndex)
                        ? "Enregistrer"
                        : "Modifier"
                    }
                  >
                    <i
                      className={`fas ${
                        editableRows.includes(rowIndex) ? "fa-check" : "fa-edit"
                      }`}
                      style={{
                        color: editableRows.includes(rowIndex)
                          ? "#008000"
                          : "#0000ff",
                        marginRight: "8px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
                  <span
                    className={`action-icon danger`}
                    onClick={() => annexe6.id && handleDeleteRow(annexe6.id)}
                    title="Supprimer une ligne"
                  >
                    <i
                      className="far fa-trash-alt"
                      style={{
                        color: "#ff0000",
                        marginRight: "13px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
                </td>
              </tr>
            ))}

            {/* Ligne d'ajout */}
            {excelData.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                <FixedNewDataCell
                  style={{ padding: "4px", margin: "0", textAlign: "center" }}
                ></FixedNewDataCell>
                {/* Composants fixes pour les premières colonnes */}
                {[0, 1, 2, 3].map((cellIndex) => {
                  const hasError = excelErrors.some(
                    (error) =>
                      error.rowIndex === rowIndex &&
                      error.cellIndices.includes(cellIndex)
                  );

                  const borderStyle = hasError
                    ? "2px solid red"
                    : "1px solid grey";
                  const cellStyle = {
                    padding: "3px",
                    margin: "0",
                    border: borderStyle,
                  };

                  let CellComponent;
                  switch (cellIndex) {
                    case 0:
                      CellComponent = FixedDataCell;
                      break;
                    case 1:
                      CellComponent = FixedSecondDataCell;
                      break;
                    case 2:
                      CellComponent = FixedThirdDataCell;
                      break;
                    case 3:
                      CellComponent = FixedFourDataCell;
                      break;
                    default:
                      CellComponent = "td"; // Default case
                  }

                  return (
                    <CellComponent key={cellIndex}>
                      <Form.Control
                        style={{
                          fontSize: "1em",
                          height: "33px",
                          border: borderStyle,
                          borderRadius: "4px",
                        }}
                        type="text"
                        value={rowData[cellIndex]}
                        onChange={(e) =>
                          handleExcelInputChange(
                            rowIndex,
                            cellIndex,
                            e.target.value
                          )
                        }
                      />
                    </CellComponent>
                  );
                })}

                {/* Autres cellules */}
                {rowData.slice(4).map((cell: any, additionalCellIndex: any) => {
                  const actualCellIndex = additionalCellIndex + 4;
                  const hasError = excelErrors.some(
                    (error) =>
                      error.rowIndex === rowIndex &&
                      error.cellIndices.includes(actualCellIndex)
                  );

                  const borderStyle = hasError
                    ? "2px solid red"
                    : "1px solid grey";
                  const cellStyle = {
                    padding: "3px",
                    margin: "0",
                  };

                  return (
                    <td key={actualCellIndex} style={cellStyle}>
                      <Form.Control
                        style={{
                          fontSize: "1em",
                          height: "33px",
                          border: borderStyle,
                          borderRadius: "4px",
                        }}
                        type="text"
                        value={String(cell)}
                        onChange={(e) =>
                          handleExcelInputChange(
                            rowIndex,
                            actualCellIndex,
                            e.target.value
                          )
                        }
                      />
                    </td>
                  );
                })}

                {/* Actions pour ajouter/supprimer */}
                <td style={{ padding: "4px", margin: "0" }}>
                  <Button
                    variant="primary"
                    onClick={() => handleAddRowFromExcel(rowData)}
                  >
                    Ajouter
                  </Button>
                  <span
                    className="action-icon danger"
                    onClick={() => handleRemoveRowFromExcel(rowIndex)}
                    title="Retirer cette ligne"
                  >
                    <i
                      className="fas fa-times"
                      style={{
                        color: "red",
                        marginLeft: "10px",
                        fontSize: "30px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
                </td>
              </tr>
            ))}

           
            <tr>
              <FixedNewDataCell
                style={{ padding: "4px", margin: "0", textAlign: "center" }}
              ></FixedNewDataCell>
              <FixedDataCell>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  as="select"
                  {...register("typeIdentifiant")}
                  onChange={(e) => {
                    const selectedValue = parseInt(e.target.value, 10);
                    setValue("typeIdentifiant", selectedValue);
                    if (selectedValue === 2 || selectedValue === 3) {
                      setValue("categorie", 2);
                    }
                  }}
                >
                  <option value={""}></option>
                  <option value={1}>1 : MF</option>
                  <option value={2}>2 : CIN</option>
                  <option value={3}>3 : CS</option>
                  <option value={4}>4 : IDE</option>
                </Form.Control>
              </FixedDataCell>
              <FixedSecondDataCell>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="identifiant"
                  onChange={(e) => {
                    let value = e.target.value;
                    const typeIdentifiant = +watch("typeIdentifiant");
                    const identifiant = e.target.value;
                    checkForDuplicates(identifiant, typeIdentifiant);

                    let errorMessage = "";

                    if (typeIdentifiant === 1) {
                      const truncatedValue = value.slice(0, 13);
                      if (!MatriculFiscalValidate(truncatedValue)) {
                        errorMessage = "L'identifiant MF n'est pas valide";
                      } else if (truncatedValue.length !== 13) {
                        errorMessage =
                          "La longueur de l'identifiant MF doit être de 13 caractères";
                      }
                    } else if (typeIdentifiant === 2) {
                      const digitsOnly = value.replace(/\D/g, "");
                      const truncatedValue = digitsOnly.slice(0, 8);
                      value = truncatedValue;
                      if (truncatedValue.length !== 8) {
                        errorMessage =
                          "Le numéro CIN doit contenir exactement 8 chiffres";
                      }
                    } else if (typeIdentifiant === 3) {
                      const truncatedValue = value.slice(0, 13);
                      value = truncatedValue;
                    }

                    if (errorMessage) {
                      setError("identifiant", {
                        type: "manual",
                        message: errorMessage,
                      });
                    } else {
                      clearErrors("identifiant");
                    }

                    e.target.value = value;
                    setValue("identifiant", value);
                  }}
                  minLength={watch("typeIdentifiant") === 1 ? 13 : 8}
                  maxLength={watch("typeIdentifiant") === 1 ? 13 : 13}
                  isInvalid={!!errors.identifiant}
                />

                {errors.identifiant && (
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ fontSize: "0.8rem" }}
                  >
                    {errors.identifiant.message}
                  </Form.Control.Feedback>
                )}
              </FixedSecondDataCell>

              <FixedThirdDataCell>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  as="select"
                  {...register("categorie")}
                  defaultValue={""}
                  disabled={
                    watch("typeIdentifiant") == 2 ||
                    watch("typeIdentifiant") == 3
                  }
                  onChange={(e) =>
                    setValue("categorie", parseInt(e.target.value))
                  }
                >
                  <option value={""}></option>
                  <option value={1}>1 : Personne morale</option>
                  <option value={2}>2 : Personne physique</option>
                </Form.Control>
              </FixedThirdDataCell>
              <FixedFourDataCell>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="name"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 40) {
                      setError("name", {
                        type: "manual",
                        message: "Le nom ne doit pas dépasser 40 caractères.",
                      });
                    } else {
                      clearErrors("name");
                      setValue("name", value);
                    }
                  }}
                  value={watch("name") || ""}
                />
                {errors.name && (
                  <span style={{ color: "red" }}>{errors.name.message}</span>
                )}
              </FixedFourDataCell>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="activity"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 40) {
                      setError("activity", {
                        type: "manual",
                        message: "L'emploi ne doit pas dépasser 40 caractères.",
                      });
                    } else {
                      clearErrors("activity");
                      setValue("activity", value);
                    }
                  }}
                  value={watch("activity") || ""}
                />
                {errors.activity && (
                  <span style={{ color: "red" }}>
                    {errors.activity.message}
                  </span>
                )}
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="adresse"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 120) {
                      setError("adresse", {
                        type: "manual",
                        message:
                          "L'adresse ne doit pas dépasser 120 caractères.",
                      });
                    } else {
                      clearErrors("adresse");
                      setValue("adresse", value);
                    }
                  }}
                  value={watch("adresse") || ""}
                />
                {errors.adresse && (
                  <span style={{ color: "red" }}>{errors.adresse.message}</span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  as="select"
                  {...register("typeRistournes")}
                  defaultValue={""}
                  onChange={(e) =>
                    setValue("typeRistournes", parseInt(e.target.value, 10))
                  }
                >
                  <option value={""}></option>
                  <option value={0}>
                    0 : Si le montant ristourne est null
                  </option>
                  <option value={1}>1 : Ristournes Commerciales</option>
                  <option value={2}>2 : Ristournes non Commerciales</option>
                </Form.Control>
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="montantRistourne"
                  disabled={isMontantRistourneDisabled}
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("montantRistourne", value);
                  }}
                />
                {errors.montantRistourne && (
                  <span style={{ color: "red" }}>
                    {errors.montantRistourne.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="ventePpForfaitaire"
                  disabled={isMontantRistourneEnabled}
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("ventePpForfaitaire", value);
                  }}
                />
                {errors.ventePpForfaitaire && (
                  <span style={{ color: "red" }}>
                    {errors.ventePpForfaitaire.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="avancePpForfaitaire"
                  disabled={isMontantRistourneEnabled}
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation(
                      "avancePpForfaitaire",
                      value
                    );
                  }}
                />
                {errors.avancePpForfaitaire && (
                  <span style={{ color: "red" }}>
                    {errors.avancePpForfaitaire.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="mntRevenuJeux"
                  disabled={isMontantRistourneEnabled}
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("mntRevenuJeux", value);
                  }}
                />
                {errors.mntRevenuJeux && (
                  <span style={{ color: "red" }}>
                    {errors.mntRevenuJeux.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="rasRevenuJeux"
                  disabled={isMontantRistourneEnabled}
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("rasRevenuJeux", value);
                  }}
                />
                {errors.rasRevenuJeux && (
                  <span style={{ color: "red" }}>
                    {errors.rasRevenuJeux.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="mntVenteProfitDist"
                  disabled={isMontantRistourneEnabled}
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("mntVenteProfitDist", value);
                  }}
                />
                {errors.mntVenteProfitDist && (
                  <span style={{ color: "red" }}>
                    {errors.mntVenteProfitDist.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="rasProfitDist"
                  disabled={isMontantRistourneEnabled}
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("rasProfitDist", value);
                  }}
                />
                {errors.rasProfitDist && (
                  <span style={{ color: "red" }}>
                    {errors.rasProfitDist.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="mntPercus"
                  disabled={isMontantRistourneEnabled}
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("mntPercus", value);
                  }}
                />
                {errors.mntPercus && (
                  <span style={{ color: "red" }}>
                    {errors.mntPercus.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="mntVenteFabAlcool"
                  disabled={isMontantRistourneEnabled}
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("mntVenteFabAlcool", value);
                  }}
                />
                {errors.mntVenteFabAlcool && (
                  <span style={{ color: "red" }}>
                    {errors.mntVenteFabAlcool.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="mntAvanceFabAlcool"
                  disabled={isMontantRistourneEnabled}
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("mntAvanceFabAlcool", value);
                  }}
                />
                {errors.mntAvanceFabAlcool && (
                  <span style={{ color: "red" }}>
                    {errors.mntAvanceFabAlcool.message}
                  </span>
                )}
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Button variant="primary" type="submit">
                  Ajouter
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Form>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cet élément ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDuplicateModal}
        onHide={() => {
          setShowDuplicateModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Identifiant déjà utilisé</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Cet identifiant est déjà utilisé. Voulez-vous continuer ou modifier la
          ligne existante?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleContinue}>
            Continuer
          </Button>
          <Button variant="primary" onClick={handleEdit}>
            Modifier
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showTypeErrorModal}
        onHide={() => setShowTypeErrorModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Erreur de type d'identifiant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Le type d'identifiant ne correspond pas à la ligne existante. Veuillez
          vérifier votre saisie.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowTypeErrorModal(false)}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Annexe6Form;
