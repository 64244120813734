import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import * as XLSX from "xlsx";
import {
  MatriculFiscalValidate,
  handleTabulation,
} from "../../_App/Helpers/helpers";
import {
  annexe4ListByDeclaration,
  createAnnexe4Api,
  deleteAnnexe4Api,
  findAnnexes4ByDeclarationApi,
  updateAnnexe4Api,
} from "../../_App/Redux/Slices/annexe4/annexe4Slice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { Annexe4Type } from "../../_App/Types/Entites/Annexe4Type";

import styled from "styled-components";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  BeneficiairesList,
  createBeneficiaireApi,
  findAllBeneficiairesApi,
} from "../../_App/Redux/Slices/beneficiaire/beneficiaireSlice";
import {
  declarationsList,
  findAllDeclarationsApi,
} from "../../_App/Redux/Slices/declaration/declarationSlice";
import { findAllSocietesApi } from "../../_App/Redux/Slices/societes/societeSlice";
import ImpressionAnnexe4 from "./ImpressionAnnexe4";
const FixedNewColumnCell = styled.th`
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedNewDataCell = styled.td`
  position: sticky;
  left: 0;
  z-index: 1;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedHeaderCell = styled.th`
  position: sticky;
  left: 37px;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedSecondHeaderCell = styled.th`
  position: sticky;
  left: 112px;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedThirdHeaderCell = styled.th`
  position: sticky;
  left: 255px;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedDataCell = styled.td`
  position: sticky;
  left: 37px;
  z-index: 1;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedSecondDataCell = styled.td`
  position: sticky;
  left: 112px;
  z-index: 1;
  padding: 2px !important;
  margin: 0 !important;
`;

const FixedThirdDataCell = styled.td`
  position: sticky;
  left: 255px;
  z-index: 1;
  padding: 2px !important;
  margin: 0 !important;
`;
const FixedFourHeaderCell = styled.th`
  position: sticky;
  left: 389px;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedFourDataCell = styled.td`
  position: sticky;
  left: 389px;
  z-index: 1;
  padding: 4px !important;
  margin: 0 !important;
`;
interface Annexe4FormProps {
  declarationId: number;
}

const Annexe4Form: React.FC<Annexe4FormProps> = ({ declarationId }) => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<Annexe4Type>();
  const annexesList = useAppSelector(annexe4ListByDeclaration);
  const [editableRows, setEditableRows] = useState<number[]>([]);
  const [editedValues, setEditedValues] = useState<Partial<Annexe4Type>>({});
  const [tauxRevenuMobJetPres, setTauxRevenuMobJetPres] = useState(0);
  const [tauxHonoraire, setTauxHonoraire] = useState(0);
  const [tauxRenumNonResid, setTauxRenumNonResid] = useState(0);
  const [TauxPluValueImmob, setTauxPluValueImmob] = useState(0);
  const [tauxPlusValueCessionAct, setTauxPlusValueCessionAct] = useState(0);

  const mntHonoraire = parseFloat(String(watch("MntHonoraire") || "0"));
  const mntRenumNonResid = parseFloat(String(watch("MntRenumNonResid") || "0"));
  const montantPluValueImmob = parseFloat(
    String(watch("MontantPluValueImmob") || "0")
  );
  const mntPlusValueCessionAct = parseFloat(
    String(watch("MntPlusValueCessionAct") || "0")
  );
  const MntRevenuMobJetPres = parseFloat(
    String(watch("MntRevenuMobJetPres") || "0")
  );
  const renumRevEtatRegimeFiscalPriv = parseFloat(
    String(watch("renumRevEtatRegimeFiscalPriv") || "0")
  );
  const retenuTitreTva100 = parseFloat(
    String(watch("RetenuTitreTva100") || "0")
  );

  const retenuOper = parseFloat(String(watch("retenuOper") || "0"));

  const netServi =
    mntHonoraire +
    mntRenumNonResid +
    montantPluValueImmob +
    MntRevenuMobJetPres +
    mntPlusValueCessionAct +
    renumRevEtatRegimeFiscalPriv -
    (retenuTitreTva100 + retenuOper);

  useEffect(() => {
    setValue("netServi", parseFloat(netServi.toFixed(3)));
  }, [setValue, netServi]);

  const [netServiValue, setNetServiValue] = useState(netServi.toFixed(3));
  useEffect(() => {
    setNetServiValue(netServi.toFixed(3));
  }, [netServi]);

  type FormControlChangeEvent = React.ChangeEvent<
    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  >;

  const handleInputChangeAndValidation = (name: any, value: any) => {
    const regex = /^\d+(\.\d{1,3})?$/;

    if (value === "") {
      setValue(name, 0.0);
      clearErrors(name);
    } else if (regex.test(value)) {
      setValue(name, parseFloat(value));
      clearErrors(name);
    } else {
      setError(name, {
        type: "manual",
        message:
          "Veuillez saisir un nombre valide avec un maximum de 3 chiffres après le point décimal.",
      });
    }
  };

  const handleInputTauxChangeAndValidation = (name: any, value: any) => {
    const regex = /^\d{0,2}(\.\d{1,2})?$/;

    if (regex.test(value) || value === "") {
      setValue(name, parseFloat(value));
      clearErrors(name);
    } else {
      setError(name, {
        type: "manual",
        message:
          "Veuillez saisir un taux valide au format xx.xx, avec un maximum de deux chiffres avant le point et deux chiffres après le point.",
      });
    }
  };

  const handleUpdateRow = async (rowIndex: number) => {
    try {
      const data = editedValues;
      const editedData = {
        ...annexesList[rowIndex],
        ...data,
      };

      const updateAction = await dispatch(
        updateAnnexe4Api({
          id: editedData.id || 0,
          data: editedData,
        })
      );

      if (updateAction.payload) {
        setEditableRows((prevEditableRows) =>
          prevEditableRows.filter((row) => row !== rowIndex)
        );
        setEditedValues({});
      }
    } catch (error) {
      console.error("Update failed", error);
    }
  };

  useEffect(() => {
    [
      findAllSocietesApi,
      findAllDeclarationsApi,
      findAllBeneficiairesApi,
    ].forEach((action) => dispatch(action(null)));

    declarationId && dispatch(findAnnexes4ByDeclarationApi(declarationId));
  }, [dispatch, declarationId]);

  const allDeclarations = useAppSelector(declarationsList);

  const currentDeclaration = declarationId
    ? allDeclarations.find(
        (declaration) => declaration.id === parseInt(declarationId.toString())
      )
    : undefined;
  const societeId = currentDeclaration?.declarant;
  const [formKey, setFormKey] = useState(0);

  const onSubmit: SubmitHandler<Annexe4Type> = async (data) => {
    try {
      data.declaration = declarationId;
      const createAction = await dispatch(createAnnexe4Api(data));

      if (createAction.payload && createAction.payload.success) {
        ToastSuccess(createAction.payload.message);

        const beneficiaireData = {
          typeIdentifiant: data.typeIdentifiant,
          identifiant: data.identifiant,
          name: data.name,
          activity: data.activity,
          adresse: data.adresse,

          societe: societeId || 0,
          typeBeneficiaire: "Fournisseur",
        };

        const beneficiaireExiste = beneficiaireList.some(
          (b) => b.identifiant === data.identifiant
        );

        if (!beneficiaireExiste) {
          const createBeneficiaireAction = await dispatch(
            createBeneficiaireApi(beneficiaireData)
          );

          if (
            createBeneficiaireAction.payload &&
            createBeneficiaireAction.payload.success
          ) {
            ToastSuccess("Bénéficiaire ajouté avec succès");
          } else {
            ToastWarning(createBeneficiaireAction.payload?.message);
          }
        }

        reset();
        setFormKey((prevKey) => prevKey + 1);
      } else {
        ToastWarning(createAction.payload?.message);
      }
    } catch (error) {
      console.error("La soumission a échoué", error);
      ToastWarning(
        "Une erreur est survenue lors de la soumission du formulaire"
      );
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const handleDeleteRow = (id: number) => {
    if (id !== undefined) {
      setDeleteId(id);
      setShowDeleteModal(true);
    }
  };
  const handleConfirmDelete = async () => {
    if (deleteId !== null) {
      try {
        const deleteAction = await dispatch(deleteAnnexe4Api(deleteId));

        if (deleteAction.payload && deleteAction.payload.success) {
          ToastSuccess(deleteAction.payload.message);
          setShowDeleteModal(false);
        } else if (deleteAction.payload && deleteAction.payload.message) {
          ToastWarning(deleteAction.payload.message);
        }

        setDeleteId(null);
      } catch (error) {
        console.error("La suppression a échoué", error);
        ToastWarning("Une erreur est survenue lors de la suppression.");
      }
    }
  };

  const [excelData, setExcelData] = useState<any[]>([]);

  const handleRemoveRowFromExcel = (rowIndex: any) => {
    const updatedExcelData = [...excelData];
    updatedExcelData.splice(rowIndex, 1);
    setExcelData(updatedExcelData);
  };

  const handleAddRowFromExcel = async (rowData: any) => {
    const errors = validateExcelData([rowData]);
    const hasErrors = errors.some((error) => error.rowIndex === 0);

    if (hasErrors) {
      ToastWarning(
        "La ligne contient des erreurs. Corrigez-les avant d'ajouter."
      );
      return;
    }
    try {
      const newData: Annexe4Type = {
        typeIdentifiant: rowData[0],
        identifiant: rowData[1],
        categorie: rowData[2],
        name: rowData[3],
        activity: rowData[4],
        adresse: rowData[5],
        TypeMntServActNonComm: rowData[6],
        tauxHonoraire: rowData[7],
        MntHonoraire: rowData[8],
        tauxRenumNonResid: rowData[9],
        MntRenumNonResid: rowData[10],
        TauxPluValueImmob: rowData[11],
        MontantPluValueImmob: rowData[12],
        tauxPlusValueCessionAct: rowData[13],
        MntPlusValueCessionAct: rowData[14],
        tauxRevenuMobJetPres: rowData[15],
        MntRevenuMobJetPres: rowData[16],
        renumRevEtatRegimeFiscalPriv: rowData[17],
        RetenuTitreTva100: rowData[18],
        retenuOper: rowData[19],
        netServi: rowData[20],

        declaration: declarationId,
      };

      await dispatch(createAnnexe4Api(newData));

      setExcelData((prevExcelData) =>
        prevExcelData.filter((row) => row !== rowData)
      );
    } catch (error) {
      console.error("Addition of row from Excel failed", error);
    }
  };

  const exportToExcel = () => {
    const filteredAnnexesList = annexesList.map(
      ({ id, type, declaration, numOrdre, ...rest }) => rest
    );

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredAnnexesList);
    XLSX.utils.book_append_sheet(wb, ws, "Annexes");
    XLSX.writeFile(wb, "annexe4.xlsx");
  };

  const printRef = useRef<HTMLDivElement>(null);

  const handlePrint = () => {
    const printContents = printRef.current;

    if (printContents) {
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents.innerHTML;
      window.print();

      document.body.innerHTML = originalContents;

      window.location.reload();
    }
  };

  const beneficiaireList = useAppSelector(BeneficiairesList);

  const identifiantBeneficiaire = watch("identifiant");
  const typeIdentifiant = watch("typeIdentifiant");

  useEffect(() => {
    if (identifiantBeneficiaire) {
      const beneficiaire = beneficiaireList.find(
        (b) =>
          b.identifiant === identifiantBeneficiaire &&
          b.typeIdentifiant == typeIdentifiant
      );

      if (beneficiaire) {
        setValue("name", beneficiaire.name);
        setValue("activity", beneficiaire.activity);
        setValue("adresse", beneficiaire.adresse);
      }
    }
  }, [identifiantBeneficiaire, setValue, beneficiaireList]);
  // gestion des doublons
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [duplicateIndex, setDuplicateIndex] = useState<number | null>(null);

  const [showTypeErrorModal, setShowTypeErrorModal] = useState(false);

  const checkForDuplicates = (identifiant: string, typeIdentifiant: number) => {
    const index = annexesList.findIndex(
      (annexe) => annexe.identifiant === identifiant
    );

    if (index !== -1) {
      setDuplicateIndex(index);

      setShowDuplicateModal(true);
    } else {
      setDuplicateIndex(null);
      setShowDuplicateModal(false);
      setShowTypeErrorModal(false);
    }
  };

  const handleContinue = () => {
    if (duplicateIndex != null) {
      const existingType = annexesList[duplicateIndex].typeIdentifiant;

      if (existingType != typeIdentifiant) {
        setShowTypeErrorModal(true);

        setShowDuplicateModal(false);
      } else {
        setShowDuplicateModal(false);
      }
    }
  };

  const handleEdit = () => {
    if (duplicateIndex != null) {
      handleEditClick(duplicateIndex);
      setSearchQuery(annexesList[duplicateIndex].identifiant); // Mettre à jour la recherche avec l'identifiant modifié

      reset();
      setShowDuplicateModal(false);
    }
  };
  // recherche
  const [searchQuery, setSearchQuery] = useState("");

  // Filtrer les lignes du tableau selon le terme de recherche
  const filteredAnnexes = annexesList.filter((annexe) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      annexe.identifiant.toLowerCase().includes(lowerCaseQuery) ||
      annexe.typeIdentifiant.toString().includes(lowerCaseQuery) ||
      annexe.categorie.toString().includes(lowerCaseQuery) ||
      annexe.name.toLowerCase().includes(lowerCaseQuery) ||
      annexe.activity.toLowerCase().includes(lowerCaseQuery) ||
      annexe.adresse.toLowerCase().includes(lowerCaseQuery) ||
      annexe.TypeMntServActNonComm.toString().includes(lowerCaseQuery) ||
      annexe.tauxHonoraire?.toString().includes(lowerCaseQuery) ||
      annexe.MntHonoraire?.toString().includes(lowerCaseQuery) ||
      annexe.tauxRenumNonResid?.toString().includes(lowerCaseQuery) ||
      annexe.MntRenumNonResid?.toString().includes(lowerCaseQuery) ||
      annexe.TauxPluValueImmob?.toString().includes(lowerCaseQuery) ||
      annexe.MontantPluValueImmob?.toString().includes(lowerCaseQuery) ||
      annexe.tauxPlusValueCessionAct?.toString().includes(lowerCaseQuery) ||
      annexe.MntPlusValueCessionAct?.toString().includes(lowerCaseQuery) ||
      annexe.tauxRevenuMobJetPres?.toString().includes(lowerCaseQuery) ||
      annexe.MntRevenuMobJetPres?.toString().includes(lowerCaseQuery) ||
      annexe.renumRevEtatRegimeFiscalPriv
        ?.toString()
        .includes(lowerCaseQuery) ||
      annexe.RetenuTitreTva100?.toString().includes(lowerCaseQuery) ||
      annexe.retenuOper?.toString().includes(lowerCaseQuery) ||
      annexe.netServi?.toString().includes(lowerCaseQuery)
    );
  });
  // validate excel

  type ExcelDataRow = (string | number | null | undefined)[];

  type ExcelError = {
    rowIndex: number;
    cellIndices: number[];
  };

  // Fonction de validation pour les données Excel
  const validateExcelData = (data: ExcelDataRow[]): ExcelError[] => {
    const errors: ExcelError[] = [];

    data.forEach((row, rowIndex) => {
      const errorIndices: number[] = [];
      row.forEach((cell, cellIndex) => {
        let hasError = false;
        let value = String(cell);

        switch (cellIndex) {
          case 0: // Type d'identifiant
            const validIdentifiantTypes = [3, 4];
            if (!validIdentifiantTypes.includes(Number(value))) {
              hasError = true;
            }
            break;

          case 1: // Identifiant
            const typeIdentifiant = Number(row[0]);
            if (
              ([3, 4].includes(typeIdentifiant) && value.length < 8) ||
              value.length > 13
            ) {
              hasError = true;
            }
            break;

          case 2: // Catégorie
            if (![1, 2].includes(Number(value))) {
              hasError = true;
            }
            break;

          case 3: // Nom
            if (value.length > 40) {
              hasError = true;
            }
            break;
          case 4: // Activité
            if (value.length > 40) {
              hasError = true;
            }
            break;

          case 5: // Adresse
            if (value.length > 120) {
              hasError = true;
            }
            break;

          case 6: // TypeMntServActNonComm:
            if (![0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(Number(value))) {
              hasError = true;
            }
            break;
          case 8: // mntComprisTvaIs15: :
          case 10: // mntComprisTvaEntrepUnipers:
          case 12: //mntComprisTvaAutreEntrep:
          case 14: //retenuTitreTva:
          case 16: //retenuOpere:
          case 17: // netServi
          case 18: // netServi
          case 19: // netServi

          case 20: // netServi
            value = value.replace(/[^\d.]/g, "");
            const parts = value.split(".");
            if (parts.length > 2 || (parts[1] && parts[1].length > 3)) {
              hasError = true;
            } else {
              const numericValue = parseFloat(value);
              if (isNaN(numericValue) || numericValue < 0) {
                hasError = true;
              }
            }
            break;

          case 7: // mntComprisTvaIs15: :
          case 9: // mntComprisTvaEntrepUnipers:
          case 11: //mntComprisTvaAutreEntrep:
          case 13: //retenuTitreTva:
          case 15: //retenuOpere:
            value = value.replace(/[^\d.]/g, "");
            const part = value.split(".");
            if (
              part.length > 2 ||
              (part[1] && part[1].length > 2) ||
              (part[0] && part[0].length > 2)
            ) {
              hasError = true;
            } else {
              const numericValue = parseFloat(value);
              if (isNaN(numericValue) || numericValue < 0) {
                hasError = true;
              }
            }
            break;
        }

        if (hasError) {
          errorIndices.push(cellIndex);
        }
      });

      if (errorIndices.length > 0) {
        errors.push({ rowIndex, cellIndices: errorIndices });
      }
    });

    return errors;
  };

  const [excelErrors, setExcelErrors] = useState<ExcelError[]>([]);

  const handleExcelInputChange = (
    rowIndex: number,
    cellIndex: number,
    newValue: string
  ) => {
    const updatedExcelData = [...excelData];
    updatedExcelData[rowIndex][cellIndex] = newValue;

    setExcelData(updatedExcelData);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result;
      if (result instanceof ArrayBuffer) {
        const data = new Uint8Array(result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const rawData = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
        }) as ExcelDataRow[];

        const validatedData = rawData.slice(1); // Retirer les en-têtes
        setExcelData(validatedData);
        setExcelErrors(validateExcelData(validatedData));
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const isExcelLoaded = excelData.length > 0;

  const validateExcel = () => {
    const errors = validateExcelData(excelData);
    setExcelErrors(errors);

    if (errors.length === 0) {
      console.log("Données Excel valides.");
    } else {
      console.log("Des erreurs existent dans les données Excel.");
    }
  };

  // update

  const [rowErrors, setRowErrors] = useState<{
    [key: number]: { [key: string]: boolean };
  }>({});

  const handleInputChange = (
    e: FormControlChangeEvent,
    fieldName: string,
    rowIndex: number
  ) => {
    const { value } = e.target;

    const hasError = validateField(fieldName, value, rowIndex);

    if (hasError && editableRows.includes(rowIndex)) {
      setRowError(rowIndex, fieldName, true);
    } else if (editableRows.includes(rowIndex)) {
      setRowError(rowIndex, fieldName, false);
    }

    setEditedValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  const validateField = (
    fieldName: string,
    value: string,
    rowIndex: number
  ): boolean => {
    let hasError = false;

    switch (fieldName) {
      case "typeIdentifiant":
        const validIdentifiantTypes = [3, 4];
        if (!validIdentifiantTypes.includes(Number(value))) {
          hasError = true;
        }
        break;

      case "identifiant":
        const typeIdentifiant = Number(editedValues.typeIdentifiant);
        if (
          ([3, 4].includes(typeIdentifiant) && value.length < 8) ||
          value.length > 13
        ) {
          hasError = true;
        }
        break;

      case "categorie":
        if (![1, 2].includes(Number(value))) {
          hasError = true;
        }
        break;

      case "name":
        if (value.length > 40) {
          hasError = true;
        }
        break;

      case "activity":
        if (value.length > 40) {
          hasError = true;
        }
        break;

      case "adresse":
        if (value.length > 120) {
          hasError = true;
        }
        break;

      case "MntHonoraire":
      case "MntRenumNonResid":
      case "MontantPluValueImmob":
      case "MntPlusValueCessionAct":
      case "MntRevenuMobJetPres":
      case "renumRevEtatRegimeFiscalPriv":
      case "RetenuTitreTva100":
      case "retenuOper":
      case "netServi":
        value = value.replace(/[^\d.]/g, ""); // Ne conserve que les chiffres et les points
        const parts = value.split(".");
        if (parts.length > 2 || (parts[1] && parts[1].length > 3)) {
          hasError = true; // Vérifie si le format du montant est valide (maximum un point et trois chiffres après le point)
        } else {
          const numericValue = parseFloat(value);
          if (isNaN(numericValue) || numericValue < 0) {
            hasError = true; // Vérifie si la valeur est un nombre valide et positif
          }
        }
        break;

      case "tauxHonoraire":
      case "tauxRenumNonResid":
      case "TauxPluValueImmob":
      case "tauxPlusValueCessionAct":
      case "tauxRevenuMobJetPres":
        value = value.replace(/[^\d.]/g, "");
        const part = value.split(".");
        if (
          part.length > 2 ||
          (part[1] && part[1].length > 2) ||
          (part[0] && part[0].length > 2)
        ) {
          hasError = true;
        } else {
          const numericValue = parseFloat(value);
          if (isNaN(numericValue) || numericValue < 0) {
            hasError = true;
          }
        }
        break;

      default:
        break;
    }

    return hasError;
  };

  const setRowError = (
    rowIndex: number,
    fieldName: string,
    hasError: boolean
  ) => {
    setRowErrors((prevRowErrors) => ({
      ...prevRowErrors,
      [rowIndex]: {
        ...prevRowErrors[rowIndex],
        [fieldName]: hasError,
      },
    }));
  };

  const handleEditClick = (rowIndex: number) => {
    const rowHasErrors = Object.values(rowErrors[rowIndex] || {}).some(
      (error) => error
    );

    if (rowHasErrors) {
      ToastWarning("Veuillez corriger les erreurs avant de continuer.");

      return;
    }

    const valuesToEdit: Partial<Annexe4Type> = annexesList[rowIndex] || {};

    setEditedValues(valuesToEdit);

    if (editableRows.includes(rowIndex)) {
      handleUpdateRow(rowIndex);
    }

    setEditableRows((prevEditableRows) =>
      prevEditableRows.includes(rowIndex)
        ? prevEditableRows.filter((row) => row !== rowIndex)
        : [...prevEditableRows, rowIndex]
    );
  };

  return (
    <div
      style={{
        overflowX: "auto",
        width: "100%",
        scrollbarWidth: "thin",
        scrollbarColor: "rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1)",
      }}
    >
      <div style={{ display: "flex", marginTop: "10px" }}>
        <div style={{ flex: 0.7, marginRight: "5px", paddingRight: "20px" }}>
          <p
            style={{
              textAlign: "justify",
              lineHeight: "1.5",
              margin: "10px 0",
            }}
          >
            Montants servis à des personnes non résidentes ou non établies en
            Tunisie au titre des honoraires, loyers, redevances, jetons de
            présence, plus-value immobilière, plus-value de cession des actions
            et des parts sociales, rémunérations ou revenus servis à des
            personnes résidentes ou établies dans un Etat ou un territoire dont
            le régime fiscal est privilégié et autres revenus servis à des non
            résidents et non établis, Rémunérations payées en contrepartie de la
            performance dans la prestation des services pour le compte d'autrui,
            Montants servis aux non résidents et non établis en Tunisie et qui
            ne procèdent pas au dépôt de la déclaration d'existence avant
            d'entamer leur activité, honoraires servis aux personnes qui
            réalisent des travaux de construction ou des opérations de montage
            ou des services de con
          </p>
        </div>

        <div
          style={{
            flex: 0.3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              height: "32px",
            }}
            onClick={handlePrint}
            variant="info"
          >
            Imprimer
          </Button>
          <div ref={printRef} style={{ display: "none" }}>
            <ImpressionAnnexe4 annexesList={annexesList} />
          </div>
          <Button
            variant="light"
            onClick={exportToExcel}
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              height: "32px",
            }}
          >
            Save as Excel
            <i
              className="fas fa-download"
              style={{ color: "green", fontSize: "18px", marginLeft: "10px" }}
            ></i>
          </Button>

          <Button
            variant="light"
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "32px",
            }}
          >
            <label
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                color: "black",
              }}
            >
              <input
                type="file"
                accept=".xls,.xlsx"
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
              Import Excel
              <i
                className="far fa-file-excel"
                style={{ color: "green", fontSize: "18px", marginLeft: "10px" }}
              ></i>
            </label>
          </Button>
        </div>
      </div>
      <div
        style={{
          marginTop: "10px",
          padding: "10px",
          display: "flex",
          justifyContent: "space-between", // Les éléments se répartissent entre début et fin
          alignItems: "center", // Pour aligner verticalement
        }}
      >
        <span style={{ fontWeight: "bold", fontSize: "1.1em", color: "black" }}>
          {`Nombre total de bénéficiaires : ${annexesList.length}`}
        </span>

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>
            {isExcelLoaded && (
              <Button
                variant="success"
                onClick={validateExcel}
                style={{
                  marginRight: "15px",
                  height: "30px",
                  fontSize: "0.8em",
                  marginBottom: "7px",
                }}
              >
                Valider l'import
              </Button>
            )}
          </span>

          <Form.Control
            type="text"
            placeholder="Rechercher..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{
              width: "200px",
              height: "30px",
              textAlign: "center",
            }}
          />
        </div>
      </div>
      <Form onKeyDown={handleTabulation} onSubmit={handleSubmit(onSubmit)}>
        <Table bordered striped hover className="table-responsive">
          <thead>
            <tr>
              <FixedNewColumnCell>
                <div
                  style={{
                    width: "30px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  N°
                </div>
              </FixedNewColumnCell>
              <FixedHeaderCell>
                <div
                  style={{
                    width: "70px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Type ID
                </div>
              </FixedHeaderCell>
              <FixedSecondHeaderCell>
                <div
                  style={{
                    width: "135px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Identifiant
                </div>
              </FixedSecondHeaderCell>
              <FixedThirdHeaderCell>
                {" "}
                <div
                  style={{
                    width: "130px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Catégorie
                </div>
              </FixedThirdHeaderCell>
              <FixedFourHeaderCell>
                {" "}
                <div
                  style={{
                    width: "140px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Nom ou raison soc
                </div>
              </FixedFourHeaderCell>

              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "140px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Activité
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "140px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Adresse
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "170px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Type Montant Servi
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "220px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Taux Mnt brut servi
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "180px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Mnt brut servi
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "170px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Taux Honoraires temporaires
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "160px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Mnt Honoraires temporaires
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "180px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Taux Plus-Value Immob
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "200px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Montant Plus-Value Immob
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "200px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Taux Plus-Value Cession Act
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "200px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Montant Plus-Value Cession Act
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "200px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Taux Revenu Mob Jet Pres
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "200px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Montant Revenu Mob Jet Pres
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "250px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Renum Rev Etat Regime Fiscal Priv
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "200px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Retenu Titre TVA 100%
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "170px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Retenu Opér
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "170px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Net Servi
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "130px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Action
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredAnnexes.map((annexe4: Annexe4Type, rowIndex) => (
              <tr
                key={annexe4.id}
                onDoubleClick={() => handleEditClick(rowIndex)}
              >
                <FixedNewDataCell
                  style={{ padding: "4px", margin: "0", textAlign: "center" }}
                >
                  {rowIndex + 1}
                </FixedNewDataCell>
                <FixedDataCell>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    as="select"
                    name="typeIdentifiant"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.typeIdentifiant ||
                          annexe4.typeIdentifiant
                        : annexe4.typeIdentifiant
                    }
                    onChange={(e) =>
                      handleInputChange(e, "typeIdentifiant", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  >
                    <option value={3}>3 : CS</option>
                    <option value={4}>4 : IDE</option>
                  </Form.Control>
                </FixedDataCell>
                <FixedSecondDataCell>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.identifiant
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="text"
                    name="identifiant"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.identifiant !== undefined
                          ? editedValues.identifiant
                          : annexe4.identifiant
                        : annexe4.identifiant
                    }
                    onChange={(e) =>
                      handleInputChange(e, "identifiant", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </FixedSecondDataCell>
                <FixedThirdDataCell>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.categorie
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    as="select"
                    name={`annexesList[${rowIndex}].categorie`}
                    value={annexe4.categorie}
                    onChange={(e) =>
                      handleInputChange(e, "categorie", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  >
                    <option value={1}>1 : Personne Morale</option>
                    <option value={2}>2 : Personne Physique</option>
                  </Form.Control>
                </FixedThirdDataCell>
                <FixedFourDataCell>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.name
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="text"
                    name={`annexesList[${rowIndex}].name`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.name !== undefined
                          ? editedValues.name
                          : annexe4.name
                        : annexe4.name
                    }
                    onChange={(e) => handleInputChange(e, "name", rowIndex)}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </FixedFourDataCell>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.activity
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="text"
                    name={`annexesList[${rowIndex}].activity`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.activity !== undefined
                          ? editedValues.activity
                          : annexe4.activity
                        : annexe4.activity
                    }
                    onChange={(e) => handleInputChange(e, "activity", rowIndex)}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.adresse
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="text"
                    name={`annexesList[${rowIndex}].adresse`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.adresse !== undefined
                          ? editedValues.adresse
                          : annexe4.adresse
                        : annexe4.adresse
                    }
                    onChange={(e) => handleInputChange(e, "adresse", rowIndex)}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{ fontSize: "1em", height: "33px" }}
                    as="select"
                    name={`annexesList[${rowIndex}].TypeMntServActNonComm`}
                    value={annexe4.TypeMntServActNonComm}
                    onChange={(e) =>
                      handleInputChange(e, "TypeMntServActNonComm", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  >
                    <option value={1}>1 : Pour les honoraires</option>
                    <option value={2}>2 : Pour les commissions</option>
                    <option value={3}>3 : Pour les courtages</option>
                    <option value={4}>4 : Pour les loyers</option>
                    <option value={5}>
                      5 : Pour les rémunérations au titre des activités non
                      commerciales
                    </option>
                    <option value={6}>6 : Pour les autres revenus</option>
                    <option value={7}>
                      7 : Pour les montants servis aux non résidents...
                    </option>
                    <option value={8}>
                      8 : Pour les montants servis aux non-résidents...
                    </option>
                    <option value={9}>
                      9 : Rémunérations payées en contrepartie de la
                      performance...
                    </option>
                    <option value={0}>0 : Si le montant A413 = 0</option>
                  </Form.Control>
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.tauxHonoraire
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name={`annexesList[${rowIndex}].tauxHonoraire`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.tauxHonoraire !== undefined
                          ? editedValues.tauxHonoraire
                          : annexe4.tauxHonoraire
                        : annexe4.tauxHonoraire
                    }
                    onChange={(e) =>
                      handleInputChange(e, "tauxHonoraire", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.MntHonoraire
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name={`annexesList[${rowIndex}].MntHonoraire`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.MntHonoraire !== undefined
                          ? editedValues.MntHonoraire
                          : annexe4.MntHonoraire
                        : annexe4.MntHonoraire
                    }
                    onChange={(e) =>
                      handleInputChange(e, "MntHonoraire", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.tauxRenumNonResid
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name={`annexesList[${rowIndex}].tauxRenumNonResid`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.tauxRenumNonResid !== undefined
                          ? editedValues.tauxRenumNonResid
                          : annexe4.tauxRenumNonResid
                        : annexe4.tauxRenumNonResid
                    }
                    onChange={(e) =>
                      handleInputChange(e, "tauxRenumNonResid", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.MntRenumNonResid
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name={`annexesList[${rowIndex}].MntRenumNonResid`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.MntRenumNonResid !== undefined
                          ? editedValues.MntRenumNonResid
                          : annexe4.MntRenumNonResid
                        : annexe4.MntRenumNonResid
                    }
                    onChange={(e) =>
                      handleInputChange(e, "MntRenumNonResid", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.TauxPluValueImmob
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name={`annexesList[${rowIndex}].TauxPluValueImmob`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.TauxPluValueImmob !== undefined
                          ? editedValues.TauxPluValueImmob
                          : annexe4.TauxPluValueImmob
                        : annexe4.TauxPluValueImmob
                    }
                    onChange={(e) =>
                      handleInputChange(e, "TauxPluValueImmob", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.MontantPluValueImmob
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name={`annexesList[${rowIndex}].MontantPluValueImmob`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.MontantPluValueImmob !== undefined
                          ? editedValues.MontantPluValueImmob
                          : annexe4.MontantPluValueImmob
                        : annexe4.MontantPluValueImmob
                    }
                    onChange={(e) =>
                      handleInputChange(e, "MontantPluValueImmob", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.tauxPlusValueCessionAct
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name={`annexesList[${rowIndex}].tauxPlusValueCessionAct`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.tauxPlusValueCessionAct !== undefined
                          ? editedValues.tauxPlusValueCessionAct
                          : annexe4.tauxPlusValueCessionAct
                        : annexe4.tauxPlusValueCessionAct
                    }
                    onChange={(e) =>
                      handleInputChange(e, "tauxPlusValueCessionAct", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.MntPlusValueCessionAct
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name={`annexesList[${rowIndex}].MntPlusValueCessionAct`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.MntPlusValueCessionAct !== undefined
                          ? editedValues.MntPlusValueCessionAct
                          : annexe4.MntPlusValueCessionAct
                        : annexe4.MntPlusValueCessionAct
                    }
                    onChange={(e) =>
                      handleInputChange(e, "MntPlusValueCessionAct", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.tauxRevenuMobJetPres
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name={`annexesList[${rowIndex}].tauxRevenuMobJetPres`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.tauxRevenuMobJetPres !== undefined
                          ? editedValues.tauxRevenuMobJetPres
                          : annexe4.tauxRevenuMobJetPres
                        : annexe4.tauxRevenuMobJetPres
                    }
                    onChange={(e) =>
                      handleInputChange(e, "tauxRevenuMobJetPres", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.MntRevenuMobJetPres
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name={`annexesList[${rowIndex}].MntRevenuMobJetPres`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.MntRevenuMobJetPres !== undefined
                          ? editedValues.MntRevenuMobJetPres
                          : annexe4.MntRevenuMobJetPres
                        : annexe4.MntRevenuMobJetPres
                    }
                    onChange={(e) =>
                      handleInputChange(e, "MntRevenuMobJetPres", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.renumRevEtatRegimeFiscalPriv
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name={`annexesList[${rowIndex}].renumRevEtatRegimeFiscalPriv`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.renumRevEtatRegimeFiscalPriv !==
                          undefined
                          ? editedValues.renumRevEtatRegimeFiscalPriv
                          : annexe4.renumRevEtatRegimeFiscalPriv
                        : annexe4.renumRevEtatRegimeFiscalPriv
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "renumRevEtatRegimeFiscalPriv",
                        rowIndex
                      )
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.RetenuTitreTva100
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name={`annexesList[${rowIndex}].RetenuTitreTva100`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.RetenuTitreTva100 !== undefined
                          ? editedValues.RetenuTitreTva100
                          : annexe4.RetenuTitreTva100
                        : annexe4.RetenuTitreTva100
                    }
                    onChange={(e) =>
                      handleInputChange(e, "RetenuTitreTva100", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.retenuOper
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name={`annexesList[${rowIndex}].retenuOper`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.retenuOper !== undefined
                          ? editedValues.retenuOper
                          : annexe4.retenuOper
                        : annexe4.retenuOper
                    }
                    onChange={(e) =>
                      handleInputChange(e, "retenuOper", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.netServi
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name={`annexesList[${rowIndex}].netServi`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.netServi !== undefined
                          ? editedValues.netServi
                          : annexe4.netServi
                        : annexe4.netServi
                    }
                    onChange={(e) => handleInputChange(e, "netServi", rowIndex)}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <span
                    className={`action-icon ${
                      editableRows.includes(rowIndex) ? "success" : "warning"
                    }`}
                    onClick={() => handleEditClick(rowIndex)}
                    title={
                      editableRows.includes(rowIndex)
                        ? "Enregistrer"
                        : "Modifier"
                    }
                  >
                    <i
                      className={`fas ${
                        editableRows.includes(rowIndex) ? "fa-check" : "fa-edit"
                      }`}
                      style={{
                        color: editableRows.includes(rowIndex)
                          ? "#008000"
                          : "#0000ff",
                        marginRight: "8px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
                  <span
                    className={`action-icon danger`}
                    onClick={() => annexe4.id && handleDeleteRow(annexe4.id)}
                    title="Supprimer une ligne"
                  >
                    <i
                      className="far fa-trash-alt"
                      style={{
                        color: "#ff0000",
                        marginRight: "13px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
                </td>
              </tr>
            ))}

            {/* Ligne d'ajout */}

            {excelData.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                <FixedNewDataCell
                  style={{ padding: "4px", margin: "0", textAlign: "center" }}
                ></FixedNewDataCell>
                {/* Composants fixes pour les premières colonnes */}
                {[0, 1, 2, 3].map((cellIndex) => {
                  const hasError = excelErrors.some(
                    (error) =>
                      error.rowIndex === rowIndex &&
                      error.cellIndices.includes(cellIndex)
                  );

                  const borderStyle = hasError
                    ? "2px solid red"
                    : "1px solid grey";
                  const cellStyle = {
                    padding: "3px",
                    margin: "0",
                    border: borderStyle,
                  };

                  let CellComponent;
                  switch (cellIndex) {
                    case 0:
                      CellComponent = FixedDataCell;
                      break;
                    case 1:
                      CellComponent = FixedSecondDataCell;
                      break;
                    case 2:
                      CellComponent = FixedThirdDataCell;
                      break;
                    case 3:
                      CellComponent = FixedFourDataCell;
                      break;
                    default:
                      CellComponent = "td"; // Default case
                  }

                  return (
                    <CellComponent key={cellIndex}>
                      <Form.Control
                        style={{
                          fontSize: "1em",
                          height: "33px",
                          border: borderStyle,
                          borderRadius: "4px",
                        }}
                        type="text"
                        value={rowData[cellIndex]}
                        onChange={(e) =>
                          handleExcelInputChange(
                            rowIndex,
                            cellIndex,
                            e.target.value
                          )
                        }
                      />
                    </CellComponent>
                  );
                })}

                {/* Autres cellules */}
                {rowData.slice(4).map((cell: any, additionalCellIndex: any) => {
                  const actualCellIndex = additionalCellIndex + 4;
                  const hasError = excelErrors.some(
                    (error) =>
                      error.rowIndex === rowIndex &&
                      error.cellIndices.includes(actualCellIndex)
                  );

                  const borderStyle = hasError
                    ? "2px solid red"
                    : "1px solid grey";
                  const cellStyle = {
                    padding: "3px",
                    margin: "0",
                  };

                  return (
                    <td key={actualCellIndex} style={cellStyle}>
                      <Form.Control
                        style={{
                          fontSize: "1em",
                          height: "33px",
                          border: borderStyle,
                          borderRadius: "4px",
                        }}
                        type="text"
                        value={String(cell)}
                        onChange={(e) =>
                          handleExcelInputChange(
                            rowIndex,
                            actualCellIndex,
                            e.target.value
                          )
                        }
                      />
                    </td>
                  );
                })}

                {/* Actions pour ajouter/supprimer */}
                <td style={{ padding: "4px", margin: "0" }}>
                  <Button
                    variant="primary"
                    onClick={() => handleAddRowFromExcel(rowData)}
                  >
                    Ajouter
                  </Button>
                  <span
                    className="action-icon danger"
                    onClick={() => handleRemoveRowFromExcel(rowIndex)}
                    title="Retirer cette ligne"
                  >
                    <i
                      className="fas fa-times"
                      style={{
                        color: "red",
                        marginLeft: "10px",
                        fontSize: "30px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
                </td>
              </tr>
            ))}

            <tr>
              <FixedNewDataCell
                style={{ padding: "4px", margin: "0", textAlign: "center" }}
              ></FixedNewDataCell>
              <FixedDataCell>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  as="select"
                  {...register("typeIdentifiant")}
                  onChange={(e) => {
                    const selectedValue = parseInt(e.target.value, 10);
                    setValue("typeIdentifiant", selectedValue);
                    if (selectedValue === 3) {
                      setValue("categorie", 2);
                    }
                  }}
                >
                  <option value={""}></option>
                  <option value={3}>3 : CS</option>
                  <option value={4}>4 : IDE</option>
                </Form.Control>
              </FixedDataCell>

              <FixedSecondDataCell>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="identifiant"
                  onChange={(e) => {
                    let value = e.target.value;
                    const typeIdentifiant = +watch("typeIdentifiant");
                    const identifiant = e.target.value;
                    checkForDuplicates(identifiant, typeIdentifiant);
                    let errorMessage = "";

                    if (typeIdentifiant === 3) {
                      const truncatedValue = value.slice(0, 13);
                      value = truncatedValue;
                    }

                    if (errorMessage) {
                      setError("identifiant", {
                        type: "manual",
                        message: errorMessage,
                      });
                    } else {
                      clearErrors("identifiant");
                    }

                    setValue("identifiant", value);
                  }}
                  maxLength={13}
                  isInvalid={!!errors.identifiant}
                />

                {errors.identifiant && (
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ fontSize: "0.8rem" }}
                  >
                    {errors.identifiant.message}
                  </Form.Control.Feedback>
                )}
              </FixedSecondDataCell>

              <FixedThirdDataCell>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  as="select"
                  {...register("categorie")}
                  defaultValue={""}
                  disabled={watch("typeIdentifiant") == 3}
                  onChange={(e) =>
                    setValue("categorie", parseInt(e.target.value))
                  }
                >
                  <option value={""}></option>
                  <option value={1}>1 : Personne morale</option>
                  <option value={2}>2 : Personne physique</option>
                </Form.Control>
              </FixedThirdDataCell>
              <FixedFourDataCell>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="name"
                  className={errors.name ? "is-invalid" : ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    const truncatedValue = value.slice(0, 40);
                    e.target.value = truncatedValue;
                    setValue("name", truncatedValue);
                  }}
                  value={watch("name") || ""}
                />
              </FixedFourDataCell>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="activity"
                  className={errors.activity ? "is-invalid" : ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    const truncatedValue = value.slice(0, 40);
                    setValue("activity", truncatedValue);
                  }}
                  value={watch("activity") || ""}
                />
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="adresse"
                  className={errors.adresse ? "is-invalid" : ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    const truncatedValue = value.slice(0, 120);
                    setValue("adresse", truncatedValue);
                  }}
                  value={watch("adresse") || ""}
                />
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  as="select"
                  name="TypeMntServActNonComm"
                  onChange={(e) =>
                    setValue(
                      "TypeMntServActNonComm",
                      parseInt(e.target.value, 10)
                    )
                  }
                >
                  <option value={""}></option>
                  <option value={1}>1 : Pour les honoraires</option>
                  <option value={2}>2 : Pour les commissions</option>
                  <option value={3}>3 : Pour les courtages</option>
                  <option value={4}>4 : Pour les loyers</option>
                  <option value={5}>
                    5 : Pour les rémunérations au titre des activités non
                    commerciales
                  </option>
                  <option value={6}>6 : Pour les autres revenus</option>
                  <option value={7}>
                    7 : Les Mnt servis aux non résidents qui ne procèdent pas au
                    dépôt de la déclaration d’existence avant d’entamer leur
                    activité :personnes établies en Tun et résidentes dans un
                    Etat dont le régime fiscal est privilégié
                  </option>
                  <option value={8}>
                    8 : Pour les Mnt servis aux non-résidents qui ne procèdent
                    pas au dépôt de la déclaration d’existence avant d’entamer
                    leur activité : autres établissements stables
                  </option>
                  <option value={9}>
                    9 : Rémunérations payées en contrepartie de la
                    performance...
                  </option>
                  <option value={0}>0 : Si le montant A413 = 0</option>
                </Form.Control>
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="tauxHonoraire"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 2) {
                      value = `${integerPart}.${decimalPart.slice(0, 2)}`;
                    }
                    e.target.value = value;
                    setTauxHonoraire(parseFloat(value));
                    handleInputTauxChangeAndValidation("tauxHonoraire", value);
                  }}
                />
                {errors.tauxHonoraire && (
                  <span style={{ color: "red" }}>
                    {errors.tauxHonoraire.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="MntHonoraire"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("MntHonoraire", value);
                  }}
                />
                {errors.MntHonoraire && (
                  <span style={{ color: "red" }}>
                    {errors.MntHonoraire.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="tauxRenumNonResid"
                  placeholder="0.00"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 2) {
                      value = `${integerPart}.${decimalPart.slice(0, 2)}`;
                    }
                    e.target.value = value;
                    setTauxRenumNonResid(parseFloat(value));
                    handleInputTauxChangeAndValidation(
                      "tauxRenumNonResid",
                      value
                    );
                  }}
                />
                {errors.tauxRenumNonResid && (
                  <span style={{ color: "red" }}>
                    {errors.tauxRenumNonResid.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="MntRenumNonResid"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("MntRenumNonResid", value);
                  }}
                />
                {errors.MntRenumNonResid && (
                  <span style={{ color: "red" }}>
                    {errors.MntRenumNonResid.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="TauxPluValueImmob"
                  placeholder="0.00"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 2) {
                      value = `${integerPart}.${decimalPart.slice(0, 2)}`;
                    }
                    e.target.value = value;
                    setTauxPluValueImmob(parseFloat(value));
                    handleInputTauxChangeAndValidation(
                      "TauxPluValueImmob",
                      value
                    );
                  }}
                />
                {errors.TauxPluValueImmob && (
                  <span style={{ color: "red" }}>
                    {errors.TauxPluValueImmob.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="MontantPluValueImmob"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation(
                      "MontantPluValueImmob",
                      value
                    );
                  }}
                />
                {errors.MontantPluValueImmob && (
                  <span style={{ color: "red" }}>
                    {errors.MontantPluValueImmob.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="tauxPlusValueCessionAct"
                  placeholder="0.00"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 2) {
                      value = `${integerPart}.${decimalPart.slice(0, 2)}`;
                    }
                    e.target.value = value;
                    setTauxPlusValueCessionAct(parseFloat(value));
                    handleInputTauxChangeAndValidation(
                      "tauxPlusValueCessionAct",
                      value
                    );
                  }}
                />
                {errors.tauxPlusValueCessionAct && (
                  <span style={{ color: "red" }}>
                    {errors.tauxPlusValueCessionAct.message}
                  </span>
                )}
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="MntPlusValueCessionAct"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation(
                      "MntPlusValueCessionAct",
                      value
                    );
                  }}
                />
                {errors.MntPlusValueCessionAct && (
                  <span style={{ color: "red" }}>
                    {errors.MntPlusValueCessionAct.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="tauxRevenuMobJetPres"
                  placeholder="0.00"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 2) {
                      value = `${integerPart}.${decimalPart.slice(0, 2)}`;
                    }
                    e.target.value = value;
                    setTauxRevenuMobJetPres(parseFloat(value));
                    handleInputTauxChangeAndValidation(
                      "tauxRevenuMobJetPres",
                      value
                    );
                  }}
                />
                {errors.tauxRevenuMobJetPres && (
                  <span style={{ color: "red" }}>
                    {errors.tauxRevenuMobJetPres.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="MntRevenuMobJetPres"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation(
                      "MntRevenuMobJetPres",
                      value
                    );
                  }}
                />
                {errors.MntRevenuMobJetPres && (
                  <span style={{ color: "red" }}>
                    {errors.MntRevenuMobJetPres.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="renumRevEtatRegimeFiscalPriv"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation(
                      "renumRevEtatRegimeFiscalPriv",
                      value
                    );
                  }}
                />
                {errors.renumRevEtatRegimeFiscalPriv && (
                  <span style={{ color: "red" }}>
                    {errors.renumRevEtatRegimeFiscalPriv.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="RetenuTitreTva100"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("RetenuTitreTva100", value);
                  }}
                />
                {errors.RetenuTitreTva100 && (
                  <span style={{ color: "red" }}>
                    {errors.RetenuTitreTva100.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="retenuOper"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("retenuOper", value);
                  }}
                />
                {errors.retenuOper && (
                  <span style={{ color: "red" }}>
                    {errors.retenuOper.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="netServi"
                  value={netServiValue}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    setNetServiValue(value);

                    handleInputChangeAndValidation("netServi", value);
                  }}
                />
                {errors.netServi && (
                  <span style={{ color: "red" }}>
                    {errors.netServi.message}
                  </span>
                )}
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Button type="submit">Ajouter</Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Form>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cet élément ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDuplicateModal}
        onHide={() => {
          setShowDuplicateModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Identifiant déjà utilisé</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Cet identifiant est déjà utilisé. Voulez-vous continuer ou modifier la
          ligne existante?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleContinue}>
            Continuer
          </Button>
          <Button variant="primary" onClick={handleEdit}>
            Modifier
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showTypeErrorModal}
        onHide={() => setShowTypeErrorModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Erreur de type d'identifiant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Le type d'identifiant ne correspond pas à la ligne existante. Veuillez
          vérifier votre saisie.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowTypeErrorModal(false)}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Annexe4Form;
