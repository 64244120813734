import RetenueCertifParam from "../../Components/RetenueCertifParam/RetenueCertifParam";
import Navbar from "../../_Layouts/Navbar/Navbar";


const RetenueParamPage = () => {


  return (
    <div>
      <div style={{ marginBottom: "30px" }}>
        <Navbar titre={"Paramétrage retenues"} />
      </div>

      <div style={{ marginTop: "60px" }}>
      {/* <RetenueParam  /> */}
      <RetenueCertifParam />
         </div>
    </div>
  );
};

export default RetenueParamPage;
