import { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  deleteSocieteApi
} from "../../_App/Redux/Slices/societes/societeSlice";
import { currentUserId } from "../../_App/Redux/Slices/user/userSlice";
import { findSocietesByUserIdApi, selectSocietesByUser } from "../../_App/Redux/Slices/userSociete/userSociete";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import SocieteForm from "./SocieteForm";





const SocieteList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const societes = useAppSelector(selectSocietesByUser);
  const userId = useAppSelector(currentUserId);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedSociete, setSelectedSociete] = useState<any>(null);

  useEffect(() => {
    if (userId) {
      dispatch(findSocietesByUserIdApi(userId)); 
    }
  }, [dispatch, userId]); 
  

  const openCreateForm = () => {
    setShowCreateForm(true);
  };

  const closeCreateForm = () => {
    setShowCreateForm(false);
  };

  const openUpdateForm = (societe: any) => {
    setSelectedSociete(societe);
    setShowUpdateForm(true);
  };

  const handleDelete = (id: number | undefined) => {
    if (id !== undefined) {
      setDeleteId(id);
      setShowDeleteModal(true);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      if (deleteId) {
        const response = await dispatch(deleteSocieteApi(deleteId));
        if (response.payload.success) {
          ToastSuccess(response.payload.message);
        } else {
          ToastWarning(response.payload.message);
        }
        setShowDeleteModal(false);
        setDeleteId(null);
      }
    } catch (error) {
      console.error("Deletion failed", error);
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const handleRowClick = (id: number | undefined) => {
    navigate(`/declarations/${id || ""}`);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="primary" onClick={openCreateForm}>
          Créer une société
        </Button>
      </div>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Activity</th>
            <th>Ville</th>
            <th>Rue</th>
            <th>Num</th>
            <th>Code Postal</th>
            <th>Matricule Fiscale</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {societes &&
            societes.map((societe, index) => (
              <tr
                key={index}
                style={{ cursor: "pointer" }}
                onClick={() => handleRowClick(societe.id)}
              >
                <td>{societe.code}</td>
                <td>{societe.name}</td>
                <td>{societe.activity}</td>
                <td>{societe.ville}</td>
                <td>{societe.rue}</td>
                <td>{societe.num}</td>
                <td>{societe.codePostal}</td>
                <td>{societe.matriculeFiscale}</td>
                <td style={{ textAlign: "left" }}>
                  <span
                    style={{
                      fontSize: "20px",
                      color: "blue",
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      openUpdateForm(societe);
                    }}
                  >
                    <i className="fas fa-edit"></i>
                  </span>
                  <span
                    style={{
                      fontSize: "20px",
                      marginLeft: "10px",
                      color: "red",
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(societe.id);
                    }}
                  >
                    <i className="far fa-trash-alt"></i>
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      {showCreateForm && (
        <Modal show={true} size="lg">
          <Modal.Header closeButton onClick={closeCreateForm}>
            <Modal.Title>Créer une nouvelle société</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SocieteForm closeForm={closeCreateForm} />
          </Modal.Body>
        </Modal>
      )}

      {showUpdateForm && selectedSociete && (
        <Modal show={true} size="lg">
          <Modal.Header closeButton onClick={() => setShowUpdateForm(false)}>
            <Modal.Title>Modifier une société</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SocieteForm
              initialData={selectedSociete}
              closeForm={() => setShowUpdateForm(false)}
            />
          </Modal.Body>
        </Modal>
      )}

      {showDeleteModal && (
        <Modal show={true}>
          <Modal.Header closeButton onClick={() => setShowDeleteModal(false)}>
            <Modal.Title>Confirmation de suppression</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Êtes-vous sûr de vouloir supprimer cette société ?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Annuler
            </Button>
            <Button variant="danger" onClick={handleConfirmDelete}>
              Supprimer
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default SocieteList;
