import React, { useEffect, useMemo, useState } from "react";
import { Button, Modal, Table ,Form } from "react-bootstrap";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  BeneficiairesListBySociete,
  deleteBeneficiaireApi,
  getBeneficiaireBySocieteIdsApi
} from "../../_App/Redux/Slices/beneficiaire/beneficiaireSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { BeneficiaireType } from "../../_App/Types/Entites/BeneficiaireType";
import BeneficiaireForm from "./BeneficiareForm";
import { currentUserId } from "../../_App/Redux/Slices/user/userSlice";
import { findSocietesByUserIdApi, selectSocietesByUser } from "../../_App/Redux/Slices/userSociete/userSociete";
interface BeneficiaireListProps {
  closeForm: () => void;
}

const BeneficiaireList: React.FC<BeneficiaireListProps> = ({ closeForm }) => {
  const dispatch = useAppDispatch();
  const beneficiaires = useAppSelector(BeneficiairesListBySociete);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedBeneficiaire, setSelectedBeneficiaire] =
    useState<BeneficiaireType | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);


  const societes = useAppSelector(selectSocietesByUser);
  const userId = useAppSelector(currentUserId);

  useEffect(() => {
    if (userId) {
      dispatch(findSocietesByUserIdApi(userId)); 
    }
  }, [dispatch, userId]); 


  const societeIds = useMemo(
    () => societes.map((societe) => societe.id).filter((id): id is number => id !== undefined),
    [societes]
  );

  useEffect(() => {
    if (societeIds.length > 0) {
      dispatch(getBeneficiaireBySocieteIdsApi(societeIds));
    }
  }, [dispatch, societeIds]);



  const openCreateForm = () => {
    setShowCreateForm(true);
  };

  const closeCreateForm = () => {
    setShowCreateForm(false);
  };

  const openUpdateForm = (beneficiaire: BeneficiaireType) => {
    setSelectedBeneficiaire(beneficiaire);
    setShowUpdateForm(true);
  };

  const handleDelete = (id: number | undefined) => {
    if (id !== undefined) {
      setShowDeleteModal(true);
      setDeleteId(id);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      if (deleteId) {
        const response = await dispatch(deleteBeneficiaireApi(deleteId));
        if (response.payload.success) {
          ToastSuccess(response.payload.message);
        } else {
          ToastWarning(response.payload.message);
        }
        setShowDeleteModal(false);
        setDeleteId(null);
      }
    } catch (error) {
      console.error("Deletion failed", error);
    }
  };
  const [searchQuery, setSearchQuery] = useState("");

  const filteredBeneficiaires = beneficiaires.filter((beneficiaire) => {
    const lowerCaseQuery = searchQuery.toLowerCase(); 
    return (
      beneficiaire.typeBeneficiaire.toLowerCase().includes(lowerCaseQuery) ||
      beneficiaire.typeIdentifiant.toString().includes(lowerCaseQuery) ||
      beneficiaire.identifiant.toLowerCase().includes(lowerCaseQuery) ||
      beneficiaire.name.toLowerCase().includes(lowerCaseQuery) ||
      beneficiaire.activity.toLowerCase().includes(lowerCaseQuery) ||
      beneficiaire.adresse.toLowerCase().includes(lowerCaseQuery) ||
      (beneficiaire.situationFamiliale?.toString().includes(lowerCaseQuery) ?? false) ||
      (beneficiaire.nbrEnfant?.toString().includes(lowerCaseQuery) ?? false)
    );
  });





  return (
    <div>
   <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
   <Form.Control
    type="text"
    placeholder="Rechercher..."
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
    style={{ width: "200px", textAlign: "left" }}
  />
  <Button variant="success" onClick={openCreateForm}>
    Créer un bénéficiaire
  </Button>
</div>


      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Type Beneficiaire</th>

            <th>Type Identifiant</th>
            <th>Identifiant</th>
            <th>Nom</th>
            <th>Activité</th>
            <th>Adresse</th>
            <th>Situation Familiale</th>
            <th>Nombre d'enfant</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {filteredBeneficiaires.map((beneficiaire, index) => (
              <tr key={index}>
                <td>{beneficiaire.typeBeneficiaire}</td>

                <td>{beneficiaire.typeIdentifiant}</td>
                <td>{beneficiaire.identifiant}</td>
                <td>{beneficiaire.name}</td>
                <td>{beneficiaire.activity}</td>
                <td>{beneficiaire.adresse}</td>
                <td>{beneficiaire.situationFamiliale ?? "x"}</td>
                <td>{beneficiaire.nbrEnfant ?? "x"}</td>
                <td style={{ textAlign: "left" }}>
                  <span
                    style={{
                      fontSize: "20px",
                      color: "blue",
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                    onClick={() => openUpdateForm(beneficiaire)}
                  >
                    <i className="fas fa-edit"></i>
                  </span>
                  <span
                    style={{
                      fontSize: "20px",
                      marginLeft: "10px",
                      color: "red",
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                    onClick={() => handleDelete(beneficiaire.id)}
                  >
                    <i className="far fa-trash-alt"></i>
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      {showCreateForm && (
        <Modal show={true} size="lg">
          <Modal.Header closeButton onClick={closeCreateForm}>
            <Modal.Title>Créer un nouveau bénéficiaire</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BeneficiaireForm closeForm={closeCreateForm} />
          </Modal.Body>
        </Modal>
      )}

      {showUpdateForm && selectedBeneficiaire && (
        <Modal show={true} size="lg">
          <Modal.Header
            closeButton
            onClick={() => {
              setShowUpdateForm(false);
            }}
          >
            {" "}
            <Modal.Title>Modifier un bénéficiaire</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BeneficiaireForm
              initialData={selectedBeneficiaire}
              closeForm={() => setShowUpdateForm(false)}
            />
          </Modal.Body>
        </Modal>
      )}

      {showDeleteModal && (
        <Modal show={true} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation de suppression</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Êtes-vous sûr de vouloir supprimer ce bénéficiaire ?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Annuler
            </Button>
            <Button variant="danger" onClick={handleConfirmDelete}>
              Supprimer
            </Button>
          </Modal.Footer>
        </Modal>
      )}


    </div>
  );
};

export default BeneficiaireList;
