import { saveAs } from "file-saver";

interface Annexe1FileProps {
  currentDeclaration: any;
  currentExercice: any;
  currentActe: any;
  currentSociete: any;
  annexe1List: any[] | null; }


export const generateAnnexe1File = (
  currentDeclaration: any,
  currentExercice: any,
  currentActe: any,
  currentSociete: any,
  annexe1List : any,
  

) => {

    

  const generateAnnexe1Content = () => {
    if (!currentSociete || !currentExercice || !currentActe || !annexe1List) return "";

    const matriculeFiscale = (currentSociete.matriculeFiscale).slice(0, 8) + (currentSociete.matriculeFiscale).slice(   9, 13);
    const exerciceLibelle = (currentExercice.libelle).padEnd(4);
    const companyName = (currentSociete.name).padEnd(40);
    const companyActivity = (currentSociete.activity).padEnd(40);
    const companyCity = (currentSociete.ville).padEnd(40);
    const companyStreet = (currentSociete.rue).padEnd(72); 
    const companyNum = (currentSociete.num).toString().padEnd(4); 
    const codePostal = (currentSociete.codePostal).toString().padEnd(4);
    const spaces = " ".repeat(171);

    let annexe1Content = `E1${matriculeFiscale}${exerciceLibelle}An1${currentActe.code}${String(annexe1List.length).padStart(6, '0')}${companyName}${companyActivity}${companyCity}${companyStreet}${companyNum}${codePostal}${spaces}`;

    annexe1List.forEach((annexe1 : any, index : any) => {
        const order = String(index + 1).padStart(6, '0');
        const typeIdentifiant = String(annexe1.typeIdentifiant)
        const identifiantBeneficiaire = String(annexe1.identifiantBeneficiaire).padStart(13, '0');;
        const nomBeneficiaire = annexe1.nomBeneficiaire.padEnd(40);
        const emploiBenef = annexe1.emploiBenef.padEnd(40);
        const adresseBenef = annexe1.adresseBenef.padEnd(120);
        const situationFamiliale = annexe1.situationFamiliale.toString();

        const nbrEnfant = String(annexe1.nbrEnfant || 0).padStart(2, '0');
        const dateDebTravail = annexe1.dateDebTravail ? formatDate(new Date(annexe1.dateDebTravail)) : "";
        const dateFinTravail = annexe1.dateFinTravail ? formatDate(new Date(annexe1.dateFinTravail)) : "";
        const periodeTravail = String(annexe1.periodeTravail || 0).padStart(3, '0');
        const revenuImposable = String(annexe1.revenuImposable * 1000 || 0).padStart(15, '0');
        const valeurAvantageNature = String(annexe1.valeurAvantageNature * 1000 || 0).padStart(15, '0');
        const totalRevenuBrutImposable = String(annexe1.totalRevenuBrutImposable * 1000 || 0).padStart(15, '0');
        const revenuReinvesti = String(annexe1.revenuReinvesti * 1000 || 0).padStart(15, '0');
        const retenuRegCommun = String(annexe1.retenuRegCommun * 1000 || 0).padStart(15, '0');
        const retenuOpereEtrang = String(annexe1.retenuOpereEtrang * 1000 || 0).padStart(15, '0');
        const contributionSociale = String(annexe1.contributionSociale * 1000 || 0).padStart(15, '0');
        const netServi = String(annexe1.netServi * 1000 || 0).padStart(15, '0');
        const EndZone = " ".repeat(19);
        








        annexe1Content += `\nL1${matriculeFiscale}${exerciceLibelle}${order}${typeIdentifiant}${identifiantBeneficiaire}${nomBeneficiaire}${emploiBenef}${adresseBenef}${situationFamiliale}${nbrEnfant}${dateDebTravail}${dateFinTravail}${periodeTravail}${revenuImposable}${valeurAvantageNature}${totalRevenuBrutImposable}${revenuReinvesti}${retenuRegCommun}${retenuOpereEtrang}${contributionSociale}${netServi}${EndZone}`;
    
    
      });
      const EndZoneAnx = " ".repeat(242);
      const totalRevenuImposable = String(
        (annexe1List.reduce((acc :any, annexe1 : any) => acc + (annexe1.revenuImposable || 0), 0).toFixed(3) * 1000)
      ).padStart(15, '0');
      
      const totalAvantageNature = String(
        (annexe1List.reduce((acc :any, annexe1 : any) => acc + (annexe1.valeurAvantageNature || 0), 0).toFixed(3) * 1000)
      ).padStart(15, '0');
      
      const totRevenuBrutImposable = String(
        (annexe1List.reduce((acc :any, annexe1 : any) => acc + (annexe1.totalRevenuBrutImposable || 0), 0).toFixed(3) * 1000)
      ).padStart(15, '0');
      
      const totalReveneuReinvesti = String(
        (annexe1List.reduce((acc :any, annexe1 : any) => acc + (annexe1.revenuReinvesti || 0), 0).toFixed(3) * 1000)
      ).padStart(15, '0');
      
      const totalretenuRegCommun = String(
        (annexe1List.reduce((acc :any, annexe1 : any) => acc + (annexe1.retenuRegCommun || 0), 0).toFixed(3) * 1000)
      ).padStart(15, '0');
      
      const totalretenuOpereEtrang = String(
        (annexe1List.reduce((acc :any, annexe1 : any) => acc + (annexe1.retenuOpereEtrang || 0), 0).toFixed(3) * 1000)
      ).padStart(15, '0');
      
      const totalcontributionSociale = String(
        (annexe1List.reduce((acc :any, annexe1 : any) => acc + (annexe1.contributionSociale || 0), 0).toFixed(3) * 1000)
      ).padStart(15, '0');
      
      const totalnetServi = String(
        (annexe1List.reduce((acc :any, annexe1 : any) => acc + (annexe1.netServi || 0), 0).toFixed(3) * 1000)
      ).padStart(15, '0');
      
      
        const EndZone = " ".repeat(19);


    const FinAnnexe = `T1${matriculeFiscale}${exerciceLibelle}${EndZoneAnx}${totalRevenuImposable}${totalAvantageNature}${totRevenuBrutImposable}${totalReveneuReinvesti}${totalretenuRegCommun}${totalretenuOpereEtrang}${totalcontributionSociale}${totalnetServi}${EndZone}`;
    annexe1Content += `\n${FinAnnexe}`;
    return annexe1Content;
};

const formatDate = (date: Date) => {
  if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
    return ""; 
  }

  const day = String(date.getDate () ).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear());
  return `${day}${month}${year}`;
};


  const textContent = generateAnnexe1Content();
  const blob = new Blob([textContent], { type: "text/plain;charset=utf-8" });
  const exerciseLabel = currentExercice.libelle;
  const lastTwoChars = exerciseLabel.slice(-2);
  const fileName = `ANXEMP_1_${lastTwoChars}_1.txt`;
  saveAs(blob, fileName);
  console.log("La fonction generateAnnexe1File a été appelée.");
};


const Annexe1File: Annexe1FileProps = {
  currentDeclaration: null,
  currentExercice: null,
  currentActe: null,
  currentSociete: null,
  annexe1List : null,
};

export default Annexe1File;