import { configureStore } from "@reduxjs/toolkit";


import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";
import annexe6Slice from "./Slices/Annexe6/annexe6Slice";
import EnteteCertificatSlice from "./Slices/EnteteCertificat/EnteteCertificatSlice";
import LigneCertificatSlice from "./Slices/LigneCertificat/LigneCertificatSlice";
import acteSlice from "./Slices/acte/acteSlice";
import additionalTaxSlice from "./Slices/additionalTax/additionalTaxSlice";
import annexe1Slice from "./Slices/annexe1/annexe1Slice";
import annexe2Slice from "./Slices/annexe2/annexe2Slice";
import annexe3Slice from "./Slices/annexe3/annexe3Slice";
import annexe4Slice from "./Slices/annexe4/annexe4Slice";
import annexe5Slice from "./Slices/annexe5/annexe5Slice";
import annexe7Slice from "./Slices/annexe7/annexe7Slice";
import beneficiaireSlice from "./Slices/beneficiaire/beneficiaireSlice";
import declarationSlice from "./Slices/declaration/declarationSlice";
import exerciceSlice from "./Slices/exercices/exerciceSlice";
import paysDeviseSlice from "./Slices/paysDevise/paysDeviseSlice";
import recapSlice from "./Slices/recap/recapSlice";
import recapParamSlice from "./Slices/recapParam/recapParamSlice";
import retenueCertifParamSlice from "./Slices/retenueCertifParam/retenueCertifParamSlice";
import retenueParamSlice from "./Slices/retenueParam/retenueParamSlice";
import societeSlice from "./Slices/societes/societeSlice";
import tvaCategorySlice from "./Slices/tvaCategory/tvaCategorySlice";
import userSlice from "./Slices/user/userSlice";
import userSocietesSlice from "./Slices/userSociete/userSociete";
import tvaSlice from "./Slices/tva/tvaSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "panier", "appParametragesMenus", "caisse" ,"user"],
};

const rootReducer = combineReducers({
  tvaCategories :tvaCategorySlice,
  societes :societeSlice,
  exercices :exerciceSlice,
  actes :acteSlice,
  declarations : declarationSlice,
  annexe1:annexe1Slice,
  annexe2 :annexe2Slice,
  annexe3 : annexe3Slice,
  annexe5 : annexe5Slice,

  annexe6 : annexe6Slice,
  annexe7 : annexe7Slice,
  annexe4 : annexe4Slice,
  recap : recapSlice,
  recapParam :recapParamSlice,
  beneficiaire : beneficiaireSlice,
  userSocietes : userSocietesSlice,
  user : userSlice,
  retenueParam : retenueParamSlice,
  ligneCertificat : LigneCertificatSlice, 
  enteteCertificat : EnteteCertificatSlice ,
  paysDevises : paysDeviseSlice,
  retenueCertifParam : retenueCertifParamSlice,
  additionalTax :additionalTaxSlice,
  tva : tvaSlice,




});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// console.log('-------------> ', process.env.REACT_APP_NODE_ENV , ' <-------------')
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_NODE_ENV === "developpement",
  middleware: [thunk],
  //middleware: [applyMiddleware(thunk), curryGetDefaultMiddleware()]
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
