import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Annexe6Type } from "../../../Types/Entites/Annexe6Type";
import { RootState } from "../../store";

export const createAnnexe6Api = createAsyncThunk(
  "annexe6/createAnnexe6",
  async (data: Annexe6Type, { rejectWithValue }) => {
    try {
      const response = await axios.post("annexe6/createAnnexe6", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const findAllAnnexe6Api = createAsyncThunk(
  "annexe6/findAllAnnexe6",
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await axios.get("annexe6/findAllAnnexes6");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const updateAnnexe6Api = createAsyncThunk(
  "annexe6/updateAnnexe6",
  async (
    { id, data }: { id: number; data: Annexe6Type },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(`annexe6/updateAnnexe6/${id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const getAnnexe6ByIdApi = createAsyncThunk(
  "annexe6/getAnnexe6ById",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(`annexe6/getAnnexe6ById/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const deleteAnnexe6Api = createAsyncThunk(
  "annexe6/deleteAnnexe6",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`annexe6/deleteAnnexe6/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const findAnnexes6ByDeclarationApi = createAsyncThunk(
  "annexe6/findAnnexes6ByDeclaration",
  async (declarationId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `annexe6/findAnnexes6ByDeclaration/${declarationId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface Annexe6s {
  annexe6List: Annexe6Type[];
  annexe6ListByDeclaration: Annexe6Type[];
}

const initialState: Annexe6s = {
  annexe6List: [],
  annexe6ListByDeclaration: [],
};

export const annexe6Slice = createSlice({
  name: "annexe6",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAnnexe6Api.fulfilled, (state, action) => {
      state.annexe6List.push(action.payload.data);
      state.annexe6ListByDeclaration.push(action.payload.data);
    });
    builder.addCase(findAllAnnexe6Api.fulfilled, (state, action) => {
      return {
        ...state,
        annexe6List: action.payload,
      };
    });
    builder.addCase(updateAnnexe6Api.fulfilled, (state, action) => {
      const updatedIndex = state.annexe6List.findIndex(
        (annexe6) => annexe6.id === action.payload.data.id
      );
      if (updatedIndex !== -1) {
        state.annexe6List[updatedIndex] = action.payload.data;
      }

      const updatedDeclarationIndex = state.annexe6ListByDeclaration.findIndex(
        (annexe6) => annexe6.id === action.payload.data.id
      );
      if (updatedDeclarationIndex !== -1) {
        state.annexe6ListByDeclaration[updatedDeclarationIndex] =
          action.payload.data;
      }
    });


    builder.addCase(getAnnexe6ByIdApi.fulfilled, (state, action) => {
      return {
        ...state,
        annexe6List: [action.payload],
      };
    });
    builder.addCase(deleteAnnexe6Api.fulfilled, (state, action) => {
      state.annexe6List = state.annexe6List.filter(annexe6 => annexe6.id !== action.meta.arg);
      state.annexe6ListByDeclaration = state.annexe6ListByDeclaration.filter(annexe6 => annexe6.id !== action.meta.arg);
    });
    builder.addCase(findAnnexes6ByDeclarationApi.fulfilled, (state, action) => {
      return {
        ...state,
        annexe6ListByDeclaration: action.payload,
      };
    });
  },
});

export const annexe6List = (state: RootState) => state.annexe6.annexe6List;
export const annexe6ListByDeclaration = (state: RootState) =>
  state.annexe6.annexe6ListByDeclaration;

export default annexe6Slice.reducer;
