import BeneficiaireList from "../../Components/Beneficiaire/BeneficiaireList";
import Navbar from "../../_Layouts/Navbar/Navbar";

const BeneficiairePage = () => {



  return (
    <div>
      <div style={{ marginBottom: "30px" }}>
        <Navbar titre={"Beneficiaires"} />
      </div>

      <div style={{ marginTop: "60px" }}>
      <BeneficiaireList closeForm={() => {}} />
         </div>
    </div>
  );
};

export default BeneficiairePage;
