import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Annexe7Type } from "../../../Types/Entites/Annexe7Type";
import { RootState } from "../../store";

export const createAnnexe7Api = createAsyncThunk(
  "annexe7/createAnnexe7",
  async (data: Annexe7Type, { rejectWithValue }) => {
    try {
      const response = await axios.post("annexe7/createAnnexe7", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const findAllAnnexe7Api = createAsyncThunk(
  "annexe7/findAllAnnexe7",
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await axios.get("annexe7/findAllAnnexes7");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const updateAnnexe7Api = createAsyncThunk(
  "annexe7/updateAnnexe7",
  async (
    { id, data }: { id: number; data: Annexe7Type },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(`annexe7/updateAnnexe7/${id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const getAnnexe7ByIdApi = createAsyncThunk(
  "annexe7/getAnnexe7ById",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(`annexe7/getAnnexe7ById/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const deleteAnnexe7Api = createAsyncThunk(
  "annexe7/deleteAnnexe7",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`annexe7/deleteAnnexe7/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const findAnnexes7ByDeclarationApi = createAsyncThunk(
  "annexe7/findAnnexes7ByDeclaration",
  async (declarationId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `annexe7/findAnnexes7ByDeclaration/${declarationId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface Annexe7s {
  annexe7List: Annexe7Type[];
  annexe7ListByDeclaration: Annexe7Type[];
}

const initialState: Annexe7s = {
  annexe7List: [],
  annexe7ListByDeclaration: [],
};

export const annexe7Slice = createSlice({
  name: "annexe7",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAnnexe7Api.fulfilled, (state, action) => {
      state.annexe7List.push(action.payload.data);
      state.annexe7ListByDeclaration.push(action.payload.data);
    });
    builder.addCase(findAllAnnexe7Api.fulfilled, (state, action) => {
      return {
        ...state,
        annexe7List: action.payload,
      };
    });
    builder.addCase(updateAnnexe7Api.fulfilled, (state, action) => {
      const updatedIndex = state.annexe7List.findIndex(
        (annexe7) => annexe7.id === action.payload.data.id
      );
      if (updatedIndex !== -1) {
        state.annexe7List[updatedIndex] = action.payload.data;
      }

      const updatedDeclarationIndex = state.annexe7ListByDeclaration.findIndex(
        (annexe7) => annexe7.id === action.payload.data.id
      );
      if (updatedDeclarationIndex !== -1) {
        state.annexe7ListByDeclaration[updatedDeclarationIndex] =
          action.payload.data;
      }
    });

    builder.addCase(getAnnexe7ByIdApi.fulfilled, (state, action) => {
      return {
        ...state,
        annexe7List: [action.payload],
      };
    });
    builder.addCase(deleteAnnexe7Api.fulfilled, (state, action) => {
      state.annexe7List = state.annexe7List.filter(annexe7 => annexe7.id !== action.meta.arg);
      state.annexe7ListByDeclaration = state.annexe7ListByDeclaration.filter(annexe7 => annexe7.id !== action.meta.arg);
 
    });
    builder.addCase(findAnnexes7ByDeclarationApi.fulfilled, (state, action) => {
      return {
        ...state,
        annexe7ListByDeclaration: action.payload,
      };
    });
  },
});

export const annexe7List = (state: RootState) => state.annexe7.annexe7List;
export const annexe7ListByDeclaration = (state: RootState) =>
  state.annexe7.annexe7ListByDeclaration;

export default annexe7Slice.reducer;
