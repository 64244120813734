import {
  useForm,
  SubmitHandler,
  FieldValues,
  SetValueConfig,
} from "react-hook-form";
import { useAppDispatch } from "../../_App/Redux/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ExerciceType } from "../../_App/Types/Entites/ExerciceType";
import { Button, Form, Modal } from "react-bootstrap";
import {
  createExerciceApi,
  updateExerciceApi,
} from "../../_App/Redux/Slices/exercices/exerciceSlice";
import { differenceInDays, isLeapYear } from "date-fns";
import { useEffect, useState } from "react";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { Asterisk, formadate } from "../../_App/Helpers/helpers";

interface ExerciceFormProps {
  closeForm: () => void;
  initialData?: ExerciceType;
  show?: boolean;
  declarantId?: number;
}

const ExerciceForm: React.FC<ExerciceFormProps> = ({
  closeForm,
  initialData,
  declarantId,
}) => {
  const dispatch = useAppDispatch();

  const years = Array.from({ length: 11 }, (_, index) => 2020 + index);

  const validationSchema = yup.object().shape({
    libelle: yup.string().required("Le libellé est requis"),
    dateDebut: yup.date().required("La date de début est requise"),
    dateFin: yup.date().required("La date de fin est requise"),
    nombreJours: yup
      .number()
      .required("Le nombre de jours est requis")
      .positive("Le nombre de jours doit être positif"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<ExerciceType>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (initialData) {
      Object.keys(initialData).forEach((key) => {
        if (key in initialData) {
          if (key === "dateDebut" || key === "dateFin") {
            if (initialData && initialData[key as keyof ExerciceType]) {
              const dateValue = new Date(
                initialData[key as keyof ExerciceType] as string | number | Date
              );
              const formattedDate = new Date(dateValue)
                .toISOString()
                .split("T")[0];
              setValue(key as keyof ExerciceType, formattedDate);
            }
          } else {
            setValue(
              key as keyof ExerciceType,
              initialData[key as keyof ExerciceType]
            );
          }
        }
      });
    }
  }, [initialData, setValue]);

  const watchDateDebut = watch("dateDebut");
  const watchDateFin = watch("dateFin");

  const onSubmit: SubmitHandler<ExerciceType> = async (data) => {
    try {
      data.declarant = declarantId;
      data.dateDebut = resetTime(new Date(data.dateDebut));
      data.dateFin = resetTime(new Date(data.dateFin));

      if (initialData && initialData.id) {
        await dispatch(updateExerciceApi({ id: initialData.id, data }));
      } else {
        const response = await dispatch(createExerciceApi(data));
        if (response.payload.success) {
          ToastSuccess(response.payload.message);
        } else {
          ToastWarning(response.payload.message);
        }
      }
      closeForm();
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };
  useEffect(() => {
    if (watchDateDebut && watchDateFin) {
      const startDate = new Date(watchDateDebut);
      const endDate = new Date(watchDateFin);

      const daysDifference = differenceInDays(endDate, startDate);
      const adjustedDays = daysDifference + countLeapYears(startDate, endDate);

      setValue("nombreJours", adjustedDays);
    }
  }, [watchDateDebut, watchDateFin, setValue]);

  const countLeapYears = (startDate: Date, endDate: Date): number => {
    let count = 0;
    for (
      let year = startDate.getFullYear();
      year <= endDate.getFullYear();
      year++
    ) {
      if (isLeapYear(year)) {
        count++;
      }
    }
    return count;
  };

  const formatDate = (date: Date | undefined): string => {
    if (!date) return "";

    const modifiedDate = new Date(date);
    if (isNaN(modifiedDate.getTime())) return "";

    modifiedDate.setDate(modifiedDate.getDate() + 1 );

    const formattedDate = new Date(modifiedDate).toISOString().split("T")[0];
    return formattedDate;
  };

  const resetTime = (date: Date): Date => {
    date.setHours(9, 9, 9, 8);
    return date;
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="libelle">
        <Form.Label>
          Année : <Asterisk>*</Asterisk>
        </Form.Label>
        <Form.Control
  as="select"
  {...register("libelle", { required: true })}
  className={errors.libelle ? "is-invalid" : ""}
  onChange={(e) => {
    const selectedYear = parseInt(e.target.value);
    if (!isNaN(selectedYear)) {
      const startDate = new Date(selectedYear, 0, 1);
      const endDate = new Date(selectedYear, 11, 31);

      setValue("dateDebut", startDate);
      setValue("dateFin", endDate);
    }
  }}
>
  <option value="">Sélectionnez une année</option>
  {years.map((year) => (
    <option key={year} value={year}>
      {year}
    </option>
  ))}
</Form.Control>

        {errors.libelle && (
          <Form.Control.Feedback type="invalid">
            {errors.libelle.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group controlId="dateDebut">
        <Form.Label>
          Date de début : <Asterisk>*</Asterisk>
        </Form.Label>
        <Form.Control
          type="date"
          {...register("dateDebut")}
          className={errors.dateDebut ? "is-invalid" : ""}
          value={watchDateDebut ? formatDate(watchDateDebut) : ""}
        />
        {errors.dateDebut && (
          <Form.Control.Feedback type="invalid">
            {errors.dateDebut.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group controlId="dateFin">
        <Form.Label>
          Date de fin :<Asterisk>*</Asterisk>
        </Form.Label>
        <Form.Control
          type="date"
          {...register("dateFin")}
          className={errors.dateFin ? "is-invalid" : ""}
          value={watchDateFin ? formatDate(watchDateFin) : ""}
        />
        {errors.dateFin && (
          <Form.Control.Feedback type="invalid">
            {errors.dateFin.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group controlId="nombreJours">
        <Form.Label>
          Nombre de jours : <Asterisk>*</Asterisk>
        </Form.Label>
        <Form.Control
          type="number"
          {...register("nombreJours")}
          disabled
          className={errors.nombreJours ? "is-invalid" : ""}
        />
        {errors.nombreJours && (
          <Form.Control.Feedback type="invalid">
            {errors.nombreJours.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Button
        style={{ float: "right", marginTop: "15px" }}
        type="submit"
        variant="primary"
      >
        Enregistrer
      </Button>
    </Form>
  );
};

export default ExerciceForm;
