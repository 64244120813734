import { useEffect } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import {
  actesList,
  findAllActesApi,
} from "../../_App/Redux/Slices/acte/acteSlice";
import {
  declarationsList,
  findAllDeclarationsApi,
} from "../../_App/Redux/Slices/declaration/declarationSlice";
import {
  exercicesList,
  findAllExercicesApi,
} from "../../_App/Redux/Slices/exercices/exerciceSlice";
import {
  findAllSocietesApi,
  societesList,
} from "../../_App/Redux/Slices/societes/societeSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { RecapType } from "../../_App/Types/Entites/RecapType";

const ImpressionRecap = ({ recapsList }: { recapsList: RecapType[] }) => {
  const declarationId = recapsList[0]?.declaration;

  const allDeclarations = useAppSelector(declarationsList);
  const allExercices = useAppSelector(exercicesList);
  const allActes = useAppSelector(actesList);
  const allSocietes = useAppSelector(societesList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(findAllActesApi(null));
    dispatch(findAllDeclarationsApi(null));
    dispatch(findAllExercicesApi(null));
    dispatch(findAllSocietesApi(null));
  }, [dispatch]);

  const currentDeclaration = declarationId
    ? allDeclarations.find(
        (declaration) => declaration.id === declarationId
      )
    : undefined;
  const currentExercice = currentDeclaration
    ? allExercices.find(
        (exercice) => exercice.id === currentDeclaration.exercice
      )
    : undefined;
  const currentActe = currentDeclaration
    ? allActes.find((acte) => acte.id === currentDeclaration.acte)
    : undefined;

  const currentSociete = currentDeclaration
    ? allSocietes.find((societe) => societe.id === currentDeclaration.declarant)
    : undefined;

  const formattedDate = currentDeclaration
    ? new Date(currentDeclaration.date).toLocaleDateString("fr-FR")
    : "Date non disponible";
  const formattedStartDate = currentExercice
    ? new Date(currentExercice.dateDebut).toLocaleDateString("fr-FR")
    : "Date non disponible";
  const formattedEndDate = currentExercice
    ? new Date(currentExercice.dateFin).toLocaleDateString("fr-FR")
    : "Date non disponible";

  return (
    <div style={{ color: "black" }}>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <h6>Société</h6>
              {currentSociete && (
                <>
                  <p>Nom : {currentSociete.name}</p>
                  <p>Activité : {currentSociete.activity}</p>
                  <p>Matricule Fiscale : {currentSociete.matriculeFiscale}</p>
                </>
              )}
            </Col>

            <Col>
              <h6>Exercice</h6>
              {currentExercice && (
                <>
                  <p>Date de début : {formattedStartDate}</p>
                  <p>Date de fin : {formattedEndDate}</p>
                  <p>Nombre de jours d'exercice : {currentExercice.nombreJours}</p>
                </>
              )}
            </Col>
            <Col>
              <h6>Déclaration</h6>
              {currentDeclaration && (
                <>
                  <p>Date : {formattedDate}</p>
                  {currentActe && <p>Acte : {currentActe.libelle}</p>}
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <h1>Tableau récapitulatif</h1>

      <Table bordered striped hover style={{ color: "black" }}>
        <thead>
          <tr>
            <th>Code</th>
            <th>Libellé</th>
            <th>Assiette</th>
            <th>Taux</th>
            <th>Retenue opérée</th>
          </tr>
        </thead>
        <tbody>
          {recapsList.map((recap, index) => (
            <tr key={index}>
              <td>{recap.code}</td>
              <td>{recap.libelle}</td>
              <td>{recap.assiete}</td>
              <td>{recap.taux}</td>
              <td>{recap.retenue}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ImpressionRecap;
