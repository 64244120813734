import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RecapParamType } from "../../../Types/Entites/RecapParam";
import { RootState } from "../../store";

export const createRecapParamApi = createAsyncThunk(
  "recapParam/createRecapParam",
  async (data: RecapParamType, { rejectWithValue }) => {
    try {
      const response = await axios.post("recapParam/createRecapParam", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

// Action asynchrone pour récupérer toutes les RecapParams
export const findAllRecapParamApi = createAsyncThunk(
  "recapParam/findAllRecapParam",
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await axios.get("recapParam/findAllRecapParams");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

// Action asynchrone pour mettre à jour une RecapParam
export const updateRecapParamApi = createAsyncThunk(
  "recapParam/updateRecapParam",
  async (
    { id, data }: { id: number; data: RecapParamType },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(
        `recapParam/updateRecapParam/${id}`,
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const deleteRecapParamApi = createAsyncThunk(
  "recapParam/deleteRecapParam",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`recapParam/deleteRecapParam/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

// Action asynchrone pour récupérer une RecapParam par ID
export const getRecapParamByIdApi = createAsyncThunk(
  "recapParam/getRecapParamById",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(`recapParam/getRecapParamById/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);



interface RecapParams {
  recapParamList: RecapParamType[];
}

const initialState: RecapParams = {
  recapParamList: [],
};

export const recapParamSlice = createSlice({
  name: "recapParam",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createRecapParamApi.fulfilled, (state, action) => {
      state.recapParamList.push(action.payload.data);
    });
    builder.addCase(findAllRecapParamApi.fulfilled, (state, action) => {
      return {
        ...state,
        recapParamList: action.payload,
      };
    });
    builder.addCase(updateRecapParamApi.fulfilled, (state, action) => {
      const updatedRecapParam = action.payload.data;
      const updatedIndex = state.recapParamList.findIndex(
        (recapParam) => recapParam.id === updatedRecapParam.id
      );
      if (updatedIndex !== -1) {
        state.recapParamList[updatedIndex] = updatedRecapParam;
      }
    });
    
    builder.addCase(getRecapParamByIdApi.fulfilled, (state, action) => {
      return {
        ...state,
        recapParamList: [action.payload],
      };
    });
    builder.addCase(deleteRecapParamApi.fulfilled, (state, action) => {
      const updatedList = state.recapParamList.filter(
        (recapParam) => recapParam.id !== action.payload.data.id
      );
      return {
        ...state,
        recapParamList: updatedList,
      };
    });
  
  },
});

export const recapParamList = (state: RootState) =>
  state.recapParam.recapParamList;

export default recapParamSlice.reducer;
