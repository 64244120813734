import { useEffect } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import styled from "styled-components";
import {
    actesList,
    findAllActesApi,
} from "../../_App/Redux/Slices/acte/acteSlice";
import {
    declarationsList,
    findAllDeclarationsApi,
} from "../../_App/Redux/Slices/declaration/declarationSlice";
import {
    exercicesList,
    findAllExercicesApi,
} from "../../_App/Redux/Slices/exercices/exerciceSlice";
import {
    findAllSocietesApi,
    societesList,
} from "../../_App/Redux/Slices/societes/societeSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { Annexe1Type } from "../../_App/Types/Entites/Annexe1Type";

const StyledTable = styled(Table)`
  font-size: 22px !important;
  text-align: center;
`;

const StyledTableCell = styled.td`
  font-size: 22px !important;
  text-align: center;
  color: black;
`;

const PageBreak = styled.div`
  page-break-after: always;
`;

const ImpressionAnnexe1 = ({ annexesList }: { annexesList: Annexe1Type[] }) => {
  const firstHalf = annexesList.map((item, index) => ({
    ...item,
    index: index + 1,
  }));
  const secondHalf = annexesList.map((item, index) => ({
    ...item,
    index: index + 1,
  }));

  const declarationId = annexesList[0]?.declaration;

  const allDeclarations = useAppSelector(declarationsList);
  const allExercices = useAppSelector(exercicesList);
  const allActes = useAppSelector(actesList);
  const allSocietes = useAppSelector(societesList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(findAllActesApi(null));
    dispatch(findAllDeclarationsApi(null));
    dispatch(findAllExercicesApi(null));
    dispatch(findAllSocietesApi(null));
  }, [dispatch]);

  const currentDeclaration = declarationId
    ? allDeclarations.find(
        (declaration: any) => declaration.id === declarationId
      )
    : undefined;
  const currentExercice = currentDeclaration
    ? allExercices.find(
        (exercice) => exercice.id === currentDeclaration.exercice
      )
    : undefined;
  const currentActe = currentDeclaration
    ? allActes.find((acte) => acte.id === currentDeclaration.acte)
    : undefined;

  const currentSociete = currentDeclaration
    ? allSocietes.find((societe) => societe.id === currentDeclaration.declarant)
    : undefined;

  const formattedDate = currentDeclaration
    ? new Date(currentDeclaration.date).toLocaleDateString("fr-FR")
    : "Date non disponible";
  const formattedStartDate = currentExercice
    ? new Date(currentExercice.dateDebut).toLocaleDateString("fr-FR")
    : "Date non disponible";
  const formattedEndDate = currentExercice
    ? new Date(currentExercice.dateFin).toLocaleDateString("fr-FR")
    : "Date non disponible";

  return (
    <div style={{ color: "black" }}>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <h6 style={{ color: "black" }}>Société</h6>
              {currentSociete && (
                <>
                  <p>Nom : {currentSociete.name}</p>
                  <p>Activité : {currentSociete.activity}</p>
                  <p>Matricule Fiscale : {currentSociete.matriculeFiscale}</p>
                </>
              )}
            </Col>

            <Col>
              <h6 style={{ color: "black" }}>Exercice</h6>
              {currentExercice && (
                <>
                  <p>Date de début : {formattedStartDate}</p>
                  <p>Date de fin : {formattedEndDate}</p>
                  <p>
                    Nombre de jours d'exercice : {currentExercice.nombreJours}
                  </p>
                </>
              )}
            </Col>
            <Col>
              <h6 style={{ color: "black" }}>Déclaration</h6>
              {currentDeclaration && (
                <>
                  <p>Date : {formattedDate}</p>
                  {currentActe && <p>Acte : {currentActe.libelle}</p>}
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <h1>Annexes 1</h1>

      <PageBreak>
        <StyledTable bordered striped hover style={{ color: "black" }}>
          <thead>
            <tr>
              <th>#</th>
              <th>Type id</th>
              <th>Id</th>
              <th>Nom</th>
              <th>Activité</th>
              <th>Adresse</th>
              <th>Situation F</th>
              <th>N° enfants</th>
              <th>Date début</th>
              <th>Date fin</th>
            </tr>
          </thead>
          <tbody>
            {firstHalf.map((annexe) => (
              <tr key={annexe.index}>
                <StyledTableCell>{annexe.index}</StyledTableCell>
                <StyledTableCell>{annexe.typeIdentifiant}</StyledTableCell>
                <StyledTableCell>
                  {annexe.identifiantBeneficiaire}
                </StyledTableCell>
                <StyledTableCell>{annexe.nomBeneficiaire}</StyledTableCell>
                <StyledTableCell>{annexe.emploiBenef}</StyledTableCell>
                <StyledTableCell>{annexe.adresseBenef}</StyledTableCell>
                <StyledTableCell>{annexe.situationFamiliale}</StyledTableCell>
                <StyledTableCell>{annexe.nbrEnfant}</StyledTableCell>
                <StyledTableCell>
                  {annexe.dateDebTravail
                    ? new Date(annexe.dateDebTravail).toLocaleDateString("fr-FR")
                    : "Date non disponible"}
                </StyledTableCell>
                <StyledTableCell>
                  {annexe.dateFinTravail
                    ? new Date(annexe.dateFinTravail).toLocaleDateString("fr-FR")
                    : "Date non disponible"}
                </StyledTableCell>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </PageBreak>

      <StyledTable bordered striped hover style={{ color: "black" }}>
        <thead>
          <tr>
            <th>#</th>

            <th>Revenu Imposable</th>

            <th>Avantage nature </th>
            <th>Total B.imposable</th>
            <th>Revenu réinvesti</th>
            <th>Retenue régime commun</th>
            <th>Retenu Opérée Étranger</th>

            <th>Contribution sociale</th>
            <th>Net Servi</th>
          </tr>
        </thead>
        <tbody>
          {secondHalf.map((annexe) => (
            <tr key={annexe.index}>
              <StyledTableCell>{annexe.index}</StyledTableCell>
              <StyledTableCell>
                {annexe.revenuImposable}
              </StyledTableCell>

              <StyledTableCell>{annexe.valeurAvantageNature}</StyledTableCell>

              <StyledTableCell>{annexe.totalRevenuBrutImposable}</StyledTableCell>

              <StyledTableCell>{annexe.revenuReinvesti}</StyledTableCell>
              <StyledTableCell>{annexe.retenuRegCommun}</StyledTableCell>
              <StyledTableCell>{annexe.retenuOpereEtrang}</StyledTableCell>

              <StyledTableCell>{annexe.contributionSociale}</StyledTableCell>

              <StyledTableCell>{annexe.netServi}</StyledTableCell>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </div>
  );
};

export default ImpressionAnnexe1;
