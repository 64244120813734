import React from "react";
import Navbar from "../../_Layouts/Navbar/Navbar";
import ListExercices from "../../Components/Exercice/ExerciceList";
const ExercicePage= () => {
  return (
    <div>
      <Navbar titre={"Exercices"} />

      {/* <ListExercices /> */}
    </div>
  );
};

export default ExercicePage;
