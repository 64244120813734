import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Button, Form } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { createTvaCategoryApi } from "../../_App/Redux/Slices/tvaCategory/tvaCategorySlice";
import { useAppDispatch } from "../../_App/Redux/hooks";
import { TvaCategoryType } from "../../_App/Types/Entites/TvaCategoryType";

interface TvaCategoryFormProps {
  closeForm: () => void;
}

const TvaCategoryForm: React.FC<TvaCategoryFormProps> = ({ closeForm }) => {
  const dispatch = useAppDispatch();

  const validationSchema = yup.object().shape({
    type: yup
      .string()
      .oneOf(["category", "tva"])
      .required("Le type est requis"),
    code: yup.string().required("Le code est requis"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<{ type: string; code: string }> = (data) => {
    const tvaCategoryData: TvaCategoryType = {
      type: data.type as "category" | "tva",
      code: data.code,
    };
    dispatch(createTvaCategoryApi(tvaCategoryData));
    closeForm();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="type">
        <Form.Label>Type:</Form.Label>
        <Form.Control
          as="select"
          {...register("type")}
          className={errors.type ? "is-invalid" : ""}
        >
          <option value="">Choisir le type</option>
          <option value="category">code catégorie</option>
          <option value="tva">code TVA</option>
        </Form.Control>
        {errors.type && (
          <Form.Control.Feedback type="invalid">
            {errors.type.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group controlId="code">
        <Form.Label>Code:</Form.Label>
        <Form.Control
          type="text"
          {...register("code")}
          className={errors.code ? "is-invalid" : ""}
        />
        {errors.code && (
          <Form.Control.Feedback type="invalid">
            {errors.code.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Button
        type="submit"
        variant="primary"
        style={{ float: "right", marginTop: "10px" }}
      >
        Enregistrer
      </Button>
    </Form>
  );
};

export default TvaCategoryForm;
