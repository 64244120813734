import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import {
  deleteExerciceApi,
  exercicesListByDeclarant,
  findExercicesByDeclarantApi,
} from "../../_App/Redux/Slices/exercices/exerciceSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { ExerciceType } from "../../_App/Types/Entites/ExerciceType";

import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import ExerciceForm from "./ExerciceForm";

const ExerciceList: React.FC<{ declarantId: number | undefined }> = ({
  declarantId,
}) => {
  const dispatch = useAppDispatch();
  const exercices = useAppSelector(exercicesListByDeclarant);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedExercice, setSelectedExercice] = useState<ExerciceType | null>(
    null
  );
  const [selectedExerciceId, setSelectedExerciceId] = useState<number | null>(
    null
  );

  useEffect(() => {
    if (declarantId !== undefined) {
      dispatch(findExercicesByDeclarantApi(declarantId));
    }
  }, [declarantId, dispatch]);

  const handleDelete = (id: number) => {
    setSelectedExerciceId(id);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (selectedExerciceId !== null) {
        const response = await dispatch(deleteExerciceApi(selectedExerciceId));
        if (response.payload.success) {
          ToastSuccess(response.payload.message);
        } else {
          ToastWarning(response.payload.message);
        }
        setShowDeleteConfirmation(false);
      }
    } catch (error) {
      console.error("Error deleting exercice:", error);
    }
  };

  const handleEdit = (exercice: ExerciceType) => {
    setSelectedExercice(exercice);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCreate = () => {
    setSelectedExercice(null);
    setShowModal(true);
  };

  return (
    <div>
      <div className="mb-3">
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="primary" onClick={handleCreate}>
            Créer un exercice
          </Button>
        </div>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Libellé</th>
            <th>Date Début</th>
            <th>Date Fin</th>
            <th>Nombre de Jours</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {exercices.map((exercice: ExerciceType) => (
            <tr key={exercice.id}>
              <td>{exercice.libelle}</td>
              <td>{new Date(exercice.dateDebut).toLocaleDateString()}</td>
              <td>{new Date(exercice.dateFin).toLocaleDateString()}</td>
              <td>{exercice.nombreJours}</td>
              <td>
                <span
                  style={{
                    fontSize: "20px",
                    color: "blue",
                    cursor: "pointer",
                    display: "inline-block",
                  }}
                  onClick={() => handleEdit(exercice)}
                >
                  <i className="fas fa-edit"></i>
                </span>
                <span
                  style={{
                    fontSize: "20px",
                    marginLeft: "10px",
                    color: "red",
                    cursor: "pointer",
                    display: "inline-block",
                  }}
                  onClick={() => exercice.id && handleDelete(exercice.id)}
                >
                  <i className="far fa-trash-alt"></i>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={showModal}>
        <Modal.Header closeButton onClick={handleCloseModal}>
          <Modal.Title>
            {selectedExercice
              ? "Modifier l'exercice"
              : "Créer un nouvel exercice"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExerciceForm
            closeForm={handleCloseModal}
            initialData={selectedExercice ?? undefined}
            declarantId={declarantId}
          />
        </Modal.Body>
      </Modal>
      <Modal show={showDeleteConfirmation}>
        <Modal.Header
          closeButton
          onClick={() => setShowDeleteConfirmation(false)}
        >
          <Modal.Title>Confirmation de suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cet exercice ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            Annuler
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ExerciceList;
