import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RecapType } from "../../../Types/Entites/RecapType";
import { RootState } from "../../store";













// action pour supprimer les recaps by declaration

export const deleteRecapsByDeclarationApi = createAsyncThunk(
  "recap/deleteRecapsByDeclaration",
  async (declarationId: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `recap/deleteRecapsByDeclaration/${declarationId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

// Action asynchrone pour créer une récap
export const createRecapApi = createAsyncThunk(
  "recap/createRecap",
  async (data: RecapType, { rejectWithValue }) => {
    try {
      const response = await axios.post("recap/createRecap", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

// Action asynchrone pour récupérer toutes les récaps
export const findAllRecapApi = createAsyncThunk(
  "recap/findAllRecap",
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await axios.get("recap/findAllRecaps");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

// Action asynchrone pour mettre à jour une récap
export const updateRecapApi = createAsyncThunk(
  "recap/updateRecap",
  async (
    { id, data }: { id: number; data: RecapType },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(`recap/updateRecap/${id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const deleteRecapApi = createAsyncThunk(
    "recap/deleteRecap",
    async (id: number, { rejectWithValue }) => {
      try {
        const response = await axios.delete(`recap/deleteRecap/${id}`);
        return response.data;
      } catch (error: any) {
        return rejectWithValue({
          success: false,
          message: error.response?.data?.message || "Erreur inconnue de l'API",
        });
      }
    }
  );
  
// Action asynchrone pour récupérer une récap par ID
export const getRecapByIdApi = createAsyncThunk(
  "recap/getRecapById",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(`recap/getRecapById/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

// Action asynchrone pour récupérer les récaps d'une déclaration par ID
export const findRecapsByDeclarationApi = createAsyncThunk(
  "recap/findRecapsByDeclaration",
  async (declarationId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `recap/findRecapsByDeclaration/${declarationId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

interface Recaps {
  recapList: RecapType[];
  recapListByDeclaration: RecapType[];
}

const initialState: Recaps = {
  recapList: [],
  recapListByDeclaration: [],
};

export const recapSlice = createSlice({
  name: "recap",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createRecapApi.fulfilled, (state, action) => {
      state.recapList.push(action.payload.data);
      state.recapListByDeclaration.push(action.payload.data);
    });
    builder.addCase(findAllRecapApi.fulfilled, (state, action) => {
      return {
        ...state,
        recapList: action.payload,
      };
    });
   

    builder.addCase(updateRecapApi.fulfilled, (state, action) => {
        const updatedIndex = state.recapList.findIndex(
          (recap) =>  recap.id === action.payload.data.id
        );
        if (updatedIndex !== -1) {
          state.recapList[updatedIndex] = action.payload.data;
        }
  
        const updatedDeclarationIndex = state.recapListByDeclaration.findIndex(
          (recap) => recap.id === action.payload.data.id
        );
        if (updatedDeclarationIndex !== -1) {
          state.recapListByDeclaration[updatedDeclarationIndex] =
            action.payload.data;
        }
      });
    builder.addCase(getRecapByIdApi.fulfilled, (state, action) => {
      return {
        ...state,
        recapList: [action.payload],
      };
    });
    builder.addCase(deleteRecapApi.fulfilled, (state, action) => {
      const updatedList = state.recapList.filter(
        (recap) => recap.id !== action.payload.data.id
      );
      return {
        ...state,
        recapList: updatedList,
      };
    });
    builder.addCase(findRecapsByDeclarationApi.fulfilled, (state, action) => {
      return {
        ...state,
        recapListByDeclaration: action.payload,
      };
    });

    builder.addCase(deleteRecapsByDeclarationApi.fulfilled, (state, action) => {
      const declarationId = action.payload.declarationId;

      state.recapList = state.recapList.filter(
        (recap) => recap.declaration !== declarationId
      );
      state.recapListByDeclaration = state.recapListByDeclaration.filter(
        (recap) => recap.declaration !== declarationId
      );
    });
  },
});

export const recapList = (state: RootState) => state.recap.recapList;
export const recapListByDeclaration = (state: RootState) =>
  state.recap.recapListByDeclaration;

export default recapSlice.reducer;
