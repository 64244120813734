import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import styled from "styled-components";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
    EnteteCertificatsByDeclaration,
    deleteEnteteCertificatApi,
    getEnteteCertificatByDeclarationApi,
} from "../../_App/Redux/Slices/EnteteCertificat/EnteteCertificatSlice";
import {
    LignesCertificatByDeclaration,
    getLigneCertificatByDeclarationApi,
} from "../../_App/Redux/Slices/LigneCertificat/LigneCertificatSlice";
import {
    createAnnexe2Api,
    deleteAllAnnexes2ByDeclarationApi,
} from "../../_App/Redux/Slices/annexe2/annexe2Slice";
import {
    createAnnexe3Api,
    deleteAllAnnexes3ByDeclarationApi,
} from "../../_App/Redux/Slices/annexe3/annexe3Slice";
import {
    createAnnexe4Api,
    deleteAllAnnexes4ByDeclarationApi,
} from "../../_App/Redux/Slices/annexe4/annexe4Slice";
import {
    createAnnexe5Api,
    deleteAllAnnexes5ByDeclarationApi,
} from "../../_App/Redux/Slices/annexe5/annexe5Slice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { EnteteCertificatType } from "../../_App/Types/Entites/EnteteCertificatType";
import { LigneCertificatType } from "../../_App/Types/Entites/LigneCertificatType";
import ImpressionCertificat from "./ImpressionCetificat";

const CustomModal = styled(Modal)`
  .modal-dialog {
    width: 73%;
    max-width: none;
  }
`;

interface EnteteCertificatListProps {
  declarantId: number;
  exerciceId: number;
  declarationId: number;
}

const EnteteCertificatList: React.FC<EnteteCertificatListProps> = ({
  declarantId,
  exerciceId,
  declarationId,
}) => {
  const dispatch = useAppDispatch();
  const enteteCertificats = useAppSelector(EnteteCertificatsByDeclaration);
  const allLignesCertificat = useAppSelector(LignesCertificatByDeclaration);

  const [showPrintModal, setShowPrintModal] = useState(false);
  const [showAnnexModal, setShowAnnexModal] = useState(false);
  const [selectedEntete, setSelectedEntete] =
    useState<EnteteCertificatType | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortDescending, setSortDescending] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredCertificatsCount, setFilteredCertificatsCount] = useState(0);
  const [checkedAnnexes, setCheckedAnnexes] = useState<string[]>([]);

  useEffect(() => {
    dispatch(getEnteteCertificatByDeclarationApi(declarationId));
    dispatch(getLigneCertificatByDeclarationApi(declarationId));
  }, [declarationId, dispatch]);

  useEffect(() => {
    console.log("Liste des lignes de certificat:", allLignesCertificat);
  }, [allLignesCertificat]);

  useEffect(() => {
    const filteredCount = enteteCertificats.filter((entete) => {
      const searchString = searchQuery.toLowerCase();
      const enteteDate = new Date(entete.date);
      const startDateObj = startDate ? new Date(startDate) : null;
      const endDateObj = endDate ? new Date(endDate) : null;

      const isWithinDateRange =
        (!startDateObj || enteteDate >= startDateObj) &&
        (!endDateObj || enteteDate <= endDateObj);

      return (
        isWithinDateRange &&
        ((entete.numCertificat?.toLowerCase().includes(searchString) ??
          false) ||
          (entete.Beneficiaire?.name?.toLowerCase().includes(searchString) ??
            false) ||
          (entete.Beneficiaire?.identifiant
            ?.toLowerCase()
            .includes(searchString) ??
            false) ||
          new Date(entete.date).toLocaleDateString().includes(searchString) ||
          entete.montantTotal.toString().includes(searchString) ||
          entete.retenueTotal.toString().includes(searchString) ||
          entete.netServiTotal.toString().includes(searchString))
      );
    }).length;

    setFilteredCertificatsCount(filteredCount);
  }, [enteteCertificats, searchQuery, startDate, endDate]);

  const handleDeleteClick = async (numCertificat: string) => {
    try {
      const response = await dispatch(deleteEnteteCertificatApi(numCertificat));
      if (response.payload.success) {
        ToastSuccess(response.payload.message);
      } else {
        ToastWarning(response.payload.message);
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du certificat :", error);
    }
  };

  const handlePrintClick = (entete: EnteteCertificatType) => {
    setSelectedEntete(entete);
    setShowPrintModal(true);
  };

  const handleAnnexClick = () => {
    setShowAnnexModal(true);
  };

  const handleAnnexChange = (annex: string) => {
    setCheckedAnnexes((prev) =>
      prev.includes(annex) ? prev.filter((a) => a !== annex) : [...prev, annex]
    );
  };

  const groupLines = (lines: LigneCertificatType[]): LigneCertificatType[] => {
    const groupedLines: { [key: string]: LigneCertificatType } = {};

    lines.forEach((ligne) => {
      const key = `${ligne.Beneficiaire?.identifiant}-${ligne.RetenueParam?.codeRetenue}-${ligne.typeMontant}`;
      if (!groupedLines[key]) {
        groupedLines[key] = {
          ...ligne,
          montant: 0,
          mntRetenue: 0,
          netServi: 0,
        };
      }
      groupedLines[key].montant += ligne.montant;
      groupedLines[key].mntRetenue += ligne.mntRetenue;
      groupedLines[key].netServi += ligne.netServi;
    });

    return Object.values(groupedLines);
  };

  const annexe2retenue1 = async () => {
    let lignesPourAnnexe2 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 2 &&
        Number(ligne.RetenueParam.codeRetenue) === 1 &&
        ligne.typeMontant !== 0 &&
        ligne.Beneficiaire?.categorie === 2
    );

    lignesPourAnnexe2 = groupLines(lignesPourAnnexe2);

    const annexe2Data = lignesPourAnnexe2.map((ligne) => ({
      declaration: declarantId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      nom: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      typeMontantServis: ligne.typeMontant ?? 0,
      montantServisActNonComm: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe2Data.length; index++) {
      console.log(`Calling createAnnexe2Api with data: `, annexe2Data[index]);
      const response = await dispatch(createAnnexe2Api(annexe2Data[index]));
      console.log(`API response: `, response);
    }
  };

  const annexe2retenue2 = async () => {
    let lignesPourAnnexe2 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 2 &&
        Number(ligne.RetenueParam.codeRetenue) === 2 &&
        ligne.typeMontant !== 0 &&
        ligne.Beneficiaire?.categorie === 1
    );

    lignesPourAnnexe2 = groupLines(lignesPourAnnexe2);

    const annexe2Data = lignesPourAnnexe2.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      nom: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      typeMontantServis: ligne.typeMontant ?? 0,
      montantServisActNonComm: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe2Data.length; index++) {
      console.log(`Calling createAnnexe2Api with data: `, annexe2Data[index]);
      const response = await dispatch(createAnnexe2Api(annexe2Data[index]));
      console.log(`API response: `, response);
    }
  };

  const annexe2retenue5 = async () => {
    let lignesPourAnnexe2 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 2 &&
        Number(ligne.RetenueParam.codeRetenue) === 5 &&
        ligne.typeMontant === 0 &&
        ligne.Beneficiaire?.categorie === 2 &&
        ligne.Beneficiaire?.typeIdentifiant === 2
    );

    lignesPourAnnexe2 = groupLines(lignesPourAnnexe2);

    const annexe2Data = lignesPourAnnexe2.map((ligne) => ({
      declaration: declarantId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      nom: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      typeMontantServis: ligne.typeMontant ?? 0,
      honorairesServisAuxSoc: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe2Data.length; index++) {
      const response = await dispatch(createAnnexe2Api(annexe2Data[index]));
    }
  };

  const annexe2retenue6 = async () => {
    let lignesPourAnnexe2 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 2 &&
        Number(ligne.RetenueParam.codeRetenue) === 6 &&
        ligne.typeMontant === 0 &&
        ligne.Beneficiaire?.typeIdentifiant === 1
    );

    lignesPourAnnexe2 = groupLines(lignesPourAnnexe2);
    const annexe2Data = lignesPourAnnexe2.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      nom: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      typeMontantServis: ligne.typeMontant ?? 0,
      honorairesServisAuxSoc: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe2Data.length; index++) {
      const response = await dispatch(createAnnexe2Api(annexe2Data[index]));
    }
  };

  const annexe2retenue7 = async () => {
    let lignesPourAnnexe2 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 2 &&
        Number(ligne.RetenueParam.codeRetenue) === 7 &&
        ligne.typeMontant === 0
    );

    lignesPourAnnexe2 = groupLines(lignesPourAnnexe2);
    const annexe2Data = lignesPourAnnexe2.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      nom: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      typeMontantServis: ligne.typeMontant ?? 0,
      renumArtist: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe2Data.length; index++) {
      const response = await dispatch(createAnnexe2Api(annexe2Data[index]));
    }
  };

  const annexe2retenue8 = async () => {
    let lignesPourAnnexe2 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 2 &&
        Number(ligne.RetenueParam.codeRetenue) === 8 &&
        ligne.typeMontant === 0 &&
        ligne.Beneficiaire?.typeIdentifiant === 1
    );
    lignesPourAnnexe2 = groupLines(lignesPourAnnexe2);

    const annexe2Data = lignesPourAnnexe2.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      nom: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      typeMontantServis: ligne.typeMontant ?? 0,
      loyeHotelPayeSoc: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe2Data.length; index++) {
      const response = await dispatch(createAnnexe2Api(annexe2Data[index]));
    }
  };

  const annexe2retenue9 = async () => {
    let lignesPourAnnexe2 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 2 &&
        Number(ligne.RetenueParam.codeRetenue) === 9 &&
        ligne.typeMontant !== 0
    );
    lignesPourAnnexe2 = groupLines(lignesPourAnnexe2);

    const annexe2Data = lignesPourAnnexe2.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      nom: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      typeMontantServis: ligne.typeMontant ?? 0,
      montantServisActNonComm: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe2Data.length; index++) {
      const response = await dispatch(createAnnexe2Api(annexe2Data[index]));
    }
  };

  const annexe2retenue15 = async () => {
    let lignesPourAnnexe2 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 2 &&
        Number(ligne.RetenueParam.codeRetenue) === 15 &&
        ligne.Beneficiaire?.categorie === 2 &&
        ligne.typeMontant == 0
    );
    lignesPourAnnexe2 = groupLines(lignesPourAnnexe2);

    const annexe2Data = lignesPourAnnexe2.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      nom: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      typeMontantServis: ligne.typeMontant ?? 0,
      renumPrimeActionPartSoc: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe2Data.length; index++) {
      const response = await dispatch(createAnnexe2Api(annexe2Data[index]));
    }
  };

  const annexe2retenue17 = async () => {
    let lignesPourAnnexe2 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 2 &&
        Number(ligne.RetenueParam.codeRetenue) === 17 &&
        ligne.Beneficiaire?.categorie === 2 &&
        ligne.typeMontant == 0
    );
    lignesPourAnnexe2 = groupLines(lignesPourAnnexe2);

    const annexe2Data = lignesPourAnnexe2.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      nom: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      typeMontantServis: ligne.typeMontant ?? 0,
      renumPrimeActionPartSoc: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe2Data.length; index++) {
      const response = await dispatch(createAnnexe2Api(annexe2Data[index]));
    }
  };

  const annexe2retenue18 = async () => {
    let lignesPourAnnexe2 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 2 &&
        Number(ligne.RetenueParam.codeRetenue) === 18 &&
        ligne.Beneficiaire?.categorie === 1 &&
        ligne.typeMontant == 0
    );
    lignesPourAnnexe2 = groupLines(lignesPourAnnexe2);

    const annexe2Data = lignesPourAnnexe2.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      nom: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      typeMontantServis: ligne.typeMontant ?? 0,
      renumPrimeActionPartSoc: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe2Data.length; index++) {
      const response = await dispatch(createAnnexe2Api(annexe2Data[index]));
    }
  };

  const annexe2retenue21 = async () => {
    let lignesPourAnnexe2 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 2 &&
        Number(ligne.RetenueParam.codeRetenue) === 21 &&
        ligne.typeMontant == 0
    );
    lignesPourAnnexe2 = groupLines(lignesPourAnnexe2);

    const annexe2Data = lignesPourAnnexe2.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      nom: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      typeMontantServis: ligne.typeMontant ?? 0,
      renumOccas: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe2Data.length; index++) {
      const response = await dispatch(createAnnexe2Api(annexe2Data[index]));
    }
  };
  const annexe2retenue23 = async () => {
    let lignesPourAnnexe2 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 2 &&
        Number(ligne.RetenueParam.codeRetenue) === 23 &&
        ligne.typeMontant == 0
    );
    lignesPourAnnexe2 = groupLines(lignesPourAnnexe2);

    const annexe2Data = lignesPourAnnexe2.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      nom: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      typeMontantServis: ligne.typeMontant ?? 0,
      plusValueImmob: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe2Data.length; index++) {
      const response = await dispatch(createAnnexe2Api(annexe2Data[index]));
    }
  };

  const annexe2retenue42 = async () => {
    let lignesPourAnnexe2 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 2 &&
        Number(ligne.RetenueParam.codeRetenue) === 42 &&
        ligne.typeMontant !== 0 &&
        ligne.Beneficiaire?.categorie === 2
    );
    lignesPourAnnexe2 = groupLines(lignesPourAnnexe2);

    const annexe2Data = lignesPourAnnexe2.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      nom: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      typeMontantServis: ligne.typeMontant ?? 0,
      montantServisActNonComm: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe2Data.length; index++) {
      const response = await dispatch(createAnnexe2Api(annexe2Data[index]));
    }
  };

  const annexe2retenue43 = async () => {
    let lignesPourAnnexe2 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 2 &&
        Number(ligne.RetenueParam.codeRetenue) === 43 &&
        ligne.typeMontant !== 0 &&
        ligne.Beneficiaire?.categorie === 1
    );

    lignesPourAnnexe2 = groupLines(lignesPourAnnexe2);

    const annexe2Data = lignesPourAnnexe2.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      nom: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      typeMontantServis: ligne.typeMontant ?? 0,
      montantServisActNonComm: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe2Data.length; index++) {
      const response = await dispatch(createAnnexe2Api(annexe2Data[index]));
    }
  };

  //////// annexe 3

  const annexe3retenue10 = async () => {
    let lignesPourAnnexe3 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 3 &&
        Number(ligne.RetenueParam.codeRetenue) === 10
    );
    lignesPourAnnexe3 = groupLines(lignesPourAnnexe3);

    const annexe3Data = lignesPourAnnexe3.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      nom: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      interetDesComptEpargne: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe3Data.length; index++) {
      const response = await dispatch(createAnnexe3Api(annexe3Data[index]));
    }
  };

  const annexe3retenue11 = async () => {
    let lignesPourAnnexe3 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 3 &&
        Number(ligne.RetenueParam.codeRetenue) === 11 &&
        ligne.Beneficiaire?.categorie === 2 &&
        ligne.Beneficiaire.typeIdentifiant !== 4
    );
    lignesPourAnnexe3 = groupLines(lignesPourAnnexe3);

    const annexe3Data = lignesPourAnnexe3.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      nom: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      interetAutreCapitImmob: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe3Data.length; index++) {
      const response = await dispatch(createAnnexe3Api(annexe3Data[index]));
    }
  };

  const annexe3retenue12 = async () => {
    let lignesPourAnnexe3 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 3 &&
        Number(ligne.RetenueParam.codeRetenue) === 12 &&
        ligne.Beneficiaire?.categorie === 1 &&
        ligne.Beneficiaire.typeIdentifiant !== 4
    );
    lignesPourAnnexe3 = groupLines(lignesPourAnnexe3);

    const annexe3Data = lignesPourAnnexe3.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      nom: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      interetAutreCapitImmob: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe3Data.length; index++) {
      const response = await dispatch(createAnnexe3Api(annexe3Data[index]));
    }
  };

  const annexe3retenue13 = async () => {
    let lignesPourAnnexe3 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 3 &&
        Number(ligne.RetenueParam.codeRetenue) === 13 &&
        ligne.Beneficiaire?.categorie === 2 &&
        ligne.Beneficiaire.typeIdentifiant === 4
    );
    lignesPourAnnexe3 = groupLines(lignesPourAnnexe3);

    const annexe3Data = lignesPourAnnexe3.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      nom: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      interetAutreCapitImmob: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe3Data.length; index++) {
      const response = await dispatch(createAnnexe3Api(annexe3Data[index]));
    }
  };

  const annexe3retenue14 = async () => {
    let lignesPourAnnexe3 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 3 &&
        Number(ligne.RetenueParam.codeRetenue) === 14 &&
        ligne.Beneficiaire?.categorie === 1 &&
        ligne.Beneficiaire.typeIdentifiant === 4
    );
    lignesPourAnnexe3 = groupLines(lignesPourAnnexe3);

    const annexe3Data = lignesPourAnnexe3.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      nom: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      interetAutreCapitImmob: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe3Data.length; index++) {
      const response = await dispatch(createAnnexe3Api(annexe3Data[index]));
    }
  };

  const annexe3retenue22 = async () => {
    let lignesPourAnnexe3 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 3 &&
        Number(ligne.RetenueParam.codeRetenue) === 22
    );
    lignesPourAnnexe3 = groupLines(lignesPourAnnexe3);

    const annexe3Data = lignesPourAnnexe3.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      nom: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      interetPretHorsTun: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe3Data.length; index++) {
      const response = await dispatch(createAnnexe3Api(annexe3Data[index]));
    }
  };

  ///////////////// annexe 5

  const annexe5retenue26 = async () => {
    let lignesPourAnnexe5 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 5 &&
        Number(ligne.RetenueParam.codeRetenue) === 26
    );
    lignesPourAnnexe5 = groupLines(lignesPourAnnexe5);

    const annexe5Data = lignesPourAnnexe5.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      name: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      mntComprisTvaIs15: ligne.montant,
      retenuOpere: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe5Data.length; index++) {
      const response = await dispatch(createAnnexe5Api(annexe5Data[index]));
    }
  };

  const annexe5retenue27 = async () => {
    let lignesPourAnnexe5 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 5 &&
        Number(ligne.RetenueParam.codeRetenue) === 27
    );
    lignesPourAnnexe5 = groupLines(lignesPourAnnexe5);

    const annexe5Data = lignesPourAnnexe5.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      name: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      mntComprisTvaIs10: ligne.montant,
      retenuOpere: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe5Data.length; index++) {
      const response = await dispatch(createAnnexe5Api(annexe5Data[index]));
    }
  };

  const annexe5retenue28 = async () => {
    let lignesPourAnnexe5 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 5 &&
        Number(ligne.RetenueParam.codeRetenue) === 28
    );

    lignesPourAnnexe5 = groupLines(lignesPourAnnexe5);

    const annexe5Data = lignesPourAnnexe5.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      name: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      mntComprisTvaEntrepUnipers: ligne.montant,
      retenuOpere: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe5Data.length; index++) {
      const response = await dispatch(createAnnexe5Api(annexe5Data[index]));
    }
  };

  const annexe5retenue29 = async () => {
    let lignesPourAnnexe5 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 5 &&
        Number(ligne.RetenueParam.codeRetenue) === 29
    );
    lignesPourAnnexe5 = groupLines(lignesPourAnnexe5);

    const annexe5Data = lignesPourAnnexe5.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      name: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      mntComprisTvaAutreEntrep: ligne.montant,
      retenuOpere: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe5Data.length; index++) {
      const response = await dispatch(createAnnexe5Api(annexe5Data[index]));
    }
  };

  ////// a verifier
  const annexe5retenue30 = async () => {
    let lignesPourAnnexe5 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 5 &&
        Number(ligne.RetenueParam.codeRetenue) === 30
    );

    lignesPourAnnexe5 = groupLines(lignesPourAnnexe5);

    const annexe5Data = lignesPourAnnexe5.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      name: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      mntComprisTvaAutreEntrep: ligne.montant,
      retenuOpere: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe5Data.length; index++) {
      const response = await dispatch(createAnnexe5Api(annexe5Data[index]));
    }
  };

  ////////////////////// annexe 4

  const annexe4retenue3 = async () => {
    let lignesPourAnnexe4 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 4 &&
        Number(ligne.RetenueParam.codeRetenue) === 3 &&
        ligne.Beneficiaire?.categorie === 2 &&
        ligne.Beneficiaire.typeIdentifiant === 4 &&
        ligne.typeMontant !== 0
    );
    lignesPourAnnexe4 = groupLines(lignesPourAnnexe4);

    const annexe4Data = lignesPourAnnexe4.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      name: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      TypeMntServActNonComm: Number(ligne.typeMontant),
      tauxHonoraire: ligne.taux,
      MntHonoraire: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe4Data.length; index++) {
      const response = await dispatch(createAnnexe4Api(annexe4Data[index]));
    }
  };

  const annexe4retenue4 = async () => {
    let lignesPourAnnexe4 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 4 &&
        Number(ligne.RetenueParam.codeRetenue) === 4 &&
        ligne.Beneficiaire?.categorie === 1 &&
        ligne.Beneficiaire.typeIdentifiant === 4 &&
        ligne.typeMontant !== 0
    );
    lignesPourAnnexe4 = groupLines(lignesPourAnnexe4);

    const annexe4Data = lignesPourAnnexe4.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      name: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      TypeMntServActNonComm: Number(ligne.typeMontant),
      tauxHonoraire: ligne.taux,
      MntHonoraire: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe4Data.length; index++) {
      const response = await dispatch(createAnnexe4Api(annexe4Data[index]));
    }
  };

  const annexe4retenue16 = async () => {
    let lignesPourAnnexe4 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 4 &&
        Number(ligne.RetenueParam.codeRetenue) === 16 &&
        ligne.Beneficiaire?.typeIdentifiant === 4 &&
        ligne.typeMontant === 0
    );

    lignesPourAnnexe4 = groupLines(lignesPourAnnexe4);

    const annexe4Data = lignesPourAnnexe4.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      name: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      TypeMntServActNonComm: Number(ligne.typeMontant),
      tauxRevenuMobJetPres: ligne.taux,
      MntRevenuMobJetPres: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe4Data.length; index++) {
      const response = await dispatch(createAnnexe4Api(annexe4Data[index]));
    }
  };

  const annexe4retenue19 = async () => {
    let lignesPourAnnexe4 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 4 &&
        Number(ligne.RetenueParam.codeRetenue) === 19 &&
        ligne.Beneficiaire?.categorie === 2 &&
        ligne.typeMontant === 0
    );
    lignesPourAnnexe4 = groupLines(lignesPourAnnexe4);

    const annexe4Data = lignesPourAnnexe4.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      name: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      TypeMntServActNonComm: Number(ligne.typeMontant),
      tauxRevenuMobJetPres: ligne.taux,
      MntRevenuMobJetPres: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));
    for (let index = 0; index < annexe4Data.length; index++) {
      const response = await dispatch(createAnnexe4Api(annexe4Data[index]));
    }
  };

  const annexe4retenue20 = async () => {
    let lignesPourAnnexe4 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 4 &&
        Number(ligne.RetenueParam.codeRetenue) === 20 &&
        ligne.Beneficiaire?.categorie === 1 &&
        ligne.typeMontant === 0
    );
    lignesPourAnnexe4 = groupLines(lignesPourAnnexe4);

    const annexe4Data = lignesPourAnnexe4.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      name: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      TypeMntServActNonComm: Number(ligne.typeMontant),
      tauxRevenuMobJetPres: ligne.taux,
      MntRevenuMobJetPres: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe4Data.length; index++) {
      const response = await dispatch(createAnnexe4Api(annexe4Data[index]));
    }
  };

  const annexe4retenue24 = async () => {
    let lignesPourAnnexe4 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 4 &&
        Number(ligne.RetenueParam.codeRetenue) === 24 &&
        ligne.Beneficiaire?.typeIdentifiant === 4 &&
        ligne.Beneficiaire.categorie == 2 &&
        ligne.typeMontant === 0
    );
    lignesPourAnnexe4 = groupLines(lignesPourAnnexe4);

    const annexe4Data = lignesPourAnnexe4.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      name: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      TypeMntServActNonComm: Number(ligne.typeMontant),
      TauxPluValueImmob: ligne.taux,
      MontantPluValueImmob: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe4Data.length; index++) {
      const response = await dispatch(createAnnexe4Api(annexe4Data[index]));
    }
  };

  const annexe4retenue25 = async () => {
    let lignesPourAnnexe4 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 4 &&
        Number(ligne.RetenueParam.codeRetenue) === 25 &&
        ligne.Beneficiaire?.typeIdentifiant === 4 &&
        ligne.Beneficiaire.categorie == 1 &&
        ligne.typeMontant === 0
    );
    lignesPourAnnexe4 = groupLines(lignesPourAnnexe4);

    const annexe4Data = lignesPourAnnexe4.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      name: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      TypeMntServActNonComm: Number(ligne.typeMontant),
      TauxPluValueImmob: ligne.taux,
      MontantPluValueImmob: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe4Data.length; index++) {
      const response = await dispatch(createAnnexe4Api(annexe4Data[index]));
    }
  };

  const annexe4retenue32 = async () => {
    let lignesPourAnnexe4 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 4 &&
        Number(ligne.RetenueParam.codeRetenue) === 32 &&
        ligne.Beneficiaire?.typeIdentifiant === 4 &&
        ligne.typeMontant === 0
    );
    lignesPourAnnexe4 = groupLines(lignesPourAnnexe4);

    const annexe4Data = lignesPourAnnexe4.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      name: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      TypeMntServActNonComm: Number(ligne.typeMontant),
      tauxRenumNonResid: ligne.taux,
      MntRenumNonResid: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe4Data.length; index++) {
      const response = await dispatch(createAnnexe4Api(annexe4Data[index]));
    }
  };

  const annexe4retenue33 = async () => {
    let lignesPourAnnexe4 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 4 &&
        Number(ligne.RetenueParam.codeRetenue) === 33 &&
        ligne.Beneficiaire?.typeIdentifiant === 4 &&
        ligne.typeMontant === 7
    );
    lignesPourAnnexe4 = groupLines(lignesPourAnnexe4);

    const annexe4Data = lignesPourAnnexe4.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      name: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      TypeMntServActNonComm: Number(ligne.typeMontant),
      tauxHonoraire: ligne.taux,
      MntHonoraire: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe4Data.length; index++) {
      const response = await dispatch(createAnnexe4Api(annexe4Data[index]));
    }
  };
  const annexe4retenue34 = async () => {
    let lignesPourAnnexe4 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 4 &&
        Number(ligne.RetenueParam.codeRetenue) === 34 &&
        ligne.Beneficiaire?.typeIdentifiant === 4 &&
        ligne.typeMontant === 8
    );
    lignesPourAnnexe4 = groupLines(lignesPourAnnexe4);

    const annexe4Data = lignesPourAnnexe4.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      name: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      TypeMntServActNonComm: Number(ligne.typeMontant),
      tauxHonoraire: ligne.taux,
      MntHonoraire: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe4Data.length; index++) {
      const response = await dispatch(createAnnexe4Api(annexe4Data[index]));
    }
  };

  const annexe4retenue37 = async () => {
    let lignesPourAnnexe4 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 4 &&
        Number(ligne.RetenueParam.codeRetenue) === 37 &&
        ligne.Beneficiaire?.typeIdentifiant === 4 &&
        ligne.Beneficiaire?.categorie == 2 &&
        ligne.typeMontant === 0
    );
    lignesPourAnnexe4 = groupLines(lignesPourAnnexe4);

    const annexe4Data = lignesPourAnnexe4.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      name: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      TypeMntServActNonComm: Number(ligne.typeMontant),
      tauxPlusValueCessionAct: ligne.taux,
      MntPlusValueCessionAct: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe4Data.length; index++) {
      const response = await dispatch(createAnnexe4Api(annexe4Data[index]));
    }
  };

  const annexe4retenue38 = async () => {
    let lignesPourAnnexe4 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 4 &&
        Number(ligne.RetenueParam.codeRetenue) === 38 &&
        ligne.Beneficiaire?.typeIdentifiant === 4 &&
        ligne.Beneficiaire?.categorie == 1 &&
        ligne.typeMontant === 0
    );
    lignesPourAnnexe4 = groupLines(lignesPourAnnexe4);

    const annexe4Data = lignesPourAnnexe4.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      name: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      TypeMntServActNonComm: Number(ligne.typeMontant),
      tauxPlusValueCessionAct: ligne.taux,
      MntPlusValueCessionAct: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe4Data.length; index++) {
      const response = await dispatch(createAnnexe4Api(annexe4Data[index]));
    }
  };

  const annexe4retenue39 = async () => {
    let lignesPourAnnexe4 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 4 &&
        Number(ligne.RetenueParam.codeRetenue) === 39 &&
        ligne.Beneficiaire?.typeIdentifiant === 4 &&
        ligne.Beneficiaire?.categorie == 2 &&
        ligne.typeMontant === 6
    );
    lignesPourAnnexe4 = groupLines(lignesPourAnnexe4);

    const annexe4Data = lignesPourAnnexe4.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      name: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      TypeMntServActNonComm: Number(ligne.typeMontant),
      tauxPlusValueCessionAct: ligne.taux,
      MntPlusValueCessionAct: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe4Data.length; index++) {
      const response = await dispatch(createAnnexe4Api(annexe4Data[index]));
    }
  };

  const annexe4retenue40 = async () => {
    let lignesPourAnnexe4 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 4 &&
        Number(ligne.RetenueParam.codeRetenue) === 40 &&
        ligne.Beneficiaire?.typeIdentifiant === 4 &&
        ligne.Beneficiaire?.categorie == 1 &&
        ligne.typeMontant === 6
    );

    const annexe4Data = lignesPourAnnexe4.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      name: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      TypeMntServActNonComm: Number(ligne.typeMontant),
      tauxPlusValueCessionAct: ligne.taux,
      MntPlusValueCessionAct: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe4Data.length; index++) {
      const response = await dispatch(createAnnexe4Api(annexe4Data[index]));
    }
  };

  const annexe4retenue41 = async () => {
    let lignesPourAnnexe4 = allLignesCertificat.filter(
      (ligne: LigneCertificatType) =>
        ligne.RetenueParam?.affectationAnx === 4 &&
        Number(ligne.RetenueParam.codeRetenue) === 41 &&
        ligne.Beneficiaire?.typeIdentifiant !== 4 &&
        ligne.typeMontant === 0
    );
    lignesPourAnnexe4 = groupLines(lignesPourAnnexe4);

    const annexe4Data = lignesPourAnnexe4.map((ligne) => ({
      declaration: declarationId,
      typeIdentifiant: ligne.Beneficiaire?.typeIdentifiant || 0,
      identifiant: ligne.Beneficiaire?.identifiant || "",
      categorie: ligne.Beneficiaire?.categorie || 0,
      name: ligne.Beneficiaire?.name || "",
      activity: ligne.Beneficiaire?.activity || "",
      adresse: ligne.Beneficiaire?.adresse || "",
      TypeMntServActNonComm: Number(ligne.typeMontant),
      renumRevEtatRegimeFiscalPriv: ligne.montant,
      retenuOper: ligne.mntRetenue,
      netServi: ligne.netServi,
    }));

    for (let index = 0; index < annexe4Data.length; index++) {
      const response = await dispatch(createAnnexe4Api(annexe4Data[index]));
    }
  };

  const handleSaveAnnexes = async () => {
    if (checkedAnnexes.includes("Annexe 2")) {
      const response = await dispatch(
        deleteAllAnnexes2ByDeclarationApi(declarationId)
      );

      await annexe2retenue1();
      await annexe2retenue2();
      await annexe2retenue5();
      await annexe2retenue6();
      await annexe2retenue7();
      await annexe2retenue8();
      await annexe2retenue9();
      await annexe2retenue15();
      await annexe2retenue17();
      await annexe2retenue18();
      await annexe2retenue21();
      await annexe2retenue23();
      await annexe2retenue42();
      await annexe2retenue43();
    }

    if (checkedAnnexes.includes("Annexe 3")) {
      const response = await dispatch(
        deleteAllAnnexes3ByDeclarationApi(declarationId)
      );
      await annexe3retenue10();
      await annexe3retenue11();
      await annexe3retenue12();
      await annexe3retenue13();
      await annexe3retenue14();
      await annexe3retenue22();
    }

    if (checkedAnnexes.includes("Annexe 5")) {
      const response = await dispatch(
        deleteAllAnnexes5ByDeclarationApi(declarationId)
      );

      await annexe5retenue26();
      await annexe5retenue27();
      await annexe5retenue28();
      await annexe5retenue29();
      // await annexe5retenue30();
    }

    if (checkedAnnexes.includes("Annexe 4")) {
      const response = await dispatch(
        deleteAllAnnexes4ByDeclarationApi(declarationId)
      );

      await annexe4retenue3();
      await annexe4retenue4();
      await annexe4retenue16();
      await annexe4retenue19();
      await annexe4retenue20();
      await annexe4retenue24();
      await annexe4retenue25();
      //await annexe4retenue31();
      await annexe4retenue32();
      await annexe4retenue33();
      await annexe4retenue34();
      await annexe4retenue37();
      await annexe4retenue38();
      await annexe4retenue39();
      await annexe4retenue40();
      await annexe4retenue41();
    }

    setShowAnnexModal(false);
  };

  const handleCancelAnnexes = () => {
    setShowAnnexModal(false);
  };

  return (
    <>
      <Card style={{ backgroundColor: "#f0f4fc" }}>
        <Card.Body>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Card.Title style={{ justifyContent: "flex-start" }}>
                Nombre des certificats : {filteredCertificatsCount}
              </Card.Title>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Control
                type="text"
                placeholder="Rechercher..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                  width: "200px",
                  textAlign: "left",
                  marginRight: "10px",
                }}
              />
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Du"
                style={{ width: "200px", marginRight: "10px" }}
              />
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="À"
                style={{ width: "200px", marginRight: "10px" }}
              />
              <Button
                variant="success"
                onClick={() => setSortDescending(!sortDescending)}
                style={{ marginLeft: "15px" }}
              >
                {sortDescending
                  ? "Date la plus ancienne"
                  : "Date la plus récente"}
              </Button>
              <Button
                variant="warning"
                onClick={handleAnnexClick}
                style={{ marginLeft: "10px" }}
              >
                Générer les annexes
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Numéro Certificat</th>
            <th>Beneficiaire</th>
            <th>Identifiant</th>
            <th>Date</th>
            <th>Montant Total</th>
            <th>Retenue Total</th>
            <th>Net Servi Total</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {enteteCertificats
            .filter((entete) => {
              const searchString = searchQuery.toLowerCase();
              const enteteDate = new Date(entete.date);
              const startDateObj = startDate ? new Date(startDate) : null;
              const endDateObj = endDate ? new Date(endDate) : null;

              const isWithinDateRange =
                (!startDateObj || enteteDate >= startDateObj) &&
                (!endDateObj || enteteDate <= endDateObj);

              return (
                isWithinDateRange &&
                ((entete.numCertificat?.toLowerCase().includes(searchString) ??
                  false) ||
                  (entete.Beneficiaire?.name
                    ?.toLowerCase()
                    .includes(searchString) ??
                    false) ||
                  (entete.Beneficiaire?.identifiant
                    ?.toLowerCase()
                    .includes(searchString) ??
                    false) ||
                  new Date(entete.date)
                    .toLocaleDateString()
                    .includes(searchString) ||
                  entete.montantTotal.toString().includes(searchString) ||
                  entete.retenueTotal.toString().includes(searchString) ||
                  entete.netServiTotal.toString().includes(searchString))
              );
            })
            .sort((a, b) => {
              if (sortDescending) {
                return new Date(b.date).getTime() - new Date(a.date).getTime();
              } else {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
              }
            })
            .map((entete: EnteteCertificatType) => (
              <tr key={entete.numCertificat}>
                <td>{entete.numCertificat}</td>
                <td>{entete.Beneficiaire && entete.Beneficiaire.name}</td>
                <td>
                  {entete.Beneficiaire && entete.Beneficiaire.identifiant}
                </td>
                <td>{new Date(entete.date).toLocaleDateString()}</td>
                <td>{entete.montantTotal}</td>
                <td>{entete.retenueTotal}</td>
                <td>{entete.netServiTotal}</td>
                <td>
                  <span
                    className="action-icon danger"
                    onClick={() => handleDeleteClick(entete.numCertificat)}
                    title="Supprimer le certificat"
                  >
                    <i
                      className="far fa-trash-alt"
                      style={{
                        color: "#ff0000",
                        marginRight: "13px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>

                  <span
                    style={{
                      fontSize: "20px",
                      color: "green",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handlePrintClick(entete)}
                  >
                    <i className="fas fa-download"></i>
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      <CustomModal
        show={showPrintModal}
        onHide={() => setShowPrintModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Impression du Certificat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEntete && (
            <ImpressionCertificat
              numCertificat={selectedEntete.numCertificat}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPrintModal(false)}>
            Fermer
          </Button>
        </Modal.Footer>
      </CustomModal>

      <Modal show={showAnnexModal} onHide={() => setShowAnnexModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Générer les annexes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              {["Annexe 2", "Annexe 3", "Annexe 4", "Annexe 5", "Annexe 6"].map(
                (annex) => (
                  <Col md={6} key={annex}>
                    <Form.Check
                      type="checkbox"
                      label={annex}
                      checked={checkedAnnexes.includes(annex)}
                      onChange={() => handleAnnexChange(annex)}
                    />
                  </Col>
                )
              )}
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelAnnexes}>
            Annuler
          </Button>
          <Button variant="success" onClick={handleSaveAnnexes}>
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EnteteCertificatList;
