import * as yup from "yup";

export const DeclarationSchema = yup.object().shape({
  date: yup.date().required("Champ obligatoire"),
  declarant: yup.number().required("Champ obligatoire"),
  acte: yup.number().required("Champ obligatoire"),
  exercice: yup.number().required("Champ obligatoire"),
  annexe1: yup.boolean(),
  annexe2: yup.boolean(),
  annexe3: yup.boolean(),
  annexe4: yup.boolean(),
  annexe5: yup.boolean(),
  annexe6: yup.boolean(),
  annexe7: yup.boolean(),
 
});

export default DeclarationSchema;
