import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AnnexesFile from "../../Components/AnnexesTab/AnnexesFiles";
import AnnexesTabs from "../../Components/AnnexesTab/AnnexesTab";
import {
  annexe6ListByDeclaration,
  findAnnexes6ByDeclarationApi,
} from "../../_App/Redux/Slices/Annexe6/annexe6Slice";
import {
  actesList,
  findAllActesApi,
} from "../../_App/Redux/Slices/acte/acteSlice";
import {
  annexe1ListByDeclaration,
  findAnnexes1ByDeclarationApi,
} from "../../_App/Redux/Slices/annexe1/annexe1Slice";
import {
  annexe2ListByDeclaration,
  findAnnexes2ByDeclarationApi,
} from "../../_App/Redux/Slices/annexe2/annexe2Slice";
import {
  annexe3ListByDeclaration,
  findAnnexes3ByDeclarationApi,
} from "../../_App/Redux/Slices/annexe3/annexe3Slice";
import {
  annexe4ListByDeclaration,
  findAnnexes4ByDeclarationApi,
} from "../../_App/Redux/Slices/annexe4/annexe4Slice";
import {
  annexe5ListByDeclaration,
  findAnnexes5ByDeclarationApi,
} from "../../_App/Redux/Slices/annexe5/annexe5Slice";
import {
  annexe7ListByDeclaration,
  findAnnexes7ByDeclarationApi,
} from "../../_App/Redux/Slices/annexe7/annexe7Slice";
import {
  declarationsList,
  findAllDeclarationsApi,
} from "../../_App/Redux/Slices/declaration/declarationSlice";
import {
  exercicesList,
  findAllExercicesApi,
} from "../../_App/Redux/Slices/exercices/exerciceSlice";
import { findRecapsByDeclarationApi, recapListByDeclaration } from "../../_App/Redux/Slices/recap/recapSlice";
import {
  findAllSocietesApi,
  societesList,
} from "../../_App/Redux/Slices/societes/societeSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

const AnnexesPage = () => {
  const { declarationId } = useParams();
  const allDeclarations = useAppSelector(declarationsList);
  const allExercices = useAppSelector(exercicesList);
  const allActes = useAppSelector(actesList);
  const allSocietes = useAppSelector(societesList);
  const dispatch = useAppDispatch();

  const annexe1List = useAppSelector(annexe1ListByDeclaration);
  const annexe2List = useAppSelector(annexe2ListByDeclaration);
  const annexe3List = useAppSelector(annexe3ListByDeclaration);
  const annexe4List = useAppSelector(annexe4ListByDeclaration);
  const annexe5List = useAppSelector(annexe5ListByDeclaration);
  const annexe6List = useAppSelector(annexe6ListByDeclaration);
  const annexe7List = useAppSelector(annexe7ListByDeclaration);
  const recapList = useAppSelector(recapListByDeclaration);




  useEffect(() => {
    dispatch(findAllActesApi(null));
    dispatch(findAllDeclarationsApi(null));
    dispatch(findAllExercicesApi(null));
    dispatch(findAllSocietesApi(null));
  }, [dispatch]);

  const currentDeclaration = declarationId
    ? allDeclarations.find(
        (declaration: any) => declaration.id === parseInt(declarationId)
      )
    : undefined;
  const currentExercice = currentDeclaration
    ? allExercices.find(
        (exercice) => exercice.id === currentDeclaration.exercice
      )
    : undefined;
  const currentActe = currentDeclaration
    ? allActes.find((acte) => acte.id === currentDeclaration.acte)
    : undefined;

  const currentSociete = currentDeclaration
    ? allSocietes.find((societe) => societe.id === currentDeclaration.declarant)
    : undefined;

  const formattedDate = currentDeclaration
    ? new Date(currentDeclaration.date).toLocaleDateString("fr-FR")
    : "Date non disponible";
  const formattedStartDate = currentExercice
    ? new Date(currentExercice.dateDebut).toLocaleDateString("fr-FR")
    : "Date non disponible";
  const formattedEndDate = currentExercice
    ? new Date(currentExercice.dateFin).toLocaleDateString("fr-FR")
    : "Date non disponible";

  useEffect(() => {
    if (typeof declarationId === "number") {
      dispatch(findAnnexes1ByDeclarationApi(declarationId));
      dispatch(findAnnexes2ByDeclarationApi(declarationId));
      dispatch(findAnnexes3ByDeclarationApi(declarationId));
      dispatch(findAnnexes4ByDeclarationApi(declarationId));
      dispatch(findAnnexes5ByDeclarationApi(declarationId));
      dispatch(findAnnexes6ByDeclarationApi(declarationId));
      dispatch(findAnnexes7ByDeclarationApi(declarationId));
      dispatch(findRecapsByDeclarationApi(declarationId));

    }
  }, [declarationId, dispatch]);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          {currentSociete && (
            <Card>
              <Card.Body
                style={{
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h4 className="card-title" style={{ color: "green" }}>
                  Informations de la Société
                </h4>
                <h4
                  className="card-title"
                  style={{ marginLeft: "230px", marginRight: "250px" }}
                >
                  Nom : {currentSociete.name}
                </h4>
                <h4 className="card-title" style={{ marginLeft: "100x" }}>
                  Activité : {currentSociete.activity}
                </h4>
                <h4 className="card-title" style={{ marginLeft: "230px" }}>
                  Matricule Fiscale : {currentSociete.matriculeFiscale}
                </h4>
              </Card.Body>
            </Card>
          )}
        </div>
        <div className="col-md-6">
          {currentDeclaration && (
            <Card className="h-100">
              <Card.Body>
                <h6 className="card-title" style={{ color: "green" }}>
                  Informations de la déclaration
                </h6>

                <p className="card-text">Date : {formattedDate}</p>
                {currentActe && (
                  <p className="card-text">Acte : {currentActe.libelle}</p>
                )}
              </Card.Body>
            </Card>
          )}
        </div>
        <div className="col-md-6">
          {currentExercice && (
            <Card className="h-100">
              <Card.Body>
                <Row>
                  <Col md={6}>
                    <h6 style={{ color: "green" }}>
                      Informations de l'exercice
                    </h6>
                    <p>Libellé : {currentExercice.libelle}</p>
                    <p>Date de début : {formattedStartDate}</p>
                  </Col>
                  <Col md={6}>
                    <p>Date de fin : {formattedEndDate}</p>
                    <p>
                      Nombre de jours d'exercice : {currentExercice.nombreJours}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </div>
      </div>
      <div style={{ marginBottom: "30px" }}>
        {currentDeclaration &&
          currentExercice &&
          currentActe &&
          currentSociete && (
            <AnnexesFile
              currentDeclaration={currentDeclaration}
              currentExercice={currentExercice}
              currentActe={currentActe}
              currentSociete={currentSociete}
              annexe1List={annexe1List}
              annexe2List={annexe2List}
              annexe3List={annexe3List}
              annexe4List={annexe4List}
              annexe5List={annexe5List}
              annexe7List={annexe7List}
              annexe6List={annexe6List}
              recapList = {recapList}
            />
          )}
      </div>
      <AnnexesTabs declarationId={declarationId} />
    </div>
  );
};

export default AnnexesPage;
