import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RetenueCertifParamType } from "../../../Types/Entites/ReteneueCertifParamType";
import { RootState } from "../../store";

export const createRetenueCertifParamApi = createAsyncThunk(
  "retenueCertifParam/createRetenueCertifParam",
  async (data: RetenueCertifParamType, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "retenueCertifParam/createRetenueCertifParam",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const findAllRetenueCertifParamsApi = createAsyncThunk(
  "retenueCertifParam/findAllRetenueCertifParams",
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "retenueCertifParam/findAllRetenueCertifParams"
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const updateRetenueCertifParamApi = createAsyncThunk(
  "retenueCertifParam/updateRetenueCertifParam",
  async (
    { id, data }: { id: number; data: RetenueCertifParamType },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(
        `retenueCertifParam/updateRetenueCertifParam/${id}`,
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const deleteRetenueCertifParamApi = createAsyncThunk(
  "retenueCertifParam/deleteRetenueCertifParam",
  async (id: number, { rejectWithValue }) => {
    try {
      await axios.delete(`retenueCertifParam/deleteRetenueCertifParam/${id}`);
      return id;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const findRetenueCertifParamsByExerciceApi = createAsyncThunk(
  "retenueCertifParam/findRetenueCertifParamsByExercice",
  async (exerciceId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `retenueCertifParam/findRetenueCertifParamsByExercice/${exerciceId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

interface RetenueCertifParams {
  retenueCertifParamsList: RetenueCertifParamType[];
  retenueCertifParamsListByExercie: RetenueCertifParamType[];
}

const initialState: RetenueCertifParams = {
  retenueCertifParamsList: [],
  retenueCertifParamsListByExercie: [],
};

export const retenueCertifParamSlice = createSlice({
  name: "retenueCertifParam",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createRetenueCertifParamApi.fulfilled, (state, action) => {
      state.retenueCertifParamsList.push(action.payload.data);
      state.retenueCertifParamsListByExercie.push(action.payload.data);
    });
    builder.addCase(
      findAllRetenueCertifParamsApi.fulfilled,
      (state, action) => {
        state.retenueCertifParamsList = action.payload;
      }
    );
    builder.addCase(updateRetenueCertifParamApi.fulfilled, (state, action) => {
      const updatedRetenueCertifParam = action.payload.data;
      const index = state.retenueCertifParamsListByExercie.findIndex(
        (retenueCertifParam) =>
          retenueCertifParam.id === updatedRetenueCertifParam.id
      );
      if (index !== -1) {
        state.retenueCertifParamsListByExercie[index] =
          updatedRetenueCertifParam;
      }
    });
    builder.addCase(
      findRetenueCertifParamsByExerciceApi.fulfilled,
      (state, action) => {
        state.retenueCertifParamsListByExercie = action.payload;
      }
    );
    builder.addCase(deleteRetenueCertifParamApi.fulfilled, (state, action) => {
      const id = action.payload;
      state.retenueCertifParamsList = state.retenueCertifParamsList.filter(
        (retenueCertifParam) => retenueCertifParam.id !== id
      );
      state.retenueCertifParamsListByExercie =
        state.retenueCertifParamsList.filter(
          (retenueCertifParam) => retenueCertifParam.id !== id
        );
    });
  },
});

export const {} = retenueCertifParamSlice.actions;
export const selectRetenueCertifParamsList = (state: RootState) =>
  state.retenueCertifParam.retenueCertifParamsList;
export const retenueCertifParamsListByExercice = (state: RootState) =>
  state.retenueCertifParam.retenueCertifParamsListByExercie;

export default retenueCertifParamSlice.reducer;
