import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";

import {
  createRecapApi,
  findRecapsByDeclarationApi,
  recapListByDeclaration,
  updateRecapApi,
} from "../../_App/Redux/Slices/recap/recapSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { RecapType } from "../../_App/Types/Entites/RecapType";

interface RecapParamFormProps {
  declarationId: number;
}

const RecapParamForm: React.FC<RecapParamFormProps> = ({ declarationId }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (declarationId) {
      dispatch(findRecapsByDeclarationApi(declarationId));
    }
  }, [dispatch, declarationId]);

  const { register, handleSubmit, setValue, reset, watch } =
    useForm<RecapType>();
  const recapsParamsList = useAppSelector(recapListByDeclaration);
  const [editableRows, setEditableRows] = useState<number[]>([]);
  const [editedValues, setEditedValues] = useState<Partial<RecapType>>({});

  type FormControlChangeEvent = React.ChangeEvent<
    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  >;

  const handleInputChange = (e: FormControlChangeEvent, fieldName: string) => {
    const { value } = e.target;
    setEditedValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  const handleUpdateRow = async (rowIndex: number) => {
    try {
      const data = editedValues;
      const editedData = {
        ...recapsParamsList[rowIndex],
        ...data,
      };

      const updateAction = await dispatch(
        updateRecapApi({
          id: editedData.id || 0,
          data: editedData,
        })
      );

      if (updateAction.payload) {
        setEditableRows((prevEditableRows) =>
          prevEditableRows.filter((row) => row !== rowIndex)
        );
        setEditedValues({});
      }
    } catch (error) {
      console.error("Update failed", error);
    }
  };
  const [formKey, setFormKey] = useState(0);

  const onSubmit: SubmitHandler<RecapType> = async (data) => {
    try {
      data.declaration = declarationId;
      await dispatch(createRecapApi(data));
      setFormKey((prevKey) => prevKey + 1);

      reset();
    } catch (error) {
      console.error("Validation failed", error);
    }
  };

  const handleEditClick = (rowIndex: number) => {
    const valuesToEdit: Partial<RecapType> = recapsParamsList[rowIndex] || {};

    setEditedValues(valuesToEdit);

    if (editableRows.includes(rowIndex)) {
      handleUpdateRow(rowIndex);
    }

    setEditableRows((prevEditableRows) =>
      prevEditableRows.includes(rowIndex)
        ? prevEditableRows.filter((row) => row !== rowIndex)
        : [...prevEditableRows, rowIndex]
    );
  };

  return (
    <div key={formKey} style={{ overflowX: "auto" }}>
      <div style={{ marginTop: "20px" }}></div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Table bordered striped hover>
          <thead>
            <tr>
              <th style={{ width: "130px" }}>Code</th>
              <th style={{ width: "800px" }}>Libelle</th>
              <th style={{ width: "150px" }}>Taux</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {recapsParamsList.map((recapParam: RecapType, rowIndex) => (
              <tr key={recapParam.id}>
                <td>
                  <Form.Control
                    type="number"
                    name={`recapsParamsList[${rowIndex}].code`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.code !== undefined
                          ? editedValues.code
                          : recapParam.code
                        : recapParam.code
                    }
                    onChange={(e) => handleInputChange(e, "code")}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td>
                  <div style={{ width: "100%", wordWrap: "break-word" }}>
                    <Form.Control
                      type="text"
                      name="libelle"
                      value={
                        editableRows.includes(rowIndex)
                          ? editedValues.libelle !== undefined
                            ? editedValues.libelle
                            : recapParam.libelle
                          : recapParam.libelle
                      }
                      onChange={(e) => handleInputChange(e, "libelle")}
                      disabled={!editableRows.includes(rowIndex)}
                    />
                  </div>
                </td>
                <td>
                  <Form.Control
                    type="number"
                    step="0.1"
                    name={`recapsParamsList[${rowIndex}].taux`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.taux !== undefined
                          ? editedValues.taux
                          : recapParam.taux
                        : recapParam.taux
                    }
                    onChange={(e) => handleInputChange(e, "taux")}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td>
                  <span
                    className={`action-icon ${
                      editableRows.includes(rowIndex) ? "success" : "warning"
                    }`}
                    onClick={() => handleEditClick(rowIndex)}
                    title={
                      editableRows.includes(rowIndex)
                        ? "Enregistrer"
                        : "Modifier"
                    }
                  >
                    <i
                      className={`fas ${
                        editableRows.includes(rowIndex) ? "fa-check" : "fa-edit"
                      }`}
                      style={{
                        color: editableRows.includes(rowIndex)
                          ? "#008000"
                          : "#0000ff",
                        marginRight: "8px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
                </td>
              </tr>
            ))}

            {/* Ligne d'ajout */}
            <tr>
              <td>
                <Form.Control
                  type="number"
                  name="code"
                  onChange={(e) =>
                    setValue("code", parseInt(e.target.value, 10))
                  }
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  name="libelle"
                  onChange={(e) => setValue("libelle", e.target.value)}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  step="0.1"
                  name="taux"
                  onChange={(e) => setValue("taux", parseFloat(e.target.value))}
                />
              </td>
              <td>
                <Button variant="primary" type="submit">
                  Ajouter
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Form>
    </div>
  );
};

export default RecapParamForm;
