// tvaCategorySlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import { TvaCategoryType } from "../../../Types/Entites/TvaCategoryType";

// Action asynchrone pour créer une nouvelle catégorie TVA
export const createTvaCategoryApi = createAsyncThunk(
  "tvaCategories/createTvaCategory",
  async (data: TvaCategoryType, { rejectWithValue }) => {
    try {
      const response = await axios.post("tvacategory/createTvaCategory", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

// Action asynchrone pour récupérer toutes les catégories TVA
export const findAllTvaCategoriesApi = createAsyncThunk(
  "tvaCategories/findAllTvaCategories",
  async (data: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get("tvacategory/findAllTvaCategories");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour mettre à jour une catégorie TVA
export const updateTvaCategoryApi = createAsyncThunk(
  "tvaCategories/updateTvaCategory",
  async ({ id, data }: { id: number; data: TvaCategoryType }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`tvacategory/updateTvaCategory/${id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

// Action asynchrone pour récupérer les catégories TVA par type
export const findByTypeApi = createAsyncThunk(
  "tvaCategories/findByType",
  async (type: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`tvacategory/findByType/${type}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface TvaCategories {
  tvaCategoriesList: TvaCategoryType[];
}

const initialState: TvaCategories = {
  tvaCategoriesList: [],
};

export const tvaCategorySlice = createSlice({
  name: "tvaCategories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createTvaCategoryApi.fulfilled, (state, action) => {
      state.tvaCategoriesList.push(action.payload.data);
    });
    builder.addCase(findAllTvaCategoriesApi.fulfilled, (state, action) => {
      return {
        ...state,
        tvaCategoriesList: action.payload,
      };
    });
    builder.addCase(updateTvaCategoryApi.fulfilled, (state, action) => {
      const updatedIndex = state.tvaCategoriesList.findIndex(
        (category) => category.id === action.payload.data.id
      );
      if (updatedIndex !== -1) {
        state.tvaCategoriesList[updatedIndex] = action.payload.data;
      }
    });
    builder.addCase(findByTypeApi.fulfilled, (state, action) => {
      return {
        ...state,
        tvaCategoriesList: action.payload,
      };
    });
  },
});

export const tvaCategoriesList = (state: RootState) => state.tvaCategories.tvaCategoriesList;

export default tvaCategorySlice.reducer;
