import RecapParametrage from "../../Components/RecapParam/RecapParametrage";
import Navbar from "../../_Layouts/Navbar/Navbar";

const RecapParamPage = () => {



  return (
    <div>
      <div style={{ marginBottom: "30px" }}>
        <Navbar titre={"Paramétrage recap"} />
      </div>

      <div style={{ marginTop: "60px" }}>
      <RecapParametrage  />
         </div>
    </div>
  );
};

export default RecapParamPage;
