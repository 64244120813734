import { saveAs } from "file-saver";

interface Annexe5FileProps {
    currentDeclaration: any;
    currentExercice: any;
    currentActe: any;
    currentSociete: any;
    annexe5List: any[] | null; 

}

export const generateAnnexe5File = (
    currentDeclaration: any,
    currentExercice: any,
    currentActe: any,
    currentSociete: any,
    annexe5List : any,

) => {
    const generateAnnexe5Content = () => {
        if (!currentSociete || !currentExercice || !currentActe || !annexe5List) return "";
    
        const matriculeFiscale = (currentSociete.matriculeFiscale).slice(0, 8) + (currentSociete.matriculeFiscale).slice(   9, 13);
        const exerciceLibelle = (currentExercice.libelle).padEnd(4);
        const companyName = (currentSociete.name).padEnd(40);
        const companyActivity = (currentSociete.activity).padEnd(40);
        const companyCity = (currentSociete.ville).padEnd(40);
        const companyStreet = (currentSociete.rue).padEnd(72); 
        const companyNum = (currentSociete.num).toString().padEnd(4); 
        const codePostal = (currentSociete.codePostal).toString().padEnd(4);
        const spaces = " ".repeat(171);
    
        let annexe5Content = `E5${matriculeFiscale}${exerciceLibelle}An5${currentActe.code}${String(annexe5List.length).padStart(6, '0')}${companyName}${companyActivity}${companyCity}${companyStreet}${companyNum}${codePostal}${spaces}`;
    
        annexe5List.forEach((annexe5 : any, index : any) => {
            const order = String(index + 1).padStart(6, '0');
            const typeIdentifiant = String(annexe5.typeIdentifiant)
            const identifiantBeneficiaire = String(annexe5.identifiant).padStart(13, '0');;
            const nomBeneficiaire = annexe5.name.padEnd(40);
            const emploiBenef = annexe5.activity.padEnd(40);
            const adresseBenef = annexe5.adresse.padEnd(120);
            const mntComprisTvaIs10 = String(annexe5.mntComprisTvaIs10 * 1000 || 0).padStart(15, '0');
            const mntComprisTvaIs15 = String(annexe5.mntComprisTvaIs15 * 1000 || 0).padStart(15, '0');
            const mntComprisTvaEntrepUnipers = String(annexe5.mntComprisTvaEntrepUnipers * 1000 || 0).padStart(15, '0');
            const mntComprisTvaAutreEntrep = String(annexe5.mntComprisTvaAutreEntrep * 1000 || 0).padStart(15, '0');
            const mntRetenuTitreTva =  String(annexe5.retenuTitreTva * 1000 || 0).padStart(15, '0');
            const retenuOpere = String(annexe5.retenuOpere * 1000 || 0).padStart(15, '0');



            
            const netServi = String(annexe5.netServi * 1000 || 0).padStart(15, '0');
    
    
            const EndZone = " ".repeat(56);
            
    
            annexe5Content += `\nL5${matriculeFiscale}${exerciceLibelle}${order}${typeIdentifiant}${identifiantBeneficiaire}${nomBeneficiaire}${emploiBenef}${adresseBenef}${mntComprisTvaIs10}${mntComprisTvaIs15}${mntComprisTvaEntrepUnipers}${mntComprisTvaAutreEntrep}${mntRetenuTitreTva}${retenuOpere}${netServi}${EndZone}`;
        
        
          });
          const totalmntComprisTvaIs10 = String(
            (annexe5List.reduce((acc : any, annexe5 : any) => acc + (annexe5.mntComprisTvaIs10 || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
          const totalmntComprisTvaIs15 = String(
            (annexe5List.reduce((acc : any, annexe5 : any) => acc + (annexe5.mntComprisTvaIs15 || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
          const totalmntComprisTvaEntrepUnipers = String(
            (annexe5List.reduce((acc : any, annexe5 : any) => acc + (annexe5.mntComprisTvaEntrepUnipers || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
          const totalmntmntComprisTvaAutreEntrep = String(
            (annexe5List.reduce((acc : any, annexe5 : any) => acc + (annexe5.mntComprisTvaAutreEntrep || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
          const totalmntRetenuTitreTva = String(
            (annexe5List.reduce((acc : any, annexe5 : any) => acc + (annexe5.retenuTitreTva || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
          const totalRetenuOper = String(
            (annexe5List.reduce((acc : any, annexe5 : any) => acc + (annexe5.retenuOpere || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
          const totalnetServi = String(
            (annexe5List.reduce((acc : any, annexe5 : any) => acc + (annexe5.netServi || 0), 0).toFixed(3) * 1000)
          ).padStart(15, '0');
          
    
    
    
            const EndZone = " ".repeat(56);
            const EndZoneAnx = " ".repeat(220);

    
    
        const FinAnnexe = `T5${matriculeFiscale}${exerciceLibelle}${EndZoneAnx}${totalmntComprisTvaIs10}${totalmntComprisTvaIs15}${totalmntComprisTvaEntrepUnipers}${totalmntmntComprisTvaAutreEntrep}${totalmntRetenuTitreTva}${totalRetenuOper}${totalnetServi}${EndZone}`;
        annexe5Content += `\n${FinAnnexe}`;
        return annexe5Content;
    };
    

    const textContent = generateAnnexe5Content();
    const blob = new Blob([textContent], { type: "text/plain;charset=utf-8" });
    const exerciseLabel = currentExercice.libelle;
    const lastTwoChars = exerciseLabel.slice(-2);
    const fileName = `ANXEMP_5_${lastTwoChars}_1.txt`;
    saveAs(blob, fileName);
};

const Annexe5File: Annexe5FileProps = {
    currentDeclaration: null,
    currentExercice: null,
    currentActe: null,
    currentSociete: null,
    annexe5List : null,
};

export default Annexe5File;
