import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import { EnteteCertificatDataType } from "../../../Types/Forms/EnteteCertificatType";
import { EnteteCertificatType } from "../../../Types/Entites/EnteteCertificatType";


export const getAllEnteteCertificatsApi = createAsyncThunk(
  "enteteCertificat/getAll",
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await axios.get("enteteCertificat/getAllCertificat");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const getEnteteCertificatByNumCertApi = createAsyncThunk(
  "enteteCertificat/getByNumCert",
  async (numCertificat: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `enteteCertificat/getCertificatByNumCertificat/${numCertificat}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const createEnteteCertificatApi = createAsyncThunk(
  "enteteCertificat/create",
  async (data: EnteteCertificatDataType, { rejectWithValue }) => {
    try {
      const response = await axios.post("/enteteCertificat/createCertificat", data); 
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const updateEnteteCertificatApi = createAsyncThunk(
  "enteteCertificat/update",
  async (
    {
      numCertificat,
      data,
    }: { numCertificat: string; data: EnteteCertificatDataType },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(
        `enteteCertificat/updateCertificat/${numCertificat}`,
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const deleteEnteteCertificatApi = createAsyncThunk(
  "enteteCertificat/delete",
  async (numCertificat: string, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `enteteCertificat/deleteCertificat/${numCertificat}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const getEnteteCertificatBySocieteIdApi = createAsyncThunk(
  "enteteCertificat/getBySocieteId",
  async (societeId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `enteteCertificat/getCertificatBySocieteId/${societeId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const getEnteteCertificatBySocieteAndExerciceApi = createAsyncThunk(
  "enteteCertificat/getBySocieteAndExercice",
  async (
    { societeId, exerciceId }: { societeId: number; exerciceId: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.get(
        `enteteCertificat/getCertificatBySocieteAndExercice/${societeId}/${exerciceId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const getEnteteCertificatByDeclarationApi = createAsyncThunk(
    "enteteCertificat/getByDeclaration",
    async (declarationId: number, { rejectWithValue }) => {
      try {
        const response = await axios.get(
          `enteteCertificat/getCertificatByDeclaration/${declarationId}`
        );
        return response.data;
      } catch (error: any) {
        return rejectWithValue({
          success: false,
          message: error.response?.data?.message || "Erreur inconnue de l'API",
        });
      }
    }
  );


  export const getEnteteCertificatBySocieteAndExerciceAndMonthApi = createAsyncThunk(
    "enteteCertificat/getCertificatBySocieteExerciceAndMonth",
    async (
      { societeId, exerciceId, month }: { societeId: number; exerciceId: number; month: number },
      { rejectWithValue }
    ) => {
      try {
        const response = await axios.get(
          `enteteCertificat/getCertificatBySocieteExerciceAndMonth/${societeId}/${exerciceId}/${month}`
        );
        return response.data;
      } catch (error: any) {
        return rejectWithValue({
          success: false,
          message: error.response?.data?.message || "Erreur inconnue de l'API",
        });
      }
    }
  );
  

interface EnteteCertificatState {
    enteteCertificatsList: EnteteCertificatType[];
    currentEnteteCertificat: EnteteCertificatType | null;
    enteteCertificatsBySocieteAndExercice: EnteteCertificatType[];
    enteteCertificatsByDeclaration: EnteteCertificatType[];
    certificatsBySociete : EnteteCertificatType[];
    certificatsBySocieteAndExerciceAndMonth: EnteteCertificatType[];
    
  }
  
  const initialState: EnteteCertificatState = {
    enteteCertificatsList: [],
    currentEnteteCertificat: null,
    enteteCertificatsBySocieteAndExercice: [],
    enteteCertificatsByDeclaration: [],
    certificatsBySociete: [],
    certificatsBySocieteAndExerciceAndMonth: [], 
  };
  

export const enteteCertificatSlice = createSlice({
  name: "enteteCertificat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllEnteteCertificatsApi.fulfilled, (state, action) => {
      state.enteteCertificatsList = action.payload;
    });

    builder.addCase(
      getEnteteCertificatByNumCertApi.fulfilled,
      (state, action) => {
        state.currentEnteteCertificat = action.payload;
      }
    );

    builder.addCase(
      getEnteteCertificatBySocieteAndExerciceApi.fulfilled,
      (state, action) => {
        state.enteteCertificatsBySocieteAndExercice = action.payload;
      }
    );

    builder.addCase(createEnteteCertificatApi.fulfilled, (state, action) => {
        state.enteteCertificatsBySocieteAndExercice.push(action.payload);

      state.enteteCertificatsList.push(action.payload);
      state.enteteCertificatsByDeclaration.push(action.payload);
    });

    builder.addCase(updateEnteteCertificatApi.fulfilled, (state, action) => {
      const updatedEnteteCertificat = action.payload;
      const index = state.enteteCertificatsList.findIndex(
        (certificat) => certificat.numCertificat === updatedEnteteCertificat.numCertificat
      );
      if (index !== -1) {
        state.enteteCertificatsList[index] = updatedEnteteCertificat;
      }

      const indexSocieteExercice =
        state.enteteCertificatsBySocieteAndExercice.findIndex(
          (certificat) => certificat.numCertificat === updatedEnteteCertificat.numCertificat
        );
      if (indexSocieteExercice !== -1) {
        state.enteteCertificatsBySocieteAndExercice[indexSocieteExercice] = updatedEnteteCertificat;
      }
    });

    builder.addCase(deleteEnteteCertificatApi.fulfilled, (state, action) => {
        const deletedNumCertificat = action.payload.numCertificat;
        
        state.enteteCertificatsList = state.enteteCertificatsList.filter(
          (certificat) => certificat.numCertificat !== deletedNumCertificat
        );
      
        state.enteteCertificatsBySocieteAndExercice = state.enteteCertificatsBySocieteAndExercice.filter(
          (certificat) => certificat.numCertificat !== deletedNumCertificat
        );
        state.enteteCertificatsByDeclaration = state.enteteCertificatsByDeclaration.filter(
            (certificat) => certificat.numCertificat !== deletedNumCertificat
          );
      });

      builder.addCase(
        getEnteteCertificatByDeclarationApi.fulfilled,
        (state, action) => {
          state.enteteCertificatsByDeclaration = action.payload;
        }
      );
      builder.addCase(
        getEnteteCertificatBySocieteIdApi.fulfilled,
        (state, action) => {
          state.certificatsBySociete = action.payload;
        }
      );
      builder.addCase(
        getEnteteCertificatBySocieteAndExerciceAndMonthApi.fulfilled,
        (state, action) => {
          state.certificatsBySocieteAndExerciceAndMonth = action.payload;
        }
      );
    },
  });
      
 

export const EnteteCertificatsList = (state: RootState) => state.enteteCertificat.enteteCertificatsList;
export const selectCurrentEnteteCertificat = (state: RootState) => state.enteteCertificat.currentEnteteCertificat;
export const EnteteCertificatsBySocieteAndExercice = (state: RootState) => state.enteteCertificat.enteteCertificatsBySocieteAndExercice;
export const EnteteCertificatsByDeclaration = (state: RootState) => state.enteteCertificat.enteteCertificatsByDeclaration;
export const CertificatsBySociete = (state: RootState) => state.enteteCertificat.certificatsBySociete; 
export const CertificatsBySocieteAndExerciceAndMonth = (state: RootState) => state.enteteCertificat.certificatsBySocieteAndExerciceAndMonth; 


export default enteteCertificatSlice.reducer;
