import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { UserType } from "../../../Types/Entites/UserType";
import { RootState } from "../../store";
import { LoginDataType } from "../../../Types/Forms/LoginDataType";

// Action asynchrone pour créer un utilisateur
export const createUserApi = createAsyncThunk(
  "user/createUser",
  async (data: UserType, { rejectWithValue }) => {
    try {
      const response = await axios.post("user/createUser", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

// Action asynchrone pour récupérer tous les utilisateurs
export const findAllUsersApi = createAsyncThunk(
  "user/findAllUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("user/findAllUsers");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message && "Erreur inconnue de l'API",
      });
    }
  }
);

// Action asynchrone pour récupérer un utilisateur par ID
export const findUserByIdApi = createAsyncThunk(
  "user/findUserById",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(`user/findUserById/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message && "Erreur inconnue de l'API",
      });
    }
  }
);

// Action asynchrone pour mettre à jour un utilisateur
export const updateUserApi = createAsyncThunk(
  "user/updateUser",
  async (
    { id, data }: { id: number; data: UserType },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(`user/updateUser/${id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message && "Erreur inconnue de l'API",
      });
    }
  }
);

// Action asynchrone pour supprimer un utilisateur
export const deleteUserApi = createAsyncThunk(
  "user/deleteUser",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`user/deleteUser/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message && "Erreur inconnue de l'API",
      });
    }
  }
);


export const loginUserApi = createAsyncThunk(
  "user/loginUser",
  async ({ email, password }: LoginDataType, { rejectWithValue }) => {
    try {
      const response = await axios.post("user/login", { email, password });

      const userData: UserType = response.data.user;

      return userData;
    } catch (error: unknown) {
      const errorMessage =
        (error as any)?.response?.data?.message || "Erreur de connexion";
      return rejectWithValue({
        success: false,
        message: errorMessage,
      });
    }
  }
);
interface UsersState {
  currentUser: UserType | null;
}

const initialState: UsersState = {
  currentUser: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetCurrentUser(state) {
      state.currentUser = null; 
    },
  },
  extraReducers: (builder) => {

    builder.addCase(loginUserApi.fulfilled, (state, action) => {
      state.currentUser = action.payload; 
    });
    builder.addCase(createUserApi.fulfilled, (state, action) => {
      // state.userList.push(action.payload);
    });

    builder.addCase(findAllUsersApi.fulfilled, (state, action) => {
      // state.userList = action.payload;
    });

    builder.addCase(findUserByIdApi.fulfilled, (state, action) => {
      // state.user = action.payload;
    });

    builder.addCase(updateUserApi.fulfilled, (state, action) => {
      // const updatedIndex = state.userList.findIndex(
      //   (user) => user.id === action.payload.id
      // );
      // if (updatedIndex !== -1) {
      //   state.userList[updatedIndex] = action.payload;
      // }
    });

    builder.addCase(deleteUserApi.fulfilled, (state, action) => {
      // state.userList = state.userList.filter((user) => user.id !== action.payload.id);
    });
  },
});


export const { resetCurrentUser } = userSlice.actions; 

export const currentUserSelector = (state: RootState) => state.user.currentUser;
export const currentToken = (state: RootState) => state.user.currentUser?.token;
export const currentUserId = (state: RootState) => state.user.currentUser?.id;

export default userSlice.reducer;
