import React, { useState } from "react";
import DeclarationList from "../../Components/Declaration/DeclarationList";
import ExerciceList from "../../Components/Exercice/ExerciceList";
import Navbar from "../../_Layouts/Navbar/Navbar";
import { Button, Modal } from "react-bootstrap"; 
import { useParams } from "react-router-dom";

const DeclarationPage = () => {
  const [showExerciceModal, setShowExerciceModal] = useState(false); 
  const { declarantId } = useParams(); 
  const parsedDeclarantId = declarantId ? parseInt(declarantId) : undefined;

  const handleOpenExerciceModal = () => {
    setShowExerciceModal(true);
  };

  const handleCloseExerciceModal = () => {
    setShowExerciceModal(false);
  };

  return (
    <div>
      
      <div style={{ marginBottom: "30px" }}>
        <Navbar titre={"Déclarations"} />
      </div>
      <div style={{ marginTop: "10px" ,display: "flex", justifyContent: "flex-end" }}>
        <Button variant="secondary" onClick={handleOpenExerciceModal}>Gestion des exercices</Button>
      </div>


      <div style={{ marginTop: "20px"  }}>
        <DeclarationList declarantId={parsedDeclarantId} />
      </div>

     

      <Modal show={showExerciceModal} size='xl'>
        <Modal.Header closeButton  onClick={handleCloseExerciceModal}>
          <Modal.Title>Gestion des exercices</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExerciceList declarantId={parsedDeclarantId} /> 
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseExerciceModal}>Fermer</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeclarationPage;
