import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Annexe5Type } from "../../../Types/Entites/Annexe5Type";
import { RootState } from "../../store";

export const createAnnexe5Api = createAsyncThunk(
  "annexe5/createAnnexe5",
  async (data: Annexe5Type, { rejectWithValue }) => {
    try {
      const response = await axios.post("annexe5/createAnnexe5", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Unknown API error",
      });
    }
  }
);

export const findAllAnnexe5Api = createAsyncThunk(
  "annexe5/findAllAnnexe5",
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await axios.get("annexe5/findAllAnnexes5");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Unknown API error",
      });
    }
  }
);

export const updateAnnexe5Api = createAsyncThunk(
  "annexe5/updateAnnexe5",
  async (
    { id, data }: { id: number; data: Annexe5Type },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(`annexe5/updateAnnexe5/${id}`, data); // Replace with your actual API endpoint
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Unknown API error",
      });
    }
  }
);

export const getAnnexe5ByIdApi = createAsyncThunk(
  "annexe5/getAnnexe5ById",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(`annexe5/getAnnexe5ById/${id}`); // Replace with your actual API endpoint
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Unknown API error",
      });
    }
  }
);

export const deleteAnnexe5Api = createAsyncThunk(
  "annexe5/deleteAnnexe5",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`annexe5/deleteAnnexe5/${id}`); // Replace with your actual API endpoint
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Unknown API error",
      });
    }
  }
);

export const findAnnexes5ByDeclarationApi = createAsyncThunk(
  "annexe5/findAnnexes5ByDeclaration",
  async (declarationId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `annexe5/findAnnexes5ByDeclaration/${declarationId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);



// Action asynchrone pour supprimer toutes les Annexe5 par ID de déclaration
export const deleteAllAnnexes5ByDeclarationApi = createAsyncThunk(
  "annexe5/deleteAllAnnexes5ByDeclaration",
  async (declarationId: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `annexe5/deleteAllAnnexes5ByDeclaration/${declarationId}`
      );
      return { declarationId, data: response.data };
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);



interface Annexe5s {
  annexe5List: Annexe5Type[];
  annexe5ListByDeclaration: Annexe5Type[];
}

const initialState: Annexe5s = {
  annexe5List: [],
  annexe5ListByDeclaration: [],
};

export const annexe5Slice = createSlice({
  name: "annexe5",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAnnexe5Api.fulfilled, (state, action) => {
      state.annexe5List.push(action.payload.data);
      state.annexe5ListByDeclaration.push(action.payload.data);
    });

    builder.addCase(findAllAnnexe5Api.fulfilled, (state, action) => {
      return {
        ...state,
        annexe5List: action.payload,
      };
    });

    builder.addCase(updateAnnexe5Api.fulfilled, (state, action) => {
      const updatedIndex = state.annexe5List.findIndex(
        (annexe5) => annexe5.id === action.payload.data.id
      );
      if (updatedIndex !== -1) {
        state.annexe5List[updatedIndex] = action.payload.data;
      }

      const updatedDeclarationIndex = state.annexe5ListByDeclaration.findIndex(
        (annexe5) => annexe5.id === action.payload.data.id
      );
      if (updatedDeclarationIndex !== -1) {
        state.annexe5ListByDeclaration[updatedDeclarationIndex] =
          action.payload.data;
      }
    });


    builder.addCase(getAnnexe5ByIdApi.fulfilled, (state, action) => {
      return {
        ...state,
        annexe5List: [action.payload],
      };
    });

    builder.addCase(deleteAnnexe5Api.fulfilled, (state, action) => {
      state.annexe5List = state.annexe5List.filter(annexe5 => annexe5.id !== action.meta.arg);
      state.annexe5ListByDeclaration = state.annexe5ListByDeclaration.filter(annexe5 => annexe5.id !== action.meta.arg);
    });
    builder.addCase(findAnnexes5ByDeclarationApi.fulfilled, (state, action) => {
      return {
        ...state,
        annexe5ListByDeclaration: action.payload,
      };
    });

    builder.addCase(deleteAllAnnexes5ByDeclarationApi.fulfilled, (state, action) => {
      state.annexe5List = state.annexe5List.filter(
        (annexe5) => annexe5.declaration !== action.payload.declarationId
      );
      state.annexe5ListByDeclaration = state.annexe5ListByDeclaration.filter(
        (annexe5) => annexe5.declaration !== action.payload.declarationId
      );
    });
  },
});



export const annexe5List = (state: RootState) => state.annexe5.annexe5List;
export const annexe5ListByDeclaration = (state: RootState) =>
  state.annexe5.annexe5ListByDeclaration;

export default annexe5Slice.reducer;
