import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { PaysDeviseType } from "../../../Types/Entites/PaysDeviseType";
import { RootState } from "../../store";

// Action asynchrone pour créer un nouveau pays et devise
export const createPaysDeviseApi = createAsyncThunk(
  "paysDevises/createPaysDevise",
  async (data: PaysDeviseType, { rejectWithValue }) => {
    try {
      const response = await axios.post("paysDevise/createPaysDevise", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour récupérer tous les pays et devises
export const findAllPaysDevisesApi = createAsyncThunk(
  "paysDevises/findAllPaysDevises",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("paysDevise/findAllPaysDevises");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour mettre à jour un pays et devise
export const updatePaysDeviseApi = createAsyncThunk(
  "paysDevises/updatePaysDevise",
  async ({ id, data }: { id: number; data: PaysDeviseType }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`paysDevise/updatePaysDevise/${id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour récupérer un pays et devise par ID
export const findPaysDeviseByIdApi = createAsyncThunk(
  "paysDevises/findById",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(`paysDevise/getPaysDeviseById/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour supprimer un pays et devise
export const deletePaysDeviseApi = createAsyncThunk(
  "paysDevises/deletePaysDevise",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`paysDevise/deletePaysDevise/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface PaysDevisesState {
  paysDevisesList: PaysDeviseType[];
}

const initialState: PaysDevisesState = {
  paysDevisesList: [],
};

export const paysDeviseSlice = createSlice({
  name: "paysDevises",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createPaysDeviseApi.fulfilled, (state, action) => {
      state.paysDevisesList.push(action.payload.data);
    });
    builder.addCase(findAllPaysDevisesApi.fulfilled, (state, action) => {
      state.paysDevisesList = action.payload;
    });
    builder.addCase(updatePaysDeviseApi.fulfilled, (state, action) => {
      const updatedIndex = state.paysDevisesList.findIndex(
        (paysDevise) => paysDevise.id === action.payload.data.id
      );
      if (updatedIndex !== -1) {
        state.paysDevisesList[updatedIndex] = action.payload.data;
      }
    });
    builder.addCase(findPaysDeviseByIdApi.fulfilled, (state, action) => {
      state.paysDevisesList = [action.payload];
    });
    builder.addCase(deletePaysDeviseApi.fulfilled, (state, action) => {
      state.paysDevisesList = state.paysDevisesList.filter(
        (paysDevise) => paysDevise.id !== action.meta.arg
      );
    });
  },
});

export const selectPaysDevisesList = (state: RootState) => state.paysDevises.paysDevisesList;

export default paysDeviseSlice.reducer;
