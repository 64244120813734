import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import ExportXml from "./ExportXml";

interface GenerateXmlModalProps {
  show: boolean;
  onClose: () => void;
  declarantId: number;
  exerciceId: number;
}

const GenerateXmlModal: React.FC<GenerateXmlModalProps> = ({
  show,
  onClose,
  declarantId,
  exerciceId,
}) => {
  const [selectedMonth, setSelectedMonth] = useState("01");

  const handleMonthSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMonth(event.target.value);
  };

  const handleExportXml = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Sélectionner le mois</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="selectMonth">
          <Form.Label>Mois</Form.Label>
          <Form.Select as="select" onChange={handleMonthSelect} value={selectedMonth}>
            <option value="01">Janvier</option>
            <option value="02">Février</option>
            <option value="03">Mars</option>
            <option value="04">Avril</option>
            <option value="05">Mai</option>
            <option value="06">Juin</option>
            <option value="07">Juillet</option>
            <option value="08">Août</option>
            <option value="09">Septembre</option>
            <option value="10">Octobre</option>
            <option value="11">Novembre</option>
            <option value="12">Décembre</option>
          </Form.Select>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Annuler
        </Button>
        <ExportXml
          selectedMonth={selectedMonth}
          declarantId={declarantId}
          exerciceId={exerciceId}
          onExportXml={handleExportXml}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default GenerateXmlModal;
