import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { Asterisk, getLettreAssociee } from "../../_App/Helpers/helpers";
import {
  createSocieteApi,
  updateSocieteApi,
} from "../../_App/Redux/Slices/societes/societeSlice";
import {
  findAllTvaCategoriesApi,
  tvaCategoriesList,
} from "../../_App/Redux/Slices/tvaCategory/tvaCategorySlice";
import { currentUserId } from "../../_App/Redux/Slices/user/userSlice";
import { createUserSocieteApi } from "../../_App/Redux/Slices/userSociete/userSociete";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { SocieteFormData } from "../../_App/Types/Forms/SocieteDataType";

interface SocieteFormProps {
  closeForm: () => void;
  initialData?: SocieteFormData;
}

const SocieteForm: React.FC<SocieteFormProps> = ({
  closeForm,
  initialData,
}) => {
  const dispatch = useAppDispatch();
  const tvaCategories = useAppSelector(tvaCategoriesList);
  const [isUpdate, setIsUpdate] = useState(false);
  const userId = useAppSelector(currentUserId);

  useEffect(() => {
    if (initialData) {
      setIsUpdate(true);
    }
  }, [initialData]);

  useEffect(() => {
    if (initialData) {
      Object.keys(initialData).forEach((key) => {
        if (key in initialData) {
          const value = initialData[key as keyof SocieteFormData];
          setValue(key as keyof SocieteFormData, value);
        }
      });
    }
  }, [initialData]);
  useEffect(() => {
    if (initialData) {
      const { matriculeFiscale } = initialData;
      const part1 = matriculeFiscale.slice(0, 7);
      const part2 = matriculeFiscale.slice(7, 8);
      const part3 = matriculeFiscale.slice(8, 9);
      const part4 = matriculeFiscale.slice(9, 10);
      const part5 = "000";

      setMatriculeFiscalePart1(part1);
      setMatriculeFiscalePart2(part2);
      setMatriculeFiscalePart3(part3);
      setMatriculeFiscalePart4(part4);
      setMatriculeFiscalePart5(part5);
    }
  }, [initialData]);

  useEffect(() => {
    dispatch(findAllTvaCategoriesApi(null));
  }, [dispatch]);

  const { register, reset, handleSubmit, setValue } = useForm();

  const [matriculeFiscalePart1, setMatriculeFiscalePart1] = useState("");
  const [matriculeFiscalePart2, setMatriculeFiscalePart2] = useState("");
  const [matriculeFiscalePart5, setMatriculeFiscalePart5] = useState("000");
  const [matriculeFiscalePart3, setMatriculeFiscalePart3] = useState("");
  const [matriculeFiscalePart4, setMatriculeFiscalePart4] = useState("");

  const handleMatriculeFiscalePart1Change = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = e.target.value;
    value = value.replace(/\D/g, "");
    value = value.slice(0, 7);
    setMatriculeFiscalePart1(value);
    const lettreAssociee = getLettreAssociee(value);
    setMatriculeFiscalePart2(lettreAssociee);
  };

  const handleMatriculeFiscalePart1Blur = () => {
    let value = matriculeFiscalePart1.padStart(7, "0");
    setValue("matriculeFiscalePart1", value);
    setMatriculeFiscalePart1(value);
    const lettreAssociee = getLettreAssociee(value);
    setMatriculeFiscalePart2(lettreAssociee);
    setValue("matriculeFiscalePart2", lettreAssociee);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let truncatedValue = value;

    switch (name) {
      case "activity":
      case "ville":
      case "souche":
        truncatedValue = value.slice(0, 40);
        break;
      case "rue":
        truncatedValue = value.slice(0, 72);
        break;
      case "num":
      case "codePostal":
        truncatedValue = value.replace(/\D/g, "").slice(0, 4);
        break;
    }

    setValue(name as keyof SocieteFormData, truncatedValue);
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      const matriculeFiscale =
        matriculeFiscalePart1 +
        matriculeFiscalePart2 +
        data.matriculeFiscalePart3 +
        data.matriculeFiscalePart4 +
        data.matriculeFiscalePart5;

      // Si data.id existe, mettez à jour la société, sinon, créez-en une nouvelle
      if (data.id) {
        const response = await dispatch(
          updateSocieteApi({ id: data.id, data: { ...data, matriculeFiscale } })
        );

        if (response.payload.success) {
          ToastSuccess(response.payload.message);
          reset();
          closeForm();
        } else {
          ToastWarning(response.payload.message);
        }
      } else {
        const response = await dispatch(
          createSocieteApi({ ...data, matriculeFiscale })
        );

        if (response.payload.success) {
          ToastSuccess(response.payload.message);

          const newSocieteId = response.payload.data.id;

          if (userId) {
            const userSocieteResponse = await dispatch(
              createUserSocieteApi({ userId, societeId: newSocieteId })
            );

            if (userSocieteResponse.payload.success) {
            } else {
              ToastWarning(userSocieteResponse.payload.message);
            }
          }

          reset();
          closeForm();
        } else {
          ToastWarning(response.payload.message);
        }
      }
    } catch (error) {
      console.error("Échec de la soumission :", error);
    }
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col>
          <Form.Group controlId="name">
            <Form.Label>
              Nom ou raison sociale <Asterisk>*</Asterisk>
            </Form.Label>
            <Form.Control
              type="text"
              {...register("name")}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group controlId="activity">
            <Form.Label>
              Activité <Asterisk>*</Asterisk>
            </Form.Label>
            <Form.Control
              type="text"
              {...register("activity")}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group controlId="souche">
            <Form.Label>
              Souche certificat des retenues <Asterisk>*</Asterisk>
            </Form.Label>
            <Form.Control
              type="text"
              {...register("souche")}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>

      <Card style={{ marginTop: "20px" }}>
        <Card.Body>
          <Card.Title>Adresse</Card.Title>
          <Row>
            <Col>
              <Form.Group controlId="ville">
                <Form.Label>
                  Ville <Asterisk>*</Asterisk>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("ville")}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="rue">
                <Form.Label>
                  Rue <Asterisk>*</Asterisk>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("rue")}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="num">
                <Form.Label>
                  Numéro <Asterisk>*</Asterisk>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("num")}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="codePostal">
                <Form.Label>
                  Code Postal <Asterisk>*</Asterisk>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("codePostal")}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card style={{ marginTop: "20px" }}>
        <Card.Body>
          <Card.Title>
            Matricule Fiscal <Asterisk>*</Asterisk>
          </Card.Title>

          <Row>
            <Col xs={4}>
              {" "}
              <Form.Group controlId="matriculeFiscalePart1">
                <Form.Label>Num MF</Form.Label>
                <Form.Control
                  type="text"
                  {...register("matriculeFiscalePart1")}
                  value={matriculeFiscalePart1}
                  onChange={handleMatriculeFiscalePart1Change}
                  onBlur={handleMatriculeFiscalePart1Blur}
                  title="Numéro matricule fiscal"
                />
              </Form.Group>
            </Col>
            <Col xs={2}>
              {" "}
              <Form.Group controlId="matriculeFiscalePart2">
                <Form.Label>Clé MF</Form.Label>
                <Form.Control
                  type="text"
                  {...register("matriculeFiscalePart2")}
                  value={matriculeFiscalePart2}
                  readOnly
                  title="Clé du matricule fiscal"
                />
              </Form.Group>
            </Col>
            <Col xs={2}>
              {" "}
              <Form.Group controlId="matriculeFiscalePart3">
                <Form.Label>Code TVA</Form.Label>
                <Form.Control
                  as="select"
                  {...register("matriculeFiscalePart3")}
                  title="Code TVA"
                >
                  <option value={""}></option>
                  {tvaCategories
                    .filter((category) => category.type === "tva")
                    .map((tva) => {
                      const matriculeFiscalePart3Value =
                        initialData?.matriculeFiscale?.charAt(8) ?? "";
                      return (
                        <option
                          key={tva.id}
                          value={tva.code}
                          selected={tva.code === matriculeFiscalePart3Value}
                        >
                          {tva.code}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={2}>
              {" "}
              <Form.Group controlId="matriculeFiscalePart4">
                <Form.Label>Code CAT</Form.Label>
                <Form.Control
                  as="select"
                  {...register("matriculeFiscalePart4")}
                  title="Code Catégorie"
                >
                  <option value={""}></option>
                  {tvaCategories
                    .filter((category) => category.type === "category")
                    .map((category) => {
                      const matriculeFiscalePart4Value =
                        initialData?.matriculeFiscale?.charAt(9) ?? "";
                      return (
                        <option
                          key={category.id}
                          value={category.code}
                          selected={
                            category.code === matriculeFiscalePart4Value
                          }
                        >
                          {category.code}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={2}>
              {" "}
              <Form.Group controlId="matriculeFiscalePart5">
                <Form.Label>N° Etab</Form.Label>
                <Form.Control
                  type="text"
                  {...register("matriculeFiscalePart5")}
                  value={matriculeFiscalePart5}
                  readOnly
                  title="N° Etablissement secondaire"
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Button
        type="submit"
        variant="primary"
        style={{ float: "right", marginTop: "10px" }}
      >
        {initialData ? "Modifier" : "Enregistrer"}
      </Button>
    </Form>
  );
};

export default SocieteForm;
