import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";

import * as XLSX from "xlsx";
import {
  MatriculFiscalValidate,
  handleTabulation,
} from "../../_App/Helpers/helpers";
import {
  annexe5ListByDeclaration,
  createAnnexe5Api,
  deleteAnnexe5Api,
  findAnnexes5ByDeclarationApi,
  updateAnnexe5Api,
} from "../../_App/Redux/Slices/annexe5/annexe5Slice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { Annexe5Type } from "../../_App/Types/Entites/Annexe5Type";

import styled from "styled-components";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  BeneficiairesList,
  createBeneficiaireApi,
  findAllBeneficiairesApi,
} from "../../_App/Redux/Slices/beneficiaire/beneficiaireSlice";
import {
  declarationsList,
  findAllDeclarationsApi,
} from "../../_App/Redux/Slices/declaration/declarationSlice";
import { findAllSocietesApi } from "../../_App/Redux/Slices/societes/societeSlice";
import ImpressionAnnexe5 from "./ImpressionAnnexe5";

const FixedNewColumnCell = styled.th`
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedNewDataCell = styled.td`
  position: sticky;
  left: 0;
  z-index: 1;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedHeaderCell = styled.th`
  position: sticky;
  left: 37px;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedSecondHeaderCell = styled.th`
  position: sticky;
  left: 112px;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedThirdHeaderCell = styled.th`
  position: sticky;
  left: 255px;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedDataCell = styled.td`
  position: sticky;
  left: 37px;
  z-index: 1;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedSecondDataCell = styled.td`
  position: sticky;
  left: 112px;
  z-index: 1;
  padding: 2px !important;
  margin: 0 !important;
`;

const FixedThirdDataCell = styled.td`
  position: sticky;
  left: 255px;
  z-index: 1;
  padding: 2px !important;
  margin: 0 !important;
`;
const FixedFourHeaderCell = styled.th`
  position: sticky;
  left: 389px;
  z-index: 1;
  background-color: #ebf2fc !important;
  padding: 4px !important;
  margin: 0 !important;
`;

const FixedFourDataCell = styled.td`
  position: sticky;
  left: 389px;
  z-index: 1;
  padding: 4px !important;
  margin: 0 !important;
`;

interface Annexe5FormProps {
  declarationId: number;
}

const Annexe5Form: React.FC<Annexe5FormProps> = ({ declarationId }) => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<Annexe5Type>();
  const annexesList = useAppSelector(annexe5ListByDeclaration);
  const [editableRows, setEditableRows] = useState<number[]>([]);
  const [editedValues, setEditedValues] = useState<Partial<Annexe5Type>>({});

  type FormControlChangeEvent = React.ChangeEvent<
    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  >;

  const mntComprisTvaIs10 = parseFloat(
    String(watch("mntComprisTvaIs10") || "0")
  );
  const mntComprisTvaIs15 = parseFloat(
    String(watch("mntComprisTvaIs15") || "0")
  );
  const mntComprisTvaEntrepUnipers = parseFloat(
    String(watch("mntComprisTvaEntrepUnipers") || "0")
  );
  const mntComprisTvaAutreEntrep = parseFloat(
    String(watch("mntComprisTvaAutreEntrep") || "0")
  );
  const retenuTitreTva = parseFloat(String(watch("retenuTitreTva") || "0"));
  const retenuOpere = parseFloat(String(watch("retenuOpere") || "0"));

  const netServi =
    mntComprisTvaIs10 +
    mntComprisTvaIs15 +
    mntComprisTvaEntrepUnipers +
    mntComprisTvaAutreEntrep -
    retenuTitreTva -
    retenuOpere;

  useEffect(() => {
    setValue("netServi", parseFloat(netServi.toFixed(3)));
  }, [setValue, netServi]);

  const [netServiValue, setNetServiValue] = useState(netServi.toFixed(3));
  useEffect(() => {
    setNetServiValue(netServi.toFixed(3));
  }, [netServi]);

  // const handleInputChange = (e: FormControlChangeEvent, fieldName: string) => {
  //   const { value } = e.target;
  //   setEditedValues((prevValues) => ({
  //     ...prevValues,
  //     [fieldName]: value,
  //   }));
  // };

  const handleUpdateRow = async (rowIndex: number) => {
    try {
      const data = editedValues;
      const editedData = {
        ...annexesList[rowIndex],
        ...data,
      };

      const updateAction = await dispatch(
        updateAnnexe5Api({
          id: editedData.id || 0,
          data: editedData,
        })
      );

      if (updateAction.payload) {
        setEditableRows((prevEditableRows) =>
          prevEditableRows.filter((row) => row !== rowIndex)
        );
        setEditedValues({});
      }
    } catch (error) {
      console.error("Update failed", error);
    }
  };

  useEffect(() => {
    [
      findAllSocietesApi,
      findAllDeclarationsApi,
      findAllBeneficiairesApi,
    ].forEach((action) => dispatch(action(null)));

    declarationId && dispatch(findAnnexes5ByDeclarationApi(declarationId));
  }, [dispatch, declarationId]);

  const allDeclarations = useAppSelector(declarationsList);

  const currentDeclaration = declarationId
    ? allDeclarations.find(
        (declaration) => declaration.id === parseInt(declarationId.toString())
      )
    : undefined;
  const societeId = currentDeclaration?.declarant;
  const [formKey, setFormKey] = useState(0);

  const onSubmit: SubmitHandler<Annexe5Type> = async (data) => {
    try {
      data.declaration = declarationId;
      const createAction = await dispatch(createAnnexe5Api(data));

      if (createAction.payload && createAction.payload.success) {
        ToastSuccess(createAction.payload.message);

        const beneficiaireData = {
          typeIdentifiant: data.typeIdentifiant,
          identifiant: data.identifiant,
          name: data.name,
          activity: data.activity,
          adresse: data.adresse,

          societe: societeId || 0,
          typeBeneficiaire: "Fournisseur",
        };

        const beneficiaireExiste = beneficiaireList.some(
          (b) => b.identifiant === data.identifiant
        );

        if (!beneficiaireExiste) {
          const createBeneficiaireAction = await dispatch(
            createBeneficiaireApi(beneficiaireData)
          );

          if (
            createBeneficiaireAction.payload &&
            createBeneficiaireAction.payload.success
          ) {
            ToastSuccess("Bénéficiaire ajouté avec succès");
          } else {
            ToastWarning(createBeneficiaireAction.payload?.message);
          }
        }

        reset();
        setFormKey((prevKey) => prevKey + 1);
      } else {
        ToastWarning(createAction.payload?.message);
      }
    } catch (error) {
      console.error("La soumission a échoué", error);
      ToastWarning(
        "Une erreur est survenue lors de la soumission du formulaire"
      );
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const handleDeleteRow = (id: number) => {
    if (id !== undefined) {
      setDeleteId(id);
      setShowDeleteModal(true);
    }
  };
  const handleConfirmDelete = async () => {
    if (deleteId !== null) {
      try {
        const deleteAction = await dispatch(deleteAnnexe5Api(deleteId));

        if (deleteAction.payload && deleteAction.payload.success) {
          ToastSuccess(deleteAction.payload.message);
          setShowDeleteModal(false);
        } else if (deleteAction.payload && deleteAction.payload.message) {
          ToastWarning(deleteAction.payload.message);
        }

        setDeleteId(null);
      } catch (error) {
        console.error("La suppression a échoué", error);
        ToastWarning("Une erreur est survenue lors de la suppression.");
      }
    }
  };

  const handleInputChangeAndValidation = (name: any, value: any) => {
    const regex = /^\d+(\.\d{1,3})?$/;

    if (value === "") {
      setValue(name, 0.0);
      clearErrors(name);
    } else if (regex.test(value)) {
      setValue(name, parseFloat(value));
      clearErrors(name);
    } else {
      setError(name, {
        type: "manual",
        message:
          "Veuillez saisir un nombre valide avec un maximum de 3 chiffres après le point décimal.",
      });
    }
  };

  const [excelData, setExcelData] = useState<any[]>([]);

  const handleRemoveRowFromExcel = (rowIndex: any) => {
    const updatedExcelData = [...excelData];
    updatedExcelData.splice(rowIndex, 1);
    setExcelData(updatedExcelData);
  };

  const handleAddRowFromExcel = async (rowData: any) => {
    const errors = validateExcelData([rowData]);
    const hasErrors = errors.some((error) => error.rowIndex === 0);

    if (hasErrors) {
      ToastWarning(
        "La ligne contient des erreurs. Corrigez-les avant d'ajouter."
      );
      return;
    }
    try {
      const newData: Annexe5Type = {
        typeIdentifiant: rowData[0],
        identifiant: rowData[1],
        categorie: rowData[2],
        name: rowData[3],
        activity: rowData[4],
        adresse: rowData[5],
        mntComprisTvaIs10: rowData[6],
        mntComprisTvaIs15: rowData[7],
        mntComprisTvaEntrepUnipers: rowData[8],
        mntComprisTvaAutreEntrep: rowData[9],
        retenuTitreTva: rowData[10],
        retenuOpere: rowData[11],

        netServi: rowData[12],
        declaration: declarationId,
      };

      await dispatch(createAnnexe5Api(newData));

      setExcelData((prevExcelData) =>
        prevExcelData.filter((row) => row !== rowData)
      );
    } catch (error) {
      console.error("Addition of row from Excel failed", error);
    }
  };

  const exportToExcel = () => {
    const filteredAnnexesList = annexesList.map(
      ({ id, type, declaration, numOrdre, ...rest }) => rest
    );

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredAnnexesList);
    XLSX.utils.book_append_sheet(wb, ws, "Annexes");
    XLSX.writeFile(wb, "annexe5.xlsx");
  };

  const [showModal, setShowModal] = useState(false);

  const printRef = useRef<HTMLDivElement>(null);

  const handlePrint = () => {
    const printContents = printRef.current;

    if (printContents) {
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents.innerHTML;
      window.print();

      document.body.innerHTML = originalContents;

      window.location.reload();
    }
  };

  const beneficiaireList = useAppSelector(BeneficiairesList);

  const identifiantBeneficiaire = watch("identifiant");
  const typeIdentifiant = watch("typeIdentifiant");

  useEffect(() => {
    if (identifiantBeneficiaire) {
      const beneficiaire = beneficiaireList.find(
        (b) =>
          b.identifiant === identifiantBeneficiaire &&
          b.typeIdentifiant == typeIdentifiant
      );

      if (beneficiaire) {
        setValue("name", beneficiaire.name);
        setValue("activity", beneficiaire.activity);
        setValue("adresse", beneficiaire.adresse);
      }
    }
  }, [identifiantBeneficiaire, setValue, beneficiaireList]);

  // gestion des doublons
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [duplicateIndex, setDuplicateIndex] = useState<number | null>(null);

  const [showTypeErrorModal, setShowTypeErrorModal] = useState(false); // Pour contrôler le modal de type d'identifiant

  const checkForDuplicates = (identifiant: string, typeIdentifiant: number) => {
    const index = annexesList.findIndex(
      (annexe) => annexe.identifiant === identifiant
    );

    if (index !== -1) {
      setDuplicateIndex(index);

      setShowDuplicateModal(true);
    } else {
      setDuplicateIndex(null);
      setShowDuplicateModal(false);
      setShowTypeErrorModal(false);
    }
  };

  const handleContinue = () => {
    if (duplicateIndex != null) {
      const existingType = annexesList[duplicateIndex].typeIdentifiant;

      if (existingType != typeIdentifiant) {
        setShowTypeErrorModal(true);

        setShowDuplicateModal(false);
      } else {
        setShowDuplicateModal(false);
      }
    }
  };

  const handleEdit = () => {
    if (duplicateIndex != null) {
      handleEditClick(duplicateIndex);
      setSearchQuery(annexesList[duplicateIndex].identifiant); // Mettre à jour la recherche avec l'identifiant modifié

      reset();
      setShowDuplicateModal(false);
    }
  };

  // recherche
  const [searchQuery, setSearchQuery] = useState("");

  const filteredAnnexes = annexesList.filter((annexe) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      annexe.identifiant.toLowerCase().includes(lowerCaseQuery) ||
      annexe.typeIdentifiant.toString().includes(lowerCaseQuery) ||
      annexe.categorie.toString().includes(lowerCaseQuery) ||
      annexe.name.toLowerCase().includes(lowerCaseQuery) ||
      annexe.activity.toLowerCase().includes(lowerCaseQuery) ||
      annexe.adresse.toLowerCase().includes(lowerCaseQuery) ||
      annexe.mntComprisTvaIs10?.toString().includes(lowerCaseQuery) ||
      annexe.mntComprisTvaIs15?.toString().includes(lowerCaseQuery) ||
      annexe.mntComprisTvaEntrepUnipers?.toString().includes(lowerCaseQuery) ||
      annexe.mntComprisTvaAutreEntrep?.toString().includes(lowerCaseQuery) ||
      annexe.retenuTitreTva?.toString().includes(lowerCaseQuery) ||
      annexe.retenuOpere?.toString().includes(lowerCaseQuery) ||
      annexe.netServi?.toString().includes(lowerCaseQuery)
    );
  });

  // validate excel

  type ExcelDataRow = (string | number | null | undefined)[];

  type ExcelError = {
    rowIndex: number;
    cellIndices: number[];
  };

  // Fonction de validation pour les données Excel
  const validateExcelData = (data: ExcelDataRow[]): ExcelError[] => {
    const errors: ExcelError[] = [];

    data.forEach((row, rowIndex) => {
      const errorIndices: number[] = [];
      row.forEach((cell, cellIndex) => {
        let hasError = false;
        let value = String(cell);

        switch (cellIndex) {
          case 0: // Type d'identifiant
            const validIdentifiantTypes = [1, 2, 3, 4];
            if (!validIdentifiantTypes.includes(Number(value))) {
              hasError = true;
            }
            break;

          case 1: // Identifiant
            const typeIdentifiant = Number(row[0]);
            if (
              typeIdentifiant === 1 &&
              (value.length !== 13 || !MatriculFiscalValidate(value))
            ) {
              hasError = true;
            } else if (typeIdentifiant === 2) {
              const digitsOnly = value.replace(/\D/g, "");
              if (digitsOnly.length !== 8) {
                hasError = true;
              }
            } else if (
              ([3, 4].includes(typeIdentifiant) && value.length < 8) ||
              value.length > 13
            ) {
              hasError = true;
            }
            break;

          case 2: // Catégorie
            if (![1, 2].includes(Number(value))) {
              hasError = true;
            }
            break;

          case 3: // Nom
            if (value.length > 40) {
              hasError = true;
            }
            break;
          case 4: // Activité
            if (value.length > 40) {
              hasError = true;
            }
            break;

          case 5: // Adresse
            if (value.length > 120) {
              hasError = true;
            }
            break;

          case 6: // mntComprisTvaIs10: :
          case 7: // mntComprisTvaIs15: :
          case 8: // mntComprisTvaEntrepUnipers:
          case 9: //mntComprisTvaAutreEntrep:
          case 10: //retenuTitreTva:
          case 11: //retenuOpere:
          case 12: // netServi
            value = value.replace(/[^\d.]/g, "");
            const parts = value.split(".");
            if (parts.length > 2 || (parts[1] && parts[1].length > 3)) {
              hasError = true;
            } else {
              const numericValue = parseFloat(value);
              if (isNaN(numericValue) || numericValue < 0) {
                hasError = true;
              }
            }
            break;
        }

        if (hasError) {
          errorIndices.push(cellIndex);
        }
      });

      if (errorIndices.length > 0) {
        errors.push({ rowIndex, cellIndices: errorIndices });
      }
    });

    return errors;
  };

  const [excelErrors, setExcelErrors] = useState<ExcelError[]>([]);

  const handleExcelInputChange = (
    rowIndex: number,
    cellIndex: number,
    newValue: string
  ) => {
    const updatedExcelData = [...excelData];
    updatedExcelData[rowIndex][cellIndex] = newValue;

    setExcelData(updatedExcelData);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result;
      if (result instanceof ArrayBuffer) {
        const data = new Uint8Array(result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const rawData = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
        }) as ExcelDataRow[];

        const validatedData = rawData.slice(1); // Retirer les en-têtes
        setExcelData(validatedData);
        setExcelErrors(validateExcelData(validatedData));
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const isExcelLoaded = excelData.length > 0;

  const validateExcel = () => {
    const errors = validateExcelData(excelData);
    setExcelErrors(errors);

    if (errors.length === 0) {
      console.log("Données Excel valides.");
    } else {
      console.log("Des erreurs existent dans les données Excel.");
    }
  };

  // update

  const [rowErrors, setRowErrors] = useState<{
    [key: number]: { [key: string]: boolean };
  }>({});

  const handleInputChange = (
    e: FormControlChangeEvent,
    fieldName: string,
    rowIndex: number
  ) => {
    const { value } = e.target;

    const hasError = validateField(fieldName, value, rowIndex);

    if (hasError && editableRows.includes(rowIndex)) {
      setRowError(rowIndex, fieldName, true);
    } else if (editableRows.includes(rowIndex)) {
      setRowError(rowIndex, fieldName, false);
    }

    setEditedValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  const validateField = (
    fieldName: string,
    value: string,
    rowIndex: number
  ): boolean => {
    let hasError = false;

    switch (fieldName) {
      case "typeIdentifiant":
        const validIdentifiantTypes = [1, 2, 3, 4];
        if (!validIdentifiantTypes.includes(Number(value))) {
          hasError = true;
        }
        break;

      case "identifiant":
        const typeIdentifiant = Number(editedValues.typeIdentifiant);
        if (
          typeIdentifiant === 1 &&
          (value.length !== 13 || !MatriculFiscalValidate(value))
        ) {
          hasError = true;
        } else if (typeIdentifiant === 2) {
          const digitsOnly = value.replace(/\D/g, "");
          if (digitsOnly.length !== 8) {
            hasError = true;
          }
        } else if (
          [3, 4].includes(typeIdentifiant) &&
          (value.length < 8 || value.length > 13)
        ) {
          hasError = true;
        }
        break;

      case "categorie":
        if (![1, 2].includes(Number(value))) {
          hasError = true;
        }
        break;

      case "name":
        if (value.length > 40) {
          hasError = true;
        }
        break;

      case "activity":
        if (value.length > 40) {
          hasError = true;
        }
        break;

      case "adresse":
        if (value.length > 120) {
          hasError = true;
        }
        break;

      case "mntComprisTvaIs10":
      case "mntComprisTvaIs15":
      case "mntComprisTvaEntrepUnipers":
      case "mntComprisTvaAutreEntrep":
      case "retenuTitreTva":
      case "retenuOpere":
      case "netServi":
        value = value.replace(/[^\d.]/g, ""); // Ne conserve que les chiffres et les points
        const parts = value.split(".");
        if (parts.length > 2 || (parts[1] && parts[1].length > 3)) {
          hasError = true; // Vérifie si le format du montant est valide (maximum un point et trois chiffres après le point)
        } else {
          const numericValue = parseFloat(value);
          if (isNaN(numericValue) || numericValue < 0) {
            hasError = true; // Vérifie si la valeur est un nombre valide et positif
          }
        }
        break;

      default:
        break;
    }

    return hasError;
  };

  const setRowError = (
    rowIndex: number,
    fieldName: string,
    hasError: boolean
  ) => {
    setRowErrors((prevRowErrors) => ({
      ...prevRowErrors,
      [rowIndex]: {
        ...prevRowErrors[rowIndex],
        [fieldName]: hasError,
      },
    }));
  };

  const handleEditClick = (rowIndex: number) => {
    const rowHasErrors = Object.values(rowErrors[rowIndex] || {}).some(
      (error) => error
    );

    if (rowHasErrors) {
      ToastWarning("Veuillez corriger les erreurs avant de continuer.");

      return;
    }

    const valuesToEdit: Partial<Annexe5Type> = annexesList[rowIndex] || {};

    setEditedValues(valuesToEdit);

    if (editableRows.includes(rowIndex)) {
      handleUpdateRow(rowIndex);
    }

    setEditableRows((prevEditableRows) =>
      prevEditableRows.includes(rowIndex)
        ? prevEditableRows.filter((row) => row !== rowIndex)
        : [...prevEditableRows, rowIndex]
    );
  };

  return (
    <div
      style={{
        overflowX: "auto",
        width: "100%",
        scrollbarWidth: "thin",
        scrollbarColor: "rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1)",
      }}
    >
      <div style={{ display: "flex", marginTop: "10px" }}>
        <div style={{ flex: 0.7, marginRight: "5px", paddingRight: "20px" }}>
          <p
            style={{
              textAlign: "justify",
              lineHeight: "1.5",
              margin: "10px 0",
            }}
          >
            Autres montants payés et soumis à la retenue à la source.trôles
            connexes ou d’autres services pour une période ne dépassant pas 6
            mois.{" "}
          </p>
        </div>

        <div
          style={{
            flex: 0.3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              height: "32px",
            }}
            onClick={handlePrint}
            variant="info"
          >
            Imprimer
          </Button>
          <div ref={printRef} style={{ display: "none" }}>
            <ImpressionAnnexe5 annexesList={annexesList} />
          </div>
          <Button
            variant="light"
            onClick={exportToExcel}
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              height: "32px",
            }}
          >
            Save as Excel
            <i
              className="fas fa-download"
              style={{ color: "green", fontSize: "18px", marginLeft: "10px" }}
            ></i>
          </Button>

          <Button
            variant="light"
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "32px",
            }}
          >
            <label
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                color: "black",
              }}
            >
              <input
                type="file"
                accept=".xls,.xlsx"
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
              Import Excel
              <i
                className="far fa-file-excel"
                style={{ color: "green", fontSize: "18px", marginLeft: "10px" }}
              ></i>
            </label>
          </Button>
        </div>
      </div>
      <div
        style={{
          marginTop: "10px",
          padding: "10px",
          display: "flex",
          justifyContent: "space-between", // Les éléments se répartissent entre début et fin
          alignItems: "center", // Pour aligner verticalement
        }}
      >
        <span style={{ fontWeight: "bold", fontSize: "1.1em", color: "black" }}>
          {`Nombre total de bénéficiaires : ${annexesList.length}`}
        </span>

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>
            {isExcelLoaded && (
              <Button
                variant="success"
                onClick={validateExcel}
                style={{
                  marginRight: "15px",
                  height: "30px",
                  fontSize: "0.8em",
                  marginBottom: "7px",
                }}
              >
                Valider l'import
              </Button>
            )}
          </span>

          <Form.Control
            type="text"
            placeholder="Rechercher..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{
              width: "200px",
              height: "30px",
              textAlign: "center",
            }}
          />
        </div>
      </div>
      <Form onKeyDown={handleTabulation} onSubmit={handleSubmit(onSubmit)}>
        <Table bordered striped hover className="table-responsive">
          <thead>
            <tr>
              <FixedNewColumnCell>
                <div
                  style={{
                    width: "30px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  N°
                </div>
              </FixedNewColumnCell>
              <FixedHeaderCell>
                <div
                  style={{
                    width: "70px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Type ID
                </div>
              </FixedHeaderCell>
              <FixedSecondHeaderCell>
                <div
                  style={{
                    width: "140px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Identifiant
                </div>
              </FixedSecondHeaderCell>
              <FixedThirdHeaderCell>
                <div
                  style={{
                    width: "130px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Catégorie
                </div>
              </FixedThirdHeaderCell>
              <FixedFourHeaderCell>
                {" "}
                <div
                  style={{
                    width: "140px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Nom ou raison soc
                </div>
              </FixedFourHeaderCell>

              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "140px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Activité
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "140px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Adresse
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "170px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Mnt TVA IS 10%
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "170px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Mnt TVA IS 15%
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "200px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Mnt TVA Entreprises Unipers
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "200px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Mnt TVA Autres Entreprises
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0", fontSize: "0.75em" }}>
                <div style={{ width: "150px", display: "inline-block" }}>
                  Retenu Titre TVA
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0", fontSize: "0.75em" }}>
                <div style={{ width: "150px", display: "inline-block" }}>
                  Retenu Opérée
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0", fontSize: "0.75em" }}>
                <div style={{ width: "130px", display: "inline-block" }}>
                  Net Servi
                </div>
              </th>
              <th style={{ padding: "4px", margin: "0" }}>
                <div
                  style={{
                    width: "130px",
                    display: "inline-block",
                    fontSize: "0.75em",
                  }}
                >
                  Action
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredAnnexes.map((annexe5: Annexe5Type, rowIndex) => (
              <tr
                key={annexe5.id}
                onDoubleClick={() => handleEditClick(rowIndex)}
              >
                <FixedNewDataCell
                  style={{ padding: "4px", margin: "0", textAlign: "center" }}
                >
                  {rowIndex + 1}
                </FixedNewDataCell>
                <FixedDataCell>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.typeIdentifiant
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    as="select"
                    name="typeIdentifiant"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.typeIdentifiant !== undefined
                          ? editedValues.typeIdentifiant
                          : annexe5.typeIdentifiant
                        : annexe5.typeIdentifiant
                    }
                    onChange={(e) =>
                      handleInputChange(e, "typeIdentifiant", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  >
                    <option value={1}>1 : MF</option>
                    <option value={2}>2 : CIN</option>
                    <option value={3}>3 : CS</option>
                    <option value={4}>4 : IDE</option>
                  </Form.Control>
                </FixedDataCell>
                <FixedSecondDataCell>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.identifiant
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="text"
                    name="identifiant"
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.identifiant !== undefined
                          ? editedValues.identifiant
                          : annexe5.identifiant
                        : annexe5.identifiant
                    }
                    onChange={(e) =>
                      handleInputChange(e, "identifiant", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </FixedSecondDataCell>
                <FixedThirdDataCell>
                  <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                        border: rowErrors[rowIndex]?.categorie
                          ? "2px solid red"
                          : "1px solid #ced4da",
                      }}
                    as="select"
                    name={`annexesList[${rowIndex}].categorie`}
                    value={annexe5.categorie}
                    onChange={(e) =>
                      handleInputChange(e, "categorie", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  >
                    <option value={1}>1 : Personne Morale</option>
                    <option value={2}>2 : Personne Physique</option>
                  </Form.Control>
                </FixedThirdDataCell>
                <FixedFourDataCell>
                  <Form.Control
                     style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.name
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="text"
                    name={`annexesList[${rowIndex}].name`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.name !== undefined
                          ? editedValues.name
                          : annexe5.name
                        : annexe5.name
                    }
                    onChange={(e) => handleInputChange(e, "name", rowIndex)}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </FixedFourDataCell>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                     style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.activity
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="text"
                    name={`annexesList[${rowIndex}].activity`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.activity !== undefined
                          ? editedValues.activity
                          : annexe5.activity
                        : annexe5.activity
                    }
                    onChange={(e) => handleInputChange(e, "activity", rowIndex)}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                     style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.adresse
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="text"
                    name={`annexesList[${rowIndex}].adresse`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.adresse !== undefined
                          ? editedValues.adresse
                          : annexe5.adresse
                        : annexe5.adresse
                    }
                    onChange={(e) => handleInputChange(e, "adresse", rowIndex)}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                     style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.mntComprisTvaIs10
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name={`annexesList[${rowIndex}].mntComprisTvaIs10`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.mntComprisTvaIs10 !== undefined
                          ? editedValues.mntComprisTvaIs10
                          : annexe5.mntComprisTvaIs10
                        : annexe5.mntComprisTvaIs10
                    }
                    onChange={(e) =>
                      handleInputChange(e, "mntComprisTvaIs10", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                        border: rowErrors[rowIndex]?.mntComprisTvaIs15
                          ? "2px solid red"
                          : "1px solid #ced4da",
                      }}
                    type="number"
                    name={`annexesList[${rowIndex}].mntComprisTvaIs15`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.mntComprisTvaIs15 !== undefined
                          ? editedValues.mntComprisTvaIs15
                          : annexe5.mntComprisTvaIs15
                        : annexe5.mntComprisTvaIs15
                    }
                    onChange={(e) =>
                      handleInputChange(e, "mntComprisTvaIs15", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                    style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.mntComprisTvaEntrepUnipers
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name={`annexesList[${rowIndex}].mntComprisTvaEntrepUnipers`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.mntComprisTvaEntrepUnipers !== undefined
                          ? editedValues.mntComprisTvaEntrepUnipers
                          : annexe5.mntComprisTvaEntrepUnipers
                        : annexe5.mntComprisTvaEntrepUnipers
                    }
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        "mntComprisTvaEntrepUnipers",
                        rowIndex
                      )
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                        border: rowErrors[rowIndex]?.mntComprisTvaEntrepUnipers
                          ? "2px solid red"
                          : "1px solid #ced4da",
                      }}
                    type="number"
                    name={`annexesList[${rowIndex}].mntComprisTvaAutreEntrep`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.mntComprisTvaAutreEntrep !== undefined
                          ? editedValues.mntComprisTvaAutreEntrep
                          : annexe5.mntComprisTvaAutreEntrep
                        : annexe5.mntComprisTvaAutreEntrep
                    }
                    onChange={(e) =>
                      handleInputChange(e, "mntComprisTvaAutreEntrep", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                      style={{
                        fontSize: "1em",
                        height: "33px",
                        border: rowErrors[rowIndex]?.retenuTitreTva
                          ? "2px solid red"
                          : "1px solid #ced4da",
                      }}
                    type="number"
                    name={`annexesList[${rowIndex}].retenuTitreTva`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.retenuTitreTva !== undefined
                          ? editedValues.retenuTitreTva
                          : annexe5.retenuTitreTva
                        : annexe5.retenuTitreTva
                    }
                    onChange={(e) =>
                      handleInputChange(e, "retenuTitreTva", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                     style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.retenuOpere
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name={`annexesList[${rowIndex}].retenuOpere`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.retenuOpere !== undefined
                          ? editedValues.retenuOpere
                          : annexe5.retenuOpere
                        : annexe5.retenuOpere
                    }
                    onChange={(e) =>
                      handleInputChange(e, "retenuOpere", rowIndex)
                    }
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>
                <td style={{ padding: "4px", margin: "0" }}>
                  <Form.Control
                     style={{
                      fontSize: "1em",
                      height: "33px",
                      border: rowErrors[rowIndex]?.netServi
                        ? "2px solid red"
                        : "1px solid #ced4da",
                    }}
                    type="number"
                    name={`annexesList[${rowIndex}].netServi`}
                    value={
                      editableRows.includes(rowIndex)
                        ? editedValues.netServi !== undefined
                          ? editedValues.netServi
                          : annexe5.netServi
                        : annexe5.netServi
                    }
                    onChange={(e) => handleInputChange(e, "netServi", rowIndex)}
                    disabled={!editableRows.includes(rowIndex)}
                  />
                </td>

                <td style={{ padding: "4px", margin: "0" }}>
                  <span
                    className={`action-icon ${
                      editableRows.includes(rowIndex) ? "success" : "warning"
                    }`}
                    onClick={() => handleEditClick(rowIndex)}
                    title={
                      editableRows.includes(rowIndex)
                        ? "Enregistrer"
                        : "Modifier"
                    }
                  >
                    <i
                      className={`fas ${
                        editableRows.includes(rowIndex) ? "fa-check" : "fa-edit"
                      }`}
                      style={{
                        color: editableRows.includes(rowIndex)
                          ? "#008000"
                          : "#0000ff",
                        marginRight: "8px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
                  <span
                    className={`action-icon danger`}
                    onClick={() => annexe5.id && handleDeleteRow(annexe5.id)}
                    title="Supprimer une ligne"
                  >
                    <i
                      className="far fa-trash-alt"
                      style={{
                        color: "#ff0000",
                        marginRight: "13px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
                </td>
              </tr>
            ))}

            {/* Ligne d'ajout */}

            {excelData.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                <FixedNewDataCell
                  style={{ padding: "4px", margin: "0", textAlign: "center" }}
                ></FixedNewDataCell>
                {/* Composants fixes pour les premières colonnes */}
                {[0, 1, 2, 3].map((cellIndex) => {
                  const hasError = excelErrors.some(
                    (error) =>
                      error.rowIndex === rowIndex &&
                      error.cellIndices.includes(cellIndex)
                  );

                  const borderStyle = hasError
                    ? "2px solid red"
                    : "1px solid grey";
                  const cellStyle = {
                    padding: "3px",
                    margin: "0",
                    border: borderStyle,
                  };

                  let CellComponent;
                  switch (cellIndex) {
                    case 0:
                      CellComponent = FixedDataCell;
                      break;
                    case 1:
                      CellComponent = FixedSecondDataCell;
                      break;
                    case 2:
                      CellComponent = FixedThirdDataCell;
                      break;
                    case 3:
                      CellComponent = FixedFourDataCell;
                      break;
                    default:
                      CellComponent = "td"; // Default case
                  }

                  return (
                    <CellComponent key={cellIndex}>
                      <Form.Control
                        style={{
                          fontSize: "1em",
                          height: "33px",
                          border: borderStyle,
                          borderRadius: "4px",
                        }}
                        type="text"
                        value={rowData[cellIndex]}
                        onChange={(e) =>
                          handleExcelInputChange(
                            rowIndex,
                            cellIndex,
                            e.target.value
                          )
                        }
                      />
                    </CellComponent>
                  );
                })}

                {/* Autres cellules */}
                {rowData.slice(4).map((cell: any, additionalCellIndex: any) => {
                  const actualCellIndex = additionalCellIndex + 4;
                  const hasError = excelErrors.some(
                    (error) =>
                      error.rowIndex === rowIndex &&
                      error.cellIndices.includes(actualCellIndex)
                  );

                  const borderStyle = hasError
                    ? "2px solid red"
                    : "1px solid grey";
                  const cellStyle = {
                    padding: "3px",
                    margin: "0",
                  };

                  return (
                    <td key={actualCellIndex} style={cellStyle}>
                      <Form.Control
                        style={{
                          fontSize: "1em",
                          height: "33px",
                          border: borderStyle,
                          borderRadius: "4px",
                        }}
                        type="text"
                        value={String(cell)}
                        onChange={(e) =>
                          handleExcelInputChange(
                            rowIndex,
                            actualCellIndex,
                            e.target.value
                          )
                        }
                      />
                    </td>
                  );
                })}

                {/* Actions pour ajouter/supprimer */}
                <td style={{ padding: "4px", margin: "0" }}>
                  <Button
                    variant="primary"
                    onClick={() => handleAddRowFromExcel(rowData)}
                  >
                    Ajouter
                  </Button>
                  <span
                    className="action-icon danger"
                    onClick={() => handleRemoveRowFromExcel(rowIndex)}
                    title="Retirer cette ligne"
                  >
                    <i
                      className="fas fa-times"
                      style={{
                        color: "red",
                        marginLeft: "10px",
                        fontSize: "30px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
                </td>
              </tr>
            ))}

            <tr>
              <FixedNewDataCell
                style={{ padding: "4px", margin: "0", textAlign: "center" }}
              ></FixedNewDataCell>
              <FixedDataCell>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  as="select"
                  {...register("typeIdentifiant")}
                  onChange={(e) => {
                    const selectedValue = parseInt(e.target.value, 10);
                    setValue("typeIdentifiant", selectedValue);
                    if (selectedValue === 2 || selectedValue === 3) {
                      setValue("categorie", 2);
                    }
                  }}
                >
                  <option value={""}></option>
                  <option value={1}>1 : MF</option>
                  <option value={2}>2 : CIN</option>
                  <option value={3}>3 : CS</option>
                  <option value={4}>4 : IDE</option>
                </Form.Control>
              </FixedDataCell>
              <FixedSecondDataCell>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="identifiant"
                  onChange={(e) => {
                    let value = e.target.value;
                    const typeIdentifiant = +watch("typeIdentifiant");
                    const identifiant = e.target.value;
                    checkForDuplicates(identifiant, typeIdentifiant);

                    let errorMessage = "";

                    if (typeIdentifiant === 1) {
                      const truncatedValue = value.slice(0, 13);
                      if (!MatriculFiscalValidate(truncatedValue)) {
                        errorMessage = "L'identifiant MF n'est pas valide";
                      } else if (truncatedValue.length !== 13) {
                        errorMessage =
                          "La longueur de l'identifiant MF doit être de 13 caractères";
                      }
                    } else if (typeIdentifiant === 2) {
                      const digitsOnly = value.replace(/\D/g, "");
                      const truncatedValue = digitsOnly.slice(0, 8);
                      value = truncatedValue;
                      if (truncatedValue.length !== 8) {
                        errorMessage =
                          "Le numéro CIN doit contenir exactement 8 chiffres";
                      }
                    } else if (typeIdentifiant === 3) {
                      const truncatedValue = value.slice(0, 13);
                      value = truncatedValue;
                    }

                    if (errorMessage) {
                      setError("identifiant", {
                        type: "manual",
                        message: errorMessage,
                      });
                    } else {
                      clearErrors("identifiant");
                    }

                    e.target.value = value;
                    setValue("identifiant", value);
                  }}
                  minLength={watch("typeIdentifiant") === 1 ? 13 : 8}
                  maxLength={watch("typeIdentifiant") === 1 ? 13 : 13}
                  isInvalid={!!errors.identifiant}
                />

                {errors.identifiant && (
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ fontSize: "0.8rem" }}
                  >
                    {errors.identifiant.message}
                  </Form.Control.Feedback>
                )}
              </FixedSecondDataCell>
              <FixedThirdDataCell>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  as="select"
                  {...register("categorie")}
                  defaultValue={""}
                  disabled={
                    watch("typeIdentifiant") == 2 ||
                    watch("typeIdentifiant") == 3
                  }
                  onChange={(e) =>
                    setValue("categorie", parseInt(e.target.value))
                  }
                >
                  <option value={""}></option>
                  <option value={1}>1 : Personne morale</option>
                  <option value={2}>2 : Personne physique</option>
                </Form.Control>
              </FixedThirdDataCell>
              <FixedFourDataCell>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="name"
                  className={errors.name ? "is-invalid" : ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    const truncatedValue = value.slice(0, 40);
                    e.target.value = truncatedValue;
                    setValue("name", truncatedValue);
                  }}
                  value={watch("name") || ""}
                />
              </FixedFourDataCell>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="activity"
                  className={errors.activity ? "is-invalid" : ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    const truncatedValue = value.slice(0, 40);
                    setValue("activity", truncatedValue);
                  }}
                  value={watch("activity") || ""}
                />
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="adresse"
                  className={errors.adresse ? "is-invalid" : ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    const truncatedValue = value.slice(0, 120);
                    setValue("adresse", truncatedValue);
                  }}
                  value={watch("adresse") || ""}
                />
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="mntComprisTvaIs10"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("mntComprisTvaIs10", value);
                  }}
                />
                {errors.mntComprisTvaIs10 && (
                  <span style={{ color: "red" }}>
                    {errors.mntComprisTvaIs10.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="mntComprisTvaIs15"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("mntComprisTvaIs15", value);
                  }}
                />
                {errors.mntComprisTvaIs15 && (
                  <span style={{ color: "red" }}>
                    {errors.mntComprisTvaIs15.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="mntComprisTvaEntrepUnipers"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation(
                      "mntComprisTvaEntrepUnipers",
                      value
                    );
                  }}
                />
                {errors.mntComprisTvaEntrepUnipers && (
                  <span style={{ color: "red" }}>
                    {errors.mntComprisTvaEntrepUnipers.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="mntComprisTvaAutreEntrep"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation(
                      "mntComprisTvaAutreEntrep",
                      value
                    );
                  }}
                />
                {errors.mntComprisTvaAutreEntrep && (
                  <span style={{ color: "red" }}>
                    {errors.mntComprisTvaAutreEntrep.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="retenuTitreTva"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("retenuTitreTva", value);
                  }}
                />
                {errors.retenuTitreTva && (
                  <span style={{ color: "red" }}>
                    {errors.retenuTitreTva.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="retenuOpere"
                  placeholder="0.000"
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    e.target.value = value;
                    handleInputChangeAndValidation("retenuOpere", value);
                  }}
                />
                {errors.retenuOpere && (
                  <span style={{ color: "red" }}>
                    {errors.retenuOpere.message}
                  </span>
                )}
              </td>
              <td style={{ padding: "4px", margin: "0" }}>
                <Form.Control
                  style={{ fontSize: "1em", height: "33px" }}
                  type="text"
                  name="netServi"
                  value={netServiValue}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^\d.]/g, "");
                    const [integerPart, decimalPart] = value.split(".");
                    if (decimalPart && decimalPart.length > 3) {
                      value = `${integerPart}.${decimalPart.slice(0, 3)}`;
                    }
                    setNetServiValue(value);

                    handleInputChangeAndValidation("netServi", value);
                  }}
                />
                {errors.netServi && (
                  <span style={{ color: "red" }}>
                    {errors.netServi.message}
                  </span>
                )}
              </td>

              <td style={{ padding: "4px", margin: "0" }}>
                <Button variant="primary" type="submit">
                  Ajouter
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Form>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cet élément ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDuplicateModal}
        onHide={() => {
          setShowDuplicateModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Identifiant déjà utilisé</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Cet identifiant est déjà utilisé. Voulez-vous continuer ou modifier la
          ligne existante?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleContinue}>
            Continuer
          </Button>
          <Button variant="primary" onClick={handleEdit}>
            Modifier
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showTypeErrorModal}
        onHide={() => setShowTypeErrorModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Erreur de type d'identifiant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Le type d'identifiant ne correspond pas à la ligne existante. Veuillez
          vérifier votre saisie.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowTypeErrorModal(false)}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Annexe5Form;
