
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import { DeclarationType } from "../../../Types/Entites/DeclarationType";

// Action asynchrone pour créer une nouvelle déclaration
export const createDeclarationApi = createAsyncThunk(
    "declarations/createDeclaration",
    async (data: DeclarationType, { rejectWithValue }) => {
      try {
        const response = await axios.post("declaration/createDeclaration", data);
        return response.data;
      } catch (error: any) {
        console.error("Erreur API:", error);
        return rejectWithValue({
          success: false,
          message: error.response?.data?.message || "Erreur inconnue de l'API",
        });
      }
    }
  );
  

// Action asynchrone pour récupérer toutes les déclarations
export const findAllDeclarationsApi = createAsyncThunk(
  "declarations/findAllDeclarations",
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await axios.get("declaration/findAllDeclarations");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);


export const findDeclarationsByDeclarantApi = createAsyncThunk(
  "declarations/findDeclarationsByDeclarant",
  async (declarantId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(`declaration/findDeclarationsByDeclarant/${declarantId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);


// Action asynchrone pour mettre à jour une déclaration
export const updateDeclarationApi = createAsyncThunk(
  "declarations/updateDeclaration",
  async ({ id, data }: { id: number; data: DeclarationType }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`declaration/updateDeclaration/${id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour récupérer une déclaration par ID
export const getDeclarationByIdApi = createAsyncThunk(
  "declarations/getDeclarationById",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(`declaration/getDeclarationById/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour supprimer une déclaration
export const deleteDeclarationApi = createAsyncThunk(
  "declarations/deleteDeclaration",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`declaration/deleteDeclaration/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface Declarations {
  declarationsList: DeclarationType[];
  declarationBySocieteList: DeclarationType[];

}

const initialState: Declarations = {
  declarationsList: [],
  declarationBySocieteList: [],
  

};

export const declarationSlice = createSlice({
  name: "declarations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createDeclarationApi.fulfilled, (state, action) => {
      state.declarationsList.push(action.payload.data);
      state.declarationBySocieteList.push(action.payload.data);

    });
    builder.addCase(findAllDeclarationsApi.fulfilled, (state, action) => {
      return {
        ...state,
        declarationsList: action.payload,
      };
    });
    builder.addCase(updateDeclarationApi.fulfilled, (state, action) => {
      const updatedIndex = state.declarationBySocieteList.findIndex(
        (declaration) => declaration.id === action.payload.data.id
      );
      if (updatedIndex !== -1) {
        state.declarationsList[updatedIndex] = action.payload.data;
        state.declarationBySocieteList[updatedIndex] = action.payload.data;

      }
    });
    builder.addCase(getDeclarationByIdApi.fulfilled, (state, action) => {
      return {
        ...state,
        declarationsList: [action.payload],
      };
    });
    builder.addCase(deleteDeclarationApi.fulfilled, (state, action) => {
      state.declarationsList = state.declarationsList.filter(declaration => declaration.id !== action.meta.arg);
      state.declarationBySocieteList = state.declarationBySocieteList.filter(declaration => declaration.id !== action.meta.arg);

    });
    builder.addCase(findDeclarationsByDeclarantApi.fulfilled, (state, action) => {
      return {
        ...state,
        declarationBySocieteList: action.payload,
      };
    });
    
  },
});

export const declarationBySocieteList = (state: RootState) => state.declarations.declarationBySocieteList;
export const declarationsList = (state: RootState) => state.declarations.declarationsList;

export default declarationSlice.reducer;
