import * as yup from 'yup';

export const LoginSchema = yup.object({
  email: yup
    .string()
    .email('Veuillez entrer une adresse e-mail valide') 
    .required("L'adresse e-mail est obligatoire"), 
  password: yup
    .string()
    .min(6, "Le mot de passe doit contenir au moins 6 caractères") 
    .required("Le mot de passe est obligatoire"), 
}).required(); 
