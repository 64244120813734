import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Annexe1Type } from "../../../Types/Entites/Annexe1Type";
import { RootState } from "../../store";

// Action asynchrone pour créer une nouvelle annexe1
export const createAnnexe1Api = createAsyncThunk(
  "annexe1/createAnnexe1",
  async (data: Annexe1Type, { rejectWithValue }) => {
    try {
      const response = await axios.post("annexe1/createAnnexe1", data);
      console.log("Réponse de l'API:", response.data);
      return response.data;
    } catch (error: any) {
      console.error("Erreur API:", error);
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

// Action asynchrone pour récupérer toutes les annexe1
export const findAllAnnexe1Api = createAsyncThunk(
  "annexe1/findAllAnnexe1",
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await axios.get("annexe1/findAllAnnexes1");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour mettre à jour une annexe1
export const updateAnnexe1Api = createAsyncThunk(
  "annexe1/updateAnnexe1",
  async (
    { id, data }: { id: number; data: Annexe1Type },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(`annexe1/updateAnnexe1/${id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour récupérer une annexe1 par ID
export const getAnnexe1ByIdApi = createAsyncThunk(
  "annexe1/getAnnexe1ById",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(`annexe1/getAnnexe1ById/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Action asynchrone pour supprimer une annexe1
export const deleteAnnexe1Api = createAsyncThunk(
  "annexe1/deleteAnnexe1",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`annexe1/deleteAnnexe1/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findAnnexes1ByDeclarationApi = createAsyncThunk(
  "annexe1/findAnnexes1ByDeclaration",
  async (declarationId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `annexe1/findAnnexes1ByDeclaration/${declarationId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface Annexe1s {
  annexe1List: Annexe1Type[];
  annexe1ListByDeclaration: Annexe1Type[];
}

const initialState: Annexe1s = {
  annexe1List: [],
  annexe1ListByDeclaration: [],
};

export const annexe1Slice = createSlice({
  name: "annexe1",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAnnexe1Api.fulfilled, (state, action) => {
      state.annexe1List.push(action.payload.data);
      state.annexe1ListByDeclaration.push(action.payload.data);
    });
    builder.addCase(findAllAnnexe1Api.fulfilled, (state, action) => {
      return {
        ...state,
        annexe1List: action.payload,
      };
    });
    builder.addCase(updateAnnexe1Api.fulfilled, (state, action) => {
      const updatedIndex = state.annexe1List.findIndex(
        (annexe1) => annexe1.id === action.payload.data.id
      );

      if (updatedIndex !== -1) {
        state.annexe1List[updatedIndex] = action.payload.data;
      }

      const updatedDeclarationIndex = state.annexe1ListByDeclaration.findIndex(
        (annexe1) => annexe1.id === action.payload.data.id
      );

      if (updatedDeclarationIndex !== -1) {
        state.annexe1ListByDeclaration[updatedDeclarationIndex] =
          action.payload.data;
      }
    });
    builder.addCase(getAnnexe1ByIdApi.fulfilled, (state, action) => {
      return {
        ...state,
        annexe1List: [action.payload],
      };
    });
    builder.addCase(deleteAnnexe1Api.fulfilled, (state, action) => {
      state.annexe1List = state.annexe1List.filter(annexe1 => annexe1.id !== action.meta.arg);
      state.annexe1ListByDeclaration = state.annexe1ListByDeclaration.filter(annexe1 => annexe1.id !== action.meta.arg);


    });
    
    
    

    builder.addCase(findAnnexes1ByDeclarationApi.fulfilled, (state, action) => {
      return {
        ...state,
        annexe1ListByDeclaration: action.payload,
      };
    });
  },
});

export const annexe1List = (state: RootState) => state.annexe1.annexe1List;
export const annexe1ListByDeclaration = (state: RootState) =>
  state.annexe1.annexe1ListByDeclaration;
export default annexe1Slice.reducer;
