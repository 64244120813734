import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  getEnteteCertificatByNumCertApi,
  selectCurrentEnteteCertificat,
} from "../../_App/Redux/Slices/EnteteCertificat/EnteteCertificatSlice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  LignesCertificatByCertificatId,
  getLigneCertificatByCertificatIdApi,
} from "../../_App/Redux/Slices/LigneCertificat/LigneCertificatSlice";

interface ImpressionCetificatProps {
  numCertificat: string;
}

const ImpressionCertificat: React.FC<ImpressionCetificatProps> = ({
  numCertificat,
}) => {
  const dispatch = useAppDispatch();
  const currentCertificat = useAppSelector(selectCurrentEnteteCertificat);
  const ligneCertificat = useAppSelector(LignesCertificatByCertificatId);



  

  useEffect(() => {
    if (numCertificat) {
      dispatch(getEnteteCertificatByNumCertApi(numCertificat));
      dispatch(getLigneCertificatByCertificatIdApi(numCertificat));
    }
  }, [numCertificat, dispatch]);

  const downloadPDF = () => {
    const input = document.getElementById("retenue");
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("Certificat_retenue.pdf");
      });
    }
  };

  const calculateTotals = () => {
    let montantTotal = 0;
    let retenueTotal = 0;
    let netServiTotal = 0;
  
    ligneCertificat.forEach((item) => {
      montantTotal += item.montant;
      retenueTotal += item.mntRetenue;
      netServiTotal += item.netServi;
      if (item.additionalTax) {
        netServiTotal -= item.montantTaxeAdd || 0;
      }
    });
  
    return {
      montantTotal,
      retenueTotal,
      netServiTotal,
    };
  };
  
  const { montantTotal, retenueTotal, netServiTotal } = calculateTotals();
  

  const printContent = () => {
    const contentElement = document.getElementById("retenue");
    if (contentElement) {
      const content = contentElement.innerHTML;
      const originalContent = document.body.innerHTML;
      document.body.innerHTML = content;
      window.print();
      document.body.innerHTML = originalContent;
      window.location.reload();
    } else {
      console.error("Element with id 'retenue' not found.");
    }
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <span
          style={{
            fontSize: "40px",
            color: "blue",
            marginRight: "10px",
            cursor: "pointer",
          }}
          onClick={printContent}
          title="Imprimer"
        >
          <i className="fas fa-print"></i>
        </span>
        <span
          style={{
            fontSize: "40px",
            color: "red",
            marginRight: "10px",
            cursor: "pointer",
          }}
          onClick={downloadPDF}
          title="Sauvegarder en PDF"
        >
          <i className="far fa-file-pdf"></i>
        </span>
      </div>
      <div className="container mt-5" id="retenue">
        <div className="row">
          <div className="col-4 text-center">
            <div className="row">
              <p
                style={{
                  fontFamily: "Times New Roman",
                  color: "black",
                  fontSize: "20px",
                }}
              >
                REPUBLIQUE TUNISIENNE
              </p>
            </div>
            <div className="row">
              <p
                style={{
                  fontFamily: "Times New Roman",
                  color: "black",
                  fontSize: "20px",
                }}
              >
                MINISTERE DU PLAN ET DES FINANCES
              </p>
            </div>
            <div className="row">
              <strong
                style={{ fontFamily: "Times New Roman", fontSize: "20px" }}
              >
                DIRECTION GENERALE
              </strong>
            </div>
            <div className="row">
              <strong
                style={{ fontFamily: "Times New Roman", fontSize: "20px" }}
              >
                DU CONTROLE FISCAL
              </strong>
            </div>
          </div>

          <div style={{ paddingLeft: "200px" }}>
            <div className="text-center">
              <strong
                style={{
                  fontFamily: "Times New Roman",
                  fontSize: "30px",
                  display: "block",
                }}
              >
                CERTIFICAT DE RETENUE D'IMPOT
              </strong>
              <strong
                style={{
                  fontFamily: "Times New Roman",
                  fontSize: "30px",
                  display: "block",
                }}
              >
                SUR LE REVENU
              </strong>
              <strong
                style={{
                  fontFamily: "Times New Roman",
                  fontSize: "30px",
                  display: "block",
                }}
              >
                OU D'IMPOT SUR LES SOCIETES
              </strong>
              <div
                style={{
                  marginLeft: "430px",
                  marginTop: "25px",
                  marginBottom: "15px",
                }}
              >
                <p
                  className="row "
                  style={{ fontFamily: "Times New Roman", color: "black" }}
                >
                  Retenue effectuée le{" "}
                  {currentCertificat?.date
                    ? new Date(currentCertificat.date).toLocaleDateString(
                        "fr-FR"
                      )
                    : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div style={{ border: "1px solid black" }}>
          <div className="row">
            <div className="col-6">
              <strong
                style={{ fontFamily: "Times New Roman", marginLeft: "3px" }}
              >
                {" "}
                A-PERSONE OU ORGANISME PAYEUR
              </strong>
            </div>
            <div className="col-6">
              <div className="row center">
                <strong
                  style={{ fontFamily: "Times New Roman", marginLeft: "248px" }}
                >
                  IDENTIFIANT
                </strong>
              </div>
              <div className="row center">
                <table
                  style={{
                    borderCollapse: "collapse",
                    borderColor: "black",
                    maxWidth: "648px",
                    border: "2px solid black",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          border: "2px solid black",
                          padding: "10px 15px",
                          fontFamily: "Times New Roman",
                        }}
                      >
                        Matricule fiscal
                      </th>
                      <th
                        style={{
                          border: "2px solid black",
                          padding: "10px 15px",
                          fontFamily: "Times New Roman",
                        }}
                      >
                        Code T.V.A
                      </th>
                      <th
                        style={{
                          border: "2px solid black",
                          padding: "10px 15px",
                          fontFamily: "Times New Roman",
                        }}
                      >
                        Code catég(2)
                      </th>
                      <th
                        style={{
                          border: "2px solid black",
                          padding: "10px 15px",
                          fontFamily: "Times New Roman",
                        }}
                      >
                        N°Etab Second
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          border: "2px solid black",
                          padding: "10px 15px",
                          fontFamily: "Times New Roman",
                        }}
                      >
                        <strong className="center">
                          {currentCertificat?.Societe?.matriculeFiscale?.substring(
                            0,
                            8
                          )}
                        </strong>
                      </td>
                      <td
                        style={{
                          border: "2px solid black",
                          padding: "10px 15px",
                          fontFamily: "Times New Roman",
                        }}
                      >
                        <strong className="center">
                          {currentCertificat?.Societe?.matriculeFiscale?.charAt(
                            8
                          )}
                        </strong>
                      </td>
                      <td
                        style={{
                          border: "2px solid black",
                          padding: "10px 15px",
                          fontFamily: "Times New Roman",
                        }}
                      >
                        <strong className="center">
                          {currentCertificat?.Societe?.matriculeFiscale?.charAt(
                            9
                          )}
                        </strong>
                      </td>
                      <td
                        style={{
                          border: "2px solid black",
                          padding: "10px 15px",
                          fontFamily: "Times New Roman",
                        }}
                      >
                        <strong className="center">
                          {currentCertificat?.Societe?.matriculeFiscale?.substring(
                            10,
                            13
                          )}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* FIN A-PERSONE OU ORGANISME PAYEUR */}
          <div className="row mt-5">
            <div className="col-4">
              <p
                style={{
                  fontFamily: "Times New Roman",
                  marginLeft: "3px",
                  color: "black",
                }}
              >
                Nomination de la personne ou l'organisme payeur :
              </p>
            </div>
            <div className="col-4">
              <strong style={{ fontFamily: "Times New Roman" }}>
                {currentCertificat?.Societe.name}
              </strong>
            </div>
          </div>
          <div className="row">
            <div className="col-1">
              <p
                style={{
                  fontFamily: "Times New Roman",
                  marginLeft: "3px",
                  color: "black",
                }}
              >
                Adresse :
              </p>
            </div>
            <div className="col-9">
              <strong style={{ fontFamily: "Times New Roman" }}>
                {currentCertificat?.Societe.num}{" "}
                {currentCertificat?.Societe.rue}{" "}
                {currentCertificat?.Societe.ville}{" "}
                {currentCertificat?.Societe.codePostal}
              </strong>
            </div>
          </div>
        </div>

        <table style={{ borderCollapse: "collapse", borderColor: "black" }}>
          <thead>
            <tr>
              <th
                style={{
                  border: "0.5px solid black",
                  padding: "1px 15px",
                  fontFamily: "Times New Roman",
                  width: "1000px",
                }}
              >
                B- RETENUES EFFECTUEES SUR
              </th>
              <th
                style={{
                  border: "0.5px solid black",
                  padding: "10px 15px",
                  fontFamily: "Times New Roman",
                  width: "200px",
                }}
              >
                MONTANT BRUT
              </th>
              <th
                style={{
                  border: "0.5px solid black",
                  padding: "10px 15px",
                  fontFamily: "Times New Roman",
                  width: "200px",
                }}
              >
                RETENUE
              </th>
              <th
                style={{
                  border: "0.5px solid black",
                  padding: "10px 15px",
                  fontFamily: "Times New Roman",
                  width: "200px",
                }}
              >
                MONTANT NET
              </th>
            </tr>
          </thead>
          <tbody>
          {ligneCertificat.map((item, index) => (
  <React.Fragment key={index}>
    <tr>
      <td
        style={{
          border: "0.5px solid black",
          padding: "10px 15px",
          fontFamily: "Times New Roman",
        }}
      >
        <strong className="center">
          {item.RetenueCertifParam?.designation || ""}
        </strong>
        <br />
        <p
          style={{
            fontFamily: "Times New Roman",
            color: "black",
          }}
        >
          {" "}
          Objet : {item.objet || ""}{" "}
        </p>
      </td>
      <td
        style={{
          border: "1px solid black",
          padding: "10px 15px",
          fontFamily: "Times New Roman",
        }}
      >
        <strong className="center">{item.montant}</strong>
      </td>
      <td
        style={{
          border: "1px solid black",
          padding: "10px 15px",
          fontFamily: "Times New Roman",
        }}
      >
        <strong className="center">{item.mntRetenue}</strong>
      </td>
      <td
        style={{
          border: "1px solid black",
          padding: "10px 15px",
          fontFamily: "Times New Roman",
        }}
      >
        <strong className="center">{item.netServi}</strong>
      </td>
    </tr>
    {item.additionalTax && (
      <tr>
        <td
          style={{
            border: "0.5px solid black",
            padding: "10px 15px",
            fontFamily: "Times New Roman",
          }}
        >
          <strong className="center">Taxe additionnelle : {item.codeTaxeAdd}</strong>
          <br />
          
        </td>
        <td
          style={{
            border: "1px solid black",
            padding: "10px 15px",
            fontFamily: "Times New Roman",
          }}
        >
          <strong className="center">{item.mntBaseTaxeAdd}</strong>
        </td>
        <td
          style={{
            border: "1px solid black",
            padding: "10px 15px",
            fontFamily: "Times New Roman",
          }}
        >
          <strong className="center">{item.montantTaxeAdd}</strong>
        </td>
        <td
          style={{
            border: "1px solid black",
            padding: "10px 15px",
            fontFamily: "Times New Roman",
          }}
        >
          <strong className="center">{item.netServiTaxeAdd}</strong>
        </td>
      </tr>
    )}
  </React.Fragment>
))}

            <tr>
              <td
                style={{
                  border: "1px solid black",
                  padding: "10px 15px",
                  fontFamily: "Times New Roman",
                }}
              >
                <strong className="center">Total Général</strong>
              </td>
              <td
                style={{
                  border: "1px solid black",
                  padding: "10px 15px",
                  fontFamily: "Times New Roman",
                }}
              >
                <strong className="center">{montantTotal}</strong>
              </td>
              <td
                style={{
                  border: "1px solid black",
                  padding: "10px 15px",
                  fontFamily: "Times New Roman",
                }}
              >
                <strong className="center">{retenueTotal}</strong>
              </td>
              <td
                style={{
                  border: "1px solid black",
                  padding: "10px 15px",
                  fontFamily: "Times New Roman",
                }}
              >
                <strong className="center">{netServiTotal}</strong>
              </td>
            </tr>
          </tbody>
        </table>

        <div style={{ border: "1px solid black" }}>
          <div className="row">
            <div className="col-6">
              <strong style={{ fontFamily: "Times New Roman" }}>
                {" "}
                C-BENEFICAIRE
              </strong>

              <div className="row mt-5">
                <div className="col-5">
                  <p
                    style={{
                      fontFamily: "Times New Roman",
                      marginLeft: "5px",
                      color: "black",
                    }}
                  >
                    N° de la carte d'identité
                    <br />
                    ou de séjour pour les étrangers :{" "}
                  </p>
                </div>
                <div className="col-6">
                  {currentCertificat?.Beneficiaire.typeIdentifiant !== 1 && (
                    <strong>
                      {currentCertificat?.Beneficiaire.identifiant}
                    </strong>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row center">
                <strong
                  style={{ fontFamily: "Times New Roman", marginLeft: "248px" }}
                >
                  IDENTIFIANT
                </strong>
              </div>
              <div className="row center">
                <table
                  style={{
                    borderCollapse: "collapse",
                    borderColor: "black",
                    maxWidth: "648px",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "10px 15px",
                          fontFamily: "Times New Roman",
                        }}
                      >
                        Matricule fiscal
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "10px 15px",
                          fontFamily: "Times New Roman",
                        }}
                      >
                        Code T.V.A
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "10px 15px",
                          fontFamily: "Times New Roman",
                        }}
                      >
                        Code catég(2)
                      </th>
                      <th
                        style={{
                          border: "1px solid black",
                          padding: "10px 15px",
                          fontFamily: "Times New Roman",
                        }}
                      >
                        N°Etab Second
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "10px 15px",
                          fontFamily: "Times New Roman",
                        }}
                      >
                        {currentCertificat?.Beneficiaire.typeIdentifiant ==
                          1 && (
                          <strong className="center">
                            {currentCertificat?.Beneficiaire.identifiant?.substring(
                              0,
                              8
                            )}
                          </strong>
                        )}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "10px 15px",
                          fontFamily: "Times New Roman",
                        }}
                      >
                        {currentCertificat?.Beneficiaire.typeIdentifiant ==
                          1 && (
                          <strong className="center">
                            {currentCertificat?.Beneficiaire.identifiant?.charAt(
                              8
                            )}
                          </strong>
                        )}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "10px 15px",
                          fontFamily: "Times New Roman",
                        }}
                      >
                        {currentCertificat?.Beneficiaire.typeIdentifiant ==
                          1 && (
                          <strong className="center">
                            {currentCertificat?.Beneficiaire.identifiant?.charAt(
                              9
                            )}
                          </strong>
                        )}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          padding: "10px 15px",
                          fontFamily: "Times New Roman",
                        }}
                      >
                        {currentCertificat?.Beneficiaire.typeIdentifiant ==
                          1 && (
                          <strong className="center">
                            {currentCertificat?.Beneficiaire.identifiant?.substring(
                              10,
                              13
                            )}
                          </strong>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-3">
              <p style={{ fontFamily: "Times New Roman", marginLeft: "5px" }}>
                Nom, Prénom ou raison sociale :{" "}
              </p>
            </div>
            <div className="col-4">
              <strong> {currentCertificat?.Beneficiaire.name}</strong>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <p
                style={{
                  fontFamily: "Times New Roman",
                  marginLeft: "5px",
                  color: "black",
                }}
              >
                Adresse professionnelle :
              </p>
            </div>
            <div className="col-4">
              <strong> {currentCertificat?.Beneficiaire.adresse}</strong>
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              <p
                style={{
                  fontFamily: "Times New Roman",
                  marginLeft: "5px",
                  color: "black",
                }}
              >
                Adresse de résidence :
              </p>
            </div>
            <div className="col-9">
              <strong>{/* Adresse de résidence */}</strong>{" "}
              {/* Ajoutez l'adresse de résidence ici */}
            </div>
          </div>
        </div>

        <div
          style={{
            borderBottom: "1px solid black",
            borderLeft: "1px solid black",
            borderRight: "1px solid black",
          }}
        >
          <div className="row  text-center">
            <p style={{ fontFamily: "Times New Roman", color: "black" }}>
              Je soussigné, certifie exacts les renseignements figurant sur le
              présent
            </p>
          </div>
          <div className="row  text-center">
            <p style={{ fontFamily: "Times New Roman", color: "black" }}>
              certificat et m'expose aux sanctions prévues par la loi pour tout
              inexacitude
            </p>
          </div>
          <div className="row  mt-8 text-center">
            <strong style={{ fontFamily: "Times New Roman" }}>
              A Tunis , le{" "}
              {currentCertificat?.date
                ? new Date(currentCertificat.date).toLocaleDateString("fr-FR")
                : ""}
            </strong>
          </div>
          <div
            className="row  text-center mb-10"
            style={{ marginBottom: " 200px" }}
          >
            <strong style={{ fontFamily: "Times New Roman" }}>
              Cachet et signature du payeur
            </strong>
          </div>
        </div>
        <div>
          <span
            style={{
              fontSize: "10px",
              fontFamily: "Times New Roman",
              color: "black",
            }}
          >
            (1) le certificat est délivré a l'occasion de chaque paiement ,
            Toutefois, pour les operations répétitives, le certificat peut étre
            delivré trimestrielement
          </span>
          <br />
          <span
            style={{
              fontSize: "10px",
              fontFamily: "Times New Roman",
              color: "black",
            }}
          >
            (2) Code catégorie : M.personnes morales --C , personnes physique
            "industrie et commerce" --P,professions liberales --N , employeurs
            non soumis a l'ipmots sur le revenu ou sur les
            sociétés(administration et établissement publics) --E
            établisssements secondaire,
          </span>
        </div>
      </div>
    </div>
  );
};

export default ImpressionCertificat;
