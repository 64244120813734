import React from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function LoginLayout() {
  return (
    <>
      <ToastContainer />

      <div className="dashboard-analytics admin-header">
        <div className="main-container" id="container">
          <div className="overlay"></div>
          <div className="search-overlay"></div>
          <div id="content" className="main-content">
            <div className="layout-px-spacing">
              <div className="content-container">
                <div className="col-left layout-top-spacing">
                  <div className="col-left-content">
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
