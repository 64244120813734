import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import { LigneCertificatType } from "../../../Types/Entites/LigneCertificatType";
import { LigneCertificatDataType } from "../../../Types/Forms/LigneCertificatDataType";

export const getAllLigneCertificatsApi = createAsyncThunk(
  "ligneCertificat/getAll",
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "ligneCertificat/getAllLigneCertificats"
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const getLigneCertificatByNumCertApi = createAsyncThunk(
  "ligneCertificat/getByNumCert",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `ligneCertificat/getLigneCertificatByNumCert/${id}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const createLigneCertificatApi = createAsyncThunk(
  "ligneCertificat/create",
  async (data: LigneCertificatDataType, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/ligneCertificat/createLigneCertificat",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const updateLigneCertificatApi = createAsyncThunk(
  "ligneCertificat/update",
  async (
    { id, data }: { id: number; data: LigneCertificatDataType },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(
        `ligneCertificat/updateLigneCertificat/${id}`,
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const deleteLigneCertificatApi = createAsyncThunk(
  "ligneCertificat/delete",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `ligneCertificat/deleteLigneCertificat/${id}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const getLigneCertificatBySocieteIdApi = createAsyncThunk(
  "ligneCertificat/getBySocieteId",
  async (societeId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `ligneCertificat/getLigneCertificatBySocieteId/${societeId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const getLigneCertificatBySocieteAndExerciceApi = createAsyncThunk(
  "ligneCertificat/getBySocieteAndExercice",
  async (
    { societeId, exerciceId }: { societeId: number; exerciceId: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.get(
        `ligneCertificat/getLigneCertificatBySocieteAndExercice/${societeId}/${exerciceId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const getLigneCertificatByCertificatIdApi = createAsyncThunk(
  "ligneCertificat/getByCertificatId",
  async (certificatId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/ligneCertificat/getLigneCertificatByCertificatId/${certificatId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const getLigneCertificatByBeneficiaireApi = createAsyncThunk(
  "ligneCertificat/getByBeneficiaire",
  async (beneficiaireId: number, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/ligneCertificat/getLigneCertificatByBeneficiaire/${beneficiaireId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const getLigneCertificatByDeclarationApi = createAsyncThunk(
    "ligneCertificat/getByDeclaration",
    async (declarationId: number, { rejectWithValue }) => {
      try {
        const response = await axios.get(
          `/ligneCertificat/getLigneCertificatByDeclaration/${declarationId}`
        );
        return response.data;
      } catch (error: any) {
        return rejectWithValue({
          success: false,
          message: error.response?.data?.message || "Erreur inconnue de l'API",
        });
      }
    }
  );
  
  interface LigneCertificatState {
    ligneCertificatsList: LigneCertificatType[];
    lignesCertificatByDeclaration: LigneCertificatType[];
    currentLigneCertif: LigneCertificatType | null;
    lignesCertificatBySocieteAndExercice: LigneCertificatType[];
    lignesCertificatByCertificatId: LigneCertificatType[];
    lignesCertificatByBeneficiaire: LigneCertificatType[];
  }
  
  const initialState: LigneCertificatState = {
    ligneCertificatsList: [],
    lignesCertificatByDeclaration: [],
    currentLigneCertif: null,
    lignesCertificatBySocieteAndExercice: [],
    lignesCertificatByCertificatId: [],
    lignesCertificatByBeneficiaire: [],
  };
  
  

export const ligneCertificatSlice = createSlice({
  name: "ligneCertificat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllLigneCertificatsApi.fulfilled, (state, action) => {
      state.ligneCertificatsList = action.payload;
    });

    builder.addCase(
      getLigneCertificatByNumCertApi.fulfilled,
      (state, action) => {
        state.currentLigneCertif = action.payload;
      }
    );

    builder.addCase(
      getLigneCertificatBySocieteAndExerciceApi.fulfilled,
      (state, action) => {
        state.lignesCertificatBySocieteAndExercice = action.payload;
      }
    );

    builder.addCase(createLigneCertificatApi.fulfilled, (state, action) => {
      state.ligneCertificatsList.push(action.payload.data);
      state.lignesCertificatBySocieteAndExercice.push(action.payload.data);
    });

    builder.addCase(updateLigneCertificatApi.fulfilled, (state, action) => {
      const updatedLigneCertificat = action.payload.data;
      const index = state.ligneCertificatsList.findIndex(
        (ligneCertif) => ligneCertif.id === updatedLigneCertificat.id
      );
      if (index !== -1) {
        state.ligneCertificatsList[index] = updatedLigneCertificat;
      }

      const indexSocieteExercice =
        state.lignesCertificatBySocieteAndExercice.findIndex(
          (ligneCertif) => ligneCertif.id === updatedLigneCertificat.id
        );
      if (indexSocieteExercice !== -1) {
        state.lignesCertificatBySocieteAndExercice[indexSocieteExercice] =
          updatedLigneCertificat;
      }
    });

    builder.addCase(deleteLigneCertificatApi.fulfilled, (state, action) => {
      const deletedNumCertificat = action.payload.id;

      state.ligneCertificatsList = state.ligneCertificatsList.filter(
        (ligneCertif) => ligneCertif.id !== deletedNumCertificat
      );

      state.lignesCertificatBySocieteAndExercice =
        state.lignesCertificatBySocieteAndExercice.filter(
          (ligneCertif) => ligneCertif.id !== deletedNumCertificat
        );
    });

    builder.addCase(
      getLigneCertificatByCertificatIdApi.fulfilled,
      (state, action) => {
        state.lignesCertificatByCertificatId = action.payload;
      }
    );

    builder.addCase(getLigneCertificatByDeclarationApi.fulfilled, (state, action) => {
        state.lignesCertificatByDeclaration = action.payload;
      });

    builder.addCase(
      getLigneCertificatByBeneficiaireApi.fulfilled,
      (state, action) => {
        state.lignesCertificatByBeneficiaire = action.payload;
      }
    );
  },
});

export const LigneCertificatsList = (state: RootState) =>
  state.ligneCertificat.ligneCertificatsList;
export const selectCurrentLigneCertif = (state: RootState) =>
  state.ligneCertificat.currentLigneCertif;
export const LignesCertificatBySocieteAndExercice = (state: RootState) =>
  state.ligneCertificat.lignesCertificatBySocieteAndExercice;
export const LignesCertificatByCertificatId = (state: RootState) =>
  state.ligneCertificat.lignesCertificatByCertificatId;

export const LignesCertificatByBeneficiaire = (state: RootState) =>
  state.ligneCertificat.lignesCertificatByBeneficiaire;

export const LignesCertificatByDeclaration = (state: RootState) =>
    state.ligneCertificat.lignesCertificatByDeclaration;
  

export default ligneCertificatSlice.reducer;
