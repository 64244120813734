import { saveAs } from "file-saver";

interface RecapFileProps {
  currentDeclaration: any;
  currentExercice: any;
  currentActe: any;
  currentSociete: any;
  recapList: any[] | null;
}

export const generateRecapFile = (
  currentDeclaration: any,
  currentExercice: any,
  currentActe: any,
  currentSociete: any,
  recapList: any
) => {
  const generateRecapContent = () => {
    if (
      !currentSociete ||
      !currentExercice ||
      !currentActe ||
      !recapList ||
      !currentDeclaration
    )
      return "";

    const matriculeFiscale =
      currentSociete.matriculeFiscale.slice(0, 8) +
      currentSociete.matriculeFiscale.slice(9, 13);
    const exerciceLibelle = currentExercice.libelle.padEnd(4);
    const presenceAnx1 = currentDeclaration.annexe1 ? 1 : 0;
    const presenceAnx2 = currentDeclaration.annexe2 ? 1 : 0;
    const presenceAnx3 = currentDeclaration.annexe3 ? 1 : 0;
    const presenceAnx4 = currentDeclaration.annexe4 ? 1 : 0;
    const presenceAnx5 = currentDeclaration.annexe5 ? 1 : 0;
    const presenceAnx6 = currentDeclaration.annexe6 ? 1 : 0;
    const presenceAnx7 = currentDeclaration.annexe7 ? 1 : 0;

    const spaces = " ".repeat(12);

    let recapContent = `000${matriculeFiscale}${exerciceLibelle}${presenceAnx1}${presenceAnx2}${presenceAnx3}${presenceAnx4}${presenceAnx5}${presenceAnx6}${presenceAnx7}${spaces}`;

    const lineWithCode1 = recapList.find((line: any) => line.code === 1);
    if (lineWithCode1) {
      const additionalLine1 = `010${String(
        lineWithCode1.assiete * 1000
      ).padStart(15, "0")}00000${String(lineWithCode1.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine1}`;
    }

    const lineWithCode2 = recapList.find((line: any) => line.code === 2);
    if (lineWithCode2) {
      const additionalLine2 = `170${String(
        lineWithCode2.assiete * 1000
      ).padStart(15, "0")}02000${String(lineWithCode2.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine2}`;
    }

    const lineWithCode3 = recapList.find((line: any) => line.code === 3);
    if (lineWithCode3) {
      const ReservedZone = " ".repeat(20);

      const additionalLine3 = `300${ReservedZone}${String(
        lineWithCode3.assiete * 1000
      ).padStart(15, "0")}`;
      recapContent += `\n${additionalLine3}`;
    }

    const lineWithCode4 = recapList.find((line: any) => line.code === 4);
    if (lineWithCode4) {
      const additionalLine4 = `021${String(
        lineWithCode4.assiete * 1000
      ).padStart(15, "0")}01000${String(lineWithCode4.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine4}`;
    }

    const lineWithCode5 = recapList.find((line: any) => line.code === 5);
    if (lineWithCode5) {
      const additionalLine5 = `022${String(
        lineWithCode5.assiete * 1000
      ).padStart(15, "0")}01000${String(lineWithCode5.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine5}`;
    }
    const lineWithCode6 = recapList.find((line: any) => line.code === 6);
    if (lineWithCode6) {
      const additionalLine6 = `023${String(
        lineWithCode6.assiete * 1000
      ).padStart(15, "0")}01500${String(lineWithCode6.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine6}`;
    }

    const lineWithCode7 = recapList.find((line: any) => line.code === 7);
    if (lineWithCode7) {
      const additionalLine7 = `024${String(
        lineWithCode7.assiete * 1000
      ).padStart(15, "0")}01500${String(lineWithCode7.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine7}`;
    }

    const lineWithCode8 = recapList.find((line: any) => line.code === 8);
    if (lineWithCode8) {
      const additionalLine8 = `027${String(
        lineWithCode8.assiete * 1000
      ).padStart(15, "0")}01000${String(lineWithCode8.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine8}`;
    }

    const lineWithCode9 = recapList.find((line: any) => line.code === 9);
    if (lineWithCode9) {
      const additionalLine9 = `030${String(
        lineWithCode9.assiete * 1000
      ).padStart(15, "0")}00300${String(lineWithCode9.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine9}`;
    }

    const lineWithCode10 = recapList.find((line: any) => line.code === 10);
    if (lineWithCode10) {
      const additionalLine10 = `400${String(
        lineWithCode10.assiete * 1000
      ).padStart(15, "0")}00500${String(lineWithCode10.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine10}`;
    }

    const lineWithCode11 = recapList.find((line: any) => line.code === 11);
    if (lineWithCode11) {
      const additionalLine11 = `040${String(
        lineWithCode11.assiete * 1000
      ).padStart(15, "0")}00500${String(lineWithCode11.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine11}`;
    }

    const lineWithCode12 = recapList.find((line: any) => line.code === 12);
    if (lineWithCode12) {
      const additionalLine12 = `260${String(
        lineWithCode12.assiete * 1000
      ).padStart(15, "0")}01000${String(lineWithCode12.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine12}`;
    }

    const lineWithCode13 = recapList.find((line: any) => line.code === 13);
    if (lineWithCode13) {
      const additionalLine13 = `060${String(
        lineWithCode13.assiete * 1000
      ).padStart(15, "0")}02000${String(lineWithCode13.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine13}`;
    }

    const lineWithCode14 = recapList.find((line: any) => line.code === 14);
    if (lineWithCode14) {
      const additionalLine14 = `071${String(
        lineWithCode14.assiete * 1000
      ).padStart(15, "0")}02000${String(lineWithCode14.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine14}`;
    }

    const lineWithCode15 = recapList.find((line: any) => line.code === 15);
    if (lineWithCode15) {
      const additionalLine15 = `072${String(
        lineWithCode15.assiete * 1000
      ).padStart(15, "0")}02000${String(lineWithCode15.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine15}`;
    }

    const lineWithCode16 = recapList.find((line: any) => line.code === 16);
    if (lineWithCode16) {
      const additionalLine16 = `073${String(
        lineWithCode16.assiete * 1000
      ).padStart(15, "0")}02000${String(lineWithCode16.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine16}`;
    }

    const lineWithCode17 = recapList.find((line: any) => line.code === 17);
    if (lineWithCode17) {
      const additionalLine17 = `074${String(
        lineWithCode17.assiete * 1000
      ).padStart(15, "0")}02000${String(lineWithCode17.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine17}`;
    }

    const lineWithCode18 = recapList.find((line: any) => line.code === 18);
    if (lineWithCode18) {
      const additionalLine18 = `241${String(
        lineWithCode18.assiete * 1000
      ).padStart(15, "0")}01000${String(lineWithCode18.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine18}`;
    }

    const lineWithCode19 = recapList.find((line: any) => line.code === 19);
    if (lineWithCode19) {
      const additionalLine19 = `242${String(
        lineWithCode19.assiete * 1000
      ).padStart(15, "0")}01000${String(lineWithCode19.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine19}`;
    }

    const lineWithCode20 = recapList.find((line: any) => line.code === 20);
    if (lineWithCode20) {
      const additionalLine20 = `091${String(
        lineWithCode20.assiete * 1000
      ).padStart(15, "0")}02000${String(lineWithCode20.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine20}`;
    }

    const lineWithCode21 = recapList.find((line: any) => line.code === 21);
    if (lineWithCode21) {
      const additionalLine21 = `092${String(
        lineWithCode21.assiete * 1000
      ).padStart(15, "0")}02000${String(lineWithCode21.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine21}`;
    }

    const lineWithCode22 = recapList.find((line: any) => line.code === 22);
    if (lineWithCode22) {
      const additionalLine22 = `093${String(
        lineWithCode22.assiete * 1000
      ).padStart(15, "0")}02000${String(lineWithCode22.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine22}`;
    }

    const lineWithCode23 = recapList.find((line: any) => line.code === 23);
    if (lineWithCode23) {
      const additionalLine23 = `094${String(
        lineWithCode23.assiete * 1000
      ).padStart(15, "0")}02000${String(lineWithCode23.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine23}`;
    }

    const lineWithCode24 = recapList.find((line: any) => line.code === 24);
    if (lineWithCode24) {
      const additionalLine24 = `100${String(
        lineWithCode24.assiete * 1000
      ).padStart(15, "0")}01500${String(lineWithCode24.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine24}`;
    }

    const lineWithCode25 = recapList.find((line: any) => line.code === 25);
    if (lineWithCode25) {
      const additionalLine25 = `110${String(
        lineWithCode25.assiete * 1000
      ).padStart(15, "0")}01000${String(lineWithCode25.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine25}`;
    }

    const lineWithCode26 = recapList.find((line: any) => line.code === 26);
    if (lineWithCode26) {
      const additionalLine26 = `121${String(
        lineWithCode26.assiete * 1000
      ).padStart(15, "0")}00250${String(lineWithCode26.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine26}`;
    }

    const lineWithCode27 = recapList.find((line: any) => line.code === 27);
    if (lineWithCode27) {
      const additionalLine27 = `122${String(
        lineWithCode27.assiete * 1000
      ).padStart(15, "0")}00250${String(lineWithCode27.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine27}`;
    }

    const lineWithCode28 = recapList.find((line: any) => line.code === 28);
    if (lineWithCode28) {
      const additionalLine28 = `123${String(
        lineWithCode28.assiete * 1000
      ).padStart(15, "0")}01000${String(lineWithCode28.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine28}`;
    }

    const lineWithCode29 = recapList.find((line: any) => line.code === 29);
    if (lineWithCode29) {
      const additionalLine29 = `132${String(
        lineWithCode29.assiete * 1000
      ).padStart(15, "0")}00100${String(lineWithCode29.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine29}`;
    }

    const lineWithCode30 = recapList.find((line: any) => line.code === 30);
    if (lineWithCode30) {
      const additionalLine30 = `133${String(
        lineWithCode30.assiete * 1000
      ).padStart(15, "0")}00050${String(lineWithCode30.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine30}`;
    }

    const lineWithCode31 = recapList.find((line: any) => line.code === 31);
    if (lineWithCode31) {
      const additionalLine31 = `134${String(
        lineWithCode31.assiete * 1000
      ).padStart(15, "0")}00050${String(lineWithCode31.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine31}`;
    }

    const lineWithCode32 = recapList.find((line: any) => line.code === 32);
    if (lineWithCode32) {
      const additionalLine32 = `135${String(
        lineWithCode32.assiete * 1000
      ).padStart(15, "0")}00150${String(lineWithCode32.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine32}`;
    }

    const lineWithCode33 = recapList.find((line: any) => line.code === 33);
    if (lineWithCode33) {
      const additionalLine33 = `140${String(
        lineWithCode33.assiete * 1000
      ).padStart(15, "0")}02500${String(lineWithCode33.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine33}`;
    }

    const lineWithCode34 = recapList.find((line: any) => line.code === 34);
    if (lineWithCode34) {
      const additionalLine34 = `150${String(
        lineWithCode34.assiete * 1000
      ).padStart(15, "0")}10000${String(lineWithCode34.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine34}`;
    }

    const lineWithCode35 = recapList.find((line: any) => line.code === 35);
    if (lineWithCode35) {
      const additionalLine35 = `160${String(
        lineWithCode35.assiete * 1000
      ).padStart(15, "0")}00000${String(lineWithCode35.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine35}`;
    }

    const lineWithCode36 = recapList.find((line: any) => line.code === 36);
    if (lineWithCode36) {
      const additionalLine36 = `270${String(
        lineWithCode36.assiete * 1000
      ).padStart(15, "0")}02500${String(lineWithCode36.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine36}`;
    }

    const lineWithCode37 = recapList.find((line: any) => line.code === 37);
    if (lineWithCode37) {
      const additionalLine37 = `271${String(
        lineWithCode37.assiete * 1000
      ).padStart(15, "0")}01500${String(lineWithCode37.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine37}`;
    }

    const lineWithCode38 = recapList.find((line: any) => line.code === 38);
    if (lineWithCode38) {
      const additionalLine38 = `200${String(
        lineWithCode38.assiete * 1000
      ).padStart(15, "0")}00100${String(lineWithCode38.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine38}`;
    }

    const lineWithCode39 = recapList.find((line: any) => line.code === 39);
    if (lineWithCode39) {
      const additionalLine39 = `500${String(
        lineWithCode39.assiete * 1000
      ).padStart(15, "0")}00500${String(lineWithCode39.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine39}`;
    }

    const lineWithCode40 = recapList.find((line: any) => line.code === 40);
    if (lineWithCode40) {
      const additionalLine40 = `191${String(
        lineWithCode40.assiete * 1000
      ).padStart(15, "0")}01000${String(lineWithCode40.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine40}`;
    }

    const lineWithCode41 = recapList.find((line: any) => line.code === 41);
    if (lineWithCode41) {
      const additionalLine41 = `192${String(
        lineWithCode41.assiete * 1000
      ).padStart(15, "0")}01500${String(lineWithCode41.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine41}`;
    }

    const lineWithCode42 = recapList.find((line: any) => line.code === 42);
    if (lineWithCode42) {
      const additionalLine42 = `051${String(
        lineWithCode42.assiete * 1000
      ).padStart(15, "0")}01500${String(lineWithCode42.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine42}`;
    }

    const lineWithCode43 = recapList.find((line: any) => line.code === 43);
    if (lineWithCode43) {
      const additionalLine43 = `052${String(
        lineWithCode43.assiete * 1000
      ).padStart(15, "0")}01500${String(lineWithCode43.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine43}`;
    }

    const lineWithCode44 = recapList.find((line: any) => line.code === 44);
    if (lineWithCode44) {
      const additionalLine44 = `220${String(
        lineWithCode44.assiete * 1000
      ).padStart(15, "0")}02500${String(lineWithCode44.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine44}`;
    }

    const lineWithCode45 = recapList.find((line: any) => line.code === 45);
    if (lineWithCode45) {
      const additionalLine45 = `250${String(
        lineWithCode45.assiete * 1000
      ).padStart(15, "0")}00150${String(lineWithCode45.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine45}`;
    }

    const lineWithCode46 = recapList.find((line: any) => line.code === 46);
    if (lineWithCode46) {
      const additionalLine46 = `251${String(
        lineWithCode46.assiete * 1000
      ).padStart(15, "0")}00100${String(lineWithCode46.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine46}`;
    }

    const lineWithCode47 = recapList.find((line: any) => line.code === 47);
    if (lineWithCode47) {
      const additionalLine47 = `280${String(
        lineWithCode47.assiete * 1000
      ).padStart(15, "0")}02500${String(lineWithCode47.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine47}`;
    }

    const lineWithCode48 = recapList.find((line: any) => line.code === 48);
    if (lineWithCode48) {
      const additionalLine48 = `290${String(
        lineWithCode48.assiete * 1000
      ).padStart(15, "0")}00300${String(lineWithCode48.retenue * 1000).padStart(
        15,
        "0"
      )}`;
      recapContent += `\n${additionalLine48}`;
    }


    // Ligne 49 fin recap
    let totalRetenue = 0;
    recapList.forEach((line: any) => {
      totalRetenue += line.retenue;
    });
    const ReservedEndZone = " ".repeat(20);

    const totalRetenueFormatted = String(totalRetenue * 1000).padStart(15, "0");
    const finRecapLine = `999${ReservedEndZone}${totalRetenueFormatted}`;

    recapContent += `\n${finRecapLine}`;

    return recapContent;
  };

  const textContent = generateRecapContent();
  const blob = new Blob([textContent], { type: "text/plain;charset=utf-8" });
  const exerciseLabel = currentExercice.libelle;
  const lastTwoChars = exerciseLabel.slice(-2);
  const fileName = `DECEMP_${lastTwoChars}.txt`;
  saveAs(blob, fileName);
};

const RecapFile: RecapFileProps = {
  currentDeclaration: null,
  currentExercice: null,
  currentActe: null,
  currentSociete: null,
  recapList: null,
};

export default RecapFile;
