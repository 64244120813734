import React, { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { MatriculFiscalValidate } from "../../_App/Helpers/helpers";
import {
  createBeneficiaireApi,
  updateBeneficiaireApi,
} from "../../_App/Redux/Slices/beneficiaire/beneficiaireSlice";
import { currentUserId } from "../../_App/Redux/Slices/user/userSlice";
import {
  findSocietesByUserIdApi,
  selectSocietesByUser,
} from "../../_App/Redux/Slices/userSociete/userSociete";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { BeneficiaireType } from "../../_App/Types/Entites/BeneficiaireType";
import { SocieteType } from "../../_App/Types/Entites/SocieteType";
import {
  findAllPaysDevisesApi,
  selectPaysDevisesList,
} from "../../_App/Redux/Slices/paysDevise/paysDeviseSlice";
import { PaysDeviseType } from "../../_App/Types/Entites/PaysDeviseType";
import styled from "styled-components";

const StyledCheckbox = styled(Form.Check)`
  transform: scale(1.8);
  margin-left: 120px;

  .form-check-input:checked {
    background-color: green;
    border-color: green;
  }
`;

interface BeneficiaireFormProps {
  initialData?: BeneficiaireType;
  closeForm: () => void;
}

const BeneficiaireForm: React.FC<BeneficiaireFormProps> = ({
  initialData,
  closeForm,
}) => {
  const {
    register,
    setValue,
    setError,
    watch,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<BeneficiaireType>();

  const dispatch = useAppDispatch();
  const societes = useAppSelector(selectSocietesByUser);
  const userId = useAppSelector(currentUserId);
  const paysDevises = useAppSelector(selectPaysDevisesList);

  const handlePaysDeviseChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPaysDeviseId = parseInt(e.target.value);
    const selectedPaysDevise = paysDevises.find(
      (paysDevise) => paysDevise.id === selectedPaysDeviseId
    );
    if (selectedPaysDevise) {
      setValue("deviseBenef", selectedPaysDevise.codeDevise);
    }
  };

  useEffect(() => {
    if (userId) {
      dispatch(findSocietesByUserIdApi(userId));
    }
    dispatch(findAllPaysDevisesApi());
  }, [dispatch, userId]);

  useEffect(() => {
    if (initialData) {
      Object.entries(initialData).forEach(([key, value]) => {
        setValue(key as keyof BeneficiaireType, value);
      });
    }
  }, [initialData, setValue]);

  const handleFormSubmit = async (data: BeneficiaireType) => {
    try {
      if (initialData) {
        if (initialData.id !== undefined) {
          const { id, ...updatedData } = data;
          const response = await dispatch(
            updateBeneficiaireApi({ id: initialData.id, data: updatedData })
          );
          if (response.payload.success) {
            ToastSuccess(response.payload.message);
            closeForm();
          } else {
            ToastWarning(response.payload.message);
          }
        } else {
          console.error("ID is undefined for initialData");
        }
      } else {
        const response = await dispatch(createBeneficiaireApi(data));
        if (response.payload.success) {
          ToastSuccess(response.payload.message);
          closeForm();
        } else {
          ToastWarning(response.payload.message);
        }
      }
    } catch (error) {
      console.error("Submission failed", error);
    }
  };

  const typeIdentifiant = watch("typeIdentifiant");

  return (
    <Form onSubmit={handleSubmit(handleFormSubmit)}>
      <Row>
        <Col>
          <Form.Group controlId="typeBeneficiaire">
            <Form.Label>Type </Form.Label>
            <Form.Control as="select" {...register("typeBeneficiaire")}>
              <option value="Salarié">Salarié</option>
              <option value="Fournisseur">Fournisseur</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="categorie">
            <Form.Label>Catégorie</Form.Label>
            <Form.Control
              as="select"
              {...register("categorie")}
              className={errors.categorie ? "is-invalid" : ""}
              defaultValue={initialData?.categorie}
            >
              <option value={""}></option>
              <option value={1}>1 : Personne morale</option>
              <option value={2}>2 : Personne physique</option>
            </Form.Control>
            {errors.categorie && (
              <Form.Control.Feedback type="invalid">
                {errors.categorie.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>

        <Col>
          <Form.Group controlId="societe">
            <Form.Label>Société</Form.Label>
            <Form.Control
              as="select"
              {...register("societe")}
              className={errors.societe ? "is-invalid" : ""}
            >
              <option value={""}></option>
              {societes.map((societe: SocieteType) => (
                <option key={societe.id} value={societe.id}>
                  {societe.name}
                </option>
              ))}
            </Form.Control>
            {errors.societe && (
              <Form.Control.Feedback type="invalid">
                {errors.societe.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="typeIdentifiant">
            <Form.Label>Type Identifiant</Form.Label>
            <Form.Control
              as="select"
              {...register("typeIdentifiant")}
              defaultValue={initialData?.typeIdentifiant}
            >
              <option value={1}>1 : MF</option>
              <option value={2}>2 : CIN</option>
              <option value={3}>3 : Passeport</option>
              <option value={4}>4 : CS</option>
              <option value={5}>5 : Autre</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="identifiant">
            <Form.Label>Identifiant</Form.Label>
            <Form.Control
              type="text"
              name="identifiant"
              defaultValue={initialData?.identifiant}
              onChange={(e) => {
                const value = e.target.value;
                const typeIdentifiant = +watch("typeIdentifiant");

                if (typeIdentifiant === 1) {
                  const truncatedValue = value.slice(0, 13);
                  setValue("identifiant", truncatedValue);

                  if (!MatriculFiscalValidate(truncatedValue)) {
                    setError("identifiant", {
                      type: "manual",
                      message: "MF invalide",
                    });
                  } else if (truncatedValue.length !== 13) {
                    setError("identifiant", {
                      type: "manual",
                      message: "longeur MF invalide",
                    });
                  } else {
                    clearErrors("identifiant");
                  }
                } else if (typeIdentifiant === 2) {
                  const digitsOnly = value.replace(/\D/g, "");
                  const truncatedValue = digitsOnly.slice(0, 8);
                  e.target.value = truncatedValue;
                  setValue("identifiant", truncatedValue);

                  if (truncatedValue.length !== 8) {
                    setError("identifiant", {
                      type: "manual",
                      message: "Format n° cin : 8 chiffres",
                    });
                  } else {
                    clearErrors("identifiant");
                  }
                } else if (
                  typeIdentifiant === 3 ||
                  typeIdentifiant === 4 ||
                  typeIdentifiant === 5
                ) {
                  setValue("identifiant", value);
                  clearErrors("identifiant");
                }
              }}
              // minLength={watch("typeIdentifiant") == 1 ? 13 : 8}
              // maxLength={watch("typeIdentifiant") == 1 ? 13 : 8}
              isInvalid={!!errors.identifiant}
            />
            {errors.identifiant && (
              <Form.Control.Feedback
                type="invalid"
                style={{ fontSize: "0.8rem" }}
              >
                {errors.identifiant.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
      {(typeIdentifiant == 3 ||
        typeIdentifiant == 4 ||
        typeIdentifiant == 5) && (
        <Row>
          <Col>
            <Form.Group controlId="paysDevise">
              <Form.Label>Pays</Form.Label>
              <Form.Control
                as="select"
                {...register("paysDevise", {
                  onChange: handlePaysDeviseChange,
                })}
                className={errors.paysDevise ? "is-invalid" : ""}
              >
                <option value={""}></option>
                {paysDevises.map((paysDevise: PaysDeviseType) => (
                  <option key={paysDevise.id} value={paysDevise.id}>
                    {paysDevise.libellePays}
                  </option>
                ))}
              </Form.Control>
              {errors.paysDevise && (
                <Form.Control.Feedback type="invalid">
                  {errors.paysDevise.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="deviseBenef">
              <Form.Label>Devise</Form.Label>
              <Form.Control
                as="select"
                {...register("deviseBenef")}
                className={errors.deviseBenef ? "is-invalid" : ""}
              >
                <option value={""}></option>
                {paysDevises.map((paysDevise: PaysDeviseType) => (
                  <option key={paysDevise.id} value={paysDevise.codeDevise}>
                    {paysDevise.libelleDevise}
                  </option>
                ))}
              </Form.Control>
              {errors.deviseBenef && (
                <Form.Control.Feedback type="invalid">
                  {errors.deviseBenef.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="resident">
              <Form.Label>Cochez si résidant</Form.Label>

              <StyledCheckbox type="checkbox" {...register("resident")} />
            </Form.Group>
          </Col>
        </Row>
      )}

      {watch("typeBeneficiaire") === "Salarié" && (
        <Row>
          <Col>
            <Form.Group controlId="situationFamiliale">
              <Form.Label>Situation Familiale</Form.Label>
              <Form.Control
                as="select"
                defaultValue={
                  initialData?.situationFamiliale !== null &&
                  initialData?.situationFamiliale !== undefined
                    ? initialData?.situationFamiliale.toString()
                    : ""
                }
                name="situationFamiliale"
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10) || 0;
                  setValue("situationFamiliale", value);
                  if (value === 1) {
                    setValue("nbrEnfant", 0);
                  }
                }}
              >
                <option value={""}></option>
                <option value={1}>1 : Célibataire</option>
                <option value={2}>2 : Marié</option>
                <option value={3}>3 : Divorcé</option>
                <option value={4}>4 : Veuf</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="nbrEnfant">
              <Form.Label>Nombre d'enfants</Form.Label>
              <Form.Control
                type="number"
                defaultValue={
                  watch("situationFamiliale") === 1 ? 0 : initialData?.nbrEnfant
                }
                disabled={watch("situationFamiliale") === 1}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10) || 0;
                  setValue("nbrEnfant", value);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <Form.Group controlId="nomBeneficiaire">
            <Form.Label>Nom</Form.Label>
            <Form.Control
              type="text"
              {...register("name")}
              className={errors.name ? "is-invalid" : ""}
              onChange={(e) => {
                const value = e.target.value;
                const truncatedValue = value.slice(0, 40);
                e.target.value = truncatedValue;
                setValue("name", truncatedValue);
              }}
            />
            {errors.name && (
              <Form.Control.Feedback type="invalid">
                {errors.name.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="activity">
            <Form.Label>Activité</Form.Label>
            <Form.Control
              type="text"
              {...register("activity")}
              className={errors.activity ? "is-invalid" : ""}
              onChange={(e) => {
                const value = e.target.value;
                const truncatedValue = value.slice(0, 40);
                e.target.value = truncatedValue;
                setValue("activity", truncatedValue);
              }}
            />
            {errors.activity && (
              <Form.Control.Feedback type="invalid">
                {errors.activity.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="adresseBeneficiaire">
            <Form.Label>Adresse</Form.Label>
            <Form.Control
              type="text"
              {...register("adresse")}
              className={errors.adresse ? "is-invalid" : ""}
              onChange={(e) => {
                const value = e.target.value;
                const truncatedValue = value.slice(0, 72);
                e.target.value = truncatedValue;
                setValue("adresse", truncatedValue);
              }}
            />
            {errors.adresse && (
              <Form.Control.Feedback type="invalid">
                {errors.adresse.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="dateNaissance">
            <Form.Label>Date de Naissance</Form.Label>
            <Form.Control
              type="date"
              {...register("dateNaissance")}
              className={errors.dateNaissance ? "is-invalid" : ""}
            />
            {errors.dateNaissance && (
              <Form.Control.Feedback type="invalid">
                {errors.dateNaissance.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="adresseMail">
            <Form.Label>Adresse e-mail</Form.Label>
            <Form.Control
              type="email"
              {...register("adresseMail")}
              className={errors.adresseMail ? "is-invalid" : ""}
              onChange={(e) => {
                const value = e.target.value;
                setValue("adresseMail", value);
              }}
            />
            {errors.adresseMail && (
              <Form.Control.Feedback type="invalid">
                {errors.adresseMail.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="numTel">
            <Form.Label>Numéro de téléphone</Form.Label>
            <Form.Control
              type="tel"
              {...register("numTel")}
              className={errors.numTel ? "is-invalid" : ""}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/, "");
                setValue("numTel", value);
              }}
            />
            {errors.numTel && (
              <Form.Control.Feedback type="invalid">
                {errors.numTel.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>

      <Button
        type="submit"
        style={{ float: "right", marginTop: "10px" }}
        variant="success"
      >
        {initialData ? "Modifier" : "Enregistrer"}
      </Button>
    </Form>
  );
};

export default BeneficiaireForm;
