import React, { useState, ChangeEvent } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { generateAnnexe1File } from "../Annexe1/Annexe1File";
import { generateAnnexe2File } from "../Annexe2/Annexe2File";
import { generateAnnexe3File } from "../Annexe3/Annexe3File";
import { generateAnnexe4File } from "../Annexe4/Annexe4File";
import { generateAnnexe5File } from "../Annexe5/Annexe5File";
import { generateAnnexe6File } from "../Annexe6/Annexe6File";
import { generateAnnexe7File } from "../Annexe7/Annexe7File";
import { generateRecapFile } from "../Recap/RecapFile";
import styled from "styled-components";

const StyledFormCheckLabel = styled.span<{ isDisabled: boolean }>`
  text-decoration: ${({ isDisabled }) =>
    isDisabled ? "line-through" : "none"};
  color: ${({ isDisabled }) => (isDisabled ? "red" : "black")};
`;

interface AnnexesFileProps {
  currentDeclaration: any;
  currentExercice: any;
  currentActe: any;
  currentSociete: any;
  annexe1List: any[];
  annexe2List: any[];
  annexe3List: any[];
  annexe4List: any[];
  annexe5List: any[];
  annexe7List: any[];
  annexe6List: any[];
  recapList: any[];
}

const AnnexesFile: React.FC<AnnexesFileProps> = ({
  currentDeclaration,
  currentExercice,
  currentActe,
  currentSociete,
  annexe1List,
  annexe2List,
  annexe3List,
  annexe4List,
  annexe5List,
  annexe6List,
  annexe7List,
  recapList,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [generateAnnexe1, setGenerateAnnexe1] = useState<boolean>(false);
  const [generateAnnexe2, setGenerateAnnexe2] = useState<boolean>(false);
  const [generateAnnexe3, setGenerateAnnexe3] = useState<boolean>(false);
  const [generateAnnexe4, setGenerateAnnexe4] = useState<boolean>(false);
  const [generateAnnexe5, setGenerateAnnexe5] = useState<boolean>(false);
  const [generateAnnexe6, setGenerateAnnexe6] = useState<boolean>(false);
  const [generateAnnexe7, setGenerateAnnexe7] = useState<boolean>(false);
  const [generateRecap, setGenerateRecap] = useState<boolean>(false);

  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

  const handleGenerateRecapChange = (e: ChangeEvent<HTMLInputElement>) => {
    const code49Item = recapList.find(
      (item) => item.code === 49 && item.assiete === 0
    );
    if (code49Item) {
      setShowWarningModal(true);
      e.preventDefault();
    } else {
      setGenerateRecap(e.target.checked);
    }
  };

  const resetCheckboxes = () => {
    setGenerateAnnexe1(false);
    setGenerateAnnexe2(false);
    setGenerateAnnexe3(false);
    setGenerateAnnexe4(false);
    setGenerateAnnexe5(false);
    setGenerateAnnexe6(false);
    setGenerateAnnexe7(false);
    setGenerateRecap(false);
  };

  const handleCloseModal = () => {
    resetCheckboxes(); 
    setShowModal(false); 
  };


  const handleConfirmButtonClick = () => {
handleCloseModal();
    if (generateAnnexe1) {
      generateAnnexe1File(
        currentDeclaration,
        currentExercice,
        currentActe,
        currentSociete,
        annexe1List
      );
    }
    if (generateAnnexe2) {
      generateAnnexe2File(
        currentDeclaration,
        currentExercice,
        currentActe,
        currentSociete,
        annexe2List
      );
    }
    if (generateAnnexe3) {
      generateAnnexe3File(
        currentDeclaration,
        currentExercice,
        currentActe,
        currentSociete,
        annexe3List
      );
    }
    if (generateAnnexe4) {
      generateAnnexe4File(
        currentDeclaration,
        currentExercice,
        currentActe,
        currentSociete,
        annexe4List
      );
    }
    if (generateAnnexe5) {
      generateAnnexe5File(
        currentDeclaration,
        currentExercice,
        currentActe,
        currentSociete,
        annexe5List
      );
    }
    if (generateAnnexe6) {
      generateAnnexe6File(
        currentDeclaration,
        currentExercice,
        currentActe,
        currentSociete,
        annexe6List
      );
    }
    if (generateAnnexe7) {
      generateAnnexe7File(
        currentDeclaration,
        currentExercice,
        currentActe,
        currentSociete,
        annexe7List
      );
    }
    if (generateRecap) {
      generateRecapFile(
        currentDeclaration,
        currentExercice,
        currentActe,
        currentSociete,
        recapList
      );
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <Button variant="light" onClick={() => setShowModal(true)}>
          Générer fichiers
          <i className="fas fa-file-alt" style={{ marginLeft: "5px" }}></i>
        </Button>
      </div>
      <Modal show={showModal} size="lg" >
        <Modal.Header closeButton onClick={handleCloseModal}>
          <Modal.Title>Choisissez les fichiers à exporter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Form.Group as={Col} sm={6}>
                <Form.Check
                  type="checkbox"
                  label={
                    <StyledFormCheckLabel isDisabled={annexe1List.length === 0}>
                      Générer fichier annexe 1
                    </StyledFormCheckLabel>
                  }
                  disabled={annexe1List.length === 0}
                  checked={generateAnnexe1}
                  onChange={(e) => setGenerateAnnexe1(e.target.checked)}
                />
              </Form.Group>

              <Form.Group as={Col} sm={6}>
                <Form.Check
                  type="checkbox"
                  label={
                    <StyledFormCheckLabel isDisabled={annexe2List.length === 0}>
                      Générer fichier annexe 2
                    </StyledFormCheckLabel>
                  }
                  disabled={annexe2List.length === 0}
                  checked={generateAnnexe2}
                  onChange={(e) => setGenerateAnnexe2(e.target.checked)}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} sm={6}>
                <Form.Check
                  type="checkbox"
                  label={
                    <StyledFormCheckLabel isDisabled={annexe3List.length === 0}>
                      Générer fichier annexe 3
                    </StyledFormCheckLabel>
                  }
                  disabled={annexe3List.length === 0}
                  checked={generateAnnexe3}
                  onChange={(e) => setGenerateAnnexe3(e.target.checked)}
                />
              </Form.Group>
              <Form.Group as={Col} sm={6}>
                <Form.Check
                  type="checkbox"
                  label={
                    <StyledFormCheckLabel isDisabled={annexe4List.length === 0}>
                      Générer fichier annexe 4
                    </StyledFormCheckLabel>
                  }
                  disabled={annexe4List.length === 0}
                  checked={generateAnnexe4}
                  onChange={(e) => setGenerateAnnexe4(e.target.checked)}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} sm={6}>
                <Form.Check
                  type="checkbox"
                  label={
                    <StyledFormCheckLabel isDisabled={annexe5List.length === 0}>
                      Générer fichier annexe 5
                    </StyledFormCheckLabel>
                  }
                  disabled={annexe5List.length === 0}
                  checked={generateAnnexe5}
                  onChange={(e) => setGenerateAnnexe5(e.target.checked)}
                />
              </Form.Group>
              <Form.Group as={Col} sm={6}>
                <Form.Check
                  type="checkbox"
                  label={
                    <StyledFormCheckLabel isDisabled={annexe6List.length === 0}>
                      Générer fichier annexe 6
                    </StyledFormCheckLabel>
                  }
                  disabled={annexe6List.length === 0}
                  checked={generateAnnexe6}
                  onChange={(e) => setGenerateAnnexe6(e.target.checked)}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} sm={6}>
                <Form.Check
                  type="checkbox"
                  label={
                    <StyledFormCheckLabel isDisabled={annexe7List.length === 0}>
                      Générer fichier annexe 7
                    </StyledFormCheckLabel>
                  }
                  disabled={annexe7List.length === 0}
                  checked={generateAnnexe7}
                  onChange={(e) => setGenerateAnnexe7(e.target.checked)}
                />
              </Form.Group>

              <Form.Group as={Col} sm={6}>
                <Form.Check
                  type="checkbox"
                  label={
                    <span style={{ color: "black" }}>
                      Générer fichier récapitulatif
                    </span>
                  }
                  checked={generateRecap}
                  onChange={handleGenerateRecapChange}
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary"  onClick={handleCloseModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleConfirmButtonClick}>
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showWarningModal} onHide={() => setShowWarningModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'red' }}>Tableau récapitulatif non généré</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          Merci de générer votre tableau récapitulatif avant d'exporter le
          fichier.
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowWarningModal(false)}
          >
            fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AnnexesFile;
