import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  actesList,
  findAllActesApi,
} from "../../_App/Redux/Slices/acte/acteSlice";
import { updateDeclarationApi } from "../../_App/Redux/Slices/declaration/declarationSlice";
import {
  exercicesListByDeclarant,
  findExercicesByDeclarantApi,
} from "../../_App/Redux/Slices/exercices/exerciceSlice";
import {
  findAllSocietesApi,
  societesList,
} from "../../_App/Redux/Slices/societes/societeSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { ActeType } from "../../_App/Types/Entites/ActeType";
import { DeclarationType } from "../../_App/Types/Entites/DeclarationType";
import { ExerciceType } from "../../_App/Types/Entites/ExerciceType";
import { SocieteType } from "../../_App/Types/Entites/SocieteType";
import DeclarationSchema from "../../_App/Types/Forms/Schema/DeclarationSchema";

interface UpdateFormProps {
  declarationId: number;
  initialValues: DeclarationType;
  closeForm: () => void;
  declarantId?: number;
}

const UpdateForm: React.FC<UpdateFormProps> = ({
  declarantId,
  declarationId,
  initialValues,
  closeForm,
}) => {
  const dispatch = useAppDispatch();
  const societes = useAppSelector(societesList);
  const actes = useAppSelector(actesList);
  const exercices = useAppSelector(exercicesListByDeclarant);

  const {
    register,
    handleSubmit,
    reset,
    setValue,

    formState: { errors },
  } = useForm<DeclarationType>({
    resolver: yupResolver(DeclarationSchema),
    defaultValues: initialValues,
  });

  useEffect(() => {
    reset(initialValues);
  }, [reset, initialValues]);

  useEffect(() => {
    dispatch(findAllSocietesApi(null));
    dispatch(findAllActesApi(null));
  }, [dispatch]);

  useEffect(() => {
    if (declarantId !== undefined) {
      dispatch(findExercicesByDeclarantApi(declarantId));
    }
  }, [declarantId, dispatch]);

  const onSubmit: SubmitHandler<DeclarationType> = async (data) => {
    try {
      await DeclarationSchema.validate(data);
      const response = await dispatch(
        updateDeclarationApi({ id: declarationId, data })
      );
      if (response.payload.success) {
        ToastSuccess(response.payload.message);
      } else {
        ToastWarning(response.payload.message);
      }
      closeForm();
    } catch (error) {
      console.error("Validation failed", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col>
          <Form.Group controlId="derniereDateModification">
            <Form.Label>N° Declaration</Form.Label>
            <p>{initialValues.num}</p>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="date">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              {...register("date")}
              className={errors.date ? "is-invalid" : ""}
              value={
                initialValues.date
                  ? new Date(initialValues.date).toISOString().split("T")[0]
                  : ""
              }
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="declarant">
            <Form.Label>Société</Form.Label>
            <Form.Control
              as="select"
              {...register("declarant")}
              className={errors.declarant ? "is-invalid" : ""}
            >
              {societes.map((societe: SocieteType) => (
                <option key={societe.id} value={societe.id}>
                  {societe.name}
                </option>
              ))}
            </Form.Control>
            {errors.declarant && (
              <Form.Control.Feedback type="invalid">
                {errors.declarant.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="exercice">
            <Form.Label>Exercice</Form.Label>
            <Form.Control
              as="select"
              {...register("exercice")}
              className={errors.exercice ? "is-invalid" : ""}
            >
              {exercices.map((exercice: ExerciceType) => (
                <option key={exercice.id} value={exercice.id}>
                  {exercice.libelle}
                </option>
              ))}
            </Form.Control>
            {errors.exercice && (
              <Form.Control.Feedback type="invalid">
                {errors.exercice.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={5}>
          <Card
            style={{ marginTop: "20px", height: "170px", marginBottom: "20px" }}
          >
            <Card.Body>
              <Card.Title>Acte</Card.Title>
              <Form.Group controlId="acte">
                {actes.map((acte: ActeType) => (
                  <Form.Check
                    key={acte.id}
                    type="radio"
                    label={acte.libelle}
                    value={acte.id ? acte.id.toString() : ""}
                    {...register("acte")}
                    defaultChecked={initialValues.acte === (acte.id ?? null)}
                  />
                ))}
                {errors.acte && (
                  <Form.Control.Feedback type="invalid">
                    {errors.acte.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={7}>
          <Card style={{ marginTop: "20px" }}>
            <Card.Body>
              <Card.Title>Annexes à déposer</Card.Title>
              <Row>
                <Col xs={4}>
                  <Form.Group controlId="annexe1">
                    <Form.Check
                      type="checkbox"
                      label="Annexe 1"
                      {...register("annexe1")}
                      defaultChecked={initialValues.annexe1}
                      className={errors.annexe1 ? "is-invalid" : ""}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Annexe 2"
                      {...register("annexe2")}
                      defaultChecked={initialValues.annexe2}
                      className={errors.annexe2 ? "is-invalid" : ""}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Annexe 3"
                      {...register("annexe3")}
                      defaultChecked={initialValues.annexe3}
                      className={errors.annexe3 ? "is-invalid" : ""}
                    />
                  </Form.Group>
                </Col>
                <Col xs={4}>
                  <Form.Group controlId="annexe4">
                    <Form.Check
                      type="checkbox"
                      label="Annexe 4"
                      {...register("annexe4")}
                      defaultChecked={initialValues.annexe4}
                      className={errors.annexe4 ? "is-invalid" : ""}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Annexe 5"
                      {...register("annexe5")}
                      defaultChecked={initialValues.annexe5}
                      className={errors.annexe5 ? "is-invalid" : ""}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Annexe 6"
                      {...register("annexe6")}
                      defaultChecked={initialValues.annexe6}
                      className={errors.annexe6 ? "is-invalid" : ""}
                    />
                  </Form.Group>
                </Col>
                <Col xs={4}>
                  <Form.Group controlId="annexe7">
                    <Form.Check
                      type="checkbox"
                      label="Annexe 7"
                      {...register("annexe7")}
                      defaultChecked={initialValues.annexe7}
                      className={errors.annexe7 ? "is-invalid" : ""}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="derniereDateModification">
            <Form.Label>Dernière Date de Modification</Form.Label>
            <p>
              {initialValues.derniereDateModification
                ? new Date(
                    initialValues.derniereDateModification
                  ).toLocaleDateString()
                : "Aucune date de modification disponible"}
            </p>
          </Form.Group>
        </Col>
      </Row>

      <Button
        type="submit"
        variant="success"
        style={{ float: "right", marginTop: "15px" }}
      >
        Enregistrer{" "}
      </Button>
    </Form>
  );
};

export default UpdateForm;
