import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  declarationBySocieteList,
  deleteDeclarationApi,
  findDeclarationsByDeclarantApi,
} from "../../_App/Redux/Slices/declaration/declarationSlice";
import {
  exercicesListByDeclarant,
  findExercicesByDeclarantApi,
} from "../../_App/Redux/Slices/exercices/exerciceSlice";
import { deleteRecapsByDeclarationApi } from "../../_App/Redux/Slices/recap/recapSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { DeclarationType } from "../../_App/Types/Entites/DeclarationType";
import RecapParamForm from "../RecapParam/RecapParam";
import DeclarationForm from "./DeclarationForm";
import UpdateForm from "./UpdateForm";

interface DeclarationListProps {
  declarantId?: number;
}

const DeclarationList: React.FC<DeclarationListProps> = ({ declarantId }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const declarations = useAppSelector(declarationBySocieteList);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showRecapParamForm, setShowRecapParamForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedDeclarationId, setSelectedDeclarationId] = useState<
    number | null
  >(null);
  const [deleteDeclarationId, setDeleteDeclarationId] = useState<number | null>(
    null
  );
  const [selectedDeclaration, setSelectedDeclaration] =
    useState<DeclarationType | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (declarantId) {
      dispatch(findDeclarationsByDeclarantApi(declarantId));
      dispatch(findExercicesByDeclarantApi(declarantId));
    }
  }, [dispatch, declarantId]);

  const openCreateForm = () => {
    setShowCreateForm(true);
  };

  const closeCreateForm = () => {
    setShowCreateForm(false);
  };

  const handleDeclarationClick = (declarationId: any) => {
    navigate(`/annexes/${declarationId}`);
  };
  const exercices = useAppSelector(exercicesListByDeclarant);

  const handleRecapParamClick = (declarationId: number) => {
    setSelectedDeclarationId(declarationId);
    setShowRecapParamForm(true);
  };
  const handleUpdateClick = (declarationId: number) => {
    setSelectedDeclarationId(declarationId);
    const selected = declarations.find(
      (declaration) => declaration.id === declarationId
    );
    setSelectedDeclaration(selected ?? null);
    setShowUpdateForm(true);
  };

  const handleDeleteClick = (declarationId: number) => {
    setDeleteDeclarationId(declarationId);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (deleteDeclarationId) {
        const recapResponse = await dispatch(
          deleteRecapsByDeclarationApi(deleteDeclarationId)
        );

        if (recapResponse.payload) {
          const declarationResponse = await dispatch(
            deleteDeclarationApi(deleteDeclarationId)
          );

          if (declarationResponse.payload.success) {
            ToastSuccess(declarationResponse.payload.message);
          } else {
            ToastWarning(declarationResponse.payload.message);
          }

          setShowDeleteModal(false);
        } else {
          ToastWarning(recapResponse.payload.message);
        }
      }
    } catch (error) {
      console.error("Erreur lors de la suppression de la déclaration :", error);
      ToastWarning("Erreur lors de la suppression de la déclaration.");
    }
  };

  const getExerciceLabel = (exerciceId: number): string => {
    const foundExercice = exercices.find(
      (exercice: any) => exercice.id === exerciceId
    );
    return foundExercice ? foundExercice.libelle : "";
  };

  const handleEnteteCertificatClick = (exerciceId: number, declarationId: number) => {
    navigate(`/listCertificats/${declarantId}/${exerciceId}/${declarationId}`);
  };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="success" onClick={openCreateForm}>
          Créer une déclaration
        </Button>
      </div>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Num</th>
            <th>Date</th>
            <th>Exercice</th>
            <th>Certificat des retenues </th>
            <th>Paramétrage </th>
          </tr>
        </thead>
        <tbody>
          {declarations &&
            declarations.map((declaration, index) => (
              <tr
                key={index}
                onClick={() => handleDeclarationClick(declaration.id)}
                style={{ cursor: "pointer" }}
              >
                <td>{declaration.num}</td>
                <td>{new Date(declaration.date).toLocaleDateString()}</td>
                <td>{getExerciceLabel(declaration.exercice)}</td>
                <td>
                  <span
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                    title="Créer un nouveau certificat de retenue"
                  >
                    <Link
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      to={`/certificatRetenue/${declaration.exercice}/${declarantId}/${declaration.id}`}
                    >
                      <i
                        className="fas fa-plus"
                        style={{
                          marginLeft: "15px",
                          marginRight: "15px",
                          color: "green",
                          fontSize: "25px",
                        }}
                      />
                    </Link>
                  </span>
    <span
      onClick={(e) => {
        e.stopPropagation();
        handleEnteteCertificatClick(declaration.exercice, declaration.id || 0);
      }}
      style={{ cursor: "pointer", color: "blue" }}
      title="Consulter la liste des certificats"
    >
      <i
        className="fas fa-list"
        style={{ marginLeft: "15px", fontSize: "25px" }}
      ></i>
    </span>
  
                </td>

                <td style={{ textAlign: "left" }}>
                  <span
                    style={{
                      fontSize: "20px",
                      color: "black",
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRecapParamClick(declaration.id ?? 0);
                    }}
                  >
                    <i className="fas fa-wrench"></i>{" "}
                  </span>
                  <span
                    style={{ width: "10px", display: "inline-block" }}
                  ></span>{" "}
                  <span
                    style={{
                      fontSize: "20px",
                      color: "blue",
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      declaration.id && handleUpdateClick(declaration.id);
                    }}
                  >
                    <i className="fas fa-edit"></i>
                  </span>
                  <span
                    style={{ width: "10px", display: "inline-block" }}
                  ></span>{" "}
                  <span
                    className="action-icon danger"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteClick(declaration.id ?? 0);
                    }}
                    title="Supprimer la déclaration"
                  >
                    <i
                      className="far fa-trash-alt"
                      style={{
                        color: "#ff0000",
                        marginRight: "13px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    ></i>
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      {showCreateForm && (
        <Modal show={true} size="lg">
          <Modal.Header closeButton onClick={closeCreateForm}>
            <Modal.Title>Créer une nouvelle déclaration</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DeclarationForm
              closeForm={closeCreateForm}
              declarantId={declarantId}
            />
          </Modal.Body>
        </Modal>
      )}

      {showRecapParamForm && (
        <Modal show={true} size="xl">
          <Modal.Header
            closeButton
            onClick={() => setShowRecapParamForm(false)}
          >
            <Modal.Title>Paramétrage Recap</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <RecapParamForm
              declarationId={
                selectedDeclarationId !== null ? selectedDeclarationId : 0
              }
            />
          </Modal.Body>
        </Modal>
      )}
      {showUpdateForm && selectedDeclaration && (
        <Modal show={true} size="lg">
          <Modal.Header closeButton onClick={() => setShowUpdateForm(false)}>
            <Modal.Title>Modifier la déclaration</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UpdateForm
              closeForm={() => setShowUpdateForm(false)}
              declarationId={selectedDeclarationId ?? 0}
              initialValues={selectedDeclaration}
              declarantId={declarantId}
            />
          </Modal.Body>
        </Modal>
      )}

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cette déclaration ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeclarationList;
