import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AdditionalTaxType } from "../../../Types/Entites/AdditionalTaxType";
import { RootState } from "../../store";

export const createAdditionalTaxApi = createAsyncThunk(
  "additionalTax/createAdditionalTax",
  async (data: AdditionalTaxType, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "additionalTaxe/createAdditionalTax",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const findAllAdditionalTaxesApi = createAsyncThunk(
  "additionalTax/findAllAdditionalTaxes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("additionalTaxe/findAllAdditionalTaxes");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const updateAdditionalTaxApi = createAsyncThunk(
  "additionalTax/updateAdditionalTax",
  async (
    { id, data }: { id: number; data: AdditionalTaxType },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(
        `additionalTaxe/updateAdditionalTax/${id}`,
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

export const deleteAdditionalTaxApi = createAsyncThunk(
  "additionalTax/deleteAdditionalTax",
  async (id: number, { rejectWithValue }) => {
    try {
      await axios.delete(`additionalTaxe/deleteAdditionalTax/${id}`);
      return id;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "Erreur inconnue de l'API",
      });
    }
  }
);

interface AdditionalTaxState {
  additionalTaxList: AdditionalTaxType[];
}

const initialState: AdditionalTaxState = {
  additionalTaxList: [],
};

export const additionalTaxSlice = createSlice({
  name: "additionalTax",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAdditionalTaxApi.fulfilled, (state, action) => {
      state.additionalTaxList.push(action.payload);
    });
    builder.addCase(findAllAdditionalTaxesApi.fulfilled, (state, action) => {
      state.additionalTaxList = action.payload;
    });
    builder.addCase(updateAdditionalTaxApi.fulfilled, (state, action) => {
      const updatedAdditionalTax = action.payload;
      const index = state.additionalTaxList.findIndex(
        (tax) => tax.id === updatedAdditionalTax.id
      );
      if (index !== -1) {
        state.additionalTaxList[index] = updatedAdditionalTax;
      }
    });
    builder.addCase(deleteAdditionalTaxApi.fulfilled, (state, action) => {
      const id = action.payload;
      state.additionalTaxList = state.additionalTaxList.filter(
        (tax) => tax.id !== id
      );
    });
  },
});

export const {} = additionalTaxSlice.actions;
export const AdditionalTaxList = (state: RootState) =>
  state.additionalTax.additionalTaxList;

export default additionalTaxSlice.reducer;
